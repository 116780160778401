import { ICaseFileAccessRequest, ICaseFileInfo, ICaseFileNotApprove } from 'shared/modules/case-file-access-request';

export enum ECaseFileAccessRequestAction {
  GET_CASE_FILE_ACCESS_REQUEST_REQUEST = 'GET_CASE_FILE_ACCESS_REQUEST_REQUEST',
  GET_CASE_FILE_ACCESS_REQUEST_SUCCESS = 'GET_CASE_FILE_ACCESS_REQUEST_SUCCESS',
  GET_CASE_FILE_ACCESS_REQUEST_FAILED = 'GET_CASE_FILE_ACCESS_REQUEST_FAILED',
  CREATE_CASE_FILE_ACCESS_REQUEST_REQUEST = 'CREATE_CASE_FILE_ACCESS_REQUEST_REQUEST',
  CREATE_CASE_FILE_ACCESS_REQUEST_SUCCESS = 'CREATE_CASE_FILE_ACCESS_REQUEST_SUCCESS',
  CREATE_CASE_FILE_ACCESS_REQUEST_FAILED = 'CREATE_CASE_FILE_ACCESS_REQUEST_FAILED',
  UPDATE_CASE_FILE_ACCESS_REQUEST_REQUEST = 'UPDATE_CASE_FILE_ACCESS_REQUEST_REQUEST',
  UPDATE_CASE_FILE_ACCESS_REQUEST_SUCCESS = 'UPDATE_CASE_FILE_ACCESS_REQUEST_SUCCESS',
  UPDATE_CASE_FILE_ACCESS_REQUEST_FAILED = 'UPDATE_CASE_FILE_ACCESS_REQUEST_FAILED',
  GET_CASE_FILE_INFO_REQUEST = 'GET_CASE_FILE_INFO_REQUEST',
  GET_CASE_FILE_INFO_SUCCESS = 'GET_CASE_FILE_INFO_SUCCESS',
  GET_CASE_FILE_INFO_FAILED = 'GET_CASE_FILE_INFO_FAILED',
  GET_CASE_FILE_NOT_APPROVE_REQUEST = 'GET_CASE_FILE_NOT_APPROVE_REQUEST',
  GET_CASE_FILE_NOT_APPROVE_SUCCESS = 'GET_CASE_FILE_NOT_APPROVE_SUCCESS',
  GET_CASE_FILE_NOT_APPROVE_FAILED = 'GET_CASE_FILE_NOT_APPROVE_FAILED',
  CLEAR_CASE_FILE_ACCESS_REQUEST = 'CLEAR_CASE_FILE_ACCESS_REQUEST',
}

interface IGetCaseFileAccessRequestRequest {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_ACCESS_REQUEST_REQUEST;
}

interface IGetCaseFileAccessRequestSuccess {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_ACCESS_REQUEST_SUCCESS;
  data: ICaseFileAccessRequest;
}

interface IGetCaseFileAccessRequestFailed {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_ACCESS_REQUEST_FAILED;
  error: Error;
}

interface ICreateCaseFileAccessRequestRequest {
  type: ECaseFileAccessRequestAction.CREATE_CASE_FILE_ACCESS_REQUEST_REQUEST;
}

interface ICreateCaseFileAccessRequestSuccess {
  type: ECaseFileAccessRequestAction.CREATE_CASE_FILE_ACCESS_REQUEST_SUCCESS;
}

interface ICreateCaseFileAccessRequestFailed {
  type: ECaseFileAccessRequestAction.CREATE_CASE_FILE_ACCESS_REQUEST_FAILED;
  error: Error;
}

interface IUpdateCaseFileAccessRequestRequest {
  type: ECaseFileAccessRequestAction.UPDATE_CASE_FILE_ACCESS_REQUEST_REQUEST;
}

interface IUpdateCaseFileAccessRequestSuccess {
  type: ECaseFileAccessRequestAction.UPDATE_CASE_FILE_ACCESS_REQUEST_SUCCESS;
}

interface IUpdateCaseFileAccessRequestFailed {
  type: ECaseFileAccessRequestAction.UPDATE_CASE_FILE_ACCESS_REQUEST_FAILED;
  error: Error;
}

interface ICaseFileInfoRequest {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_INFO_REQUEST;
}

interface ICaseFileInfoSuccess {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_INFO_SUCCESS;
  data: ICaseFileInfo;
}

interface ICaseFileInfoFailed {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_INFO_FAILED;
  error: Error;
}

interface ICaseFileNotApproveRequest {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_NOT_APPROVE_REQUEST;
}

interface ICaseFileNotApproveSuccess {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_NOT_APPROVE_SUCCESS;
  data: ICaseFileNotApprove;
}

interface ICaseFileNotApprovFailed {
  type: ECaseFileAccessRequestAction.GET_CASE_FILE_NOT_APPROVE_FAILED;
  error: Error;
}

interface IClearCaseFileAccessRequest {
  type: ECaseFileAccessRequestAction.CLEAR_CASE_FILE_ACCESS_REQUEST;
}

export type TCaseFileAccessRequestActionTypes =
  | IGetCaseFileAccessRequestRequest
  | IGetCaseFileAccessRequestSuccess
  | IGetCaseFileAccessRequestFailed
  | ICreateCaseFileAccessRequestRequest
  | ICreateCaseFileAccessRequestSuccess
  | ICreateCaseFileAccessRequestFailed
  | IUpdateCaseFileAccessRequestRequest
  | IUpdateCaseFileAccessRequestSuccess
  | IUpdateCaseFileAccessRequestFailed
  | ICaseFileInfoRequest
  | ICaseFileInfoSuccess
  | ICaseFileInfoFailed
  | ICaseFileNotApproveRequest
  | ICaseFileNotApproveSuccess
  | ICaseFileNotApprovFailed
  | IClearCaseFileAccessRequest;
