import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import { INITIAL_RECIVE_PHONE_STATE, IReportRecivePhone, IReportRecivePhoneState } from 'shared/modules/recive-phone';
import { EReciveReportAction, TReportRecivePhone } from './recive-phone.type';

export default (
  state: IReportRecivePhoneState = INITIAL_RECIVE_PHONE_STATE,
  action: TReportRecivePhone
): IReportRecivePhoneState => {
  switch (action.type) {
    case EReciveReportAction.SEARCH_RECIVE_PHONE_REQUEST:
      return {
        ...state,
        isTableLoading: true,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case EReciveReportAction.SEARCH_RECIVE_PHONE_SUCCESS:
      return {
        ...state,
        all: action.data,
        isTableLoading: false,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.SEARCH_RECIVE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isTableLoading: false,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CREATE_RECIVE_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.CREATE_RECIVE_PHONE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CREATE_RECIVE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_RECIVE_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.GET_RECIVE_PHONE_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_RECIVE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.UPDATE_RECIVE_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.UPDATE_RECIVE_PHONE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.UPDATE_RECIVE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.DELETE_RECIVE_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.DELETE_RECIVE_PHONE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.DELETE_RECIVE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CLEAR_SELECTED_RECIVE_PHONE:
      return {
        ...state,
        selected: {} as IReportRecivePhone,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_SUCCESS:
      return {
        ...state,
        fileUploadPhone: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CLEAR_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE:
      return {
        ...state,
        fileUploadPhone: INITIAL_DATA_TABLE_TYPE,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
