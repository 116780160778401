import { Menu as MaterialMenu, MenuItem } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { Notifications as NotificationIcon } from '@material-ui/icons';
import { HttpTransportType, HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import _ from 'lodash';
import React, { FC, forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from 'shared/config/app.env';
import { INotificationList, IReceiveNotification } from 'shared/modules/notification';
import { IReducer } from 'store';
import { getNotificationCount, getNotificationList, viewNotification } from 'store/notification/notification.action';
import { getUserInfo, IUser } from 'store/user';
import styled from 'styled-components';
import { ENotificationStatusColor } from './enum/NotificationStatus.enum';

const StyledNotification = styled.div`
  padding-right: 1em;
`;

// const StyledBadge = styled(Badge)`
const StyledBadge = styled.div`
  & .MuiBadge-badge {
    background-color: red;
    top: 8px;
    right: 8px;
    color: white;
  }
`;

const TextNotification = styled.label`
  padding-right: 1em;
  cursor: pointer;
  text-overflow: ellipsis;
  max-width: 420px;
  display: inline;
  overflow-x: hidden;
  @media only screen and (max-width: 767px) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MenuContainer = styled.div`
  height: 50vh;
`;

const Menu = styled(MaterialMenu)<{ display: number }>`
  ${MenuContainer} {
    overflow-y: scroll !important;
  }
  div {
    overflow-y: hidden !important;
  }
  display: ${(props) => (props.display ? 'block' : 'none')};
`;

const MoreMenuButton = styled.div<{ cursor: number }>`
  text-align: center;
  cursor: ${(props) => (props.cursor ? 'pointer' : 'default')};
`;

interface ComponentProps {
  backgroundColor: string;
  detail: string;
  onClick();
}

const useStyles = makeStyles({
  root: {
    border: 'solid 1px #0b2545',
  },
  list: {
    padding: 0,
  },
});

const useStylesWithItem = makeStyles({
  root: {
    backgroundColor: (props: ComponentProps) => props.backgroundColor,
    margin: 5,
    alignContent: 'center',
    placeContent: 'space-between',
  },
});

const NotificationItem: ForwardRefExoticComponent<ComponentProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  ComponentProps
>((props, ref) => {
  const classes = useStylesWithItem(props);

  return (
    <div ref={ref}>
      <MenuItem classes={{ root: classes.root }} onClick={props.onClick}>
        <TextNotification>{props.detail}</TextNotification>
      </MenuItem>
    </div>
  );
});

const Notification: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const store = useSelector((o: IReducer) => o.notification);
  const notificationList: INotificationList[] | [] = store.all.table;
  const [haseNext, setHasNext] = useState<boolean>(false);
  const user: IUser = useSelector((o: IReducer) => o.user.me);
  const [noti, setNoti] = useState<INotificationList[]>([]);
  const [page, setPage] = useState<number>(1);
  const itemRef = useRef<HTMLDivElement>(null);
  const isAuthenticate = useSelector((store: IReducer) => store.authenticate.isAuthenticate);

  // FIX: reduce call API
  // useEffect(() => {
  //   dispatch(getUserInfo());
  // }, [dispatch]);

  useEffect(() => {
    if (isAuthenticate) dispatch(getNotificationCount());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(user) && false) {
      const signalRHeader: IHttpConnectionOptions = {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'websocket',
        },
      };
      const connect: HubConnection = new HubConnectionBuilder()
        .withUrl(`${config.BASE_API_URL}/notificationhub`, signalRHeader)
        .withAutomaticReconnect()
        .build();

      connect
        .start()
        .then(() =>
          connect.on('ReceiveNotification', (notification: IReceiveNotification) => {
            if (user?.id && notification.userID === user.id) dispatch(getNotificationCount());
          })
        )
        .catch((error: Error) => console.error(error));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(notificationList)) {
      setNoti(noti.concat(notificationList));
    }
    // eslint-disable-next-line
  }, [notificationList, setNoti]);

  useEffect(() => {
    setHasNext(store.all.hasNext);
  }, [store.all.hasNext]);

  useEffect(() => {
    if (menuOpen === null) {
      setNoti([]);
      setPage(1);
    }
  }, [menuOpen, setNoti, setPage]);

  const onShowNotification = (event) => () => {
    setMenuOpen(event);
    dispatch(getNotificationCount());
  };

  const onClickNotification = (event) => {
    dispatch(getNotificationList(page, 10, onShowNotification(event.currentTarget)));
  };

  const onCloseNotification = () => {
    setMenuOpen(null);
  };

  const onSelectNotification = (url: string, param?: string | number) => () => {
    if (param) history.push(`${url}/${param}`);
    else history.push(url);
  };

  const onView = (id: string, url: string, param?: string | number) => {
    dispatch(viewNotification(id, onSelectNotification(url, param)));
    const index: number = noti.findIndex((notification) => notification.notificationID === id);
    noti[index].isView = true;
  };

  const onMoreNotification = () => {
    const nextPage: number = page + 1;
    dispatch(getNotificationList(nextPage, 10));
    setPage(nextPage);
  };

  return (
    <StyledNotification>
      {/* <StyledBadge badgeContent={store.count} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}> */}
      <StyledBadge>
        <NotificationIcon style={{ color: 'white', fontSize: 40, cursor: 'pointer' }} onClick={onClickNotification} />
        <Menu
          classes={{ paper: classes.root, list: classes.list }}
          autoFocus={false}
          anchorEl={menuOpen}
          open={Boolean(menuOpen)}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={onCloseNotification}
          display={menuOpen === null ? 0 : 1}
        >
          <MenuContainer>
            {noti.map((notification, index) => (
              <NotificationItem
                ref={itemRef}
                key={index}
                backgroundColor={
                  notification?.isView ? ENotificationStatusColor.read : ENotificationStatusColor[notification?.status]
                }
                onClick={() => onView(notification?.notificationID, notification?.url, notification?.params)}
                detail={notification?.text}
              />
            ))}
            <MoreMenuButton cursor={haseNext && !store.isButtonLoading ? 1 : 0}>
              {haseNext && !store.isButtonLoading ? (
                <p onClick={onMoreNotification}>แจ้งเตือนเพิ่มเติม</p>
              ) : store.isButtonLoading ? (
                <p>...กำลังโหลด</p>
              ) : (
                <></>
              )}
            </MoreMenuButton>
          </MenuContainer>
        </Menu>
      </StyledBadge>
    </StyledNotification>
  );
};

export default Notification;
