import React, { FC } from 'react';
import TableContext from './TableContext';
import Pagination from '@material-ui/core/TablePagination';

const TablePagination: FC = () => (
  <TableContext.Consumer>
    {({ items, itemsPerPage, page, onChangePage, onChangeItemsPerPage, hidePage }) => (
      <>
        {!hidePage && (
          <Pagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component={'div'}
            count={Math.ceil(items.length / itemsPerPage)}
            rowsPerPage={itemsPerPage}
            page={page}
            // onChangePage={(_, newPage) => onChangePage(newPage)}
            onRowsPerPageChange={onChangeItemsPerPage}
            onPageChange={(_, page) => onChangePage(page)}
          // onChangePage={onChangePage}
          // onChangeRowsPerPage={onChangeItemsPerPage}
          // onPageChange={onChangePage}
          // onRowsPerPageChange={onChangeItemsPerPage}
          />
        )}
      </>
    )}
  </TableContext.Consumer>
);

export default TablePagination;
