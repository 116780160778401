import React, { FC } from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/logo.svg';
import { AppName } from './HeaderComponents';
import constants from 'shared/constants';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
`;

const UnAuthenticated: FC = () => {
  return (
    <HeaderWrapper>
      <AppIcon src={Logo} />
      <AppName />
    </HeaderWrapper>
  );
};

export default UnAuthenticated;
