import { TGraphActionTypes, EGraphAction } from 'store/graph/graph.type';
import { IGraphState, INITIAL_GRAPH_STATE, IGraph } from 'shared/modules/graph';

export default (state: IGraphState = INITIAL_GRAPH_STATE, action: TGraphActionTypes): IGraphState => {
  switch (action.type) {
    case EGraphAction.GET_GRAPH_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EGraphAction.GET_GRAPH_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case EGraphAction.GET_GRAPH_FAILED:
      return {
        ...state,
        selected: {} as IGraph,
        error: action.error,
        isPageLoading: false,
      };
    case EGraphAction.POST_LOG_GRAPH_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EGraphAction.POST_LOG_GRAPH_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case EGraphAction.POST_LOG_GRAPH_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
