import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useOrderStatusDropdownApi = () => {
  const [orderStatuses, isLoading, isError] = useFetchApi<IDropdown[]>('/orderStatus/getDropdownList', []);

  const orderStatusSelectOptions: SelectOption[] = orderStatuses.map((orderStatus) => {
    return {
      text: orderStatus.text,
      value: orderStatus.id,
    };
  });
  return [orderStatusSelectOptions, isLoading, isError] as const;
};
export default useOrderStatusDropdownApi;
