import { TInvestigation, EInvestigationAction } from './investigation.type';
import {
  IInvestigationState,
  INITIAL_INVESTIGATION_STATE,
  IInvestigation,
  IInvestigationList,
} from 'shared/modules/investigation';
import { TDataTable } from 'shared/types';

export default (
  state: IInvestigationState = INITIAL_INVESTIGATION_STATE,
  action: TInvestigation
): IInvestigationState => {
  switch (action.type) {
    case EInvestigationAction.SEARCH_INVESTIGATION_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationAction.SEARCH_INVESTIGATION_SUCCESS:
      return {
        ...state,
        all: action.data.dataTable,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
        yearDropdownList: action.data.yearDropdownList,
        monthDropdownList: action.data.monthDropdownList,
      };
    case EInvestigationAction.SEARCH_INVESTIGATION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAction.GET_INVESTIGATION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInvestigationAction.GET_INVESTIGATION_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.GET_INVESTIGATION_FAILED:
      return {
        ...state,
        selected: {} as IInvestigation,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.CREATE_INVESTIGATION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInvestigationAction.CREATE_INVESTIGATION_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.CREATE_INVESTIGATION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.UPDATE_INVESTIGATION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInvestigationAction.UPDATE_INVESTIGATION_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.UPDATE_INVESTIGATION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.DELETE_INVESTIGATION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInvestigationAction.DELETE_INVESTIGATION_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.DELETE_INVESTIGATION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.CLEAR_SELECT_INVESTIGATION:
      return {
        ...state,
        selected: {} as IInvestigation,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.GET_INVESTIGATION_BY_ID_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInvestigationAction.GET_INVESTIGATION_BY_ID_SUCCESS:
      return {
        ...state,
        selectedByCase: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.GET_INVESTIGATION_BY_ID_FAILED:
      return {
        ...state,
        all: {} as TDataTable<IInvestigationList>,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.GET_INVESTIGATION_ATTACHMENT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case EInvestigationAction.GET_INVESTIGATION_ATTACHMENT_SUCCESS:
      return {
        ...state,
        investigationAttachmentList: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInvestigationAction.GET_INVESTIGATION_ATTACHMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
