import { IDownloadFileResponse, IUploadFileRequest, IUploadFileResponse } from '../interfaces/api.interface';
import axios from './axios';

export const uploadFile = async (data: IUploadFileRequest): Promise<IUploadFileResponse[]> => {
  const formData = new FormData();

  formData.set('routeName', data.routeName);

  data.attachments.forEach((attachment) => {
    formData.append('attachments', attachment.file, attachment.fileName);
  });

  try {
    const response = await axios.post('/attachment/uploadFile', formData, {
      // const response = await axios.post(``, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFileUnauth = async (data: IUploadFileRequest): Promise<IUploadFileResponse[]> => {
  const formData = new FormData();

  formData.set('routeName', data.routeName);

  data.attachments.forEach((attachment) => {
    formData.append('attachments', attachment.file, attachment.fileName);
  });

  try {
    const response = await axios.post('/attachment/other/uploadFile', formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const dataToParam = (requestData: any) => {
  const urlParams =
    '?' +
    Object.keys(requestData)
      .map((k) => k + '=' + requestData[k])
      .join('&');
  return urlParams;
};

export const downloadFile = async (id: string): Promise<IDownloadFileResponse> => {
  try {
    const response = await axios.get(`/BaseUpload/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
