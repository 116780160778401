import { IconButton } from '@material-ui/core';
import { Button } from 'components/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { LocalStorageKey } from 'store/authenticate';
import styled from 'styled-components';
import { RestorePageIconStyle, RoleSettingPageIconStyle } from './styled';

interface IClearCacheProps {}

const ClearCache: React.FC<IClearCacheProps> = (props) => {
  const {} = props;
  const { t } = useTranslation('main');
  const history = useHistory();

  const onClearCache = () => {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    const expire = localStorage.getItem(LocalStorageKey.EXPIRES);
    reactLocalStorage.clear();
    localStorage.setItem(LocalStorageKey.TOKEN, token || '');
    localStorage.setItem(LocalStorageKey.EXPIRES, expire || '');
    // eslint-disable-next-line no-console
    window.location.reload();
  };

  const onRoleSetting = () => {
    history.push(`/role-setting`);
  };

  const goTo = (val) => () => {
    history.push(val);
  };

  return (
    <>
      <IconButton onClick={onClearCache}>
        <RestorePageIconStyle color="error" />
      </IconButton>
      <IconButton onClick={onRoleSetting}>
        <RoleSettingPageIconStyle color="error" />
      </IconButton>
      {/* <Button onClick={goTo('linkageNSB')}>{'L'}</Button> */}
    </>
  );
};

export default ClearCache;
