import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IAttachment, IUploadFileResponse } from 'shared/interfaces';
import { TDataTable } from 'shared/types';
import {
  ICaseFileFolderAllReportGetResponse,
  ICaseFileFolderAllReportSearch,
  ICaseFileFolderArrestGetResponse,
  ICaseFileFolderArrestSearch,
  ICaseFileFolderConfiscateSearch,
  ICaseFileFolderInvestigationReportGetResponse,
  ICaseFileFolderInvestigationReportSearch,
  ICaseFileFolderSearchingReportGetResponse,
  ICaseFileFolderSearchingReportSearch,
} from '.';
import {
  ICaseFileFolderConfiscateGetResponse,
  ICaseFileForm,
  ICaseFileFormInfo,
  ICaseFileFormTable,
  ICaseFileOfficerForm,
  ICaseFileSearch,
  ICaseFileSearchValidate,
  ICaseFileState,
  ICreateCaseFileValidate,
} from './case-file.interface';
import { TOfficerTableData } from './case-file.type';

export const CLOSE_CASE_STATUS_ID = 5;

export const INITIAL_CASE_FILE_SEARCH: ICaseFileSearch = {
  caseName: '',
  caseID: '',
  caseStatusID: [],
  detail: '',
  endDate: null,
  createdBy: '',
  preciseSearch: '',
  startDate: null,
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  _caseFileID: null,
  CreateDateFrom: null,
  CreateDateTo: null,
  //
};

export const INITIAL_CASE_FILE_FORM_INFO: ICaseFileFormInfo = {
  detail: '',
};

export const INITIAL_CASE_FILE_FORM: ICaseFileForm = {
  area: '',
  caseFileRelateds: [],
  caseID: '',
  caseName: '',
  caseStatusID: null,
  detail: '',
  info: '',
  officerLeadList: [],
  officerReportList: [],
  suspects: [],
  caseEndDate: null,
  caseStartDate: null,
  interestingThing: '',
  objective: '',
  suggestion: '',
  investigateResults: [],
  isEdit: null,
  prefixID: null,
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  passportNo: '',
  middleName: '',
  nickName: '',
  telephoneNo: '',
  operationReport: '',
  reporterRank: '',
  reporterName: '',
  reporterDepartment: '',
  leaderRank: '',
  leaderName: '',
  leaderDepartment: '',
  supervisor1_Rank: '',
  supervisor1_Name: '',
  supervisor1_Department: '',
  supervisor2_Rank: '',
  supervisor2_Name: '',
  supervisor2_Department: '',
  start_date: moment(),
  end_date: null,
  _infoList: [INITIAL_CASE_FILE_FORM_INFO],
  infoList: [''],
  id: '',
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  arrestDate: null,
  year: moment(),
  // _year: moment(),
};

export const INITIAL_CASE_FILE_FORM_TABLE: ICaseFileFormTable = {
  caseTableStartDate: moment(),
  caseTableEndDate: moment(),
};

export const INITIAL_CASE_FILE_OFFICER_FORM: ICaseFileOfficerForm = {
  divisionID: null,
  headQuarterID: null,
  organizationID: null,
  mobilePhoneNumber: '',
  officerCode: '',
  policeStationID: null,
  positionID: null,
  subDivisionID: null,
  departmentID: null,
};

export const INITIAL_CASE_FILE_FOLDER_ALL_REPORT_SEARCH: ICaseFileFolderAllReportSearch = {
  id: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SEARCH: ICaseFileFolderInvestigationReportSearch = {
  id: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_CASE_FILE_FOLDER_ARREST_SEARCH: ICaseFileFolderArrestSearch = {
  id: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_CASE_FILE_FOLDER_CONFISCATE_SEARCH: ICaseFileFolderConfiscateSearch = {
  id: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_CASE_FILE_FOLDER_SEARCHING_REPORT_SEARCH: ICaseFileFolderSearchingReportSearch = {
  id: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_CASE_FILE_STATE: ICaseFileState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {
    ...INITIAL_CASE_FILE_FORM,
    id: '',
  },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  user: { ...INITIAL_CASE_FILE_OFFICER_FORM },
  createdSuspectIds: [],
  investigations: { ...INITIAL_DATA_TABLE_TYPE },
  id: '',
  fileUrl: '',
  initialOfficer: {} as TOfficerTableData,
  allReports: {} as TDataTable<ICaseFileFolderAllReportGetResponse>,
  investigationReports: {} as TDataTable<ICaseFileFolderInvestigationReportGetResponse>,
  arrests: {} as TDataTable<ICaseFileFolderArrestGetResponse>,
  confiscates: {} as TDataTable<ICaseFileFolderConfiscateGetResponse>,
  searchingReports: {} as TDataTable<ICaseFileFolderSearchingReportGetResponse>,
};

export const INITIAL_CASE_FILE_SEARCH_VALIDATE: ICaseFileSearchValidate = {
  caseName: '',
  caseID: '',
  createdBy: '',
  preciseSearch: '',
};

export const INITIAL_CREATE_CASE_FILE_VALIDATE: ICreateCaseFileValidate = {
  caseID: '',
  caseName: '',
  caseStartDate: '',
  caseEndDate: '',
  // caseStatusID: '',
  // area: '',
  // detail: '',
  // info: '',
  // interestingThing: '',
  // objective: '',
  // suggestion: '',
  // prefixID: '',
};
