import React from 'react';

type TTableContext = {
  title?: string;
  columns: any;
  isLoading: boolean;
  page: number;
  items: object[];
  itemsCount: number;
  itemsPerPage: number;
  totalCount: number;
  itemsEmptyCount: number;
  selectedItems: string[];
  selectedItemsCount: number;
  spaceCount?: number;
  onGraph: any;
  onDownload: any;
  onUpload: any;
  onFile: any;
  onView: any;
  onEdit: any;
  onDelete: any;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  onModal?: any;
};

const TableContext = React.createContext({} as TTableContext);

export default TableContext;
