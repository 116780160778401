import { TGraphActionTypes, EGraphAction } from 'store/graph-data-sci/graph-data-sci.type';
import { IHeatmapState, INITIAL_HEATMAP_STATE, IHeatmapData } from 'shared/modules/graph-data-sci';

export default (state: IHeatmapState = INITIAL_HEATMAP_STATE, action: TGraphActionTypes): IHeatmapState => {
  switch (action.type) {
    case EGraphAction.GET_HEATMAP_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EGraphAction.GET_HEATMAP_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case EGraphAction.GET_HEATMAP_FAILED:
      return {
        ...state,
        selected: {} as IHeatmapData,
        error: action.error,
        isPageLoading: false,
      };
    // NOTE: get ds model heatmap
    case EGraphAction.GET_DS_MODEL_HEATMAP_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EGraphAction.GET_DS_MODEL_HEATMAP_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        selected: action.data,
      };
    case EGraphAction.GET_DS_MODEL_HEATMAP_FAILED:
      return {
        ...state,
        selected: {} as IHeatmapData,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
