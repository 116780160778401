import {
  IBankTrackingList,
  IBankTrackingSuspectForm,
  IBankTrackingAttachment,
  IBankTrackingFileResponse,
} from 'shared/modules/bank-tracking';
import { IGraph } from 'shared/modules/graph';
import { TDataTable } from 'shared/types';

export enum EBankTrackingAction {
  BANK_TRACKING_SEARCH_REQUEST = 'BANK_TRACKING_SEARCH_REQUEST',
  BANK_TRACKING_SEARCH_SUCESS = 'BANK_TRACKING_SEARCH_SUCESS',
  BANK_TRACKING_SEARCH_FAILED = 'BANK_TRACKING_SEARCH_FAILED',
  BANK_TRACKING_GET_REQUEST = 'BANK_TRACKING_GET_REQUEST',
  BANK_TRACKING_GET_SUCESS = 'BANK_TRACKING_GET_SUCESS',
  BANK_TRACKING_GET_FAILED = 'BANK_TRACKING_GET_FAILED',
  BANK_TRACKING_CREATE_REQUEST = 'BANK_TRACKING_CREATE_REQUEST',
  BANK_TRACKING_CREATE_SUCESS = 'BANK_TRACKING_CREATE_SUCESS',
  BANK_TRACKING_CREATE_FAILED = 'BANK_TRACKING_CREATE_FAILED',
  BANK_TRACKING_UPDATE_REQUEST = 'BANK_TRACKING_UPDATE_REQUEST',
  BANK_TRACKING_UPDATE_SUCESS = 'BANK_TRACKING_UPDATE_SUCESS',
  BANK_TRACKING_UPDATE_FAILED = 'BANK_TRACKING_UPDATE_FAILED',
  UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED',
  SUBMIT_FILE_REPONSE_REQUEST = 'SUBMIT_FILE_REPONSE_REQUEST',
  SUBMIT_FILE_REPONSE_SUCCESS = 'SUBMIT_FILE_REPONSE_SUCCESS',
  SUBMIT_FILE_REPONSE_FAILED = 'SUBMIT_FILE_REPONSE_FAILED',
  GET_FILE_RESPONSE_REQUEST = 'GET_FILE_RESPONSE_REQUEST',
  GET_FILE_RESPONSE_SUCCESS = 'GET_FILE_RESPONSE_SUCCESS',
  GET_FILE_RESPONSE_FAILED = 'GET_FILE_RESPONSE_FAILED',
  GET_GRAPH_REQUEST = 'GET_GRAPH_REQUEST',
  GET_GRAPH_SUCCESS = 'GET_GRAPH_SUCCESS',
  GET_GRAPH_FAILED = 'GET_GRAPH_FAILED',
  EXCEL_REQUEST = 'EXCEL_REQUEST',
  EXCEL_SUCCESS = 'EXCEL_SUCCESS',
  EXCEL_FAILED = 'EXCEL_FAILED',
  CLEAR_BANK_TRACKING_SELECT = 'CLEAR_BANK_TRACKING_SELECT',
  CLEAR_ATTACHMENT = 'CLEAR_ATTACHMENT',
}

interface IBankTrackingSearchRequest {
  type: EBankTrackingAction.BANK_TRACKING_SEARCH_REQUEST;
}

interface IBankTrackingSearchSucess {
  type: EBankTrackingAction.BANK_TRACKING_SEARCH_SUCESS;
  data: TDataTable<IBankTrackingList>;
}

interface IBankTrackingSearchFailed {
  type: EBankTrackingAction.BANK_TRACKING_SEARCH_FAILED;
  error: Error;
}

interface IBankTrackingGetRequest {
  type: EBankTrackingAction.BANK_TRACKING_GET_REQUEST;
}

interface IBankTrackingGetSuccess {
  type: EBankTrackingAction.BANK_TRACKING_GET_SUCESS;
  data: IBankTrackingSuspectForm;
}

interface IBankTrackingGetFailed {
  type: EBankTrackingAction.BANK_TRACKING_GET_FAILED;
  error: Error;
}

interface IBankTrackingCreateRequest {
  type: EBankTrackingAction.BANK_TRACKING_CREATE_REQUEST;
}

interface IBankTrackingCreateSucess {
  type: EBankTrackingAction.BANK_TRACKING_CREATE_SUCESS;
}

interface IBankTrackingCreateFailed {
  type: EBankTrackingAction.BANK_TRACKING_CREATE_FAILED;
  error: Error;
}

interface IBankTrackingUpdateRequest {
  type: EBankTrackingAction.BANK_TRACKING_UPDATE_REQUEST;
}

interface IBankTrackingUpdatehSucess {
  type: EBankTrackingAction.BANK_TRACKING_UPDATE_SUCESS;
}

interface IBankTrackingUpdateFailed {
  type: EBankTrackingAction.BANK_TRACKING_UPDATE_FAILED;
  error: Error;
}

interface IGetGraphRequest {
  type: EBankTrackingAction.GET_GRAPH_REQUEST;
}

interface IGetGraphSuccess {
  type: EBankTrackingAction.GET_GRAPH_SUCCESS;
  data: IGraph;
}

interface IGetGraphFailed {
  type: EBankTrackingAction.GET_GRAPH_FAILED;
  error: Error;
}

interface IUploadFileRequest {
  type: EBankTrackingAction.UPLOAD_FILE_REQUEST;
}

interface IUploadFileSuccess {
  type: EBankTrackingAction.UPLOAD_FILE_SUCCESS;
  data: IBankTrackingFileResponse;
}
interface IUploadFileFailed {
  type: EBankTrackingAction.UPLOAD_FILE_FAILED;
  error: Error;
}

interface ISubmitFileResponseRequest {
  type: EBankTrackingAction.SUBMIT_FILE_REPONSE_REQUEST;
}

interface ISubmitFileResponseSuccess {
  type: EBankTrackingAction.SUBMIT_FILE_REPONSE_SUCCESS;
}

interface IGetFileResponseRequest {
  type: EBankTrackingAction.GET_FILE_RESPONSE_REQUEST;
}

interface IGetFileResponseSuccess {
  type: EBankTrackingAction.GET_FILE_RESPONSE_SUCCESS;
  data: IBankTrackingAttachment;
}

interface IGetFileResponseFailed {
  type: EBankTrackingAction.GET_FILE_RESPONSE_FAILED;
  error: Error;
}

interface ISubmitFileResponseFailed {
  type: EBankTrackingAction.SUBMIT_FILE_REPONSE_FAILED;
  error: Error;
}

interface IExcelRequest {
  type: EBankTrackingAction.EXCEL_REQUEST;
}

interface IExcelSuccess {
  type: EBankTrackingAction.EXCEL_SUCCESS;
}

interface IExcelFailed {
  type: EBankTrackingAction.EXCEL_FAILED;
  error: Error;
}

interface IClearBankTrackingSelect {
  type: EBankTrackingAction.CLEAR_BANK_TRACKING_SELECT;
}

interface IClearAttachment {
  type: EBankTrackingAction.CLEAR_ATTACHMENT;
}

export type TBankTrackingActionType =
  | IBankTrackingSearchRequest
  | IBankTrackingSearchSucess
  | IBankTrackingSearchFailed
  | IBankTrackingGetRequest
  | IBankTrackingGetSuccess
  | IBankTrackingGetFailed
  | IBankTrackingCreateRequest
  | IBankTrackingCreateSucess
  | IBankTrackingCreateFailed
  | IBankTrackingUpdateRequest
  | IBankTrackingUpdatehSucess
  | IBankTrackingUpdateFailed
  | IGetGraphRequest
  | IGetGraphSuccess
  | IGetGraphFailed
  | IClearBankTrackingSelect
  | IUploadFileRequest
  | IUploadFileSuccess
  | IUploadFileFailed
  | ISubmitFileResponseRequest
  | ISubmitFileResponseSuccess
  | ISubmitFileResponseFailed
  | IGetFileResponseRequest
  | IGetFileResponseSuccess
  | IGetFileResponseFailed
  | IExcelRequest
  | IExcelSuccess
  | IExcelFailed
  | IClearAttachment;
