import Table from '@material-ui/core/Table';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { TDataTable } from 'shared/types';
import styled from 'styled-components';
import { TableOrder } from '../constant/table.constant';
import { LargeText } from '../Font';
import { TTableColumn } from '../type';
import TableBody from './TableBody';
import TableContext from './TableContext';
import TableHeader from './TableHeader';
import TableFooter from './TablePagination';

interface ITableProps {
  columns: TTableColumn[];
  dataSource: TDataTable<any>;
  title?: string;
  isLoading: boolean;
  className?: string;
  spaceCount?: number;
  fixCheckbox?: boolean;
  disabled?: boolean | undefined;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onHandleChangePage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  onGraph?: any;
  onDownload?: any;
  onUpload?: any;
  onFile?: any;
  onEdit?: any;
  onView?: any;
  onDelete?: any;
  onGraphDS?: any;
  onMobile?: any;
  onBank?: any;
  onItem?: number;
  onCheckBox?: any;
  onViewForm?: any;
  onEditForm?: any;
  onModal?: any;
  onSwitch?: any;
  hidePage?: any;
  hideHeader?: any;
  hideOnView?: boolean;
  hideOnEdit?: boolean;
  hideOnViewForm?: boolean;
  hideOnEditForm?: boolean;
  hideOnDelete?: boolean;
  type?: string;
  order?: 'asc' | 'desc' | undefined;
  orderBy?: any;
  setOrder?: any;
  setOrderBy?: any;
  onChangeOrder?: (property: any) => void;
}

const TableWrapper = styled.div`
  display: block;
`;

const StyledTable = styled.div`
  @media (max-width: 544px) {
    overflow: auto;
    /* max-height: 450px;
    width: 100%; */
  }
`;

const TableIconComponent: FC<ITableProps> = ({
  columns,
  dataSource: data,
  title,
  isLoading,
  className,
  spaceCount,
  fixCheckbox,
  disabled,
  onChangePage,
  onChangeItemsPerPage,
  onHandleChangePage,
  onGraph,
  onDownload,
  onUpload,
  onFile,
  onView,
  onEdit,
  onDelete,
  onGraphDS,
  onMobile,
  onBank,
  onItem,
  onCheckBox,
  onViewForm,
  onEditForm,
  onModal,
  onSwitch,
  hidePage,
  hideHeader,
  hideOnView,
  hideOnEdit,
  hideOnViewForm,
  hideOnEditForm,
  hideOnDelete,
  type,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const [selectedItems] = useState<any>([]);

  const itemsEmptyCount =
    data.pageSize - Math.min(data.pageSize, _.get(data, 'table.length', 0) - (data.currentPage - 1) * data.pageSize);

  const onChangeOrder = (property) => {
    const isAsc = orderBy === property && order === TableOrder.ASCENDING;
    setOrder(isAsc ? TableOrder.DESCENDING : TableOrder.ASCENDING);
    setOrderBy(property);
  };

  return (
    <TableWrapper>
      <StyledTable>
        <LargeText>{title}</LargeText>
        <TableContext.Provider
          value={{
            title: title,
            columns: columns,
            page: data.currentPage - 1,
            items: _.get(data, 'table', []),
            isLoading,
            itemsCount: _.get(data, 'table.length', 0),
            totalCount: data.totalCount,
            itemsEmptyCount: itemsEmptyCount,
            itemsPerPage: data.pageSize,
            selectedItems: selectedItems,
            selectedItemsCount: _.get(selectedItems, 'length', 0),
            fixCheckbox: fixCheckbox,
            disabled: disabled,
            onChangePage: onChangePage,
            onChangeItemsPerPage: onChangeItemsPerPage,
            onHandleChangePage: onHandleChangePage,
            onGraph: onGraph,
            onDownload: onDownload,
            onUpload: onUpload,
            onFile: onFile,
            onView: onView,
            onEdit: onEdit,
            onDelete: onDelete,
            spaceCount: spaceCount,
            onGraphDS: onGraphDS,
            onMobile: onMobile,
            onBank: onBank,
            onItem: onItem,
            onCheckBox: onCheckBox,
            onViewForm: onViewForm,
            onEditForm: onEditForm,
            onModal: onModal,
            onSwitch: onSwitch,
            hidePage: hidePage,
            hideHeader: hideHeader,
            hideOnView: hideOnView,
            hideOnEdit: hideOnEdit,
            hideOnViewForm: hideOnViewForm,
            hideOnEditForm: hideOnEditForm,
            hideOnDelete: hideOnDelete,
            type: type,
            order: order,
            orderBy: orderBy,
            setOrder: setOrder,
            setOrderBy: setOrderBy,
            onChangeOrder: onChangeOrder,
          }}
        >
          <Table className={className}>
            <TableHeader />
            <TableBody />
          </Table>
        </TableContext.Provider>
      </StyledTable>

      <TableContext.Provider
        value={{
          title: title,
          columns: columns,
          page: data.currentPage - 1,
          items: _.get(data, 'table', []),
          isLoading,
          itemsCount: _.get(data, 'table.length', 0),
          totalCount: data.totalCount,
          itemsEmptyCount: itemsEmptyCount,
          itemsPerPage: data.pageSize,
          selectedItems: selectedItems,
          selectedItemsCount: _.get(selectedItems, 'length', 0),
          fixCheckbox: fixCheckbox,
          disabled: disabled,
          onChangePage: onChangePage,
          onChangeItemsPerPage: onChangeItemsPerPage,
          onHandleChangePage: onHandleChangePage,
          onGraph: onGraph,
          onDownload: onDownload,
          onUpload: onUpload,
          onFile: onFile,
          onView: onView,
          onEdit: onEdit,
          onDelete: onDelete,
          spaceCount: spaceCount,
          onGraphDS: onGraphDS,
          onMobile: onMobile,
          onBank: onBank,
          onItem: onItem,
          onCheckBox: onCheckBox,
          onViewForm: onViewForm,
          onEditForm: onEditForm,
          onModal: onModal,
          onSwitch: onSwitch,
          hidePage: hidePage,
          hideHeader: hideHeader,
          hideOnView: hideOnView,
          hideOnEdit: hideOnEdit,
          hideOnViewForm: hideOnViewForm,
          hideOnEditForm: hideOnEditForm,
          hideOnDelete: hideOnDelete,
          type: type,
          order: order,
          orderBy: orderBy,
          setOrder: setOrder,
          setOrderBy: setOrderBy,
          onChangeOrder: onChangeOrder,
        }}
      >
        {!isLoading && <TableFooter />}
      </TableContext.Provider>
    </TableWrapper>
  );
};

export default TableIconComponent;
