import {
  IModelConsulartResponse,
  IModelCpdResponse,
  IModelDbdResponse,
  IModelDlaResponse,
  IModelDoaeResponse,
  IModelDoeResponse,
  IModelDopaLastResponse,
  IModelDopaResponse,
  IModelDsiResponse,
  IModelError,
  IModelMeaResponse,
  IModelMwaResponse,
  IModelNhsoResponse,
  IModelOncbResponse,
  IModelPeaResponse,
  IModelPpaResponse,
  IModelPpfResponse,
  IModelPplResponse,
  IModelPwaResponse,
  IRequestInfoDopa,
} from 'shared/modules/model';
import { EModel } from 'shared/modules/model/model.enum';

export enum EModelAction {
  GET_LIST_DOPA_REQUEST = 'GET_LIST_DOPA_REQUEST',
  GET_LIST_DOPA_SUCCESS = 'GET_LIST_DOPA_SUCCESS',
  GET_LIST_DOPA_FAILED = 'GET_LIST_DOPA_FAILED',
  GET_PEA_REQUEST = 'GET_PEA_REQUEST',
  GET_PEA_SUCCESS = 'GET_PEA_SUCCESS',
  GET_PEA_FAILED = 'GET_PEA_FAILED',
  GET_MEA_REQUEST = 'GET_MEA_REQUEST',
  GET_MEA_SUCCESS = 'GET_MEA_SUCCESS',
  GET_MEA_FAILED = 'GET_MEA_FAILED',
  GET_PWA_REQUEST = 'GET_PWA_REQUEST',
  GET_PWA_SUCCESS = 'GET_PWA_SUCCESS',
  GET_PWA_FAILED = 'GET_PWA_FAILED',
  GET_MWA_REQUEST = 'GET_MWA_REQUEST',
  GET_MWA_SUCCESS = 'GET_MWA_SUCCESS',
  GET_MWA_FAILED = 'GET_MWA_FAILED',
  GET_DBD_REQUEST = 'GET_DBD_REQUEST',
  GET_DBD_SUCCESS = 'GET_DBD_SUCCESS',
  GET_DBD_FAILED = 'GET_DBD_FAILED',
  GET_PPA_REQUEST = 'GET_PPA_REQUEST',
  GET_PPA_SUCCESS = 'GET_PPA_SUCCESS',
  GET_PPA_FAILED = 'GET_PPA_FAILED',
  GET_PPL_REQUEST = 'GET_PPL_REQUEST',
  GET_PPL_SUCCESS = 'GET_PPL_SUCCESS',
  GET_PPL_FAILED = 'GET_PPL_FAILED',
  GET_PPF_REQUEST = 'GET_PPF_REQUEST',
  GET_PPF_SUCCESS = 'GET_PPF_SUCCESS',
  GET_PPF_FAILED = 'GET_PPF_FAILED',
  GET_DOAE_REQUEST = 'GET_DOAE_REQUEST',
  GET_DOAE_SUCCESS = 'GET_DOAE_SUCCESS',
  GET_DOAE_FAILED = 'GET_DOAE_FAILED',
  GET_ONCB_REQUEST = 'GET_ONCB_REQUEST',
  GET_ONCB_SUCCESS = 'GET_ONCB_SUCCESS',
  GET_ONCB_FAILED = 'GET_ONCB_FAILED',
  GET_CONSULART_REQUEST = 'GET_CONSULART_REQUEST',
  GET_CONSULART_SUCCESS = 'GET_CONSULART_SUCCESS',
  GET_CONSULART_FAILED = 'GET_CONSULART_FAILED',
  GET_DSI_REQUEST = 'GET_DSI_REQUEST',
  GET_DSI_SUCCESS = 'GET_DSI_SUCCESS',
  GET_DSI_FAILED = 'GET_DSI_FAILED',
  GET_NHSO_REQUEST = 'GET_NHSO_REQUEST',
  GET_NHSO_SUCCESS = 'GET_NHSO_SUCCESS',
  GET_NHSO_FAILED = 'GET_NHSO_FAILED',
  GET_CPD_REQUEST = 'GET_CPD_REQUEST',
  GET_CPD_SUCCESS = 'GET_CPD_SUCCESS',
  GET_CPD_FAILED = 'GET_CPD_FAILED',
  GET_DLA_REQUEST = 'GET_DLA_REQUEST',
  GET_DLA_SUCCESS = 'GET_DLA_SUCCESS',
  GET_DLA_FAILED = 'GET_DLA_FAILED',
  GET_DOE_REQUEST = 'GET_DOE_REQUEST',
  GET_DOE_SUCCESS = 'GET_DOE_SUCCESS',
  GET_DOE_FAILED = 'GET_DOE_FAILED',
  GET_DOPA_LAST_REQUEST = 'GET_DOPA_LAST_REQUEST',
  GET_DOPA_LAST_SUCCESS = 'GET_DOPA_LAST_SUCCESS',
  GET_DOPA_LAST_FAILED = 'GET_DOPA_LAST_FAILED',
  GET_DOPA_REQUEST = 'GET_DOPA_REQUEST',
  GET_DOPA_SUCCESS = 'GET_DOPA_SUCCESS',
  GET_DOPA_FAILED = 'GET_DOPA_FAILED',
  CLEAR = 'CLEAR',
  GET_FAILED = 'GET_FAILED',
}

interface IGetListDopaRequest {
  type: EModelAction.GET_LIST_DOPA_REQUEST;
}

interface IGetListDopaSuccess {
  type: EModelAction.GET_LIST_DOPA_SUCCESS;
  data: any;
}

interface IGetListDopaFailed {
  type: EModelAction.GET_LIST_DOPA_FAILED;
  error: Error;
}

interface IGetPeaRequest {
  type: EModelAction.GET_PEA_REQUEST;
}

interface IGetPeaSuccess {
  type: EModelAction.GET_PEA_SUCCESS;
  data: IModelPeaResponse[];
}

interface IGetPeaFailed {
  type: EModelAction.GET_PEA_FAILED;
  error: Error;
}
interface IGetMeaRequest {
  type: EModelAction.GET_MEA_REQUEST;
}

interface IGetMeaSuccess {
  type: EModelAction.GET_MEA_SUCCESS;
  data: IModelMeaResponse[];
}

interface IGetMeaFailed {
  type: EModelAction.GET_MEA_FAILED;
  error: Error;
}
interface IGetPwaRequest {
  type: EModelAction.GET_PWA_REQUEST;
}

interface IGetPwaSuccess {
  type: EModelAction.GET_PWA_SUCCESS;
  data: IModelPwaResponse[];
}

interface IGetPwaFailed {
  type: EModelAction.GET_PWA_FAILED;
  error: Error;
}
interface IGetMwaRequest {
  type: EModelAction.GET_MWA_REQUEST;
}

interface IGetMwaSuccess {
  type: EModelAction.GET_MWA_SUCCESS;
  data: IModelMwaResponse[];
}

interface IGetMwaFailed {
  type: EModelAction.GET_MWA_FAILED;
  error: Error;
}
interface IGetDbdRequest {
  type: EModelAction.GET_DBD_REQUEST;
}

interface IGetDbdSuccess {
  type: EModelAction.GET_DBD_SUCCESS;
  data: IModelDbdResponse[];
}

interface IGetDbdFailed {
  type: EModelAction.GET_DBD_FAILED;
  error: Error;
}
interface IGetPpaRequest {
  type: EModelAction.GET_PPA_REQUEST;
}

interface IGetPpaSuccess {
  type: EModelAction.GET_PPA_SUCCESS;
  data: IModelPpaResponse[];
}

interface IGetPpaFailed {
  type: EModelAction.GET_PPA_FAILED;
  error: Error;
}
interface IGetPplRequest {
  type: EModelAction.GET_PPL_REQUEST;
}

interface IGetPplSuccess {
  type: EModelAction.GET_PPL_SUCCESS;
  data: IModelPplResponse[];
}

interface IGetPplFailed {
  type: EModelAction.GET_PPL_FAILED;
  error: Error;
}
interface IGetPpfRequest {
  type: EModelAction.GET_PPF_REQUEST;
}

interface IGetPpfSuccess {
  type: EModelAction.GET_PPF_SUCCESS;
  data: IModelPpfResponse[];
}

interface IGetPpfFailed {
  type: EModelAction.GET_PPF_FAILED;
  error: Error;
}

interface IGetDoaeRequest {
  type: EModelAction.GET_DOAE_REQUEST;
}

interface IGetDoaeSuccess {
  type: EModelAction.GET_DOAE_SUCCESS;
  data: IModelDoaeResponse[];
}

interface IGetDoaeFailed {
  type: EModelAction.GET_DOAE_FAILED;
  error: Error;
}

interface IGetOncbRequest {
  type: EModelAction.GET_ONCB_REQUEST;
}

interface IGetOncbSuccess {
  type: EModelAction.GET_ONCB_SUCCESS;
  data: IModelOncbResponse[];
}

interface IGetOncbFailed {
  type: EModelAction.GET_ONCB_FAILED;
  error: Error;
}

interface IGetConsulartRequest {
  type: EModelAction.GET_CONSULART_REQUEST;
}

interface IGetConsulartSuccess {
  type: EModelAction.GET_CONSULART_SUCCESS;
  data: IModelConsulartResponse[];
}

interface IGetConsulartFailed {
  type: EModelAction.GET_CONSULART_FAILED;
  error: Error;
}

interface IGetDsiRequest {
  type: EModelAction.GET_DSI_REQUEST;
}

interface IGetDsiSuccess {
  type: EModelAction.GET_DSI_SUCCESS;
  data: IModelDsiResponse[];
}

interface IGetDsiFailed {
  type: EModelAction.GET_DSI_FAILED;
  error: Error;
}

interface IGetNhsoRequest {
  type: EModelAction.GET_NHSO_REQUEST;
}

interface IGetNhsoSuccess {
  type: EModelAction.GET_NHSO_SUCCESS;
  data: IModelNhsoResponse[];
}

interface IGetNhsoFailed {
  type: EModelAction.GET_NHSO_FAILED;
  error: Error;
}

interface IGetCpdRequest {
  type: EModelAction.GET_CPD_REQUEST;
}

interface IGetCpdSuccess {
  type: EModelAction.GET_CPD_SUCCESS;
  data: IModelCpdResponse[];
}

interface IGetCpdFailed {
  type: EModelAction.GET_CPD_FAILED;
  error: Error;
}

interface IGetDlaRequest {
  type: EModelAction.GET_DLA_REQUEST;
}

interface IGetDlaSuccess {
  type: EModelAction.GET_DLA_SUCCESS;
  data: IModelDlaResponse[];
}

interface IGetDlaFailed {
  type: EModelAction.GET_DLA_FAILED;
  error: Error;
}

interface IGetDoeRequest {
  type: EModelAction.GET_DOE_REQUEST;
}

interface IGetDoeSuccess {
  type: EModelAction.GET_DOE_SUCCESS;
  data: IModelDoeResponse[];
}

interface IGetDoeFailed {
  type: EModelAction.GET_DOE_FAILED;
  error: Error;
}

interface IGetDopaLastRequest {
  type: EModelAction.GET_DOPA_LAST_REQUEST;
}

interface IGetDopaLastSuccess {
  type: EModelAction.GET_DOPA_LAST_SUCCESS;
  data: IModelDopaLastResponse[];
}

interface IGetDopaLastFailed {
  type: EModelAction.GET_DOPA_LAST_FAILED;
  error: Error;
}

interface IGetDopaRequest {
  type: EModelAction.GET_DOPA_REQUEST;
}

interface IGetDopaSuccess {
  type: EModelAction.GET_DOPA_SUCCESS;
  data: IRequestInfoDopa;
}

interface IGetDopaFailed {
  type: EModelAction.GET_DOPA_FAILED;
  error: Error;
}

// ;; CLEAR
interface IClear {
  type: EModelAction.CLEAR;
}

interface IGetFailed {
  type: EModelAction.GET_FAILED;
  modelType: EModel;
  message: string;
}

export type TModel =
  | IGetListDopaRequest
  | IGetListDopaSuccess
  | IGetListDopaFailed
  | IGetPeaRequest
  | IGetPeaSuccess
  | IGetPeaFailed
  | IGetMeaRequest
  | IGetMeaSuccess
  | IGetMeaFailed
  | IGetPwaRequest
  | IGetPwaSuccess
  | IGetPwaFailed
  | IGetMwaRequest
  | IGetMwaSuccess
  | IGetMwaFailed
  | IGetDbdRequest
  | IGetDbdSuccess
  | IGetDbdFailed
  | IGetPpaRequest
  | IGetPpaSuccess
  | IGetPpaFailed
  | IGetPplRequest
  | IGetPplSuccess
  | IGetPplFailed
  | IGetPpfRequest
  | IGetPpfSuccess
  | IGetPpfFailed
  | IGetDoaeRequest
  | IGetDoaeSuccess
  | IGetDoaeFailed
  | IGetOncbRequest
  | IGetOncbSuccess
  | IGetOncbFailed
  | IGetConsulartRequest
  | IGetConsulartSuccess
  | IGetConsulartFailed
  | IGetDsiRequest
  | IGetDsiSuccess
  | IGetDsiFailed
  | IGetNhsoRequest
  | IGetNhsoSuccess
  | IGetNhsoFailed
  | IGetCpdRequest
  | IGetCpdSuccess
  | IGetCpdFailed
  | IGetDlaRequest
  | IGetDlaSuccess
  | IGetDlaFailed
  | IGetDoeRequest
  | IGetDoeSuccess
  | IGetDoeFailed
  | IGetDopaLastRequest
  | IGetDopaLastSuccess
  | IGetDopaLastFailed
  | IGetDopaRequest
  | IGetDopaSuccess
  | IGetDopaFailed
  | IClear
  | IGetFailed;
