import { INITIAL_SCORE_STATE } from 'shared/modules/score';
import { IScoreState } from 'shared/modules/score/score.interface';
import { EScoreAction, TScoreActionTypes } from './score.type';

export default (state: IScoreState = INITIAL_SCORE_STATE, action: TScoreActionTypes): IScoreState => {
  switch (action.type) {
    case EScoreAction.GET_SCORE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EScoreAction.GET_SCORE_SUCCESS:
      return {
        ...state,
        score: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EScoreAction.GET_SCORE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return state;
    }
  }
};
