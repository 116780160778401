import Pagination from '@material-ui/core/TablePagination';
import React, { FC } from 'react';
import { PaginationWrapper } from './styled';
import TableContext from './TableContext';

const TablePagination: FC = () => (
  <TableContext.Consumer>
    {({ itemsPerPage, page, totalCount, onChangePage, onChangeItemsPerPage, hidePage = false, onHandleChangePage }) =>
      !hidePage && (
        <>
          <br />
          <>
            {onHandleChangePage && (
              <PaginationWrapper count={totalCount} page={page + 1} siblingCount={2} onChange={onHandleChangePage} />
            )}
          </>
          <br />
          <br />
          <Pagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component={'div'}
            count={Math.ceil(totalCount / itemsPerPage)}
            rowsPerPage={itemsPerPage}
            page={page}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeItemsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeItemsPerPage}
          />
        </>
      )
    }
  </TableContext.Consumer>
);

export default TablePagination;
