import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import { IInvestigationReportValidate } from '.';
import { TOfficerTableData } from '../case-file';
import {
  ICreateInvestigationReportValidate,
  IInvestigationReport,
  IInvestigationReportForm,
  IInvestigationReportFormInfo,
  IInvestigationReportFormTable,
  IInvestigationReportModalInvestigationAttachmentList,
  IInvestigationReportModalInvestigationForm,
  IInvestigationReportModalInvestigationValidate,
  IInvestigationReportSearch,
  IInvestigationReportSearchValidate,
  IInvestigationReportState,
} from './investigate-report.interface';

export const INITIAL_INVESTIGATION_REPORT_SEARCH: IInvestigationReportSearch = {
  preciseSearch: '',
  caseID: '',
  detail: '',
  startDate: null,
  endDate: null,
  CreateDateFrom: null,
  CreateDateTo: null,
  caseName: '',
  caseStatusID: null || [],
  createdBy: '',
  createShortAgengy: '',
  createShortSector: '',
  createShortDepartment: '',
  createDate: '',
  caseStartDate: null,
  area: '',
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  investigationReportName: '',
  _caseFileID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_INVESTIGATION_REPORT: IInvestigationReport = {
  id: '',
  caseID: '',
  investigationFullName: '',
  investigationDate: moment(),
  detail: '',
  checkBox: false,
};

export const INITIAL_INVESTIGATION_REPORT_FORM_INFO: IInvestigationReportFormInfo = {
  detail: '',
};

export const INITIAL_INVESTIGATION_REPORT_FORM: IInvestigationReportForm = {
  area: '',
  caseFileRelateds: [],
  caseID: '',
  caseFileID: '', // REQUEST
  caseName: '',
  caseStatusID: null,
  createdDate: moment(),
  detail: '',
  info: '',
  officerLeadList: [],
  officerReportList: [],
  suspects: [],
  caseEndDate: null,
  caseStartDate: null,
  interestingThing: '',
  objective: '',
  suggestion: '',
  investigateResults: [],
  isEdit: null,
  prefixID: null,
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  passportNo: '',
  middleName: '',
  nickName: '',
  telephoneNo: '',
  operationReport: '',
  reporterRank: '',
  reporterName: '',
  reporterDepartment: '',
  leaderRank: '',
  leaderName: '',
  leaderDepartment: '',
  supervisor1_Rank: '',
  supervisor1_Name: '',
  supervisor1_Department: '',
  supervisor2_Rank: '',
  supervisor2_Name: '',
  supervisor2_Department: '',
  start_date: moment(),
  end_date: null,
  _infoList: [INITIAL_INVESTIGATION_REPORT_FORM_INFO],
  infoList: [INITIAL_INVESTIGATION_REPORT_FORM_INFO],
  infoListHandle: [],
  id: '',
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  arrestDate: null,
  year: moment(),
  _year: moment(),
  dateFrom: null,
  timeFrom: '',
  dateTo: null,
  timeTo: '',
  createdByAgencyID: null,
  createdBySectorID: null,
  createdByDepartmentID: null,
  endDate: null,
  startDate: null,
  investigationReportName: '',
  detailAttachments: [],
  operationReportAttachments: [],
  // REQUEST POST_INVESTIGATION_REPORT
  detailInvestigationAttachments: [],
  operationReportInvestigationAttachments: [],
  detailAttachmentsDetails: [],
  operationAttachmentsDetails: [],
  //
  yearCaseFile: moment(new Date()),
};

export const INITIAL_INVESTIGATION_REPORT_STATE: IInvestigationReportState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  isButtonLoading: false,
  isPageLoading: false,
  // user: { ...INITIAL_INVESTIGATION_REPORT_OFFICER_FORM },
  investigations: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  createdSuspectIds: [],
  id: '',
  fileUrl: '',
  isTableLoading: false,
  initialOfficer: {} as TOfficerTableData,
  selected: {} as IInvestigationReportForm,
};

export const INITIAL_INVESTIGATION_REPORT_FORM_TABLE: IInvestigationReportFormTable = {
  caseTableStartDate: moment(),
  caseTableEndDate: moment(),
};

export const INITIAL_CREATE_INVESTIGATION_REPORT_VALIDATE: ICreateInvestigationReportValidate = {
  // caseID: '',
  // caseName: '',
  // caseStartDate: '',
  // caseEndDate: '',
  // caseStatusID: '',
  // area: '',
  // info: '',
  // objective: '',
  // detail: '',
  // interestingThing: '',
  // area: '',
  dateFrom: '',
  // timeFrom: '',
  dateTo: '',
  timeTo: '',
};

export const INITIAL_INVESTIGATION_REPORT_VALIDATE: IInvestigationReportValidate = {
  caseID: '',
  caseName: '',
  caseStartDate: '',
  caseEndDate: '',
  caseStatusID: '',
  area: '',
  info: '',
  objective: '',
  detail: '',
  interestingThing: '',
  suggestion: '',
  dateFrom: '',
  timeFrom: '',
  dateTo: '',
  timeTo: '',
};

export const INITIAL_INVESTIGATION_REPORT_SEARCH_VALIDATE: IInvestigationReportSearchValidate = {
  caseName: '',
  caseID: '',
  createdBy: '',
  preciseSearch: '',
  // headQuarterID: null,
  // divisionID: null,
  // subDivisionID: null,
  // departmentID: null,
  // policeStationID: null,
  // organizationID: null,
};

export const INITIAL_INVESTIGATION_REPORT_MODAL_INVESTIGATION_ATTACHMENT_LIST: IInvestigationReportModalInvestigationAttachmentList =
  {
    yearId: null,
    monthId: null,
    createdById: null,
    investigationNameId: null,
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
    createdByUser: '',
    investigationName: '',
  };

// NOTE: modal investigation
export const INITIAL_INVESTIGATION_REPORT_MODAL_INVESTIGATION_FORM: IInvestigationReportModalInvestigationForm = {
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  year: moment(new Date()),
  investigationId: '',
  investigationName: '',
};

export const INITIAL_INVESTIGATION_REPORT_MODAL_INVESTIGATION_VALIDATE: IInvestigationReportModalInvestigationValidate =
  {
    year: '',
  };
