import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useTitleDropdownApi = (type: string) => {
  const requestData = {
    ...(type ? { type } : { type: '0' }),
  };
  const urlParams =
    '?' +
    Object.keys(requestData)
      .map((k) => k + '=' + requestData[k])
      .join('&');
  const [titles, isLoading, isError] = useFetchApi<IDropdown[]>('/title/getDropdownList' + urlParams, []);

  const titleSelectOptions: SelectOption[] = titles.map((title) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [titleSelectOptions, isLoading, isError] as const;
};

export default useTitleDropdownApi;
