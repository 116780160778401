import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';
import { TSelect } from 'shared/types';

const usePoliceStationDropdownApi = (departmentId: TSelect) => {
  const [policeStations, isLoading, isError] = useFetchApi<IDropdown[]>(
    `/policeStation/getDropdownList?departmentId=${departmentId}`,
    [],
    'departmentId',
    departmentId
  );

  const policeStationSelectOptions: SelectOption[] = policeStations.map((policeStation: IDropdown) => {
    return {
      text: policeStation.text,
      value: policeStation.id,
    };
  });

  return [policeStationSelectOptions, isLoading, isError] as const;
};

export default usePoliceStationDropdownApi;
