const idCardPattern = (idCard: string) => {
  const idCardUnMask: string = idCard.replaceAll('-', '');
  let total: number = 0;
  const validCheck: number = parseInt(idCardUnMask.substr(12, 1));

  for (let n: number = 0; n < 12; n++) {
    const pidcut: number = parseInt(idCardUnMask.substr(n, 1));
    total = total + pidcut * (13 - n);
  }

  let check: number = 11 - (total % 11);

  if (check === 10) check = 0;
  else if (check === 11) check = 1;

  if (check === validCheck) return true;
  else return false;
};

export default idCardPattern;
