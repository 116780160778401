import Logo from 'assets/images/logo.svg';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import constants from 'shared/constants';
import styled from 'styled-components';
import { AppName, UserProfilePortal } from './HeaderPortalComponents';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
  cursor: pointer; /* This changes the cursor to a pointer when hovering */
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const AuthenticatedHeaderPortal: FC = () => {
  const history = useHistory();

  const onHome = () => {
    history.push(`/`);
  };

  return (
    <HeaderWrapper>
      <AppIcon src={Logo} onClick={onHome} />
      <Wrapper>
        <AppName />
        <UserProfilePortal />
      </Wrapper>
    </HeaderWrapper>
  );
};

export default AuthenticatedHeaderPortal;
