import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import DefaultMiniIcon from 'assets/images/camera.png';
import { ESuspectFormMode } from 'shared/modules/suspect';
import CameraDisable from 'assets/images/cameraDisable.svg';
import CameraEnable from 'assets/images/cameraEnable.svg';


const ImageWrapper = styled.div`
  position: relative;
  align-self: center;
`;

const StyledImage = styled.img`
  z-index: 2;
  border-radius: 50%;
`;



const StyleCameraDisable = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 4rem;
  right: 0;
`;

const StyleCameraEnable = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 4rem;
  right: 0;
  cursor: pointer;
`;

interface IImageProps {
  src: string;
  width?: number;
  height?: number;
  showMiniIcon?: boolean;
  miniIcon?: string;
  onMiniIconClick?(event: SyntheticEvent): void;
  isDisabled?: boolean; //เช็คสิทธิการแก้ไข
  viewMode?: ESuspectFormMode;
}

const Image: FC<IImageProps> = ({
  src,
  width = 240,
  height = 240,
  showMiniIcon = false,
  miniIcon = CameraEnable,
  onMiniIconClick,
  isDisabled,
  viewMode
}) => {
  return (
    <ImageWrapper style={{ width, height }}>
      <StyledImage src={src} width={width} height={height} />
      {(viewMode === ESuspectFormMode.EDIT && !isDisabled) || viewMode === ESuspectFormMode.VIEW ?
        showMiniIcon && <StyleCameraDisable src={CameraDisable} onClick={onMiniIconClick} />
        :
        showMiniIcon && <StyleCameraEnable src={CameraEnable} onClick={onMiniIconClick} />}

    </ImageWrapper>
  );
};

export default Image;
