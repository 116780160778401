import React, { FC } from 'react';
import Switch from '@material-ui/core/Switch';

interface ISliderProps {
  checked?: boolean;
  onChange?: any;
  value?: boolean;
}

const Slider: FC<ISliderProps> = ({ onChange, value }) => {
  return <Switch value={value} checked={value} onChange={onChange} color="primary" />;
};

export default Slider;
