import { IInvestigationAttachmentForm } from 'shared/modules/investigation-attachment';
import { TDataTable } from 'shared/types';

export enum EInvestigationAttachmentAction {
  INVESTIGATION_ATTACHMENT_POST_REQUEST = 'INVESTIGATION_ATTACHMENT_POST_REQUEST',
  INVESTIGATION_ATTACHMENT_POST_SUCCESS = 'INVESTIGATION_ATTACHMENT_POST_SUCCESS',
  INVESTIGATION_ATTACHMENT_POST_FAILED = 'INVESTIGATION_ATTACHMENT_POST_FAILED',
  INVESTIGATION_ATTACHMENT_UPDATE_REQUEST = 'INVESTIGATION_ATTACHMENT_UPDATE_REQUEST',
  INVESTIGATION_ATTACHMENT_UPDATE_SUCCESS = 'INVESTIGATION_ATTACHMENT_UPDATE_SUCCESS',
  INVESTIGATION_ATTACHMENT_UPDATE_FAILED = 'INVESTIGATION_ATTACHMENT_UPDATE_FAILED',
  INVESTIGATION_ATTACHMENT_GET_REQUEST = 'INVESTIGATION_ATTACHMENT_GET_REQUEST',
  INVESTIGATION_ATTACHMENT_GET_SUCCESS = 'INVESTIGATION_ATTACHMENT_GET_SUCCESS',
  INVESTIGATION_ATTACHMENT_GET_FAILED = 'INVESTIGATION_ATTACHMENT_GET_FAILED',
  INVESTIGATION_ATTACHMENT_DELETE_REQUEST = 'INVESTIGATION_ATTACHMENT_DELETE_REQUEST',
  INVESTIGATION_ATTACHMENT_DELETE_SUCCESS = 'INVESTIGATION_ATTACHMENT_DELETE_SUCCESS',
  INVESTIGATION_ATTACHMENT_DELETE_FAILED = 'INVESTIGATION_ATTACHMENT_DELETE_FAILED',
}

interface IPostInvestigationRequest {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_REQUEST;
}

interface IPostInvestigationSuccess {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_SUCCESS;
  data: TDataTable<IInvestigationAttachmentForm>;
}

interface IPostInvestigationFailed {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_FAILED;
  error: Error;
}

interface IUpdateInvestigationRequest {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_REQUEST;
}

interface IUpdateInvestigationSuccess {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_SUCCESS;
  data: TDataTable<IInvestigationAttachmentForm>;
}

interface IUpdateInvestigationFailed {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_FAILED;
  error: Error;
}

interface IGetInvestigationRequest {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_REQUEST;
}

interface IGetInvestigationSuccess {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_SUCCESS;
  data: IInvestigationAttachmentForm;
}

interface IGetInvestigationFailed {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_FAILED;
  error: Error;
}

interface IDeleteInvestigationRequest {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_REQUEST;
}

interface IDeleteInvestigationSuccess {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_SUCCESS;
  data: IInvestigationAttachmentForm;
}

interface IDeleteInvestigationFailed {
  type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_FAILED;
  error: Error;
}

export type TInvestigationAttachment =
  | IPostInvestigationRequest
  | IPostInvestigationSuccess
  | IPostInvestigationFailed
  | IUpdateInvestigationRequest
  | IUpdateInvestigationSuccess
  | IUpdateInvestigationFailed
  | IGetInvestigationRequest
  | IGetInvestigationSuccess
  | IGetInvestigationFailed
  | IDeleteInvestigationRequest
  | IDeleteInvestigationSuccess
  | IDeleteInvestigationFailed;
