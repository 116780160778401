import { IDAREState, INITIAL_DARE_STATE } from 'shared/modules/dare';
import { EDareAction, TDAREActionTypes } from './dare.type';

export default (state: IDAREState = INITIAL_DARE_STATE, action: TDAREActionTypes): IDAREState => {
  switch (action.type) {
    case EDareAction.SEARCH_DARE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case EDareAction.SEARCH_DARE_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EDareAction.SEARCH_DARE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
