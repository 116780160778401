import { ELogger, TLoggerAction } from './logger.type';
import { ILoggerState, INITIAL_LOGGER_STATE } from 'shared/modules/logger';

export default (state: ILoggerState = INITIAL_LOGGER_STATE, action: TLoggerAction): ILoggerState => {
  switch (action.type) {
    case ELogger.GET_LOGGER_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELogger.GET_LOGGER_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case ELogger.GET_LOGGER_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
