import { INITIAL_SEARCHING_REPORT_STATE } from 'shared/modules/searching-report';
import {
  ISearchingReportForm,
  ISearchingReportState,
} from 'shared/modules/searching-report/searching-report.interface';
import { TSearchingReportActionTypes, ESearchingReportAction } from './searching-report.type';

export default (
  state: ISearchingReportState = INITIAL_SEARCHING_REPORT_STATE,
  action: TSearchingReportActionTypes
): ISearchingReportState => {
  switch (action.type) {
    case ESearchingReportAction.SEARCH_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case ESearchingReportAction.SEARCH_SEARCHING_REPORT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESearchingReportAction.SEARCH_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESearchingReportAction.CREATE_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESearchingReportAction.CREATE_SEARCHING_REPORT_SUCCESS:
      return { ...state };
    case ESearchingReportAction.CREATE_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.GET_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESearchingReportAction.GET_SEARCHING_REPORT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.GET_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.UPDATE_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESearchingReportAction.UPDATE_SEARCHING_REPORT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.UPDATE_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.DELETE_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESearchingReportAction.DELETE_SEARCHING_REPORT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.DELETE_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.GET_PDF_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESearchingReportAction.GET_PDF_SUCCESS:
      return {
        ...state,
        fileUrl: action.fileUrl,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.GET_PDF_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESearchingReportAction.CLEAR_SELECTED_SEARCHING_REPORT:
      return {
        ...state,
        selected: {} as ISearchingReportForm,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
