import { IUploadFileResponse } from 'shared/interfaces/api.interface';
import { IEditUserProfileForm, IEditUserProfileFormValidate, IUserProfileState } from './user-profile.interface';

export const INITIAL_EDIT_USER_PROFILE_FORM: IEditUserProfileForm = {
  id: '',
  organizationID: null,
  divisionID: null,
  email: '',
  firstName: '',
  headQuarterID: null,
  idCard: '',
  lastName: '',
  mobilePhoneNumber: '',
  officerCode: '',
  policeStationID: null,
  positionID: null,
  subDivisionID: null,
  titleID: null,
  imageFile: undefined,
  isResetPassword: false,
  userStatusID: null,
  profileImgUrl: undefined,
  teamID: [],
  birthDate: null,
  departmentID: null,
  lineIDName: '',
  lineUserID: '',
  attachedDocuments: [] as IUploadFileResponse[],
};

export const INITIAL_EDIT_USER_PROFILE_FORM_VALIDATE: IEditUserProfileFormValidate = {
  titleID: '',
  firstName: '',
  lastName: '',
  officerCode: '',
  positionID: '',
  headQuarterID: '',
  divisionID: '',
  policeStationID: '',
  subDivisionID: '',
  idCard: '',
  email: '',
  userStatusID: '',
  teamID: '',
  departmentID: '',
  mobilePhoneNumber: '',
  organizationID: '',
};

export const INITIAL_USER_PROFILE_STATE: IUserProfileState = {
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  selected: {} as IEditUserProfileForm,
};
