import { INITIAL_CONFISCATE_STATE } from 'shared/modules/confiscate';
import { IConfiscateForm, IConfiscateState } from 'shared/modules/confiscate/confiscate.interface';
import { TConfiscateActionTypes, EConfiscateAction } from './confiscate.type';

export default (
  state: IConfiscateState = INITIAL_CONFISCATE_STATE,
  action: TConfiscateActionTypes
): IConfiscateState => {
  switch (action.type) {
    case EConfiscateAction.SEARCH_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EConfiscateAction.SEARCH_CONFISCATE_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EConfiscateAction.SEARCH_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EConfiscateAction.CREATE_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EConfiscateAction.CREATE_CONFISCATE_SUCCESS:
      return { ...state };
    case EConfiscateAction.CREATE_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.GET_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EConfiscateAction.GET_CONFISCATE_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.GET_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.UPDATE_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EConfiscateAction.UPDATE_CONFISCATE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.UPDATE_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.DELETE_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EConfiscateAction.DELETE_CONFISCATE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.DELETE_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.GET_PDF_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EConfiscateAction.GET_PDF_SUCCESS:
      return {
        ...state,
        fileUrl: action.fileUrl,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.GET_PDF_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EConfiscateAction.CLEAR_SELECTED_CONFISCATE:
      return {
        ...state,
        selected: {} as IConfiscateForm,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
