import axios from 'shared/utils/axios';
import i18next from 'i18n';
import { EInvestigationAttachmentAction } from './investigation-attachment.type';
import { StatusAlert } from 'components/ui';
import { AlertStatus } from 'components/ui/enum';
import { uploadFile } from 'shared/utils/api';
import _ from 'lodash';
import {
  IInvestigationAttachmentForm,
  IInvestigationAttachmentPostRequest,
  IInvestigationAttachmentPostResponse,
  IInvestigationAttachmentUpdateRequest,
  IIvestigationAttachmentGetResponse,
} from 'shared/modules/investigation-attachment';
import moment from 'moment';

export const createInvestigateAttachment = (data: IInvestigationAttachmentForm, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_REQUEST });
    if (Array.isArray(data.newAttachments) && data.newAttachments.length) {
      const uploadedFile = await uploadFile({
        routeName: 'investigationAttachment',
        attachments: data.newAttachments,
      });
      if (!_.isEmpty(uploadedFile)) {
        data.attachments = data.attachments.concat(uploadedFile);
        data.newAttachments = [];
      }
    }
    const request: IInvestigationAttachmentPostRequest = {
      attachmentBy: data.attachmentBy,
      attachmentDate: moment(data.attachmentDate).toISOString(),
      attachmentTime: moment(data.attachmentTime).toISOString(),
      attachmentDesc: data.attachmentDesc,
      attachmentDetails: !_.isEmpty(data.attachments) ? data.attachments[0] : null,
      attachmentName: data.attachmentName,
      attachmentLocation: data.place,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    axios
      .post(`/investigationAttachment`, JSON.stringify(request), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(createInvestigateAttachmentSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(createInvestigateAttachmentFailed(error));
      });
  };
};

const createInvestigateAttachmentSuccess = (data: IInvestigationAttachmentPostResponse, cb?: Function) => {
  StatusAlert(i18next.t('แนบรูปภาพสำเร็จ'), AlertStatus.Success).then(() => {
    if (cb) cb(data);
  });
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_SUCCESS,
  };
};

const createInvestigateAttachmentFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_FAILED,
    error,
  };
};

export const updateInvestigateAttachment = (id: string, data: IInvestigationAttachmentForm, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_REQUEST });
    const request: IInvestigationAttachmentUpdateRequest = {
      attachmentBy: data.attachmentBy,
      attachmentDate: moment(data.attachmentDate).toISOString(),
      attachmentTime: moment(data.attachmentTime).toISOString(),
      attachmentDesc: data.attachmentDesc,
      attachmentDetails: data.attachment,
      attachmentName: data.attachmentName,
      attachmentLocation: data.place,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    axios
      .put(`/investigationAttachment/${id}`, JSON.stringify(request), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(updateInvestigateAttachmentSuccess(cb));
      })
      .catch((error) => {
        dispatch(updateInvestigateAttachmentFailed(error));
      });
  };
};

const updateInvestigateAttachmentSuccess = (cb?: Function) => {
  StatusAlert(i18next.t('main:alert.success.save'), AlertStatus.Success).then(() => {
    if (cb) cb();
  });
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_SUCCESS,
  };
};

const updateInvestigateAttachmentFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_FAILED,
    error,
  };
};

export const getInvestigateAttachment = (id: string, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_REQUEST });
    axios
      .get(`/investigationAttachment/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        response.data.attachment = response.data.attachmentDetails;
        response.data.attachmentDetails = response.data.attachmentDetails.fileName;
        dispatch(getInvestigateAttachmentSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(getInvestigateAttachmentFailed(error));
      });
  };
};

const getInvestigateAttachmentSuccess = (data: IIvestigationAttachmentGetResponse, cb?: Function) => {
  // StatusAlert(i18next.t('main:alert.success.save'), AlertStatus.Success).then(() => {
  if (cb) cb();
  // });
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_SUCCESS,
    data,
  };
};

const getInvestigateAttachmentFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_FAILED,
    error,
  };
};

export const deleteInvestigateAttachment = (id: string, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_REQUEST });
    axios
      .delete(`/investigationAttachment/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(deleteInvestigateAttachmentSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(deleteInvestigateAttachmentFailed(error));
      });
  };
};

const deleteInvestigateAttachmentSuccess = (data: IIvestigationAttachmentGetResponse, cb?: Function) => {
  StatusAlert(i18next.t('ลบรูปภาพสำเร็จ'), AlertStatus.Success).then(() => {
    if (cb) cb();
  });
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_SUCCESS,
    data,
  };
};

const deleteInvestigateAttachmentFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_FAILED,
    error,
  };
};
