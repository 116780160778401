import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import {
  IGamificationState,
  INITIAL_GAMIFICATION_STATE,
  INITIAL_SCORE_COUNTING_MODAL_FORM,
  IScoreCaseFile,
  IScoreSummarize,
} from 'shared/modules/gamification';
import { EGamificationAction, TGamificationTypes } from './gamification.type';

export default (
  state: IGamificationState = INITIAL_GAMIFICATION_STATE,
  action: TGamificationTypes
): IGamificationState => {
  switch (action.type) {
    case EGamificationAction.SEARCH_SCORE_COUNTING_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreCountingTableLoading: true,
      };
    case EGamificationAction.SEARCH_SCORE_COUNTING_SUCCESS:
      return {
        ...state,
        scoreCountingList: action.scoreCountingList,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreCountingTableLoading: false,
      };
    case EGamificationAction.SEARCH_SCORE_COUNTING_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreCountingTableLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_COUNTING_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_COUNTING_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_COUNTING_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_COUNTING_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_COUNTING_SUCCESS:
      return {
        ...state,
        selectedScoreCounting: action.scoreCounting,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_COUNTING_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_COUNTING_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_COUNTING_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_COUNTING_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.DELETE_SCORE_COUNTING_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EGamificationAction.DELETE_SCORE_COUNTING_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.DELETE_SCORE_COUNTING_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.SEARCH_SCORE_SUMMARIZE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreSummarizeTableLoading: true,
      };
    case EGamificationAction.SEARCH_SCORE_SUMMARIZE_SUCCESS:
      return {
        ...state,
        scoreSummarizeList: action.scoreSummarizeList,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreSummarizeTableLoading: false,
      };
    case EGamificationAction.SEARCH_SCORE_SUMMARIZE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreSummarizeTableLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_SUMMARIZE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_SUMMARIZE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_SUMMARIZE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_SUMMARIZE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_SUMMARIZE_SUCCESS:
      return {
        ...state,
        selectedScoreSummarize: action.scoreSummarize,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_SUMMARIZE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_SUMMARIZE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_SUMMARIZE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_SUMMARIZE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.DELETE_SCORE_SUMMARIZE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EGamificationAction.DELETE_SCORE_SUMMARIZE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.DELETE_SCORE_SUMMARIZE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.SEARCH_SCORE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreCaseFileTableLoading: true,
      };
    case EGamificationAction.SEARCH_SCORE_CASE_FILE_SUCCESS:
      return {
        ...state,
        scoreCaseFileList: action.scoreCaseFileList,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreCaseFileTableLoading: false,
      };
    case EGamificationAction.SEARCH_SCORE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isScoreCaseFileTableLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_CASE_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.CREATE_SCORE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_CASE_FILE_SUCCESS:
      return {
        ...state,
        selectedScoreCaseFile: action.scoreCaseFile,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.GET_SCORE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_CASE_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.EDIT_SCORE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.DELETE_SCORE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EGamificationAction.DELETE_SCORE_CASE_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.DELETE_SCORE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.CLEAR_GAMIFICATION_STATE:
      return {
        ...state,
        error: undefined,
        isButtonLoading: false,
        isPageLoading: false,
        selectedScoreSummarize: {} as IScoreSummarize,
        selectedScoreCounting: { ...INITIAL_SCORE_COUNTING_MODAL_FORM, id: '', status: false },
        selectedScoreCaseFile: {} as IScoreCaseFile,
      };
    case EGamificationAction.SEARCH_GAMIFICATION_SETTING_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EGamificationAction.SEARCH_GAMIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        all: action.all,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EGamificationAction.SEARCH_GAMIFICATION_SETTING_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return state;
    }
  }
};
