import { Grid } from '@material-ui/core';
import { FormContainer, PageHeader } from 'components/layout';
import { Button, DatePicker, HeaderText, Input, Modal, RelationalDropdown } from 'components/ui';
import { ButtonPreset, DateFormat } from 'components/ui/enum';
import { useAgencyDropdownApi, useDepartmentBySectorDropdownApi, useSectorByAgencyDropdownApi } from 'hooks/dropdown';
import _ from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICaseFileForm } from 'shared/modules/case-file';
import { IModalInvestigateValidate, INITIAL_MODAL_INVESTIGATE_VALIDATE } from 'shared/modules/modal-investigate';
import { TDateTime } from 'shared/types';
import axios from 'shared/utils/axios';
import { onDateChange, onInputChange } from 'shared/utils/event-handle';
import { IUseModal } from 'shared/utils/useModal/type';
import { setErrorList, validateList } from 'shared/validate/ValidateInvestigate.validate';
import { IReducer } from 'store';
import { createCaseFile, getCaseFileTemplate } from 'store/case-file/case-file.action';
import styled from 'styled-components';

const ModalWrapper = styled(Modal)`
  padding: 2rem;
  height: max-content;
`;

interface IProps {
  modal: IUseModal;
  formModal: ICaseFileForm;
  setFormModal: (formModal: ICaseFileForm) => void;
}

const ModalCasefile: FC<IProps> = ({ modal, formModal, setFormModal }) => {
  const { t } = useTranslation('case_file');
  const dispatch = useDispatch();
  const user = useSelector((store: IReducer) => store.user.me);
  const [errorState, setErrorState] = useState<IModalInvestigateValidate>({ ...INITIAL_MODAL_INVESTIGATE_VALIDATE });
  const [headquarter, isHeadquarter] = useAgencyDropdownApi();
  const [divisions, isDivisionLoading] = useSectorByAgencyDropdownApi(formModal.headQuarterID || null);
  const [policestations, isPolicestationLoading] = useDepartmentBySectorDropdownApi(formModal.divisionID || null);

  useEffect(() => {
    dispatch(getCaseFileTemplate(onGetTemplateSuccess));
    // axios
    //   .get(`/CaseFileFolder/GetTemplateCaseFile`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('token')}`,
    //     },
    //   })
    //   .then((response) => {
    //     const value = response.data + (parseInt(moment().format('YYYY')) + 543);
    //     setFormModal({
    //       ...formModal,
    //       caseID: value,
    //       headQuarterID: user.headQuarterID,
    //       divisionID: user.divisionID,
    //       policeStationID: user.policeStationID,
    //     });
    //   })
    //   .catch((error) => {
    //     setFormModal({ ...formModal, caseID: '' });
    //   });
  }, [modal.visible]);

  useEffect(() => {
    const tmpCaseNo = formModal.caseID?.split(/[\s\.]/);
    /*
    const tmpheader = formModal.headQuarterID
      ? headquarter?.filter((o) => o.value === formModal.headQuarterID)[0]?.code
      : tmpCaseNo[0] + ' ' + tmpCaseNo[1];
    const tmpDivision = divisions?.filter((o) => o.value === formModal.divisionID)[0]?.code;
    const tmpPolicestation = policestations?.filter((o) => o.value === formModal.policeStationID)[0]?.code;
    */
    if (tmpCaseNo && divisions) {
      var tmpCaseNoReturn: string = '';
      /*
      const tmpCaseNoReturn =
        (formModal.headQuarterID ? tmpheader || '' : tmpCaseNo.length > 1 ? tmpCaseNo[0] + ' ' + tmpCaseNo[1] : '') +
        '.' +
        (_.isEmpty(divisions) || _.isEmpty(policestations)
          ? tmpCaseNo[2] === 'X/' + (parseInt(moment().format('YYYY')) + 543)
            ? ''
            : tmpCaseNo[2] || ''
          : (tmpDivision || '') + (tmpPolicestation || '')) +
        ' ' +
        ('X/' + (parseInt(moment().format('YYYY')) + 543));
      */
      const fyear = parseInt(moment(formModal.year).format('YYYY')) + 543;

      if (!!formModal.headQuarterID) {
        const a = headquarter.filter((o) => o.value === formModal.headQuarterID);
        if (!_.isEmpty(a)) {
          tmpCaseNoReturn = a[0].code + ' X/' + fyear;
        }
      }
      if (!!formModal.divisionID) {
        const a = divisions.filter((o) => o.value === formModal.divisionID);
        if (!_.isEmpty(a)) {
          tmpCaseNoReturn = a[0].code + ' X/' + fyear;
        }
      }
      if (!!formModal.policeStationID) {
        const a = policestations.filter((o) => o.value === formModal.policeStationID);
        if (!_.isEmpty(a)) {
          tmpCaseNoReturn = a[0].code + ' X/' + fyear;
        }
      }
      setFormModal({
        ...formModal,
        caseID: tmpCaseNoReturn,
      });
    }
  }, [formModal.headQuarterID, formModal.divisionID, formModal.policeStationID, isPolicestationLoading]);

  const onGetTemplateSuccess = (response) => {
    const value = response + (parseInt(moment().format('YYYY')) + 543);
    setFormModal({
      ...formModal,
      // caseID: value,
      headQuarterID: user.headQuarterID,
      divisionID: user.divisionID,
      policeStationID: user.policeStationID,
    });
  };

  const onAddCaseFile = () => {
    if (!validateFormModal()) {
      dispatch(
        createCaseFile(
          {
            ...formModal,
            caseStartDate: formModal.arrestDate,
            caseEndDate: formModal.arrestDate,
            area: '',
          },
          onRefresh
        )
      );
    }
  };

  const onRefresh = () => {
    window.location.reload();
  };

  const onDateChangeWrapperCaseID = (value: TDateTime) => {
    onDateChange('year', formModal, setFormModal)(value);
    const tmpCaseID = formModal.caseID?.match(/[^/]*./g);
    var tmpValue = '';
    if (tmpCaseID) tmpValue = tmpCaseID?.[0] + (parseInt(moment(value).format('YYYY')) + 543);
    setFormModal({ ...formModal, caseID: tmpValue });
  };

  const validateOnBlur = (field: string) => (_event) => {
    setErrorState({
      ...errorState,
      [field]: validateList[field](formModal[field]),
    });
  };

  const validateFormModal = () => {
    const errorList = setErrorList(formModal, setErrorState);
    if (_.some(errorList) || _.some(errorState)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ModalWrapper visible={modal.visible} onClose={modal.onClose}>
        <PageHeader>
          <HeaderText>{t('modal.create.title')}</HeaderText>
        </PageHeader>
        <Grid container>
          <Grid item xs={12}>
            <FormContainer>
              <Input
                label={t('modal.create.form.case_id')}
                value={formModal.caseID}
                onChange={() => {}}
                required={true}
                disabled={true}
              />
              <RelationalDropdown
                headQuarterID={formModal.headQuarterID}
                divisionID={formModal.divisionID}
                subDivisionID={formModal.subDivisionID}
                departmentID={formModal.departmentID}
                policeStationID={formModal.policeStationID}
                organizationID={formModal.organizationID}
                isPoliceStationRequired={false}
                state={formModal}
                setState={setFormModal}
                hideSubDivision={true}
                hideDepartment={true}
                hideOrganization={true}
              />
              <DatePicker
                label={t('modal.create.form.year')}
                value={formModal.year}
                onChange={onDateChangeWrapperCaseID}
                // disableFuture={true}
                format={DateFormat.YYYY}
                minDate={moment().set('year', 2012)}
                maxDate={moment().add(1, 'years')}
                views={['year']}
              />
              <Input
                label={t('modal.create.form.case_name')}
                value={formModal.caseName}
                onChange={onInputChange('caseName', formModal, setFormModal)}
                required={true}
                onBlur={validateOnBlur('caseName')}
                error={errorState.caseName}
              />
              <DatePicker
                label={t('modal.create.form.arrest_date')}
                value={formModal.arrestDate}
                onChange={onDateChange('arrestDate', formModal, setFormModal)}
                required={true}
                disableFuture={true}
              />
            </FormContainer>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Grid item>
              <Button preset={ButtonPreset.PRIMARY} onClick={onAddCaseFile}>
                {t('modal.create.button.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ModalWrapper>
    </>
  );
};

export default ModalCasefile;
