import React, { FC } from 'react';
import TableContext from './TableContext';
import Pagination from '@material-ui/core/TablePagination';

const TablePagination: FC = () => (
  <TableContext.Consumer>
    {({ items, itemsPerPage, page, onChangePage, onChangeItemsPerPage }) => (
      <>
        {/* <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          component={'div'}
          count={items.length}
          rowsPerPage={itemsPerPage}
          page={page}
          onChangePage={(_, newPage) => onChangePage(newPage)}
          onChangeRowsPerPage={onChangeItemsPerPage}
          onPageChange={(_, page) => onChangePage(page)}
        /> */}
      </>
    )}
  </TableContext.Consumer>
);

export default TablePagination;
