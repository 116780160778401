import { TCaseFileAccessRequestActionTypes, ECaseFileAccessRequestAction } from './case-file-access-request.type';
import {
  ICaseFileAccessRequest,
  ICaseFileAccessRequestState,
  ICaseFileInfo,
  INITIAL_CASE_FILE_ACCESS_REQUEST_STATE,
} from 'shared/modules/case-file-access-request';

export default (
  state: ICaseFileAccessRequestState = INITIAL_CASE_FILE_ACCESS_REQUEST_STATE,
  action: TCaseFileAccessRequestActionTypes
): ICaseFileAccessRequestState => {
  switch (action.type) {
    case ECaseFileAccessRequestAction.GET_CASE_FILE_ACCESS_REQUEST_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_ACCESS_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.CREATE_CASE_FILE_ACCESS_REQUEST_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case ECaseFileAccessRequestAction.CREATE_CASE_FILE_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.CREATE_CASE_FILE_ACCESS_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.UPDATE_CASE_FILE_ACCESS_REQUEST_REQUEST:
      return {
        ...state,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case ECaseFileAccessRequestAction.UPDATE_CASE_FILE_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAccessRequestAction.UPDATE_CASE_FILE_ACCESS_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_INFO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_INFO_SUCCESS:
      return {
        ...state,
        info: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_INFO_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_NOT_APPROVE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_NOT_APPROVE_SUCCESS:
      return {
        ...state,
        notApprove: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.GET_CASE_FILE_NOT_APPROVE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAccessRequestAction.CLEAR_CASE_FILE_ACCESS_REQUEST:
      return {
        ...state,
        selected: {} as ICaseFileAccessRequest,
        info: {} as ICaseFileInfo,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
