import {
  IBankTrackingSuspectForm,
  IBankTrackingSearchForm,
  IBankTrackingState,
  IBankTrackingSearchFormValidate,
  IBankTrackingSuspectFormValidate,
  IBankTrackingAttachment,
} from './bank-tracking.interface';
import { IUploadFileResponse } from 'shared/interfaces';
import { IGraph } from 'shared/modules/graph';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';

export const INITIAL_BANK_TRACKING_SUSPECT_FORM: IBankTrackingSuspectForm = {
  id: '',
  bankID: null,
  suspectTitleID: null,
  suspectFirstName: '',
  suspectLastName: '',
  suspectIDCard: '',
  subjectID: null,
  descript: '',
  status: false,
  accountNo: '',
  caseFileID: null,
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  attachmentsFromBank: [] as IUploadFileResponse[],
  newAttachmentsFromBank: null,
};
export const INITIAL_BANK_TRACKING_SUSPECT_CREATE_FORM_VALIDATE: IBankTrackingSuspectFormValidate = {
  bankID: '',
  accountNo: '',
  suspectTitleID: '',
  suspectFirstName: '',
  suspectLastName: '',
  suspectIDCard: '',
  subjectID: '',
  descript: '',
};

export const INITIAL_BANK_TRACKING_SEARCH_FORM: IBankTrackingSearchForm = {
  createdBy: '',
  suspectFullName: '',
  createdDateStart: null,
  createdDateEnd: null,
  bankID: null,
  caseFileID: null,
  accountNo: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_BANK_TRACKING_SUSPECT_FORM_VALIDATE: IBankTrackingSearchFormValidate = {
  createdBy: '',
  suspectFullName: '',
  accountNo: '',
};

export const INITIAL_BANK_TRACKING_STATE: IBankTrackingState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IBankTrackingSuspectForm,
  fileResponse: {} as IBankTrackingAttachment,
  excelResponse: {
    attachment: {} as IUploadFileResponse,
    fileDataList: [],
  },
  graph: {} as IGraph,
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};

export const INITIAL_BANK_TRACKING_ATTACHMENT: IBankTrackingAttachment = {
  bankID: null,
  bankName: '',
  suspectFullName: '',
  submitterFullName: '',
  submitDate: null,
  attachment: {} as IUploadFileResponse,
  fileDataList: [],
};
