import { SelectOption } from 'components/ui/type';

const useFilterTypeDropdownApi = () => {
  const options = [
    { value: 1, text: 'หมายเลขโทรศัพท์' },
    { value: 2, text: 'โทรศัพท์' },
    { value: 3, text: 'บุคคล' },
    { value: 4, text: 'เลขที่บัญชี' },
    { value: 5, text: 'เลขบัตรประชาชน' },
    { value: 6, text: 'kbank' },
    { value: 7, text: 'uobt' },
    { value: 8, text: 'จำนวนครั้งการโอนเงิน' },
    { value: 9, text: 'จำนวนเงิน' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useFilterTypeDropdownApi;
