import React, { FC, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableContext from './TableContext';
import TableHeader from './TableHeader';
import TableBody from '.';
import TableFooter from './TablePagination';
import { TTableColumn } from '../type';
import styled from 'styled-components';
import { TDataTable } from 'shared/types';
import _ from 'lodash';

interface ITableProps {
  columns: TTableColumn[];
  dataSource: TDataTable<any>;
  title?: string;
  isLoading: boolean;
  className?: string;
  spaceCount?: number;
  onGraph?: any;
  onDownload?: any;
  onUpload?: any;
  onFile?: any;
  onEdit?: any;
  onView?: any;
  onDelete?: any;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onModal?: any;
}

const TableWrapper = styled.div`
  display: block;
`;

const StyledTable = styled.div`
  overflow: auto;
`;

const TableInvestigationComponent: FC<ITableProps> = ({
  columns,
  dataSource: data,
  title,
  isLoading,
  className,
  spaceCount,
  onGraph,
  onDownload,
  onUpload,
  onFile,
  onView,
  onEdit,
  onDelete,
  onChangePage,
  onChangeItemsPerPage,
  onModal,
}) => {
  const [selectedItems] = useState<any>([]);

  const itemsEmptyCount =
    data.pageSize - Math.min(data.pageSize, _.get(data, 'table.length', 0) - (data.currentPage - 1) * data.pageSize);

  return (
    <TableWrapper>
      <StyledTable>
        <TableContext.Provider
          value={{
            title: title,
            columns: columns,
            page: data.currentPage - 1,
            items: _.get(data, 'table', []),
            isLoading,
            itemsCount: _.get(data, 'table.length', 0),
            totalCount: data.totalCount,
            itemsEmptyCount: itemsEmptyCount,
            itemsPerPage: data.pageSize,
            selectedItems: selectedItems,
            selectedItemsCount: _.get(selectedItems, 'length', 0),
            onGraph: onGraph,
            onDownload: onDownload,
            onUpload: onUpload,
            onFile: onFile,
            onView: onView,
            onEdit: onEdit,
            onDelete: onDelete,
            spaceCount: spaceCount,
            onChangePage: onChangePage,
            onChangeItemsPerPage: onChangeItemsPerPage,
            onModal: onModal,
          }}
        >
          <Table className={className}>
            <TableHeader />
            <TableBody />
          </Table>
        </TableContext.Provider>
      </StyledTable>

      <TableContext.Provider
        value={{
          title: title,
          columns: columns,
          page: data.currentPage - 1,
          items: _.get(data, 'table', []),
          isLoading,
          itemsCount: _.get(data, 'table.length', 0),
          totalCount: data.totalCount,
          itemsEmptyCount: itemsEmptyCount,
          itemsPerPage: data.pageSize,
          selectedItems: selectedItems,
          selectedItemsCount: _.get(selectedItems, 'length', 0),
          onGraph: onGraph,
          onDownload: onDownload,
          onUpload: onUpload,
          onFile: onFile,
          onView: onView,
          onEdit: onEdit,
          onDelete: onDelete,
          spaceCount: spaceCount,
          onChangePage: onChangePage,
          onChangeItemsPerPage: onChangeItemsPerPage,
          onModal: onModal,
        }}
      >
        {!isLoading && <TableFooter />}
      </TableContext.Provider>
    </TableWrapper>
  );
};

export default TableInvestigationComponent;
