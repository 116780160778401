import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { MediumText, LargeText } from './Font';
import constants from 'shared/constants';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
`;

const InputError = styled(MediumText)`
  height: 24px;
  color: ${constants.RED};
  white-space: nowrap;
`;

const InputInfo = styled(MediumText)`
  color: ${constants.GREY};
`;

interface IInputTemplateProps {
  label?: string;
  className?: string;
  error?: string;
  info?: string;
  required?: boolean;
}

const InputTemplate: FC<IInputTemplateProps> = ({ 
  label, 
  className, 
  error, 
  info,
  required, 
  children,
}) => {
  return (
    <StylesProvider injectFirst>
      <InputWrapper className={className}>
        <LabelWrapper>
          {required && <RequiredLabel className="RequiredLabel">*A4</RequiredLabel>}
          {label && <InputLabel className="InputLabel">{label}</InputLabel>}
        </LabelWrapper>
        {children}
        <InputError className="InputError">{error}</InputError>
        {info && <InputInfo>{info}</InputInfo>}
      </InputWrapper>
    </StylesProvider>
  );
};

export default InputTemplate;
