import {
  INITIAL_TEAM_MANAGEMENT_FORM_STATE,
  INITIAL_TEAM_SEQUESTER_MANAGEMENT_FORM_STATE,
} from 'shared/modules/team-management';
import {
  TeamManagementAction,
  TeamManagementActionTypes,
  ITeamManagementState,
  ITeamSequesterManagementState,
} from './team-management.type';

export const TeamManagementReducer = (
  state: ITeamManagementState = INITIAL_TEAM_MANAGEMENT_FORM_STATE,
  action: TeamManagementActionTypes
): ITeamManagementState => {
  switch (action.type) {
    case TeamManagementAction.SEARCH_TEAMS_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case TeamManagementAction.SEARCH_TEAMS_SUCCESS:
      return {
        ...state,
        all: action.Teams,
        dropdownArres: action.dropdownMap,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case TeamManagementAction.SEARCH_TEAMS_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case TeamManagementAction.ADD_ARREST_TEAMS_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case TeamManagementAction.ADD_ARREST_TEAMS_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.ADD_ARREST_TEAMS_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.ADD_SEQUESTER_TEAMS_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case TeamManagementAction.ADD_SEQUESTER_TEAMS_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.ADD_SEQUESTER_TEAMS_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.DELETE_OFFICERHEADERS_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case TeamManagementAction.DELETE_OFFICERHEADERS_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.DELETE_OFFICERHEADERS_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.DELETE_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case TeamManagementAction.DELETE_CONFISCATE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.DELETE_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export const TeamManagementSequesterReducer = (
  state: ITeamSequesterManagementState = INITIAL_TEAM_SEQUESTER_MANAGEMENT_FORM_STATE,
  action: TeamManagementActionTypes
): ITeamSequesterManagementState => {
  switch (action.type) {
    case TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_SUCCESS:
      return {
        ...state,
        all: action.TeamsSequester,
        dropdownSequester: action.dropdownMap,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
