import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StylesProvider } from '@material-ui/styles';
// import { StylesProvider } from '@material-ui/core';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';
import { ButtonPreset } from './enum';
import { MediumText } from './Font';

interface IButtonProps {
  className?: string;
  loading?: boolean;
  preset?: ButtonPreset;
  disabled?: boolean;
  onClick?(event: SyntheticEvent): void;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
}

interface IStyledButtonProps {
  textcolor?: string;
  backgroundcolor?: string;
  bordercolor?: string;
  textDecoration?: string;
}

const ButtonText = styled(MediumText)``;

const StyledButton = styled(Button)`
  /* width: 150px; */
  width: ${(props) => (props.fullWidth ? '100%' : '180px')};
  padding: 10px !important;
  height: 38px;
  border-radius: 10px !important;
  background-color: ${(p: IStyledButtonProps) => p.backgroundcolor} !important;
  border: 1px solid ${(p: IStyledButtonProps) => p.bordercolor};
  ${ButtonText} {
    color: ${(p: IStyledButtonProps) => p.textcolor};
    text-decoration: ${(p: IStyledButtonProps) => p.textDecoration};
  }

  :hover {
    background-color: ${(p: IStyledButtonProps) => p.backgroundcolor} !important;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  /* pointer-events: ${(props) => (props.disabled ? 'auto' : 'none')}; */
`;

const CircularProgressStyles = styled(CircularProgress)`
  margin-left: 0.6rem;
`;

const SpinnerAdornment: FC = () => <CircularProgressStyles size={20} />;

const CustomButton: FC<IButtonProps> = ({
  className,
  loading = false,
  preset = ButtonPreset.PRIMARY,
  onClick,
  disabled,
  children,
  fullWidth,
  startIcon,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) setIsLoading(true);
    else setIsLoading(false);
  }, [loading]);

  let color;
  let backgroundColor;
  let borderColor = constants.INVISIBLE;
  let textDecoration = 'none';
  switch (preset) {
    case ButtonPreset.PRIMARY:
      color = constants.WHITE;
      backgroundColor = constants.PRIMARY_COLOR;
      break;
    case ButtonPreset.SECONDARY:
      color = constants.WHITE;
      backgroundColor = constants.SECONDARY_COLOR;
      break;
    case ButtonPreset.WARNING:
      color = constants.DARK_BLUE;
      backgroundColor = constants.YELLOW;
      break;
    case ButtonPreset.DANGER:
      color = constants.WHITE;
      backgroundColor = constants.RED;
      break;
    case ButtonPreset.OUTLINE:
      color = constants.PRIMARY_COLOR;
      backgroundColor = constants.INVISIBLE;
      borderColor = constants.PRIMARY_COLOR;
      break;
    case ButtonPreset.LINK:
      color = constants.PRIMARY_COLOR;
      backgroundColor = constants.INVISIBLE;
      textDecoration = 'underline';
      break;
    case ButtonPreset.SUCCESS:
      color = constants.WHITE;
      backgroundColor = constants.GREEN;
      break;
    case ButtonPreset.DISABLED:
      color = constants.WHITE;
      backgroundColor = constants.GREY;
      break;
    case ButtonPreset.DANGER_MINI:
      color = constants.BLACK;
      backgroundColor = constants.LIGHT_RED;
      break;
    case ButtonPreset.SUCCESS_MINI:
      color = constants.BLACK;
      backgroundColor = constants.LIGHT_GREEN;
      break;
    default:
      color = constants.WHITE;
      backgroundColor = constants.PRIMARY_COLOR;
      break;
  }

  return (
    <StylesProvider injectFirst>
      <StyledButton
        className={className}
        onClick={onClick}
        textcolor={color}
        bordercolor={borderColor}
        backgroundcolor={backgroundColor}
        textDecoration={textDecoration}
        disabled={(isLoading && true) || disabled}
        fullWidth={fullWidth}
        // minWidth={minWidth}
        startIcon={startIcon}
      >
        <ButtonText>{children}</ButtonText>
        {isLoading && <SpinnerAdornment />}
      </StyledButton>
    </StylesProvider>
  );
};

export default CustomButton;
