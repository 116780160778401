import {
  ICaseFileAccessRequestForm,
  ICaseFileAccessRequest,
  ICaseFileAccessRequestState,
  ICaseFileInfo,
  ICaseFileAccessRequestFormVaildate,
  ICaseFileNotApprove,
} from './case-file-access-request.interface';
import { ICreateUserForm } from 'shared/modules/user-management';

export const INITIAL_CASE_FILE_ACCESS_REQUEST_FORM: ICaseFileAccessRequestForm = {
  caseFileID: '',
  caseID: '',
  caseName:'',
  detail: '',
  attachFilesDetails: [],
  newAttachments: null,
};

export const INITIAL_CASE_FILE_ACCESS_REQUEST_FORM_VALIDATE: ICaseFileAccessRequestFormVaildate = {
  detail: '',
};

export const INITIAL_CASE_FILE_ACCESS_REQUEST: ICaseFileAccessRequest = {
  caseFileAccessRequestID: '',
  caseFileID: '',
  caseID: '',
  caseFileName: '',
  requestFullName: '',
  detail: '',
  attachFilesDetails: [],
  ownerID: '',
  isAccessRequest: false,
  userInfo: {} as ICreateUserForm,
  isAccept: null,
  prefixName: '',
};

export const INITIAL_CASE_FILE_NOT_APPROVE: ICaseFileNotApprove = {
  caseID: '',
  requestFullName: '',
  caseName: '',
  reason: '',
  updatedBy: '',
  prefixName: '',
};

export const INITIAL_CASE_FILE_ACCESS_REQUEST_STATE: ICaseFileAccessRequestState = {
  selected: {} as ICaseFileAccessRequest,
  info: {} as ICaseFileInfo,
  notApprove: INITIAL_CASE_FILE_NOT_APPROVE,
  error: undefined,
  isPageLoading: false,
  isButtonLoading: false,
};
