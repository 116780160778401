import { SelectOption } from 'components/ui/type';

const useSocialMediaDropdownApi = () => {
  const options = [
    { value: 1, text: 'LINE (ไลน์)' },
    { value: 2, text: 'FACEBOOK (เฟซบุ๊ก)' },
    { value: 3, text: 'WHATSAPP (วอทสแอป)' },
    { value: 4, text: 'WECHAT (วีแชท)' },
    { value: 5, text: 'TWITTER (ทวิตเตอร์)' },
    { value: 6, text: 'BETALK (บีทอค)' },
    { value: 7, text: 'INSTAGRAM (อินสตาแกรม)' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useSocialMediaDropdownApi;
