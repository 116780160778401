import { INITIAL_RECIVE_BANK_LIST } from '../recive-bank/recive-bank.constant';
import { INITIAL_RECIVE_PHONE_LIST } from '../recive-phone/recive-phone.constant';
import {
  IProperty,
  IPhoneProperty,
  ICarProperty,
  IPersonProperty,
  IBankAccountProperty,
  IMoneyProperty,
  IOtherProperty,
  IPropertyVaildate,
  IPhonePropertyVaildate,
  IPersonPropertyVaildate,
  IBankAccountPropertyVaildate,
  IMoneyPropertyVaildate,
  IDrugProperty,
  IPhonePropertySim,
  ISimPropertyVaildate,
  ISimProperty,
  IDrugPropertyVaildate,
  ICarPropertyVaildate,
  IAtmPropertyVaildate,
  IAtmProperty,
  IPropertyBank,
  IReciveBankPropertyVaildate,
  IRecivePhonePropertyVaildate,
  IRecivePersonPropertyVaildate,
  IRecivePropsValidate,
} from './property.interface';

export const INITIAL_PHONE_PROPERTY_SIM: IPhonePropertySim = {
  phoneNumber: '',
  imei: '',
  imsi: '',
  operatorID: null,
  operator: '',
  operatorName: '',
  operationDetail: '',
  additionalInformation: '',
};

export const INITIAL_PHONE_PROPERTY: IPhoneProperty = {
  mobilePhoneBrandID: null,
  mobilePhoneBrandName: '',
  phoneNumber: '',
  phoneOwner: '',
  imei: '',
  imsi: '',
  phoneOwnership: '',
  additionalPhoneInformation: '',
  sim: [INITIAL_PHONE_PROPERTY_SIM],
  // sim: [],
  locationDetail: '',
  operationDetail: '',
  phoneColor: '',
  phoneModel: '',
};

export const INITIAL_CAR_PROPERTY: ICarProperty = {
  carTypeID: null,
  carTypeName: '',
  carBrandID: null,
  carBrandName: '',
  carSerieID: null,
  carSerieName: '',
  licensePlateNo: '',
  licensePlateNoFirst: '',
  licensePlateNoLast: '',
  licenseProvinceID: null,
  licenseProvinceName: '',
  carOwner: '',
  carNumber: '',
  tankNumber: '',
  carOwnership: '',
  additionalCarInformation: '',
  locationDetail: '',
  operationDetail: '',
  carColor: '',
  carColorID: null,
  carColorName: '',
  checkCarColor: false,
  carBrand: null,
  carSerie: null,
  licenseProvince: null,
};

export const INITIAL_PERSON_PROPERTY: IPersonProperty = {
  firstName: '',
  lastName: '',
  age: '',
  idCard: '',
  gender: '',
  locationDetail: '',
  operationDetail: '',
};

export const INITIAL_BANK_ACCOUNT_PROPERTY: IBankAccountProperty = {
  bankID: null,
  bankName: '',
  accountNo: '',
  accountName: '',
  accountPhoneNumber: '',
  sms: '',
  otp: '',
  promptPay: '',
  promptPayCitizen: '', //
  bankAccountInformation: '',
  locationDetail: '',
  operationDetail: '',
};

export const INITIAL_ATM_PROPERTY: IAtmProperty = {
  bankID: 0,
  cardOwner: '',
  cardNo: '',
  detail: '',
  locationDetail: '',
  operationDetail: '',
};

export const INITIAL_MONEY_PROPERTY: IMoneyProperty = {
  currencyTypeID: null,
  currencyTypeName: '',
  totalAmount: '',
  moneyInformation: '',
  locationDetail: '',
  operationDetail: '',
};

export const INITIAL_DRUG_PROPERTY: IDrugProperty = {
  drugName: '',
  unitID: null,
  unit: '',
  amount: '',
  drugTypeID: null,
  drugType: '',
  detail: '',
  locationDetail: '',
  operationDetail: '',
};

export const INITIAL_OTHER_PROPERTY: IOtherProperty = {
  other: '',
  locationDetail: '',
  operationDetail: '',
};

export const INITIAL_SIM_PROPERTY: ISimProperty = {
  phoneNumber: '',
  imsi: '',
  operationDetail: '',
  operatorID: null,
  operatorName: '',
  operator: '',
};

export const INITIAL_PROPERTY: IProperty = {
  phone: [],
  car: [],
  person: [],
  bankAccount: [],
  money: [],
  drug: [],
  other: [],
  sim: [],
  atm: [],
};
export const INITIAL_PERSON_VALIDATE: IPersonPropertyVaildate = {
  firstName: '',
  lastName: '',
  idCard: '',
};
export const INITIAL_PHONE_VALIDATE: IPhonePropertyVaildate = {
  phoneNumber: '',
};

export const INITIAL_DRUG_VALIDATE: IDrugPropertyVaildate = {
  amount: '',
};

export const INITIAL_BANK_ACCOUNT_VALIDATE: IBankAccountPropertyVaildate = {
  accountNo: '',
  accountPhoneNumber: '',
  sms: '',
  otp: '',
  promptPay: '',
  promptPayCitizen: '',
};
export const INITIAL_MONEY_VALIDATE: IMoneyPropertyVaildate = {
  totalAmount: '',
};

export const INITIAL_ATM_VALIDATE: IAtmPropertyVaildate = {
  cardOwner: '',
};

export const INITIAL_SIM_VALIDATE: ISimPropertyVaildate = {
  phoneNumber: '',
};

export const INITIAL_CAR_VALIDATE: ICarPropertyVaildate = {
  licensePlateNoLast: '',
  licensePlateNoFirst: '',
};

export const INITIAL_PROPERTY_VALIDATE: IPropertyVaildate = {
  phone: [INITIAL_PHONE_VALIDATE],
  bankAccount: [INITIAL_BANK_ACCOUNT_VALIDATE],
};

export const INITIAL_PROPERTY_BANK: IPropertyBank = {
  bank: [],
  phone: [],
};

export const INITIAL_RECIVE_BANK_VALIDATE: IReciveBankPropertyVaildate = {
  id: '',
  list: [INITIAL_RECIVE_BANK_LIST],
  bankName: '',
  bankNumber: '',
  detail: '',
};

export const INITIAL_RECIVE_PHONE_VALIDATE: IRecivePhonePropertyVaildate = {
  id: '',
  list: [INITIAL_RECIVE_PHONE_LIST],
  phoneNumber: '',
  detailPhone: '',
  idCard: '',
  passport: '',
  firstName: '',
  middleName: '',
  lastName: '',
  detail: '',
  checkType: 0,
};

export const INITIAL_RECIVE_PERSON_VALIDATE: IRecivePersonPropertyVaildate = {
  list: [INITIAL_RECIVE_BANK_LIST],
  idCard: '',
  passport: '',
  firstName: '',
  middleName: '',
  lastName: '',
  detail: '',
  bankID: null,
  bankNumber: '',
  checkType: 0,
};

export const INITIAL_RECIVE_PROPS_VALIDATE: IRecivePropsValidate = {
  idCard: '',
};

// export const INITIAL_RECIVE_PROPS_VALIDATE: IRecivePropsValidate = {
//   requestDetail: '',
// }
