import moment from 'moment';
import {
  Address,
  AddressHouseDetail,
  AllName,
  BillList,
  CAList,
  Cdr,
  Datum,
  House,
  ILinkageMoveHouse,
  ILinkageNSB,
  ILinkageNSBCar,
  ILinkageNSBGunLicense,
  ILinkageNSBPensonInHouse,
  ILinkageNSBPersonDetail,
  ILinkageNSBPersonDetailID,
  ILinkageNSBPersonHouseDetail,
  ILinkageNSBPhone,
  ILinkageNSBState,
  ILinkageNSBUtilPea,
  ILinkageNSBUtilPwa,
  ILinkagePersonMarried,
  Inform,
  MarriedDetail,
  MarriedLogs,
  Move,
  Own,
  PersonalInHouse,
  PwaCAList,
  Ther,
  TOTAsset,
  Totba,
  Totca,
} from './linkage-nsb.interface';

export const INITIAL_LINKAGE_NSB_STATE: ILinkageNSBState = {
  error: undefined,
  isPageLoading: false,
};

export const INITIAL_LINKAGE: ILinkageNSB = {
  pid: '',
  fname: '',
  lname: '',
  hid: '',
  A: '',
  D: '',
  E: '',
  F: '',
  G: '',
  H: '',
  I: '',
  J: '',
  K: '',
  L: '',
  M: '',
  N: '',
  O: '',
  record: '1',
  licenseProvinceID: null,
  AddProvinceID: null,
  AddDistrictID: null,
  AddSubdistrictsID: null,
  typeProvinceID: null,
};

export const INITIAL_LINKAGE_PersonDetail: ILinkageNSBPersonDetail = {
  age: '',
  dateOfBirth: 0,
  dateOfMoveIn: 0,
  englishFirstName: '',
  englishLastName: '',
  englishMiddleName: '',
  englishTitleDesc: '',
  fatherName: '',
  fatherNationalityCode: 0,
  fatherNationalityDesc: '',
  fatherPersonalID: 0,
  firstName: '',
  fullnameAndRank: '',
  genderCode: '',
  genderDesc: '',
  lastName: '',
  middleName: '',
  motherName: '',
  motherNationalityCode: 0,
  motherNationalityDesc: '',
  motherPersonalID: 0,
  nationalityCode: 0,
  nationalityDesc: '',
  ownerStatusDesc: '',
  statusOfPersonCode: 0,
  statusOfPersonDesc: '',
  titleCode: 0,
  titleDesc: '',
  titleName: '',
  titleSex: 0,
  pid: 0,
  totalRecord: 0,
};

export const INITIAL_PERSON_DETAIL_TEST: ILinkageNSBPersonDetailID = {
  card: {
    address: {
      alleyDesc: '',
      alleyWayDesc: '',
      districtDesc: '',
      houseNo: '',
      provinceDesc: '',
      roadDesc: '',
      subdistrictDesc: '',
      villageNo: 0,
    },
    birthDate: 0,
    blood: '',
    cancelCause: '',
    documentNumber: '',
    expireDate: '',
    foreignCountry: '',
    foreignCountryCity: '',
    issueDate: 0,
    issueTime: 0,
    nameEN: {
      firstName: '',
      lastName: '',
      middleName: '',
      title: '',
    },
    nameTH: {
      firstName: '',
      lastName: '',
      middleName: '',
      title: '',
    },
    personalID: 0,
    phoneNumber: '',
    religion: '',
    religionOther: '',
    sex: '',
  },
  houseDetail: {
    alleyCode: 0,
    alleyDesc: null,
    alleyEnglishDesc: null,
    alleyWayCode: 0,
    alleyWayDesc: null,
    alleyWayEnglishDesc: null,
    dateOfTerminate: 0,
    districtCode: 0,
    districtDesc: '',
    districtEnglishDesc: '',
    houseID: 0,
    houseNo: '',
    houseType: 0,
    houseTypeDesc: '',
    provinceCode: 0,
    provinceDesc: '',
    provinceEnglishDesc: '',
    rcodeCode: '',
    rcodeDesc: '',
    roadCode: '',
    roadDesc: null,
    roadEnglishDesc: null,
    subdistrictCode: 0,
    subdistrictDesc: '',
    subdistrictEnglishDesc: '',
    villageNo: 0,
  },
  personInHouse: [],
  image: {
    image: '',
    mineType: '',
    personalID: 0,
  },
};

export const EMPTY_BILL_LIST: BillList = {
  billPeriod: '',
  cost: 0,
  dueDate: new Date(),
  invoiceNo: '',
  paydate: new Date(),
  recordDate: new Date(),
  unit: 0,
};

export const EMPTY_CA_LIST: CAList = {
  billList: [EMPTY_BILL_LIST],
  caNo: '',
  class: '',
  companyName: '',
  distict: '',
  homeNo: '',
  meterSize: '',
  moo: '',
  name: '',
  peaName: '',
  postCode: '',
  province: '',
  regisDate: null,
  room: '',
  street: '',
  subDistrict: '',
  villageName: '',
  floor: '',
};

export const INITIAL_LINKAGENSB_UTIL_PEA: ILinkageNSBUtilPea = {
  caList: [EMPTY_CA_LIST],
  rowCount: '',
  status: '',
};

export const INITIAL_MARRIED_DETAIL: MarriedDetail = {
  femaleAge: 0,
  femaleDateOfBirth: 0,
  femaleFirstName: '',
  femaleFullnameAndRank: '',
  femaleLastName: '',
  femaleMiddleName: '',
  femaleNationalityCode: 0,
  femaleNationalityDesc: '',
  femaleOtherDocID: '',
  femalePID: 0,
  femaleTitleCode: 0,
  femaleTitleDesc: '',
  maleAge: 0,
  maleDateOfBirth: 0,
  maleFirstName: '',
  maleFullnameAndRank: '',
  maleLastName: '',
  maleMiddleName: '',
  maleNationalityCode: 0,
  maleNationalityDesc: '',
  maleOtherDocID: '',
  malePID: 0,
  maleTitleCode: 0,
  maleTitleDesc: '',
  marryDate: 0,
  marryID: 0,
  marryPlace: '',
  marryPlaceDesc: '',
  marryPlaceProvince: '',
  marryTime: 0,
  marryType: '',
};

export const INITIAL_MARRIED_LOGS: MarriedLogs = {
  allMarry: [],
  total: 0,
};

export const INITIAL_LINKAGE_PERSON_MARRIED: ILinkagePersonMarried = {
  marriedLogs: INITIAL_MARRIED_LOGS,
  marriedDetail: INITIAL_MARRIED_DETAIL,
};

export const INITIAL_MOVE: Move = {
  description: '',
  documentNo: '',
  house: {
    address: {
      alleyDesc: '',
      alleyWayDesc: '',
      districtDesc: '',
      houseNo: '',
      provinceDesc: '',
      roadDesc: '',
      subdistrictDesc: '',
      villageNo: '',
    },
    houseID: 0,
    houseOwner: '',
    moveDate: 0,
  },
  inform: {
    rcodeCode: '',
    rcodeDesc: '',
  },
  oldDateMoveIn: 0,
  own: {
    dateOfBirth: 0,
    father: {
      name: '',
      nationalityDesc: '',
      personalID: 0,
    },
    firstName: '',
    gender: '',
    lastName: '',
    middleName: '',
    mother: {
      name: '',
      nationalityDesc: '',
      personalID: 0,
    },
    nationalityDesc: '',
    personalID: 0,
    titleDesc: '',
  },
  petitionNo: '',
  terminateDate: 0,
  type: 0,
};

export const INITIAL_LINKAGE_MOVE_HOUSE: ILinkageMoveHouse = {
  movedIn: {
    move: [INITIAL_MOVE],
  },
  movedOut: {
    move: [INITIAL_MOVE],
  },
};

//Phone
export const INITIAL_LINKAGE_NSB_PHONE: ILinkageNSBPhone = {
  toTjsonResult: {
    responseResult: {
      responseCode: '',
      responseDateTime: '',
      responseDesc: '',
      responseOK: false,
    },
    totca: [],
  },
};

// Define initial state for Totca
export const INITIAL_TOTCA: Totca = {
  cA_Address_Detail: '',
  cA_CitizenID: '',
  cA_CompanyName: '',
  cA_CreatedDate: '',
  cA_Email: '',
  cA_FaxNo: '',
  cA_FaxNoExt: '',
  cA_Firstname: '',
  cA_ID: '',
  cA_Lastname: '',
  cA_MobileNo1: '',
  cA_MobileNo2: '',
  cA_PhoneExtNo: '',
  cA_PhoneNo: '',
  cA_Status: '',
  cA_Title: '',
  totba: [],
};

// Define initial state for Totba
export const INITIAL_TOTBA: Totba = {
  bA_AccountStatus: '',
  bA_Address_detail: '',
  bA_BillHanding: '',
  bA_Email: '',
  bA_FirstName: '',
  bA_FullName: '',
  bA_HomePhone: '',
  bA_ID: '',
  bA_LastName: '',
  bA_Mobile: '',
  bA_PaymentMethod: '',
  bA_TelephoneDept: '',
  bA_Title: '',
  totAsset: [],
};

// Define initial state for TOTAsset
export const INITIAL_TOT_ASSET: TOTAsset = {
  asset_Address_Detail: '',
  asset_EndDate: '',
  asset_No: '',
  asset_StartDate: '',
  asset_Status: '',
  asset_product: '',
  cdr: [],
};

// Define initial state for Cdr
export const INITIAL_CDR: Cdr = {
  cdR_Data: null,
  cdR_EventDest: null,
  cdR_EventDetail: null,
  cdR_EventDtm: null,
  cdR_EventSource: null,
  cdR_Result: '',
  cdR_SMS: null,
  cdR_Talk: null,
};

export const INITIAL_DATUM: Datum = {
  note: '',
  color: '',
  regDate: '',
  own_fname: '',
  taxExpired: '',
  occ_lname: '',
  own_pid: '',
  expDate: '',
  own_addr: '',
  vehTypeDesc: '',
  brnDesc: '',
  engBrnDesc: '',
  numEng: '',
  cc: '',
  mfgYear: '',
  wgtCar: '',
  oldLastSignDate: '',
  plate2: '',
  occ_addr: '',
  gasNo: '',
  carStatus: '',
  plate1: '',
  kindDesc: '',
  lastChkDate: '',
  holdFlag: '',
  modelName: '',
  cly: '',
  lpgGasExpireDate: '',
  offLocCode: '',
  noteDate: '',
  occ_fname: '',
  gasEndCertDate: '',
  occ_pid: '',
  own_lname: '',
  lastSignDate: '',
  fuelDesc: '',
  numBody: '',
};

// Define initial state for ILinkageNSBCar
export const INITIAL_LINKAGE_NSB_CAR: ILinkageNSBCar = {
  data: [INITIAL_DATUM],
  message: '',
  status: '',
};

export const INITIAL_LINKAGE_ADDRESS_HOUSE_DETAIL: AddressHouseDetail = {
  alleyCode: 0,
  alleyDesc: null,
  alleyEnglishDesc: null,
  alleyWayCode: 0,
  alleyWayDesc: null,
  alleyWayEnglishDesc: null,
  dateOfTerminate: 0,
  districtCode: 0,
  districtDesc: '',
  districtEnglishDesc: '',
  houseID: 0,
  houseNo: '',
  houseType: 0,
  houseTypeDesc: '',
  provinceCode: 0,
  provinceDesc: '',
  provinceEnglishDesc: '',
  rcodeCode: '',
  rcodeDesc: '',
  roadCode: 0,
  roadDesc: null,
  roadEnglishDesc: null,
  subdistrictCode: 0,
  subdistrictDesc: '',
  subdistrictEnglishDesc: '',
  villageNo: 0,
};

export const INITIAL_LINKAGE_PERSON_HOUSE_DETAIL: ILinkageNSBPersonHouseDetail = {
  data: [INITIAL_LINKAGE_ADDRESS_HOUSE_DETAIL],
};

export const INITIAL_PERSONAL_IN_HOUSE: PersonalInHouse = {
  age: 0,
  fullName: '',
  gender: '',
  personalID: 0,
  statusOfPersonDesc: '',
};

export const INITIAL_LINKAGE_PERSON_IN_HOUSE: ILinkageNSBPensonInHouse = {
  personalInHouse: [INITIAL_PERSONAL_IN_HOUSE],
  total: 0,
};

export const INITIAL_UTIL_PWA_RESPONSE = {
  apiResponse: [],
};

export const INITIAL_UTIL_PWA: PwaCAList = {
  address: '',
  amount: '',
  barcode: '',
  custcode: '',
  custname: '',
  docdate: '', // Default to current date
  docno: '',
  duedate: '', // Default to current date
  unit: '',
  use12m: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
  },
  vat: '',
};

export const INITIAL_LINKAGENSB_UTIL_PWA: ILinkageNSBUtilPwa = {
  caList: [INITIAL_UTIL_PWA],
};

//Gun
export const INITIAL_ALL_NAME: AllName = {
  amphorDesc: '',
  amphorDesc2: '',
  applicantType: 0,
  businessName: '',
  businessType: '',
  districtDesc: '',
  districtDesc2: '',
  docDate: 0,
  docID: '',
  docPlace: '',
  docPlaceDesc: '',
  docPlaceProvince: '',
  expireDate: 0,
  firstName: '',
  firstName2: '',
  fullNameAndRank: '',
  fullNameAndRank2: '',
  genderCode: 0,
  genderCode2: 0,
  genderDesc: '',
  genderDesc2: '',
  gunCharacteristic: '',
  gunProduct: '',
  gunRegistrationId: '',
  gunSerialNo: '',
  gunSize: '',
  gunType: '',
  hid: 0,
  hid2: 0,
  hidRcodeCode: '',
  hidRcodeCode2: '',
  hidRcodeDesc: '',
  hidRcodeDesc2: '',
  hno: '',
  hno2: '',
  lastName: '',
  lastName2: '',
  middleName: '',
  middleName2: '',
  personalId: 0,
  personalId2: 0,
  provinceDesc: '',
  provinceDesc2: '',
  signFullName: '',
  signTitleDesc: '',
  soi: '',
  soi2: '',
  thanon: '',
  thanon2: '',
  titleCode: 0,
  titleCode2: 0,
  titleDesc: '',
  titleDesc2: '',
  trok: '',
  trok2: '',
};

export const INITIAL_GUN_LICENSE: ILinkageNSBGunLicense = {
  allName: [INITIAL_ALL_NAME], // ใช้ค่าคงที่ที่สร้างไว้ข้างต้น
  processTimestamp: 0,
  remark: '',
  total: 0,
};
