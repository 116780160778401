import { TInvestigationAttachment, EInvestigationAttachmentAction } from './investigation-attachment.type';
import {
  IInvestigationAttachmentState,
  INITIAL_INVESTIGATION_ATTACHMENT_STATE,
} from 'shared/modules/investigation-attachment';

export default (
  state: IInvestigationAttachmentState = INITIAL_INVESTIGATION_ATTACHMENT_STATE,
  action: TInvestigationAttachment
): IInvestigationAttachmentState => {
  switch (action.type) {
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_POST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_GET_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationAttachmentAction.INVESTIGATION_ATTACHMENT_DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    default:
      return state;
  }
};
