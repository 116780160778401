import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCarTypeDropdownApi = () => {
  const [carTypes, isLoading, isError] = useFetchApi<IDropdown[]>('/carType/getDropdownList', []);

  const carTypeSelectOptions: SelectOption[] = carTypes.map((carType) => {
    return {
      text: carType.text,
      value: carType.id,
    };
  });
  return [carTypeSelectOptions, isLoading, isError] as const;
};
export default useCarTypeDropdownApi;
