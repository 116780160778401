import { RadioOption } from 'components/ui/type';

const useRegistryStatusDropdownApi = () => {
  const options = [
    { value: 1, text: 'ลงทะเบียน' },
    { value: 2, text: 'ไม่ลงทะเบียน' },
  ];

  const selectOption: RadioOption[] = options.map((element) => {
    return {
      label: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useRegistryStatusDropdownApi;
