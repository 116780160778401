import { Grid, TextField } from '@material-ui/core';
import PaginationTable from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import { Button, LargeText } from 'components/ui';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonPreset } from '../enum';
import { PaginationWrapper } from './styled';
import TableContext from './TableContext';

const StyledPagination = styled.div`
  /* display: block; */

  @media (max-width: 1000px) {
    .MuiToolbar-root {
      display: inline-flex;
    }
  }

  @media (max-width: 425px) {
    .MuiToolbar-gutters {
      padding-left: 0px;
      padding-right: 16px;
    }
  }
  @media (max-width: 400px) {
    .MuiTypography-body2 {
      font-size: 0.675rem;
    }
    .MuiTablePagination-actions {
      margin-left: 4px;
    }
    .MuiTablePagination-select {
      padding-left: 0px;
    }
    .MuiSelect-select.MuiSelect-select {
      font-size: 0.675rem;
    }
  }
`;

const PaginationPage = styled.div`
  display: flex; /* ใช้ Flexbox เพื่อให้ลูกอยู่ในบรรทัดเดียวกัน */
  align-items: center; /* จัดแนวให้ตรงกลางในแนวตั้ง */
  justify-content: flex-end; /* จัดให้อยู่ขวาสุด */
  margin-bottom: -30px;
`;

const SeacrhPagPage = styled.div`
  display: flex; /* ใช้ Flexbox เพื่อให้ลูกอยู่ในบรรทัดเดียวกัน */
  align-items: center; /* จัดแนวให้ตรงกลางในแนวตั้ง */
  justify-content: flex-end; /* จัดให้อยู่ขวาสุด */
  @media (max-width: 630px) {
    flex-direction: column; /* เปลี่ยนจากแนวนอนเป็นแนวตั้ง */
    align-items: flex-start; /* จัดให้อยู่ทางซ้ายสุด */
    justify-content: flex-start;

    /* ปรับระยะห่างของแต่ละองค์ประกอบ */
  }
`;
const ButtonWrapper = styled(Button)`
  width: auto;
`;

export const LargeTextBold = styled(LargeText)`
  font-size: 20px;
  margin-top: 5px;
  @media (max-width: 630px) {
    margin-left: 8px;
  }
  margin-right: 5px;
  color: #000000; /* สีดำ */
`;

const TablePagination: FC = () => {
  const { page } = React.useContext(TableContext);

  const [inputValue, setInputValue] = useState<string>(String(page + 1)); // Store input as string

  return (
    <TableContext.Consumer>
      {({ itemsPerPage, page, totalCount, onChangePage, onChangeItemsPerPage, hidePage = false, onHandleChangePage }) =>
        !hidePage && (
          <>
            {' '}
            <br />
            {onHandleChangePage && (
              <PaginationPage>
                <PaginationWrapper
                  count={Math.ceil(totalCount / itemsPerPage)}
                  page={page + 1}
                  siblingCount={2}
                  onChange={onHandleChangePage}
                />
                <SeacrhPagPage>
                  <LargeTextBold>{'ไปที่หน้า'}</LargeTextBold>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value); // Update input value directly as string
                    }}
                    // onBlur={(e) => {
                    //   const maxPage = Math.ceil(totalCount / itemsPerPage);
                    //   const newPage = Math.min(Math.max(Number(e.target.value), 1), maxPage) - 1; // จำกัดค่าตั้งแต่ 1 ถึง maxPage
                    //   onHandleChangePage(e as ChangeEvent<unknown>, newPage + 1);
                    // }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const maxPage = Math.ceil(totalCount / itemsPerPage);
                        const newPage = Math.min(Math.max(Number(inputValue), 1), maxPage) - 1; // จำกัดค่าตั้งแต่ 1 ถึง maxPage
                        onHandleChangePage(e as ChangeEvent<unknown>, newPage + 1);
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 1, max: Math.ceil(totalCount / itemsPerPage) },
                      style: {
                        height: '30px',
                        width: '80px',
                      },
                    }}
                    size="small"
                    style={{ marginRight: '5px' }} // ปรับความกว้างที่นี่
                  />

                  <ButtonWrapper
                    preset={ButtonPreset.LINK}
                    onClick={() => {
                      const maxPage = Math.ceil(totalCount / itemsPerPage);
                      const newPage = Math.min(Math.max(Number(inputValue), 1), maxPage) - 1; // จำกัดค่าตั้งแต่ 1 ถึง maxPage
                      onHandleChangePage({} as ChangeEvent<unknown>, newPage + 1); // เรียกใช้ฟังก์ชันเพื่อเปลี่ยนหน้า
                    }}
                  >
                    {'Go'}
                  </ButtonWrapper>
                </SeacrhPagPage>
              </PaginationPage>
            )}
            <br />
            <br />
            <PaginationTable
              // rowsPerPageOptions={[5, 10, 25, 100]}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={'ข้อมูลต่อหน้า'}
              // component={'div'}
              component={StyledPagination}
              count={totalCount}
              rowsPerPage={itemsPerPage}
              page={page}
              // onChangePage={onChangePage}
              // onChangeRowsPerPage={onChangeItemsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeItemsPerPage}
              style={{}}
            />
          </>
        )
      }
    </TableContext.Consumer>
  );
};

export default TablePagination;
