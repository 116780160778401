import { TOfficer, EOfficer } from 'store/officer/officer.type';
import { IOfficerState, INITIAL_OFFICER_STATE } from 'shared/modules/officer';

export default (state: IOfficerState = INITIAL_OFFICER_STATE, action: TOfficer): IOfficerState => {
  switch (action.type) {
    case EOfficer.GET_OFFICER_HEADER_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EOfficer.GET_OFFICER_HEADER_SUCCESS:
      return {
        ...state,
        officerHeader: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EOfficer.GET_OFFICER_HEADER_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EOfficer.GET_OFFICER_HEADER_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EOfficer.GET_OFFICER_HEADER_CONFISCATE_SUCCESS:
      return {
        ...state,
        officerHeader: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EOfficer.GET_OFFICER_HEADER_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EOfficer.GET_OFFICER_BY_TEAM_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EOfficer.GET_OFFICER_BY_TEAM_SUCCESS:
      return {
        ...state,
        officerReport: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EOfficer.GET_OFFICER_BY_TEAM_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
