import React from 'react';
import styled from 'styled-components';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import { LargeText } from './Font';
import { Grid } from '@material-ui/core';

const CheckboxTemplate = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center;
`;

const StyledCheckbox = styled(MaterialCheckbox)``;

const CheckboxLabel = styled(LargeText)`
  font-weight: bold;
  font-size: 22px;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;
  align-self: center;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
`;

interface ICheckbox {
  className?: string;
  label?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  required?: boolean;
  labelCheckbox?: string;
}

const LabelCheckbox: React.FC<ICheckbox> = ({
  className,
  label,
  defaultChecked,
  checked = false,
  disabled = false,
  onChange,
  required,
  labelCheckbox
}) => {
  return (
    <CheckboxTemplate className={className}>
      {required && <RequiredLabel className="RequiredLabel">*</RequiredLabel>}
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
      {/* <CheckboxTemplate className={className}> */}
      <StyledCheckbox defaultChecked={defaultChecked} checked={checked} disabled={disabled} onChange={onChange} />
      {labelCheckbox && <CheckboxLabel>{labelCheckbox}</CheckboxLabel>}
      {/* </CheckboxTemplate> */}
    </CheckboxTemplate>
  );
};

export default LabelCheckbox;
