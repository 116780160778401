import {
  IScoreCaseFile,
  IScoreSummarize,
  IScoreCounting,
  IScoreCountingList,
  IScoreSummarizeList,
  IScoreCaseFileList,
} from 'shared/modules/gamification';
import { IGamificationSetting } from 'shared/modules/gamification/gamification.interface';
import { TDataTable } from 'shared/types';

export enum EGamificationAction {
  // GET /gamificationsetting/scoringlist/{id}
  GET_SCORE_COUNTING_REQUEST = 'GET_SCORE_COUNTING_REQUEST',
  GET_SCORE_COUNTING_SUCCESS = 'GET_SCORE_COUNTING_SUCCESS',
  GET_SCORE_COUNTING_FAILED = 'GET_SCORE_COUNTING_FAILED',
  // GET /gamificationsetting/scoringlist
  SEARCH_SCORE_COUNTING_REQUEST = 'SEARCH_SCORE_COUNTING_REQUEST',
  SEARCH_SCORE_COUNTING_SUCCESS = 'SEARCH_SCORE_COUNTING_SUCCESS',
  SEARCH_SCORE_COUNTING_FAILED = 'SEARCH_SCORE_COUNTING_FAILED',
  // POST /gamificationsetting/scoringlist
  CREATE_SCORE_COUNTING_REQUEST = 'CREATE_SCORE_COUNTING_REQUEST',
  CREATE_SCORE_COUNTING_SUCCESS = 'CREATE_SCORE_COUNTING_SUCCESS',
  CREATE_SCORE_COUNTING_FAILED = 'CREATE_SCORE_COUNTING_FAILED',
  // PUT /gamificationsetting/scoringlist/{id}
  EDIT_SCORE_COUNTING_REQUEST = 'EDIT_SCORE_COUNTING_REQUEST',
  EDIT_SCORE_COUNTING_SUCCESS = 'EDIT_SCORE_COUNTING_SUCCESS',
  EDIT_SCORE_COUNTING_FAILED = 'EDIT_SCORE_COUNTING_FAILED',
  // DELETE /gamificationsetting/scoringlist/{id}
  DELETE_SCORE_COUNTING_REQUEST = 'DELETE_SCORE_COUNTING_REQUEST',
  DELETE_SCORE_COUNTING_SUCCESS = 'DELETE_SCORE_COUNTING_SUCCESS',
  DELETE_SCORE_COUNTING_FAILED = 'DELETE_SCORE_COUNTING_FAILED',

  // GET /gamificationsetting/rankscoringlist/{id}
  GET_SCORE_SUMMARIZE_REQUEST = 'GET_SCORE_SUMMARIZE_REQUEST',
  GET_SCORE_SUMMARIZE_SUCCESS = 'GET_SCORE_SUMMARIZE_SUCCESS',
  GET_SCORE_SUMMARIZE_FAILED = 'GET_SCORE_SUMMARIZE_FAILED',
  // GET /gamificationsetting/rankscoringlist
  SEARCH_SCORE_SUMMARIZE_REQUEST = 'SEARCH_SCORE_SUMMARIZE_REQUEST',
  SEARCH_SCORE_SUMMARIZE_SUCCESS = 'SEARCH_SCORE_SUMMARIZE_SUCCESS',
  SEARCH_SCORE_SUMMARIZE_FAILED = 'SEARCH_SCORE_SUMMARIZE_FAILED',
  // POST /gamificationsetting/rankscoringlist
  CREATE_SCORE_SUMMARIZE_REQUEST = 'CREATE_SCORE_SUMMARIZE_REQUEST',
  CREATE_SCORE_SUMMARIZE_SUCCESS = 'CREATE_SCORE_SUMMARIZE_SUCCESS',
  CREATE_SCORE_SUMMARIZE_FAILED = 'CREATE_SCORE_SUMMARIZE_FAILED',
  // PUT /gamificationsetting/rankscoringlist/{id}
  EDIT_SCORE_SUMMARIZE_REQUEST = 'EDIT_SCORE_SUMMARIZE_REQUEST',
  EDIT_SCORE_SUMMARIZE_SUCCESS = 'EDIT_SCORE_SUMMARIZE_SUCCESS',
  EDIT_SCORE_SUMMARIZE_FAILED = 'EDIT_SCORE_SUMMARIZE_FAILED',
  // DELETE /gamificationsetting/rankscoringlist/{id}
  DELETE_SCORE_SUMMARIZE_REQUEST = 'DELETE_SCORE_SUMMARIZE_REQUEST',
  DELETE_SCORE_SUMMARIZE_SUCCESS = 'DELETE_SCORE_SUMMARIZE_SUCCESS',
  DELETE_SCORE_SUMMARIZE_FAILED = 'DELETE_SCORE_SUMMARIZE_FAILED',

  // GET /gamificationsetting/closecasefilescoringlist/{id}
  GET_SCORE_CASE_FILE_REQUEST = 'GET_SCORE_CASE_FILE_REQUEST',
  GET_SCORE_CASE_FILE_SUCCESS = 'GET_SCORE_CASE_FILE_SUCCESS',
  GET_SCORE_CASE_FILE_FAILED = 'GET_SCORE_CASE_FILE_FAILED',
  // GET /gamificationsetting/closecasefilescoringlist
  SEARCH_SCORE_CASE_FILE_REQUEST = 'SEARCH_SCORE_CASE_FILE_REQUEST',
  SEARCH_SCORE_CASE_FILE_SUCCESS = 'SEARCH_SCORE_CASE_FILE_SUCCESS',
  SEARCH_SCORE_CASE_FILE_FAILED = 'SEARCH_SCORE_CASE_FILE_FAILED',
  // POST /gamificationsetting/closecasefilescoringlist
  CREATE_SCORE_CASE_FILE_REQUEST = 'CREATE_SCORE_CASE_FILE_REQUEST',
  CREATE_SCORE_CASE_FILE_SUCCESS = 'CREATE_SCORE_CASE_FILE_SUCCESS',
  CREATE_SCORE_CASE_FILE_FAILED = 'CREATE_SCORE_CASE_FILE_FAILED',
  // PUT /gamificationsetting/closecasefilescoringlist/{id}
  EDIT_SCORE_CASE_FILE_REQUEST = 'EDIT_SCORE_CASE_FILE_REQUEST',
  EDIT_SCORE_CASE_FILE_SUCCESS = 'EDIT_SCORE_CASE_FILE_SUCCESS',
  EDIT_SCORE_CASE_FILE_FAILED = 'EDIT_SCORE_CASE_FILE_FAILED',
  // DELETE /gamificationsetting/closecasefilescoringlist/{id}
  DELETE_SCORE_CASE_FILE_REQUEST = 'DELETE_SCORE_CASE_FILE_REQUEST',
  DELETE_SCORE_CASE_FILE_SUCCESS = 'DELETE_SCORE_CASE_FILE_SUCCESS',
  DELETE_SCORE_CASE_FILE_FAILED = 'DELETE_SCORE_CASE_FILE_FAILED',

  // CLEAR STATE
  CLEAR_GAMIFICATION_STATE = 'CLEAR_GAMIFICATION_STATE',

  // GET /gamificationsetting
  SEARCH_GAMIFICATION_SETTING_REQUEST = 'SEARCH_GAMIFICATION_SETTING_REQUEST',
  SEARCH_GAMIFICATION_SETTING_SUCCESS = 'SEARCH_GAMIFICATION_SETTING_SUCCESS',
  SEARCH_GAMIFICATION_SETTING_FAILED = 'SEARCH_GAMIFICATION_SETTING_FAILED',
}

interface ISearchScoreCountingRequest {
  type: EGamificationAction.SEARCH_SCORE_COUNTING_REQUEST;
}

interface ISearchScoreCountingSuccess {
  type: EGamificationAction.SEARCH_SCORE_COUNTING_SUCCESS;
  scoreCountingList: TDataTable<IScoreCountingList>;
}

interface ISearchScoreCountingFailed {
  type: EGamificationAction.SEARCH_SCORE_COUNTING_FAILED;
  error: Error;
}

interface ICreateScoreCountingRequest {
  type: EGamificationAction.CREATE_SCORE_COUNTING_REQUEST;
}

interface ICreateScoreCountingSuccess {
  type: EGamificationAction.CREATE_SCORE_COUNTING_SUCCESS;
}

interface ICreateScoreCountingFailed {
  type: EGamificationAction.CREATE_SCORE_COUNTING_FAILED;
  error: Error;
}

interface IGetScoreCountingRequest {
  type: EGamificationAction.GET_SCORE_COUNTING_REQUEST;
}

interface IGetScoreCountingSuccess {
  type: EGamificationAction.GET_SCORE_COUNTING_SUCCESS;
  scoreCounting: IScoreCounting;
}

interface IGetScoreCountingFailed {
  type: EGamificationAction.GET_SCORE_COUNTING_FAILED;
  error: Error;
}

interface IEditScoreCountingRequest {
  type: EGamificationAction.EDIT_SCORE_COUNTING_REQUEST;
}

interface IEditScoreCountingSuccess {
  type: EGamificationAction.EDIT_SCORE_COUNTING_SUCCESS;
}

interface IEditScoreCountingFailed {
  type: EGamificationAction.EDIT_SCORE_COUNTING_FAILED;
  error: Error;
}

interface IDeleteScoreCountingRequest {
  type: EGamificationAction.DELETE_SCORE_COUNTING_REQUEST;
}

interface IDeleteScoreCountingSuccess {
  type: EGamificationAction.DELETE_SCORE_COUNTING_SUCCESS;
}

interface IDeleteScoreCountingFailed {
  type: EGamificationAction.DELETE_SCORE_COUNTING_FAILED;
  error: Error;
}

interface ISearchScoreSummarizeRequest {
  type: EGamificationAction.SEARCH_SCORE_SUMMARIZE_REQUEST;
}

interface ISearchScoreSummarizeSuccess {
  type: EGamificationAction.SEARCH_SCORE_SUMMARIZE_SUCCESS;
  scoreSummarizeList: TDataTable<IScoreSummarizeList>;
}

interface ISearchScoreSummarizeFailed {
  type: EGamificationAction.SEARCH_SCORE_SUMMARIZE_FAILED;
  error: Error;
}

interface ICreateScoreSummarizeRequest {
  type: EGamificationAction.CREATE_SCORE_SUMMARIZE_REQUEST;
}

interface ICreateScoreSummarizeSuccess {
  type: EGamificationAction.CREATE_SCORE_SUMMARIZE_SUCCESS;
}

interface ICreateScoreSummarizeFailed {
  type: EGamificationAction.CREATE_SCORE_SUMMARIZE_FAILED;
  error: Error;
}

interface IGetScoreSummarizeRequest {
  type: EGamificationAction.GET_SCORE_SUMMARIZE_REQUEST;
}

interface IGetScoreSummarizeSuccess {
  type: EGamificationAction.GET_SCORE_SUMMARIZE_SUCCESS;
  scoreSummarize: IScoreSummarize;
}

interface IGetScoreSummarizeFailed {
  type: EGamificationAction.GET_SCORE_SUMMARIZE_FAILED;
  error: Error;
}

interface IEditScoreSummarizeRequest {
  type: EGamificationAction.EDIT_SCORE_SUMMARIZE_REQUEST;
}

interface IEditScoreSummarizeSuccess {
  type: EGamificationAction.EDIT_SCORE_SUMMARIZE_SUCCESS;
}

interface IEditScoreSummarizeFailed {
  type: EGamificationAction.EDIT_SCORE_SUMMARIZE_FAILED;
  error: Error;
}

interface IDeleteScoreSummarizeRequest {
  type: EGamificationAction.DELETE_SCORE_SUMMARIZE_REQUEST;
}

interface IDeleteScoreSummarizeSuccess {
  type: EGamificationAction.DELETE_SCORE_SUMMARIZE_SUCCESS;
}

interface IDeleteScoreSummarizeFailed {
  type: EGamificationAction.DELETE_SCORE_SUMMARIZE_FAILED;
  error: Error;
}

interface ISearchScoreCaseFileRequest {
  type: EGamificationAction.SEARCH_SCORE_CASE_FILE_REQUEST;
}

interface ISearchScoreCaseFileSuccess {
  type: EGamificationAction.SEARCH_SCORE_CASE_FILE_SUCCESS;
  scoreCaseFileList: TDataTable<IScoreCaseFileList>;
}

interface ISearchScoreCaseFileFailed {
  type: EGamificationAction.SEARCH_SCORE_CASE_FILE_FAILED;
  error: Error;
}

interface ICreateScoreCaseFileRequest {
  type: EGamificationAction.CREATE_SCORE_CASE_FILE_REQUEST;
}

interface ICreateScoreCaseFileSuccess {
  type: EGamificationAction.CREATE_SCORE_CASE_FILE_SUCCESS;
}

interface ICreateScoreCaseFileFailed {
  type: EGamificationAction.CREATE_SCORE_CASE_FILE_FAILED;
  error: Error;
}

interface IGetScoreCaseFileRequest {
  type: EGamificationAction.GET_SCORE_CASE_FILE_REQUEST;
}

interface IGetScoreCaseFileSuccess {
  type: EGamificationAction.GET_SCORE_CASE_FILE_SUCCESS;
  scoreCaseFile: IScoreCaseFile;
}

interface IGetScoreCaseFileFailed {
  type: EGamificationAction.GET_SCORE_CASE_FILE_FAILED;
  error: Error;
}

interface IEditScoreCaseFileRequest {
  type: EGamificationAction.EDIT_SCORE_CASE_FILE_REQUEST;
}

interface IEditScoreCaseFileSuccess {
  type: EGamificationAction.EDIT_SCORE_CASE_FILE_SUCCESS;
}

interface IEditScoreCaseFileFailed {
  type: EGamificationAction.EDIT_SCORE_CASE_FILE_FAILED;
  error: Error;
}

interface IDeleteScoreCaseFileRequest {
  type: EGamificationAction.DELETE_SCORE_CASE_FILE_REQUEST;
}

interface IDeleteScoreCaseFileSuccess {
  type: EGamificationAction.DELETE_SCORE_CASE_FILE_SUCCESS;
}

interface IDeleteScoreCaseFileFailed {
  type: EGamificationAction.DELETE_SCORE_CASE_FILE_FAILED;
  error: Error;
}

interface IClearGamificationState {
  type: EGamificationAction.CLEAR_GAMIFICATION_STATE;
}

interface ISearchGamificationSettingRequest {
  type: EGamificationAction.SEARCH_GAMIFICATION_SETTING_REQUEST;
}

interface ISearchGamificationSettingSuccess {
  type: EGamificationAction.SEARCH_GAMIFICATION_SETTING_SUCCESS;
  all: TDataTable<IGamificationSetting>;
}

interface ISearchGamificationSettingFailed {
  type: EGamificationAction.SEARCH_GAMIFICATION_SETTING_FAILED;
  error: Error;
}

export type TGamificationTypes =
  | ISearchScoreCountingRequest
  | ISearchScoreCountingSuccess
  | ISearchScoreCountingFailed
  | ICreateScoreCountingRequest
  | ICreateScoreCountingSuccess
  | ICreateScoreCountingFailed
  | IGetScoreCountingRequest
  | IGetScoreCountingSuccess
  | IGetScoreCountingFailed
  | IEditScoreCountingRequest
  | IEditScoreCountingSuccess
  | IEditScoreCountingFailed
  | IDeleteScoreCountingRequest
  | IDeleteScoreCountingSuccess
  | IDeleteScoreCountingFailed
  | ISearchScoreSummarizeRequest
  | ISearchScoreSummarizeSuccess
  | ISearchScoreSummarizeFailed
  | ICreateScoreSummarizeRequest
  | ICreateScoreSummarizeSuccess
  | ICreateScoreSummarizeFailed
  | IGetScoreSummarizeRequest
  | IGetScoreSummarizeSuccess
  | IGetScoreSummarizeFailed
  | IEditScoreSummarizeRequest
  | IEditScoreSummarizeSuccess
  | IEditScoreSummarizeFailed
  | IDeleteScoreSummarizeRequest
  | IDeleteScoreSummarizeSuccess
  | IDeleteScoreSummarizeFailed
  | ISearchScoreCaseFileRequest
  | ISearchScoreCaseFileSuccess
  | ISearchScoreCaseFileFailed
  | ICreateScoreCaseFileRequest
  | ICreateScoreCaseFileSuccess
  | ICreateScoreCaseFileFailed
  | IGetScoreCaseFileRequest
  | IGetScoreCaseFileSuccess
  | IGetScoreCaseFileFailed
  | IEditScoreCaseFileRequest
  | IEditScoreCaseFileSuccess
  | IEditScoreCaseFileFailed
  | IDeleteScoreCaseFileRequest
  | IDeleteScoreCaseFileSuccess
  | IDeleteScoreCaseFileFailed
  | IClearGamificationState
  | ISearchGamificationSettingRequest
  | ISearchGamificationSettingSuccess
  | ISearchGamificationSettingFailed;
