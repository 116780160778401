import { SelectOptionString } from 'components/ui/type';

const useProcessStatusDropdownApi = () => {
  const options = [
    { value: 'W', text: 'กำลังโหลด' },
    { value: 'F', text: 'เสร็จสิ้น' },
    { value: 'N', text: 'ไม่ผ่าน' },
    { value: 'R', text: 'กำลังสร้างความเชื่อมโยง' },
    { value: 'E', text: 'เกิดข้อผิดพลาด' },
  ];

  const selectOption: SelectOptionString[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useProcessStatusDropdownApi;
