import {
    IRoleSettingState,
    IRoleSettingSearchForm,
    IRoleSettingFormValidate,
    IRoleSettingForm,
    IRoleSettingFormDetail,
    IRoleSettingFormMember,
    IRoleSettingPermissionList,
    IModalRoleSettingForm,
    IModalRoleSettingFormValidate,
} from './role-setting.interface';
import {
    IRoleForm,
    IRoleFormValidate,
} from './role-setting.interface';
import {
    INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION,
} from 'shared/constant';
import moment from 'moment';

export const INITIAL_ROLESETTING_STATE: IRoleSettingState = {
    all: INITIAL_DATA_TABLE_TYPE,
    error: undefined,
    isPageLoading: false,
    isTableLoading: false,
    isButtonLoading: false,
};

export const INITIAL_FORM_ROLESETTING_SEARCH: IRoleSettingSearchForm = {
    keyword: '',
    roleName: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_MODAL_FORM_ROLESETTING: IModalRoleSettingForm = {
    roleName: '',
    accessLevelPermissionValue: null,
    accessLevelPermission: ''
};

export const INITIAL_MODAL_FORM_ROLESETTING_VALIDATE: IModalRoleSettingFormValidate = {
    roleName: '',
    accessLevelPermissionValue: '',
}


export const INITIAL_FORM_ROLESETTING: IRoleSettingForm = {

};

export const INITIAL_FORM_ROLESETTING_VALIDATE: IRoleSettingFormValidate = {

}

export const INITIAL_FORM_ROLESETTING_DETAIL: IRoleSettingFormDetail = {
    roleID: null,
    menuAccessID: null,
    permissionName: '',
    viewPermission: undefined,
    createPermission: undefined,
    editPermission: undefined,
    deletePermission: undefined,
    exportPermission: undefined,
}

export const INITIAL_FORM_ROLESETTING_MEMBER: IRoleSettingFormMember = {

}

export const INITIAL_ROLESETTING_LIST: IRoleSettingPermissionList = {
    permissionID: 0,
    roleID: 0,
    menuAccessID: 0,
    permissionName: '',
    viewPermission: false,
    createPermission: false,
    editPermission: false,
    deletePermission: false,
    exportPermission: false,
    createdDateTime: moment(),
    updatedDateTime: moment(),
}

export const INITIAL_FORM_ROLE: IRoleForm = {
    roleName: '',
    accessLevelPermission: ''
}

export const INITIAL_FORM_ROLE_VALIDATE: IRoleFormValidate = {
    roleName: ''
}