import { IInvestigationReportForm, IInvestigationReportList } from 'shared/modules/investigate-report';
import { TDataTable } from 'shared/types';

export enum EInvestigationReportAction {
  SEARCH_INVESTIGATION_REPORT_REQUEST = 'SEARCH_INVESTIGATION_REPORT_REQUEST',
  SEARCH_INVESTIGATION_REPORT_SUCCESS = 'SEARCH_INVESTIGATION_REPORT_SUCCESS',
  SEARCH_INVESTIGATION_REPORT_FAILED = 'SEARCH_INVESTIGATION_REPORT_FAILED',
  CREATE_INVESTIGATION_REPORT_REQUEST = 'CREATE_INVESTIGATION_REPORT_REQUEST',
  CREATE_INVESTIGATION_REPORT_SUCCESS = 'CREATE_INVESTIGATION_REPORT_SUCCESS',
  CREATE_INVESTIGATION_REPORT_FAILED = 'CREATE_INVESTIGATION_REPORT_FAILED',
  GET_INVESTIGATION_REPORT_REQUEST = 'GET_INVESTIGATION_REPORT_REQUEST',
  GET_INVESTIGATION_REPORT_SUCCESS = 'GET_INVESTIGATION_REPORT_SUCCESS',
  GET_INVESTIGATION_REPORT_FAILED = 'GET_INVESTIGATION_REPORT_FAILED',
  UPDATE_INVESTIGATION_REPORT_REQUEST = 'UPDATE_INVESTIGATION_REPORT_REQUEST',
  UPDATE_INVESTIGATION_REPORT_SUCCESS = 'UPDATE_INVESTIGATION_REPORT_SUCCESS',
  UPDATE_INVESTIGATION_REPORT_FAILED = 'UPDATE_INVESTIGATION_REPORT_FAILED',
  DELETE_INVESTIGATION_REPORT_REQUEST = 'DELETE_INVESTIGATION_REPORT_REQUEST',
  DELETE_INVESTIGATION_REPORT_SUCCESS = 'DELETE_INVESTIGATION_REPORT_SUCCESS',
  DELETE_INVESTIGATION_REPORT_FAILED = 'DELETE_INVESTIGATION_REPORT_FAILED',
  CLEAR_INVESTIGATION_REPORT_FORM = 'CLEAR_INVESTIGATION_REPORT_FORM',
}

interface ISearchInvestigationReportRequest {
  type: EInvestigationReportAction.SEARCH_INVESTIGATION_REPORT_REQUEST;
}

interface ISearchInvestigationReportSuccess {
  type: EInvestigationReportAction.SEARCH_INVESTIGATION_REPORT_SUCCESS;
  data: TDataTable<IInvestigationReportList>;
}

interface ISearchInvestigationReportFailed {
  type: EInvestigationReportAction.SEARCH_INVESTIGATION_REPORT_FAILED;
  error: Error;
}

interface ICreateInvestigationReportRequest {
  type: EInvestigationReportAction.CREATE_INVESTIGATION_REPORT_REQUEST;
}

interface ICreateInvestigationReportSuccess {
  type: EInvestigationReportAction.CREATE_INVESTIGATION_REPORT_SUCCESS;
}

interface ICreateInvestigationReportFailed {
  type: EInvestigationReportAction.CREATE_INVESTIGATION_REPORT_FAILED;
  error: Error;
}

interface IGetInvestigationReportRequest {
  type: EInvestigationReportAction.GET_INVESTIGATION_REPORT_REQUEST;
}

interface IGetInvestigationReportSuccess {
  type: EInvestigationReportAction.GET_INVESTIGATION_REPORT_SUCCESS;
  data: IInvestigationReportForm;
}

interface IGetInvestigationReportFailed {
  type: EInvestigationReportAction.GET_INVESTIGATION_REPORT_FAILED;
  error: Error;
}

interface IUpdateInvestigationReportRequest {
  type: EInvestigationReportAction.UPDATE_INVESTIGATION_REPORT_REQUEST;
}

interface IUpdateInvestigationReportSuccess {
  type: EInvestigationReportAction.UPDATE_INVESTIGATION_REPORT_SUCCESS;
}

interface IUpdateInvestigationReportFailed {
  type: EInvestigationReportAction.UPDATE_INVESTIGATION_REPORT_FAILED;
  error: Error;
}

interface IDeleteInvestigationReportRequest {
  type: EInvestigationReportAction.DELETE_INVESTIGATION_REPORT_REQUEST;
}

interface IDeleteInvestigationReportSuccess {
  type: EInvestigationReportAction.DELETE_INVESTIGATION_REPORT_SUCCESS;
}

interface IDeleteInvestigationReportFailed {
  type: EInvestigationReportAction.DELETE_INVESTIGATION_REPORT_FAILED;
  error: Error;
}

interface ICleaerInvestigationReportForm {
  type: EInvestigationReportAction.CLEAR_INVESTIGATION_REPORT_FORM;
}

export type TInvestigationReportActionTypes =
  | ISearchInvestigationReportRequest
  | ISearchInvestigationReportSuccess
  | ISearchInvestigationReportFailed
  | ICreateInvestigationReportRequest
  | ICreateInvestigationReportSuccess
  | ICreateInvestigationReportFailed
  | IGetInvestigationReportRequest
  | IGetInvestigationReportSuccess
  | IGetInvestigationReportFailed
  | IUpdateInvestigationReportRequest
  | IUpdateInvestigationReportSuccess
  | IUpdateInvestigationReportFailed
  | IDeleteInvestigationReportRequest
  | IDeleteInvestigationReportSuccess
  | IDeleteInvestigationReportFailed
  | ICleaerInvestigationReportForm;
