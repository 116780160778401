import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import { INITIAL_RECIVE_BANK_STATE, IReportReciveBank, IReportReciveBankState } from 'shared/modules/recive-bank';
import { EReciveReportAction, TReportReciveBank } from './recive-bank.type';

export default (
  state: IReportReciveBankState = INITIAL_RECIVE_BANK_STATE,
  action: TReportReciveBank
): IReportReciveBankState => {
  switch (action.type) {
    case EReciveReportAction.SEARCH_RECIVE_BANK_REQUEST:
      return {
        ...state,
        isTableLoading: true,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case EReciveReportAction.SEARCH_RECIVE_BANK_SUCCESS:
      return {
        ...state,
        all: action.data,
        isTableLoading: false,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.SEARCH_RECIVE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isTableLoading: false,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CREATE_RECIVE_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.CREATE_RECIVE_BANK_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CREATE_RECIVE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_RECIVE_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.GET_RECIVE_BANK_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_RECIVE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.UPDATE_RECIVE_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.UPDATE_RECIVE_BANK_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.UPDATE_RECIVE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.DELETE_RECIVE_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.DELETE_RECIVE_BANK_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.DELETE_RECIVE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CLEAR_SELECTED_RECIVE_BANK:
      return {
        ...state,
        selected: {} as IReportReciveBank,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EReciveReportAction.GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_SUCCESS:
      return {
        ...state,
        fileUploadBank: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EReciveReportAction.CLEAR_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK:
      return {
        ...state,
        fileUploadBank: INITIAL_DATA_TABLE_TYPE,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
