import { ICaseFileState, INITIAL_CASE_FILE_OFFICER_FORM, INITIAL_CASE_FILE_STATE } from 'shared/modules/case-file';
import { ECaseFileAction, TCaseFileActionTypes } from './case-file.type';

export default (state: ICaseFileState = INITIAL_CASE_FILE_STATE, action: TCaseFileActionTypes): ICaseFileState => {
  switch (action.type) {
    case ECaseFileAction.SEARCH_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case ECaseFileAction.SEARCH_CASE_FILE_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ECaseFileAction.SEARCH_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_CASE_FILE_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        id: action.id,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_USER_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_USER_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.CREATE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.CREATE_CASE_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.CREATE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.CLEAR_CASE_FILE_FORM:
      return {
        ...state,
        selected: { ...INITIAL_CASE_FILE_STATE.selected },
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.EDIT_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.EDIT_CASE_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.EDIT_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_INVESTIGATION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
        isTableLoading: true,
      };
    case ECaseFileAction.GET_INVESTIGATION_SUCCESS:
      return {
        ...state,
        investigations: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ECaseFileAction.GET_INVESTIGATION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ECaseFileAction.GET_PDF_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_PDF_SUCCESS:
      return {
        ...state,
        fileUrl: action.fileUrl,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_PDF_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_INITIAL_OFFICER_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_INITIAL_OFFICER_SUCCESS:
      return {
        ...state,
        initialOfficer: action.initialOfficer,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_INITIAL_OFFICER_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.CLEAR_CASE_FILE_USER:
      return {
        ...state,
        user: { ...INITIAL_CASE_FILE_OFFICER_FORM },
      };
    case ECaseFileAction.DELETE_CASE_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECaseFileAction.DELETE_CASE_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.DELETE_CASE_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_SUCCESS:
      return {
        ...state,
        allReports: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS:
      return {
        ...state,
        investigationReports: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_SUCCESS:
      return {
        ...state,
        arrests: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_SUCCESS:
      return {
        ...state,
        confiscates: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_SUCCESS:
      return {
        ...state,
        searchingReports: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return { ...state };
  }
};
