import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useDrugTypeDropdownApi = () => {
  const [drugTypes, isLoading, isError] = useFetchApi<IDropdown[]>('/drugType/getDropdownList', []);

  const drugTypeSelectOptions: SelectOption[] = drugTypes.map((drugType) => {
    return {
      text: drugType.text,
      value: drugType.id,
    };
  });
  return [drugTypeSelectOptions, isLoading, isError] as const;
};
export default useDrugTypeDropdownApi;
