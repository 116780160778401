import { IModalPhoneUploadRemarkForm, IPhoneUploadAttachment } from 'shared/modules/phone-upload';
import { TDataTable } from 'shared/types';

export enum EPhoneUpload {
  UPLOAD_REQUEST = 'UPLOAD_REQUEST',
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  GET_REQUEST = 'GET_REQUEST',
  GET_SUCCESS = 'GET_SUCCESS',
  GET_FAILED = 'GET_FAILED',
  DELETE_REQUEST = 'DELETE_REQUEST',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  DELETE_FAILED = 'DELETE_FAILED',
  VIEW_REQUEST = 'VIEW_REQUEST',
  VIEW_SUCCESS = 'VIEW_SUCCESS',
  VIEW_FAILED = 'VIEW_FAILED',
  GET_GRAPH_SEARCH_REQUEST = 'GET_GRAPH_SEARCH_REQUEST',
  GET_GRAPH_SEARCH_SUCCESS = 'GET_GRAPH_SEARCH_SUCCESS',
  GET_GRAPH_SEARCH_FAILED = 'GET_GRAPH_SEARCH_FAILED',
  PUT_REQUEST = 'PUT_REQUEST',
  PUT_SUCCESS = 'PUT_SUCCESS',
  PUT_FAILED = 'PUT_FAILED',
  GET_REMARK_REQUEST = 'GET_REMARK_REQUEST',
  GET_REMARK_SUCCESS = 'GET_REMARK_SUCCESS',
  GET_REMARK_FAILED = 'GET_REMARK_FAILED',
}

interface IUploadRequest {
  type: EPhoneUpload.UPLOAD_REQUEST;
}

interface IUploadSuccess {
  type: EPhoneUpload.UPLOAD_SUCCESS;
}

interface IUploadFailed {
  type: EPhoneUpload.UPLOAD_FAILED;
  error;
}

interface IGetRequest {
  type: EPhoneUpload.GET_REQUEST;
}

interface IGetSuccess {
  type: EPhoneUpload.GET_SUCCESS;
  data: TDataTable<IPhoneUploadAttachment>;
}

interface IGetFailed {
  type: EPhoneUpload.GET_FAILED;
  error;
}

interface IDeleteRequest {
  type: EPhoneUpload.DELETE_REQUEST;
}

interface IDeleteSuccess {
  type: EPhoneUpload.DELETE_SUCCESS;
}

interface IDeleteFailed {
  type: EPhoneUpload.DELETE_FAILED;
  error;
}

interface IViewRequest {
  type: EPhoneUpload.VIEW_REQUEST;
}

interface IViewSuccess {
  type: EPhoneUpload.VIEW_SUCCESS;
  data: IModalPhoneUploadRemarkForm;
}

interface IViewFailed {
  type: EPhoneUpload.VIEW_FAILED;
  error;
}

interface IGetSearchRequest {
  type: EPhoneUpload.GET_GRAPH_SEARCH_REQUEST;
}

interface IGetSearchSuccess {
  type: EPhoneUpload.GET_GRAPH_SEARCH_SUCCESS;
  data: TDataTable<IPhoneUploadAttachment>;
}

interface IGetSearchFailed {
  type: EPhoneUpload.GET_GRAPH_SEARCH_FAILED;
  error;
}

interface IPutRequest {
  type: EPhoneUpload.PUT_REQUEST;
}

interface IPutSuccess {
  type: EPhoneUpload.PUT_SUCCESS;
}

interface IPutFailed {
  type: EPhoneUpload.PUT_FAILED;
  error;
}

interface IGetRemarkRequest {
  type: EPhoneUpload.GET_REMARK_REQUEST;
}

interface IGetRemarkSuccess {
  type: EPhoneUpload.GET_REMARK_SUCCESS;
  data: any;
}

interface IGetRemarkFailed {
  type: EPhoneUpload.GET_REMARK_FAILED;
  error;
}

export type TPhoneUpload =
  | IUploadRequest
  | IUploadSuccess
  | IUploadFailed
  | IGetRequest
  | IGetSuccess
  | IGetFailed
  | IDeleteRequest
  | IDeleteSuccess
  | IDeleteFailed
  | IViewRequest
  | IViewSuccess
  | IViewFailed
  | IGetSearchRequest
  | IGetSearchSuccess
  | IGetSearchFailed
  | IPutRequest
  | IPutSuccess
  | IPutFailed
  | IGetRemarkRequest
  | IGetRemarkSuccess
  | IGetRemarkFailed;
