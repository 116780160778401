import React, { FC, useEffect, useState, FocusEvent } from 'react';
import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputTemplate from './InputTemplate';
import { SelectOption } from 'components/ui/type';
import { InputSize, InputVariant } from './enum';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InputLabelTemplate from './InputTemplateLabel';

const SelectBody = styled(Autocomplete)`
  width: 100%;

  .MuiIconButton-root {
    padding: 2px;
  }
`;

const IconButtonWrapper = styled(IconButton)`
  height: 2.5rem;
  float: left;
`;

interface ISelectLabelProps {
    label?: string;
    placeholder?: string;
    className?: string;
    error?: string;
    info?: string;
    variant?: InputVariant;
    size?: InputSize;
    disabled?: boolean;
    value?: number | number[] | string | string[] | null;
    onChange(value: unknown, event: unknown): void;
    onBlur?(event: FocusEvent<HTMLDivElement>): void;
    multiple?: boolean;
    options: SelectOption[];
    isLoading: boolean;
    required?: boolean;
    onModalPDF?(field: string): void;
    field?: string
}

const SelectLabel: FC<ISelectLabelProps> = ({
    label,
    placeholder,
    className,
    error,
    info,
    size = InputSize.SMALL,
    variant = InputVariant.OUTLINED,
    disabled,
    value,
    onChange,
    onBlur,
    multiple = false,
    options = [],
    isLoading,
    required,
    field,
    onModalPDF,
}) => {
    const [optionLabels, setOptionLabels] = useState({});

    useEffect(() => {
        setOptionLabels(options.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {}));
    }, [options]);

    return (
        <>
            <Grid container>
                <Grid xs={4}>
                    <InputLabelTemplate
                        className={className}
                        label={label}
                        info={info}
                        error={error}
                        required={required}
                    />
                </Grid>

                <Grid item xs={onModalPDF ? 10 : 8}>
                    <SelectBody
                        placeholder={placeholder}
                        disabled={disabled}
                        value={isLoading ? (multiple ? [] : null) : value}
                        onChange={(_, newValue) => {
                            if (onChange) onChange(newValue, _);
                        }}
                        onBlur={onBlur}
                        multiple={multiple}
                        options={options.map((option) => option.value)}
                        loading={isLoading}
                        getOptionLabel={(option: any) => optionLabels[option] || ''}
                        noOptionsText={'ไม่พบข้อมูล'}
                        // will use in future
                        // renderOption={(option, { selected }) => (
                        //   <React.Fragment>
                        //     <Checkbox
                        //       icon={icon}
                        //       checkedIcon={checkedIcon}
                        //       style={{ marginRight: 8 }}
                        //       checked={selected}
                        //     />
                        //     {option.title}
                        //   </React.Fragment>
                        // )}
                        renderInput={(param) => <TextField {...param} variant={variant} size={size} error={error ? true : false} label={!value ? placeholder : ''} />}
                    />
                </Grid>{onModalPDF && field && <Grid item xs={2}>
                    <Tooltip title="สร้างแฟ้มใหม่">
                        <IconButtonWrapper >
                            <AddIcon onClick={() => onModalPDF(field)} />
                        </IconButtonWrapper>
                    </Tooltip>
                </Grid>}
            </Grid>

        </>
    );
};

export default SelectLabel;
