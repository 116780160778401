import {
  ISuspectEngine,
  ISuspectEngineGlobalSearch,
  IGlobalSearchSuspect,
  IGlobalSearchAddress,
  IGlobalSearchPhone,
  IGlobalSearchBank,
  IGlobalSearchInvestigate,
  IGlobalSearchReport,
  ISuspectEngineState,
  IGlobalSearchAllReport,
  IGlobalSearchInvestigationReport,
  IGlobalSearchPhoneUpload,
  IGlobalSearchBankUpload,
  IGlobalSearchConfiscate,
  IGlobalSearchSearchingReport,
  IGlobalSearchArrest,
  IGlobalSearchFormRequestPhone,
  IGlobalSearchFormRequestBank,
  IGlobalSearchVehicle,
  IGlobalSearchSocial,
  IGlobalSearchRemark,
  IGlobalSearchCircumstance,
} from './suspect-engine.interface';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';

export const INITIAL_SUSPECT_ENGINE_STATE: ISuspectEngineState = {
  all: INITIAL_DATA_TABLE_TYPE,
  selected: {} as ISuspectEngine,
  error: undefined,
  isPageLoading: false,
  isTableLoading: false,
  isButtonLoading: false,
  suspectedResponse: INITIAL_DATA_TABLE_TYPE,
  isTableSuspectedLoading: false,
  addressResponse: INITIAL_DATA_TABLE_TYPE,
  isTableAddressLoading: false,
  phoneResponse: INITIAL_DATA_TABLE_TYPE,
  isTablePhoneLoading: false,
  bankResponse: INITIAL_DATA_TABLE_TYPE,
  isTableBankLoading: false,
  vehicleResponse: INITIAL_DATA_TABLE_TYPE,
  isTableVehicleLoading: false,
  socialResponse: INITIAL_DATA_TABLE_TYPE,
  isTableSocialLoading: false,
  remarkResponse: INITIAL_DATA_TABLE_TYPE,
  isTableRemarkLoading: false,
  circumstanceResponse: INITIAL_DATA_TABLE_TYPE,
  isTableCircumstanceLoading: false,
  investigateResponse: INITIAL_DATA_TABLE_TYPE,
  isTableInvestigateLoading: false,
  reportResponse: INITIAL_DATA_TABLE_TYPE,
  isTableReportLoading: false,
  allReportResponse: INITIAL_DATA_TABLE_TYPE,
  isTableAllReportLoading: false,
  investigationReportResponse: INITIAL_DATA_TABLE_TYPE,
  isTableInvestigationLoading: false,
  arrestResponse: INITIAL_DATA_TABLE_TYPE,
  isTableArrestLoading: false,
  searchingReportResponse: INITIAL_DATA_TABLE_TYPE,
  isTableSearchingReportLoading: false,
  confiscateResponse: INITIAL_DATA_TABLE_TYPE,
  isTableconfiscateLoading: false,
  phoneUploadResponse: INITIAL_DATA_TABLE_TYPE,
  isTablePhoneUploadLoading: false,
  bankUploadResponse: INITIAL_DATA_TABLE_TYPE,
  isTableBankUploadLoading: false,
  formRequestPhoneResponse: INITIAL_DATA_TABLE_TYPE,
  isTableFormRequestPhoneLoading: false,
  formRequestBankResponse: INITIAL_DATA_TABLE_TYPE,
  isTableFormRequestBankLoading: false,
}

export const INITIAL_SUSPECT_ENGINE_GLOBAL_SEARCH: ISuspectEngineGlobalSearch = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_GLOBAL_SEARCH_SUSPECT: IGlobalSearchSuspect = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
  isTableLoading: false,
};

export const INITIAL_GLOBAL_SEARCH_ADDRESS: IGlobalSearchAddress = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_PHONE: IGlobalSearchPhone = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_BANK: IGlobalSearchBank = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_VEHICLE: IGlobalSearchVehicle = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_SOCIAL: IGlobalSearchSocial = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_REMARK: IGlobalSearchRemark = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_CIRCUMSTANCE: IGlobalSearchCircumstance = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_INVESTIGATE: IGlobalSearchInvestigate = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_REPORT: IGlobalSearchReport = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_ALL_REPORT: IGlobalSearchAllReport = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_INVESTIGATION_REPORT: IGlobalSearchInvestigationReport = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_ARREST: IGlobalSearchArrest = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_SEARCHING_REPORT: IGlobalSearchSearchingReport = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_CONFISCATE: IGlobalSearchConfiscate = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_PHONE_UPLOAD: IGlobalSearchPhoneUpload = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_BANK_UPLOAD: IGlobalSearchBankUpload = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_FORM_REQUEST_PHONE: IGlobalSearchFormRequestPhone = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_GLOBAL_SEARCH_FORM_REQUEST_BANK: IGlobalSearchFormRequestBank = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  all: INITIAL_DATA_TABLE_TYPE,
};

