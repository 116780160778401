import { IDAREList } from 'shared/modules/dare';
import { TDataTable } from 'shared/types';

export enum EDareAction {
  SEARCH_DARE_REQUEST = 'SEARCH_DAR_REQUEST',
  SEARCH_DARE_SUCCESS = 'SEARCH_DARE_SUCCESS',
  SEARCH_DARE_FAILED = 'SEARCH_DARE_FAILED',
}

interface ISearchDARERequest {
  type: EDareAction.SEARCH_DARE_REQUEST;
}

interface ISearchDARESuccess {
  type: EDareAction.SEARCH_DARE_SUCCESS;
  data: TDataTable<IDAREList>;
}

interface ISearchDAREFailed {
  type: EDareAction.SEARCH_DARE_FAILED;
  error: Error;
}

export type TDAREActionTypes = ISearchDARERequest | ISearchDARESuccess | ISearchDAREFailed;
