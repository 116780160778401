import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCarBrandDropdownApi = (carTypeId: number | string | null | undefined) => {
  const requestData = {
    ...(carTypeId ? { carTypeId } : { carTypeId: '0' }),
  };
  const urlParams =
    '?' +
    Object.keys(requestData)
      .map((k) => k + '=' + requestData[k])
      .join('&');
  const [carBrands, isLoading, isError] = useFetchApi<IDropdown[]>('/carBrand/getDropdownList' + urlParams, []);

  const carBrandSelectOptions: SelectOption[] = carBrands.map((carBrand) => {
    return {
      text: carBrand.text,
      value: carBrand.id,
    };
  });
  return [carBrandSelectOptions, isLoading, isError] as const;
};
export default useCarBrandDropdownApi;
