import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPowerOff, faUndo, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileImage from 'assets/images/user.svg';
import { Tooltip } from 'components/ui';
import { ConfirmMessage } from 'components/ui/Alert';
import { AlertStatus } from 'components/ui/enum/alert.enum';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import config from 'shared/config/app.env';
import constants from 'shared/constants';
import { IReducer } from 'store';
import { logout, logoutSso } from 'store/authenticate';
import { getRolePermission, getUserInfo, IUser } from 'store/user';
import styled from 'styled-components';
import ClearCache from './ClearCache';
import Notification from './Notification';
// import { useCookies } from 'react-cookie';
import _ from 'lodash';
import { ELocalStorageForm } from 'shared/enum/local-storage.type';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const AppNameWrapper = styled.div`
  padding-top: 5px;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  @media only screen and (max-width: 767px) {
    /* For tablet: */
    display: none;
  }
`;

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    /* For tablet: */
    margin-left: auto;
  }
`;

const UserProfileInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    /* For tablet: */
    display: none;
  }
`;

const HeaderText = styled.div`
  color: white;
  margin: 0;
  font-size: 18px;
  user-select: none;
  height: 1em;
`;

const UserNameText = styled(HeaderText)`
  font-size: 20px;
  font-weight: bold;
`;

const UserText = styled(HeaderText)`
  font-size: 16px;
  flex: 3;
  margin: 3px;
  width: 70px;
`;

const UserLabel = styled(UserText)`
  flex: 1;
  font-weight: bold;
  margin: 3px;
`;

const UserImage = styled.img`
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_HEIGHT}px;
  margin-right: 10px;
  cursor: pointer;
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #6d6d77;
  height: 50px;
`;

const LogoutButton = styled(FontAwesomeIcon)`
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_HEIGHT}px;
  margin-right: 16px;
  margin-left: 16px;
  cursor: pointer;
`;

const HeaderHighlightText = styled(HeaderText)`
  color: #f7b801;
`;

const TextRow = styled.div`
  display: flex;
`;

export const AppName: FC = () => {
  const { t } = useTranslation('main');
  const history = useHistory();
  const isLinkage = _.get(history, 'location.pathname').includes('linkage');
  return (
    <AppNameWrapper>
      {!isLinkage && (
        <>
          <HeaderText>{t('header.royal_thai_police_th.label')}</HeaderText>
          <HeaderText>{t('header.royal_thai_police.label')}</HeaderText>
          <HeaderHighlightText>{t('header.nacrotic_suppression.label')}</HeaderHighlightText>
        </>
      )}
    </AppNameWrapper>
  );
};

export const UserProfile: FC = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const user: IUser = useSelector((store: IReducer) => store.user.me);
  const history = useHistory();
  const isAuthenticate = useSelector((store: IReducer) => store.authenticate.isAuthenticate);
  // const [cookies, setCookie, removeCookie] = useCookies(['cookie-arrest']);

  useEffect(() => {
    if (isAuthenticate) dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticate && user?.roleID) dispatch(getRolePermission(user?.roleID));
    // dispatch(getRolePermission(2))
  }, [user?.roleID]);

  const onLogout = () => {
    reactLocalStorage.clear();
    window.close();
    return;
    ConfirmMessage(
      t('logout.logout_modal.header.label'),
      AlertStatus.Warning,
      t('logout.logout_modal.description.label')
    ).then((isConfirm) => {
      if (isConfirm) {
        if (config.SSO) dispatch(logoutSso());
        else dispatch(logout(onLogoutSuccess));
        // reactLocalStorage.clear();
        // caches.keys().then((names) => {
        //   names.forEach((name) => {
        //     caches.delete(name);
        //   });
        // });
      }
    });
  };

  const onLogoutSuccess = () => {
    // eslint-disable-next-line no-console
    // const Scookie = cookies['cookie-arrest'];
    const Scookie = reactLocalStorage.getObject(ELocalStorageForm.ARREST_FORM_RECOVERY, {});
    reactLocalStorage.clear();
    // setCookie('cookie-arrest', Scookie);
    reactLocalStorage.setObject(ELocalStorageForm.ARREST_FORM_RECOVERY, Scookie);
    window.location.reload();
  };

  return (
    <UserProfileWrapper>
      {/* NOTE: CONFIGURE CLEAR CACHE */}
      {window.location.href.includes('localhost') && <ClearCache />}
      <Notification />
      <UserProfileInfoWrapper>
        <UserNameText>{`${user?.titleName || ''} ${user?.firstName || ''} ${user?.lastName || ''}`}</UserNameText>
        <TextRow>
          <UserLabel>{t('logout.position.label')}</UserLabel>
          <Tooltip baseText={user?.positionName || ''} title={user?.positionName || ''} />
        </TextRow>
        <TextRow>
          <UserLabel>{t('logout.division.label')}</UserLabel>
          <Tooltip baseText={user?.divisionName || ''} title={user?.divisionName || ''} />
        </TextRow>
      </UserProfileInfoWrapper>
      <UserImage
        src={user?.profileImgUrl ? user.profileImgUrl.filePath : ProfileImage}
        onClick={() => history.push('/user-profile')}
      />
      <VerticalDivider />
      {/* <LogoutButton icon={faPowerOff as IconProp} color={'#fff'} size={'2x'} onClick={onLogout} /> */}
      <LogoutButton icon={faUndoAlt as IconProp} color={'#fff'} size={'2x'} onClick={onLogout} />
    </UserProfileWrapper>
  );
};
