import axios from 'shared/utils/axios';
import { ELogger, TLoggerAction } from './logger.type';
import { ILogger } from 'shared/modules/logger';

export const getModuleLoggerById = (module: string, id: string, cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: ELogger.GET_LOGGER_REQUEST });
    axios
      .get(`/log/${module}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(getModuleLoggerByIdSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(getModuleLoggerByIdFailed(error));
      });
  };
};

const getModuleLoggerByIdSuccess = (data: ILogger[], cb?: Function): TLoggerAction => {
  if (cb) cb();
  return {
    type: ELogger.GET_LOGGER_SUCCESS,
    data,
  };
};

const getModuleLoggerByIdFailed = (error: Error): TLoggerAction => {
  return {
    type: ELogger.GET_LOGGER_FAILED,
    error,
  };
};

export const clearLogger = () => {
  return (dispatch) => {
    dispatch({ type: ELogger.CLEAR_LOGGER });
  };
};
