import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const usePhoneBrandDropdownApi = () => {
  const [phoneBrands, isLoading, isError] = useFetchApi<IDropdown[]>('/phoneBrand/getDropdownList', []);

  const phoneBrandSelectOptions: SelectOption[] = phoneBrands.map((phoneBrand) => {
    return {
      text: phoneBrand.text,
      value: phoneBrand.id,
    };
  });
  return [phoneBrandSelectOptions, isLoading, isError] as const;
};
export default usePhoneBrandDropdownApi;
