export enum ELoggerModule {
  CASEFILE = 'casefile',
  INFORM = 'inform',
  LAWSUIT = 'lawsuit',
  SUSPECT = 'suspect',
  LICENSE_PLATE = 'licensePlate',
  BANK_TRACKING = 'bankTracking',
  ARREST = 'arrest',
  CONFISCATE = 'confiscate',
  INVESTIGATION = 'investigation',
  SEARCHING_REPORT = 'searchingReport',
  COMPANY = 'company',
  INVESTIGATION_REPORT = 'InvestigationReport',
  CASEFILE_FOLDER = 'caseFileFolder'
}
