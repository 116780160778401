import { IReportRecivePhone, IReportRecivePhoneList } from 'shared/modules/recive-phone';
import { TDataTable } from 'shared/types';

export enum EReciveReportAction {
  SEARCH_RECIVE_PHONE_REQUEST = 'SEARCH_RECIVE_PHONE_REQUEST',
  SEARCH_RECIVE_PHONE_SUCCESS = 'SEARCH_RECIVE_PHONE_SUCCESS',
  SEARCH_RECIVE_PHONE_FAILED = 'SEARCH_RECIVE_PHONE_FAILED',
  CREATE_RECIVE_PHONE_REQUEST = 'CREATE_RECIVE_PHONE_REQUEST',
  CREATE_RECIVE_PHONE_SUCCESS = 'CREATE_RECIVE_PHONE_SUCCESS',
  CREATE_RECIVE_PHONE_FAILED = 'CREATE_RECIVE_PHONE_FAILED',
  UPDATE_RECIVE_PHONE_REQUEST = 'UPDATE_RECIVE_PHONE_REQUEST',
  UPDATE_RECIVE_PHONE_SUCCESS = 'UPDATE_RECIVE_PHONE_SUCCESS',
  UPDATE_RECIVE_PHONE_FAILED = 'UPDATE_RECIVE_PHONE_FAILED',
  GET_RECIVE_PHONE_REQUEST = 'GET_RECIVE_PHONE_REQUEST',
  GET_RECIVE_PHONE_SUCCESS = 'GET_RECIVE_PHONE_SUCCESS',
  GET_RECIVE_PHONE_FAILED = 'GET_RECIVE_PHONE_FAILED',
  DELETE_RECIVE_PHONE_REQUEST = 'DELETE_RECIVE_PHONE_REQUEST',
  DELETE_RECIVE_PHONE_SUCCESS = 'DELETE_RECIVE_PHONE_SUCCESS',
  DELETE_RECIVE_PHONE_FAILED = 'DELETE_RECIVE_PHONE_FAILED',
  CLEAR_SELECTED_RECIVE_PHONE = 'CLEAR_SELECTED_RECIVE_PHONE',
  GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_REQUEST = 'GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_REQUEST',
  GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_SUCCESS = 'GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_SUCCESS',
  GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_FAILED = 'GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_FAILED',
  CLEAR_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE = 'CLEAR_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE',
}

interface ISearchReportReciveRequest {
  type: EReciveReportAction.SEARCH_RECIVE_PHONE_REQUEST;
}

interface ISearchReportReciveSuccess {
  type: EReciveReportAction.SEARCH_RECIVE_PHONE_SUCCESS;
  data: TDataTable<IReportRecivePhoneList>;
  // data: IReportRecivePhone;
}

interface ISearchReportReciveFailed {
  type: EReciveReportAction.SEARCH_RECIVE_PHONE_FAILED;
  error: Error;
}

interface ICreateReportReciveRequest {
  type: EReciveReportAction.CREATE_RECIVE_PHONE_REQUEST;
}

interface ICreateReportReciveSuccess {
  type: EReciveReportAction.CREATE_RECIVE_PHONE_SUCCESS;
  // data: TDataTable<IReportRecivePHONEList>;
}

interface ICreateReportReciveFailed {
  type: EReciveReportAction.CREATE_RECIVE_PHONE_FAILED;
  error: Error;
}

interface IGetReportReciveRequest {
  type: EReciveReportAction.GET_RECIVE_PHONE_REQUEST;
}

interface IGetReportReciveSuccess {
  type: EReciveReportAction.GET_RECIVE_PHONE_SUCCESS;
  // data: TDataTable<IReportRecivePhoneList>;
  data: IReportRecivePhone;
}

interface IGetReportReciveFailed {
  type: EReciveReportAction.GET_RECIVE_PHONE_FAILED;
  error: Error;
}

interface IUpdateReportReciveRequest {
  type: EReciveReportAction.UPDATE_RECIVE_PHONE_REQUEST;
}

interface IUpdateReportReciveSuccess {
  type: EReciveReportAction.UPDATE_RECIVE_PHONE_SUCCESS;
  // data: TDataTable<IReportRecivePhoneList>;
  data: any;
}

interface IUpdateReportReciveFailed {
  type: EReciveReportAction.UPDATE_RECIVE_PHONE_FAILED;
  error: Error;
}

interface IDeleteReportReciveRequest {
  type: EReciveReportAction.DELETE_RECIVE_PHONE_REQUEST;
}

interface IDeleteReportReciveSuccess {
  type: EReciveReportAction.DELETE_RECIVE_PHONE_SUCCESS;
  data: TDataTable<IReportRecivePhoneList>;
}

interface IDeleteReportReciveFailed {
  type: EReciveReportAction.DELETE_RECIVE_PHONE_FAILED;
  error: Error;
}

interface IClearSelectedRecivePhone {
  type: EReciveReportAction.CLEAR_SELECTED_RECIVE_PHONE;
}

interface IGetReportRecivePhoneFileUploadPhoneRequest {
  type: EReciveReportAction.GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_REQUEST;
}

interface IGetReportRecivePhoneFileUploadPhoneSuccess {
  type: EReciveReportAction.GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_SUCCESS;
  data: TDataTable<any>;
}

interface IGetReportRecivePhoneFileUploadPhoneFailed {
  type: EReciveReportAction.GET_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE_FAILED;
  error: Error;
}

interface IClearReportRecivePhoneFileUploadPhone {
  type: EReciveReportAction.CLEAR_REPORT_RECIVE_PHONE_FILE_UPLOAD_PHONE;
  error: Error;
}

export type TReportRecivePhone =
  | ISearchReportReciveRequest
  | ISearchReportReciveSuccess
  | ISearchReportReciveFailed
  | ICreateReportReciveRequest
  | ICreateReportReciveSuccess
  | ICreateReportReciveFailed
  | IGetReportReciveRequest
  | IGetReportReciveSuccess
  | IGetReportReciveFailed
  | IUpdateReportReciveRequest
  | IUpdateReportReciveSuccess
  | IUpdateReportReciveFailed
  | IDeleteReportReciveRequest
  | IDeleteReportReciveSuccess
  | IDeleteReportReciveFailed
  | IClearSelectedRecivePhone
  | IUpdateReportReciveFailed
  | IGetReportRecivePhoneFileUploadPhoneRequest
  | IGetReportRecivePhoneFileUploadPhoneSuccess
  | IGetReportRecivePhoneFileUploadPhoneFailed
  | IClearReportRecivePhoneFileUploadPhone;
