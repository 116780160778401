export enum ELocalStorage {
  INIT_URL = 'INIT_URL',
  CURRENT_PAGE = '1',
  PAGE_SIZE = '5',
  PREVIOUS_SELECTED = 'PREVIOUS_SELECTED',
  PHONEUPLOAD_FORM = 'PHONEUPLOAD_FORM',
  PHONEUPLOAD_CURRENTPAGE = 'PHONEUPLOAD_CURRENTPAGE',
  PHONEUPLOAD_PAGESIZE = 'PHONEUPLOAD_PAGESIZE',
  BANKUPLOAD_FORM = 'BANKUPLOAD_FORM',
  BANKUPLOAD_CURRENTPAGE = 'BANKUPLOAD_CURRENTPAGE',
  BANKUPLOAD_PAGESIZE = 'BANKUPLOAD_PAGESIZE',
  ARREST_FORM = 'ARREST_FORM',
  ARREST_CURRENTPAGE = 'ARREST_CURRENTPAGE',
  ARREST_PAGESIZE = 'ARREST_PAGESIZE',
  ARREST_CI_FORM = 'ARREST_CI_FORM',
  ARREST_CI_CURRENTPAGE = 'ARREST_CI_CURRENTPAGE',
  ARREST_CI_PAGESIZE = 'ARREST_CI_PAGESIZE',
  CONFISCATE_FORM = 'CONFISCATE_FORM',
  CONFISCATE_CURRENTPAGE = 'CONFISCATE_CURRENTPAGE',
  CONFISCATE_PAGESIZE = 'CONFISCATE_PAGESIZE',
  SEARCHINGREPORT_FORM = 'SEARCHINGREPORT_FORM',
  SEARCHINGREPORT_CURRENTPAGE = 'SEARCHINGREPORT_CURRENTPAGE',
  SEARCHINGREPORT_PAGESIZE = 'SEARCHINGREPORT_PAGESIZE',
  USERMANAGEMENT_FORM = 'USERMANAGEMENT_FORM',
  USERMANAGEMENT_CURRENTPAGE = 'USERMANAGEMENT_CURRENTPAGE',
  USERMANAGEMENT_PAGESIZE = 'USERMANAGEMENT_PAGESIZE',
  CASEFILE_FORM = 'CASEFILE_FORM',
  CASEFILE_CURRENTPAGE = 'CASEFILE_CURRENTPAGE',
  CASEFILE_PAGESIZE = 'CASEFILE_PAGESIZE',
  INVESTIGATE_FORM = 'INVESTIGATE_FORM',
  INVESTIGATE_CURRENTPAGE = 'INVESTIGATE_CURRENTPAGE',
  INVESTIGATE_PAGESIZE = 'INVESTIGATE_PAGESIZE',
  SUSPECTSEARCH_SUSPECT_FORM = 'SUSPECTSEARCH_SUSPECT_FORM',
  SUSPECTSEARCH_SUSPECT_CURRENTPAGE = 'SUSPECTSEARCH_SUSPECT_CURRENTPAGE',
  SUSPECTSEARCH_SUSPECT_PAGESIZE = 'SUSPECTSEARCH_SUSPECT_PAGESIZE',
  SUSPECTSEARCH_PHONE_FORM = 'SUSPECTSEARCH_PHONE_FORM',
  SUSPECTSEARCH_PHONE_CURRENTPAGE = 'SUSPECTSEARCH_PHONE_CURRENTPAGE',
  SUSPECTSEARCH_PHONE_PAGESIZE = 'SUSPECTSEARCH_PHONE_PAGESIZE',
  SUSPECTSEARCH_BANK_FORM = 'SUSPECTSEARCH_BANK_FORM',
  SUSPECTSEARCH_BANK_CURRENTPAGE = 'SUSPECTSEARCH_BANK_CURRENTPAGE',
  SUSPECTSEARCH_BANK_PAGESIZE = 'SUSPECTSEARCH_BANK_PAGESIZE',
  SUSPECTSEARCH_ADDRESS_FORM = 'SUSPECTSEARCH_ADDRESS_FORM',
  SUSPECTSEARCH_ADDRESS_CURRENTPAGE = 'SUSPECTSEARCH_ADDRESS_CURRENTPAGE',
  SUSPECTSEARCH_ADDRESS_PAGESIZE = 'SUSPECTSEARCH_ADDRESS_PAGESIZE',
  SUSPECTENGINE_SEARCH = 'SUSPECTENGINE_SEARCH',
  SUSPECTENGINE_SUSPECT_CURRENTPAGE = 'SUSPECTENGINE_SUSPECT_CURRENTPAGE',
  SUSPECTENGINE_SUSPECT_PAGESIZE = 'SUSPECTENGINE_SUSPECT_PAGESIZE',
  SUSPECTENGINE_ADDRESS_CURRENTPAGE = 'SUSPECTENGINE_ADDRESS_CURRENTPAGE',
  SUSPECTENGINE_ADDRESS_PAGESIZE = 'SUSPECTENGINE_ADDRESS_PAGESIZE',
  SUSPECTENGINE_PHONE_CURRENTPAGE = 'SUSPECTENGINE_PHONE_CURRENTPAGE',
  SUSPECTENGINE_PHONE_PAGESIZE = 'SUSPECTENGINE_PHONE_PAGESIZE',
  SUSPECTENGINE_BANK_CURRENTPAGE = 'SUSPECTENGINE_BANK_CURRENTPAGE',
  SUSPECTENGINE_BANK_PAGESIZE = 'SUSPECTENGINE_BANK_PAGESIZE',
  SUSPECTENGINE_VEHICLE_CURRENTPAGE = 'SUSPECTENGINE_VEHICLE_CURRENTPAGE',
  SUSPECTENGINE_VEHICLE_PAGESIZE = 'SUSPECTENGINE_VEHICLE_PAGESIZE',
  SUSPECTENGINE_SOCIAL_CURRENTPAGE = 'SUSPECTENGINE_SOCIAL_CURRENTPAGE',
  SUSPECTENGINE_SOCIAL_PAGESIZE = 'SUSPECTENGINE_SOCIAL_PAGESIZE',
  SUSPECTENGINE_REMARK_CURRENTPAGE = 'SUSPECTENGINE_REMARK_CURRENTPAGE',
  SUSPECTENGINE_REMARK_PAGESIZE = 'SUSPECTENGINE_REMARK_PAGESIZE',
  SUSPECTENGINE_CIRCUMSTANCE_CURRENTPAGE = 'SUSPECTENGINE_CIRCUMSTANCE_CURRENTPAGE',
  SUSPECTENGINE_CIRCUMSTANCE_PAGESIZE = 'SUSPECTENGINE_CIRCUMSTANCE_PAGESIZE',
  SUSPECTENGINE_INVESTIGATE_CURRENTPAGE = 'SUSPECTENGINE_INVESTIGATE_CURRENTPAGE',
  SUSPECTENGINE_INVESTIGATE_PAGESIZE = 'SUSPECTENGINE_INVESTIGATE_PAGESIZE',
  SUSPECTENGINE_REPORT_CURRENTPAGE = 'SUSPECTENGINE_REPORT_CURRENTPAGE',
  SUSPECTENGINE_REPORT_PAGESIZE = 'SUSPECTENGINE_REPORT_PAGESIZE',
  SUSPECTENGINE_ALL_REPORT_CURRENTPAGE = 'SUSPECTENGINE_ALL_REPORT_CURRENTPAGE',
  SUSPECTENGINE_INVESTIGATION_REPORT_CURRENTPAGE = 'SUSPECTENGINE_INVESTIGATION_REPORT_CURRENTPAGE',
  SUSPECTENGINE_INVESTIGATION_REPORT_PAGESIZE = 'SUSPECTENGINE_INVESTIGATION_REPORT_PAGESIZE',
  SUSPECTENGINE_ARREST_CURRENTPAGE = 'SUSPECTENGINE_ARREST_CURRENTPAGE',
  SUSPECTENGINE_ARREST_PAGESIZE = 'SUSPECTENGINE_ARREST_PAGESIZE',
  SUSPECTENGINE_SEARCHING_REPORT_CURRENTPAGE = 'SUSPECTENGINE_SEARCHING_REPORT_CURRENTPAGE',
  SUSPECTENGINE_SEARCHING_REPORT_PAGESIZE = 'SUSPECTENGINE_SEARCHING_REPORT_PAGESIZE',
  SUSPECTENGINE_CONFISCATE_CURRENTPAGE = 'SUSPECTENGINE_CONFISCATE_CURRENTPAGE',
  SUSPECTENGINE_CONFISCATE_PAGESIZE = 'SUSPECTENGINE_CONFISCATE_PAGESIZE',
  SUSPECTENGINE_PHONE_UPLOAD_CURRENTPAGE = 'SUSPECTENGINE_PHONE_UPLOAD_CURRENTPAGE',
  SUSPECTENGINE_PHONE_UPLOAD_PAGESIZE = 'SUSPECTENGINE_PHONE_UPLOAD_PAGESIZE',
  SUSPECTENGINE_BANK_UPLOAD_CURRENTPAGE = 'SUSPECTENGINE_BANK_UPLOAD_CURRENTPAGE',
  SUSPECTENGINE_BANK_UPLOAD_PAGESIZE = 'SUSPECTENGINE_BANK_UPLOAD_PAGESIZE',
  SUSPECTENGINE_FORM_REQUEST_PHONE_CURRENTPAGE = 'SUSPECTENGINE_FORM_REQUEST_PHONE_CURRENTPAGE',
  SUSPECTENGINE_FORM_REQUEST_PHONE_PAGESIZE = 'SUSPECTENGINE_FORM_REQUEST_PHONE_PAGESIZE',
  SUSPECTENGINE_FORM_REQUEST_BANK_CURRENTPAGE = 'SUSPECTENGINE_FORM_REQUEST_BANK_CURRENTPAGE',
  SUSPECTENGINE_FORM_REQUEST_BANK_PAGESIZE = 'SUSPECTENGINE_FORM_REQUEST_BANK_PAGESIZE',
  SUSPECTENGINE_ALL_REPORT_PAGESIZE = 'SUSPECTENGINE_ALL_REPORT_PAGESIZE',
  INVESTIGATION_REPORT_CURRENTPAGE = 'INVESTIGATION_REPORT_CURRENTPAGE',
  INVESTIGATION_REPORT_PAGESIZE = 'INVESTIGATION_REPORT_PAGESIZE',
  INVESTIGATION_REPORT_FORM = 'INVESTIGATION_REPORT_FORM',
  INVESTIGAION_ATTACHMENT_LIST_CURRENTPAGE = 'INVESTIGAION_ATTACHMENT_LIST_CURRENTPAGE',
  INVESTIGAION_ATTACHMENT_LIST_PAGESIZE = 'INVESTIGAION_ATTACHMENT_LIST_PAGESIZE',
  INVESTIGAION_ATTACHMENT_LIST_FORM = 'INVESTIGAION_ATTACHMENT_LIST_FORM',
  DOPA_FORM = 'DOPA_FORM',
  DOPA_CURRENTPAGE = 'DOPA_CURRENTPAGE',
  DOPA_PAGESIZE = 'DOPA_CURRENTPAGE',
  DOPA_LOG_FORM = 'DOPA_LOG_FORM',
  DOPA_LOG_CURRENTPAGE = 'DOPA_LOG_CURRENTPAGE',
  DOPA_LOG_PAGESIZE = 'DOPA_LOG_PAGESIZE',
  DOPA_LOG_CODE = 'DOPA_LOG_CODE',
  DOPA_LOG_DOPA_CURRENTPAGE = 'DOPA_LOG_DOPA_CURRENTPAGE',
  DOPA_LOG_DOPA_PAGESIZE = 'DOPA_LOG_DOPA_PAGESIZE',
  DOPA_LOG_PEA_CURRENTPAGE = 'DOPA_LOG_PEA_CURRENTPAGE',
  DOPA_LOG_PEA_PAGESIZE = 'DOPA_LOG_PEA_PAGESIZE',
  DOPA_LOG_PWA_CURRENTPAGE = 'DOPA_LOG_PWA_CURRENTPAGE',
  DOPA_LOG_PWA_PAGESIZE = 'DOPA_LOG_PWA_PAGESIZE',
  DOPA_LOG_DBD_CURRENTPAGE = 'DOPA_LOG_DBD_CURRENTPAGE',
  DOPA_LOG_DBD_PAGESIZE = 'DOPA_LOG_DBD_PAGESIZE',
  DOPA_LOG_MEA_CURRENTPAGE = 'DOPA_LOG_MEA_CURRENTPAGE',
  DOPA_LOG_MEA_PAGESIZE = 'DOPA_LOG_MEA_PAGESIZE',
  DOPA_LOG_MWA_CURRENTPAGE = 'DOPA_LOG_MWA_CURRENTPAGE',
  DOPA_LOG_MWA_PAGESIZE = 'DOPA_LOG_MWA_PAGESIZE',
  DOPA_LOG_PPA_CURRENTPAGE = 'DOPA_LOG_PPA_CURRENTPAGE',
  DOPA_LOG_PPA_PAGESIZE = 'DOPA_LOG_PPA_PAGESIZE',
  DOPA_LOG_PPF_CURRENTPAGE = 'DOPA_LOG_PPF_CURRENTPAGE',
  DOPA_LOG_PPF_PAGESIZE = 'DOPA_LOG_PPF_PAGESIZE',
  DOPA_LOG_PPL_CURRENTPAGE = 'DOPA_LOG_PPL_CURRENTPAGE',
  DOPA_LOG_PPL_PAGESIZE = 'DOPA_LOG_PPL_PAGESIZE',
  RECIVE_BANK_FORM = 'RECIVE_BANK_FORM',
  RECIVE_BANK_CURRENTPAGE = 'RECIVE_BANK_CURRENTPAGE',
  RECIVE_BANK_PAGESIZE = 'RECIVE_BANK_PAGESIZE',
  RECIVE_PHONE_FORM = 'RECIVE_PHONE_FORM',
  RECIVE_PHONE_CURRENTPAGE = 'RECIVE_PHONE_CURRENTPAGE',
  RECIVE_PHONE_PAGESIZE = 'RECIVE_PHONE_PAGESIZE',
  ROLE_SETTING_FORM = 'ROLE_SETTING_FORM',
  ROLE_SETTING_CURRENTPAGE = 'ROLE_SETTING_CURRENTPAGE',
  ROLE_SETTING_PAGESIZE = 'ROLE_SETTING_PAGESIZE',
}

export enum ELocalStorageDropdown {
  DROPDOWN_CASEFILE = 'DROPDOWN_CASEFILE',
  DROPDOWN_CASESTATUS = 'DROPDOWN_CASESTATUS',
  DROPDOWN_ORGANIZATION = 'DROPDOWN_ORGANIZATION',
  DROPDOWN_POSITION = 'DROPDOWN_POSITION',
  DROPDOWN_TEAM = 'DROPDOWN_TEAM',
  DROPDOWN_ROLE = 'DROPDOWN_ROLE',
  DROPDOWN_YEAR = 'DROPDOWN_YEAR',
  DROPDOWN_MONTH = 'DROPDOWN_MONTH',
  DROPDOWN_CREATEBY = 'DROPDOWN_CREATEBY',
  DROPDOWN_INVESTIGATION_NAME = 'DROPDOWN_INVESTIGATION_NAME',
  DROPDOWN_HEADQUARTER = 'DROPDOWN_HEADQUARTER',
  DROPDOWN_DIVISION = 'DROPDOWN_DIVISION',
  DROPDOWN_POLICESTATION = 'DROPDOWN_POLICESTATION',
}

export enum ELocalStorageTable {
  TABLE_PHONEUPLOAD = 'TABLE_PHONEUPLOAD',
  TABLE_PHONEUPLOAD_LOADING = 'TABLE_PHONEUPLOAD_LOADING',
  TABLE_BANKUPLOAD = 'TABLE_BANKUPLOAD',
  TABLE_BANKUPLOAD_LOADING = 'TABLE_BANKUPLOAD_LOADING',
  TABLE_RECIVE_BANK = 'TABLE_RECIVE_BANK',
  TABLE_RECIVE_BANK_LOADING = 'TABLE_RECIVE_BANK_LOADING',
  TABLE_RECIVE_PHONE = 'TABLE_RECIVE_PHONE',
  TABLE_RECIVE_PHONE_LOADING = 'TABLE_RECIVE_PHONE_LOADING',
}

export enum ELocalStorageUser {
  USER_INFO = 'USER_INFO',
  ACCESS_PERMISSION = 'ACCESS_PERMISSION',
  PERMISSION = 'PERMISSION',
}

export enum ELocalStoragePassVar {
  GRAPH_PHONEUPLOAD_SEARCH = 'GRAPH_PHONEUPLOAD_SEARCH',
  GRAPH_BANKUPLOAD_SEARCH = 'GRAPH_BANKUPLOAD_SEARCH',
}

export enum ELocalStorageForm {
  ARREST_FORM_RECOVERY = 'ARREST_FORM_RECOVERY',
}

export enum ELocalStorageTemp {
  TEMP = 'TEMP',
}
