import { SelectOption } from 'components/ui/type';

const useSituationDropdownApi = () => {
  const options = [
    { value: 0, text: 'บันทึกการจับกุม / ตรวจยึดยาเสพติด' },
    { value: 1, text: 'บันทึกการตรวจยึดทรัพย์สิน / เอกสาร' },
    { value: 2, text: 'บันทึกการตรวจค้น' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useSituationDropdownApi;
