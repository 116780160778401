import React from 'react';
import { IconWrapper } from './styled';
import { ENodeType } from './types';

import arrest from 'assets/images/icon node type/Ic_arrest_record_02.svg';
import searching_report from 'assets/images/icon node type/Ic_search_report.svg';
import confiscate from 'assets/images/icon node type/Ic_search_sequester_report.svg';
import undentified from 'assets/images/icon node type/Ic_unspecified.svg';
import { StatusAlert } from 'components/ui';
import i18next from 'i18next';
import { AlertStatus } from 'components/ui/enum';

export const iconType = (type) => {
  switch (type) {
    case ENodeType.ARREST:
      return <IconWrapper src={arrest} />;
    case ENodeType.CONFISCATE:
      return <IconWrapper src={confiscate} />;
    case ENodeType.SEARCHING_REPORT:
      return <IconWrapper src={searching_report} />;
    default:
      return <IconWrapper src={undentified} />;
  }
};

export const openType = (id, type) => {
  switch (type) {
    case ENodeType.ARREST:
      window.open(`/arrest/${id}`);
      break;
    case ENodeType.CONFISCATE:
      window.open(`/confiscate/${id}`);
      break;
    case ENodeType.SEARCHING_REPORT:
      window.open(`/searching-report/${id}`);
      break;
    default:
      StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
      break;
  }
};

export const titleType = (type) => {
  switch (type) {
    case ENodeType.ARREST:
      return 'บันทึกการจับกุม / ตรวจยึดยาเสพติด';
    case ENodeType.CONFISCATE:
      return 'บันทึกการตรวจยึดทรัพย์สิน / เอกสาร';
    case ENodeType.SEARCHING_REPORT:
      return 'บันทึกการตรวจค้น';
    default:
      return '';
  }
};
