import {
  IInvestigationAttachmentForm,
  IInvestigationAttachmentState,
  IInvestigationAttachmentValidate,
} from './investigation-attachment.interface';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import { INITIAL_COORDS } from 'components/ui/constant/google-maps.constant';
import { IUploadFileResponse } from 'shared/interfaces';

export const INITIAL_INVESTIGATION_ATTACHMENT_STATE: IInvestigationAttachmentState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IInvestigationAttachmentForm,
  error: undefined,
  isPageLoading: false,
  isTableLoading: false,
  isButtonLoading: false,
};

export const INITIAL_INVESTIGATION_ATTACHMENT_FORM: IInvestigationAttachmentForm = {
  id: '',
  attachmentName: '',
  attachmentDetails: '',
  attachmentDesc: '',
  attachmentLocation: '',
  latitude: INITIAL_COORDS.lat,
  longitude: INITIAL_COORDS.lng,
  place: '',
  attachmentDate: null,
  attachmentTime: null,
  attachmentBy: '',
  attachment: {} as IUploadFileResponse,
  attachments: [],
  newAttachments: [],
};

export const INITIAL_INVESTIGATION_ATTACHMENT_VALIDATE: IInvestigationAttachmentValidate = {
  attachmentDetails: '',
};
