import React, { FC } from 'react';
import styled from 'styled-components';
import IconPerson from 'assets/images/menu/Ic_personal.svg';

const StyledTab = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  border: 1px solid #ddd;
  /* border-radius: 20px; */
  height: auto;
  flex-direction: column;
`;

interface TabProps {
  title: string;
  src: string;
}

const Tab: FC<TabProps> = ({ children }) => {
  return <StyledTab>{children}</StyledTab>;
};

export default Tab;
