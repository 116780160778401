import { IInvestigationList, IInvestigation } from 'shared/modules/investigation';
import {
  IInvestigationAttachmentList,
  IInvestigationGetResponse,
} from 'shared/modules/investigation/investigation.interface';
import { TDataTable } from 'shared/types';

export enum EInvestigationAction {
  SEARCH_INVESTIGATION_REQUEST = 'SEARCH_INVESTIGATION_REQUEST',
  SEARCH_INVESTIGATION_SUCCESS = 'SEARCH_INVESTIGATION_SUCCESS',
  SEARCH_INVESTIGATION_FAILED = 'SEARCH_INVESTIGATION_FAILED',
  GET_INVESTIGATION_REQUEST = 'GET_INVESTIGATION_REQUEST',
  GET_INVESTIGATION_SUCCESS = 'GET_INVESTIGATION_SUCCESS',
  GET_INVESTIGATION_FAILED = 'GET_INVESTIGATION_FAILED',
  CREATE_INVESTIGATION_REQUEST = 'CREATE_INVESTIGATION_REQUEST',
  CREATE_INVESTIGATION_SUCCESS = 'CREATE_INVESTIGATION_SUCCESS',
  CREATE_INVESTIGATION_FAILED = 'CREATE_INVESTIGATION_FAILED',
  UPDATE_INVESTIGATION_REQUEST = 'UPDATE_INVESTIGATION_REQUEST',
  UPDATE_INVESTIGATION_SUCCESS = 'UPDATE_INVESTIGATION_SUCCESS',
  UPDATE_INVESTIGATION_FAILED = 'UPDATE_INVESTIGATION_FAILED',
  DELETE_INVESTIGATION_REQUEST = 'DELETE_INVESTIGATION_REQUEST',
  DELETE_INVESTIGATION_SUCCESS = 'DELETE_INVESTIGATION_SUCCESS',
  DELETE_INVESTIGATION_FAILED = 'DELETE_INVESTIGATION_FAILED',
  CLEAR_SEARCH_INVESTIGATION = 'CLEAR_SEARCH_INVESTIGATION',
  CLEAR_SELECT_INVESTIGATION = 'CLEAR_SELECT_INVESTIGATION',
  GET_INVESTIGATION_BY_ID_REQUEST = 'GET_INVESTIGATION_BY_ID_REQUEST',
  GET_INVESTIGATION_BY_ID_SUCCESS = 'GET_INVESTIGATION_BY_ID_SUCCESS',
  GET_INVESTIGATION_BY_ID_FAILED = 'GET_INVESTIGATION_BY_ID_FAILED',
  GET_INVESTIGATION_ATTACHMENT_REQUEST = 'GET_INVESTIGATION_ATTACHMENT_REQUEST',
  GET_INVESTIGATION_ATTACHMENT_SUCCESS = 'GET_INVESTIGATION_ATTACHMENT_SUCCESS',
  GET_INVESTIGATION_ATTACHMENT_FAILED = 'GET_INVESTIGATION_ATTACHMENT_FAILED',
}

interface ISearchInvestigationRequest {
  type: EInvestigationAction.SEARCH_INVESTIGATION_REQUEST;
}

interface ISearchInvestigationSuccess {
  type: EInvestigationAction.SEARCH_INVESTIGATION_SUCCESS;
  data: IInvestigationGetResponse;
}

interface ISearchInvestigationFailed {
  type: EInvestigationAction.SEARCH_INVESTIGATION_FAILED;
  error: Error;
}

interface IGetInvestigationRequest {
  type: EInvestigationAction.GET_INVESTIGATION_REQUEST;
}

interface IGetInvestigationSuccess {
  type: EInvestigationAction.GET_INVESTIGATION_SUCCESS;
  data: IInvestigation;
}

interface IGetInvestigationFailed {
  type: EInvestigationAction.GET_INVESTIGATION_FAILED;
  error: Error;
}

interface ICreateInvestigationRequest {
  type: EInvestigationAction.CREATE_INVESTIGATION_REQUEST;
}

interface ICreateInvestigationSuccess {
  type: EInvestigationAction.CREATE_INVESTIGATION_SUCCESS;
}

interface ICreateInvestigationFailed {
  type: EInvestigationAction.CREATE_INVESTIGATION_FAILED;
  error: Error;
}

interface IUpdateInvestigationRequest {
  type: EInvestigationAction.UPDATE_INVESTIGATION_REQUEST;
}

interface IUpdateInvestigationSuccess {
  type: EInvestigationAction.UPDATE_INVESTIGATION_SUCCESS;
}

interface IUpdateInvestigationFailed {
  type: EInvestigationAction.UPDATE_INVESTIGATION_FAILED;
  error: Error;
}

interface IDeleteInvestigationRequest {
  type: EInvestigationAction.DELETE_INVESTIGATION_REQUEST;
}

interface IDeleteInvestigationSuccess {
  type: EInvestigationAction.DELETE_INVESTIGATION_SUCCESS;
}

interface IDeleteInvestigationFailed {
  type: EInvestigationAction.DELETE_INVESTIGATION_FAILED;
  error: Error;
}

interface IClearInvestigationSearch {
  type: EInvestigationAction.CLEAR_SEARCH_INVESTIGATION;
}

interface IClearInvestigationSelect {
  type: EInvestigationAction.CLEAR_SELECT_INVESTIGATION;
}

interface IGetInvestigationByIdRequest {
  type: EInvestigationAction.GET_INVESTIGATION_BY_ID_REQUEST;
}

interface IGetInvestigationByIdSuccess {
  type: EInvestigationAction.GET_INVESTIGATION_BY_ID_SUCCESS;
  data: TDataTable<IInvestigationList>;
}

interface IGetInvestigationByIdFailed {
  type: EInvestigationAction.GET_INVESTIGATION_BY_ID_FAILED;
  error: Error;
}

interface IGetInvestigationAttachmentRequest {
  type: EInvestigationAction.GET_INVESTIGATION_ATTACHMENT_REQUEST;
}

interface IGetInvestigationAttachmentSuccess {
  type: EInvestigationAction.GET_INVESTIGATION_ATTACHMENT_SUCCESS;
  data: IInvestigationAttachmentList;
}

interface IGetInvestigationAttachmentFailed {
  type: EInvestigationAction.GET_INVESTIGATION_ATTACHMENT_FAILED;
  error: Error;
}

export type TInvestigation =
  | ISearchInvestigationRequest
  | ISearchInvestigationSuccess
  | ISearchInvestigationFailed
  | IGetInvestigationRequest
  | IGetInvestigationSuccess
  | IGetInvestigationFailed
  | ICreateInvestigationRequest
  | ICreateInvestigationSuccess
  | ICreateInvestigationFailed
  | IUpdateInvestigationRequest
  | IUpdateInvestigationSuccess
  | IUpdateInvestigationFailed
  | IDeleteInvestigationRequest
  | IDeleteInvestigationSuccess
  | IDeleteInvestigationFailed
  | IClearInvestigationSearch
  | IClearInvestigationSelect
  | IGetInvestigationByIdRequest
  | IGetInvestigationByIdSuccess
  | IGetInvestigationByIdFailed
  | IGetInvestigationAttachmentRequest
  | IGetInvestigationAttachmentSuccess
  | IGetInvestigationAttachmentFailed;
