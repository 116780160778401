import { TGraphActionTypes, EGraphAction } from 'store/graph-data-sci/graph-data-sci.type';
import {
  IGraphSciCommentState,
  INITIAL_GRAPH_SCI_COMMENT_STATE,
  IGraphSciComment,
} from 'shared/modules/graph-data-sci';

export default (
  state: IGraphSciCommentState = INITIAL_GRAPH_SCI_COMMENT_STATE,
  action: TGraphActionTypes
): IGraphSciCommentState => {
  switch (action.type) {
    case EGraphAction.GET_GRAPH_SCI_COMMENT_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EGraphAction.GET_GRAPH_SCI_COMMENT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case EGraphAction.GET_GRAPH_SCI_COMMENT_FAILED:
      return {
        ...state,
        selected: {} as IGraphSciComment,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
