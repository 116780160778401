import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useTeamOperateDropdownApi = () => {
  const [teams, isLoading, isError] = useFetchApi<IDropdown[]>('/team/operate/getDropdownList', []);

  const teamSelectOptions: SelectOption[] = teams.map((team) => {
    return {
      text: team.text,
      value: team.id,
    };
  });

  return [teamSelectOptions, isLoading, isError] as const;
};

export default useTeamOperateDropdownApi;
