import _ from 'lodash';
import React, { ChangeEvent, FC, RefObject } from 'react';
import { AlertMessage } from '.';
import { EInputAccept } from './enum/image.enum';

interface IFileUploader {
  fileInputRef: RefObject<HTMLInputElement>;
  accept?: EInputAccept[];
  onAddFile(event: ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
}

const FileUploader: FC<IFileUploader> = ({ fileInputRef, accept, onAddFile, disabled }) => {
  const fileNames: string[] = [];

  return (
    <input
      ref={fileInputRef}
      type="file"
      accept={
        _.isEmpty(accept)
          ? `${EInputAccept.AllImages},${EInputAccept.PDF},${EInputAccept.CSV},${EInputAccept.XML}`
          : accept?.join(',')
      }
      hidden
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const files: FileList = event.target.files as FileList;

        Array.from(files).forEach((file: File) => {
          const fileSize = (file.size || 0) / (1024 * 1024);

          if (fileSize > 100) {
            throw AlertMessage('กรุณาเลือกไฟล์อัปโหลดที่มีขนาดไม่เกิน 100 MB');
          }
        });

        onAddFile(event);
      }}
      value={fileNames}
      multiple
      disabled={disabled}
    />
  );
};

export default FileUploader;
