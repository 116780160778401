import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import authenticate, { IAuthenticateState } from './authenticate';
import user, { IUserState } from './user';

import bankUpload from 'store/bank-upload/bank-upload.reducer';
import companySearchText from 'store/company/company-search-text.reducer';
import company from 'store/company/company.reducer';
import dopa from 'store/dopa/dopa.reducer';
import dopalog from 'store/dopalog/dopalog.reducer';
import graphSciComment from 'store/graph-data-sci/graph-data-sci-comment.reducer';
import graphSci from 'store/graph-data-sci/graph-data-sci.reducer';
import exportSci from 'store/graph-data-sci/graph-export-sci.reducer';
import heatMap from 'store/graph-data-sci/graph-heatmap.reducer';
import graph from 'store/graph/graph.reducer';
import inform from 'store/inform/inform.reducer';
import investigationAttachment from 'store/investigation-attachment/investigation-attachment.reducer';
import investigationReport from 'store/investigation-report/investigation-report.reducer';
import lawsuit from 'store/lawsuit/lawsuit.reducer';
import licensePlate from 'store/license-plate/license-plate.reducer';
import linkage_nsb from 'store/linkage-nsb/linkage-nsb.reducer';
import officerTypes from 'store/master-data/dropdown/officer-type/officer-type.reducer';
import ranks from 'store/master-data/dropdown/rank/rank.reducer';
import scoreType from 'store/master-data/dropdown/score-type/score-type.reducer';
import model from 'store/model/model.reducer';
import reciveBank from 'store/recive-bank/recive-bank.reducer';
import recivePhone from 'store/recive-phone/recive-phone.reducer';
import roleSetting from 'store/role-setting/role-setting.reducer';
import suspectEngine from 'store/suspect-engine/suspect-engine.reducer';
import suspectSearch from 'store/suspect-search/suspect-search.reducer';
import suspect from 'store/suspect/suspect.reducer';
import accessPermissionManagement from './access-permission-management/access-permission-management.reducer';
import arrest from './arrest/arrest.reducer';
import bankTracking from './bank-tracking/banking-tracking.rudecer';
import caseFileAccessRequest from './case-file-access-request/case-file-access-request.reducer';
import caseFile from './case-file/case-file.reducer';
import confiscate from './confiscate/confiscate.reducer';
import dare from './dare/dare.reducer';
import dataVisualize from './data-visualize/data-visualize.rudecer';
import gamification from './gamification/gamification.reducer';
import googleMaps from './google-maps/google-maps.reducer';
import investigation from './investigation/investigation.reducer';
import logger from './logger/logger.reducer';
import menu from './menu/menu.reducer';
import notification from './notification/notification.reducer';
import officer from './officer/officer.reducer';
import phoneUpload from './phone-upload/phone-upload.reducer';
import score from './score/score.reducer';
import searchingReport from './searching-report/searching-report.reducer';
import userProfile from './user-profile/user-profile.reducer';

import { IDropdownState } from 'shared/interfaces';
import { IGoogleMapsState } from 'shared/interfaces/google-maps.interface';
import { IAccessPermissionManagementState } from 'shared/modules/access-permission-management';
import { IArrestState } from 'shared/modules/arrest';
import { IBankTrackingState } from 'shared/modules/bank-tracking';
import { IBankUploadState } from 'shared/modules/bank-upload';
import { ICaseFileState } from 'shared/modules/case-file';
import { ICaseFileAccessRequestState } from 'shared/modules/case-file-access-request';
import { ICompanyState } from 'shared/modules/company';
import { IConfiscateState } from 'shared/modules/confiscate';
import { IDAREState } from 'shared/modules/dare';
import { IDataVisualizeState } from 'shared/modules/data-visualize';
import { IDopaState } from 'shared/modules/dopa';
import { IDopaLogState } from 'shared/modules/dopalog';
import { IGamificationState } from 'shared/modules/gamification';
import { IGraphState } from 'shared/modules/graph';
import { IExportSciState, IGraphSciCommentState, IGraphSciState, IHeatmapState } from 'shared/modules/graph-data-sci';
import { IInformState } from 'shared/modules/inform';
import { IInvestigationReportState } from 'shared/modules/investigate-report';
import { IInvestigationState } from 'shared/modules/investigation';
import { IInvestigationAttachmentState } from 'shared/modules/investigation-attachment';
import { ILawsuitState } from 'shared/modules/lawsuit';
import { ILicensePlateState } from 'shared/modules/license-plate';
import { ILinkageNSBState } from 'shared/modules/linkage-nsb';
import { ILoggerState } from 'shared/modules/logger';
import { IMenuState } from 'shared/modules/menu';
import { IModelState } from 'shared/modules/model';
import { INotificationState } from 'shared/modules/notification';
import { IOfficerState } from 'shared/modules/officer';
import { IPhoneUploadState } from 'shared/modules/phone-upload';
import { IReportReciveBankState } from 'shared/modules/recive-bank';
import { IReportRecivePhoneState } from 'shared/modules/recive-phone';
import { IRoleSettingState } from 'shared/modules/role-setting';
import { IScoreState } from 'shared/modules/score';
import { ISearchingReportState } from 'shared/modules/searching-report';
import { ISuspectState } from 'shared/modules/suspect';
import { ISuspectEngineState } from 'shared/modules/suspect-engine';
import { ISuspectSearchState } from 'shared/modules/suspect-search';
import { IUserProfileState } from 'shared/modules/user-profile/user-profile.interface';
import {
  ITeamManagementState,
  ITeamSequesterManagementState,
  TeamManagementReducer,
  TeamManagementSequesterReducer,
} from './team-management';
import { IUserManagementState, userManagementReducer } from './user-management';

export interface IReducer {
  authenticate: IAuthenticateState;
  user: IUserState;
  inform: IInformState;
  lawsuit: ILawsuitState;
  suspect: ISuspectState;
  licensePlate: ILicensePlateState;
  userManagement: IUserManagementState;
  TeamArrest: ITeamManagementState;
  TeamsSequester: ITeamSequesterManagementState;
  graph: IGraphState;
  graphSci: IGraphSciState;
  graphSciComment: IGraphSciCommentState;
  exportSci: IExportSciState;
  heatMap: IHeatmapState;
  arrest: IArrestState;
  confiscate: IConfiscateState;
  accessPermissionManagement: IAccessPermissionManagementState;
  searchingReport: ISearchingReportState;
  bankTracking: IBankTrackingState;
  dataVisualize: IDataVisualizeState;
  dare: IDAREState;
  caseFile: ICaseFileState;
  investigation: IInvestigationState;
  notification: INotificationState;
  caseFileAccessRequest: ICaseFileAccessRequestState;
  userProfile: IUserProfileState;
  score: IScoreState;
  gamification: IGamificationState;
  menu: IMenuState;
  officer: IOfficerState;
  logger: ILoggerState;
  phoneUpload: IPhoneUploadState;
  googleMaps: IGoogleMapsState;
  company: ICompanyState;
  companySearchText: ICompanyState;
  officerTypes: IDropdownState;
  ranks: IDropdownState;
  scoreType: IDropdownState;
  suspectSearch: ISuspectSearchState;
  bankUpload: IBankUploadState;
  roleSetting: IRoleSettingState;
  suspectEngine: ISuspectEngineState;
  dopa: IDopaState;
  dopalog: IDopaLogState;
  investigationReport: IInvestigationReportState;
  investigationAttachment: IInvestigationAttachmentState;
  model: IModelState;
  reciveBank: IReportReciveBankState;
  recivePhone: IReportRecivePhoneState;
  linkage_nsb: ILinkageNSBState;
}

export type { IAuthenticateState, IUserState };

const rootReducer = combineReducers({
  authenticate,
  user,
  inform,
  lawsuit,
  suspect,
  licensePlate,
  userManagement: userManagementReducer,
  TeamArrest: TeamManagementReducer,
  TeamsSequester: TeamManagementSequesterReducer,
  graph,
  graphSci,
  graphSciComment,
  exportSci,
  heatMap,
  arrest,
  confiscate,
  searchingReport,
  accessPermissionManagement,
  bankTracking,
  dataVisualize,
  dare,
  caseFile,
  investigation,
  notification,
  caseFileAccessRequest,
  userProfile,
  score,
  gamification,
  menu,
  officer,
  logger,
  phoneUpload,
  googleMaps,
  company,
  companySearchText,
  officerTypes,
  ranks,
  scoreType,
  suspectSearch,
  bankUpload,
  roleSetting,
  suspectEngine,
  dopa,
  dopalog,
  investigationReport,
  investigationAttachment,
  model,
  reciveBank,
  recivePhone,
  linkage_nsb,
});

export default createStore(rootReducer, applyMiddleware(thunkMiddleware));
