import { TCoords } from '../type/google-maps.type';

export const INITIAL_COORDS: TCoords = {
  lat: 13.868603683086025,
  lng: 100.57780860313946,
};

export const INITIAL_COORDS_TEST: TCoords = {
  lat: 13.868603683086025,
  lng: 100.57780860313946,
};

