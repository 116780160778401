import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components';
import { LargeText } from 'components/ui';
import constants from 'shared/constants';

interface ISectionProps {
  className?: string;
  label?: string;
  hasBorder?: boolean;
  sectionContentStyle?: CSSProperties;
  color?: string;
}

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled(LargeText)``;

const SectionContent = styled.div`
  // padding: 24px 24px 24px 24px;
  padding: 10px;
  border: ${(props: ISectionProps) => (props.hasBorder ? `1px solid ${constants.PRIMARY_COLOR}` : 'none')};
  // border:1px solid;
  border-radius: 8px;
  background-color: ${(props) => (!!props.color ? props.color : '')};
`;

const Section: FC<ISectionProps> = ({ className, label, hasBorder = true, sectionContentStyle, children, color }) => {
  return (
    <SectionWrapper className={className}>
      {label && <SectionLabel>{label}</SectionLabel>}
      <SectionContent hasBorder={hasBorder} style={sectionContentStyle} color={color}>
        {children}
      </SectionContent>
    </SectionWrapper>
  );
};

export default Section;
