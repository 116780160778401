import {
  ICompanySearch,
  ICompany,
  ICompanyState,
  ICompanySearchValidate,
  ICompanyValidate,
  ICompanySearchText,
  ICompanyCaptcha,
} from './company.interface';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
// import moment from 'moment';

export const INITIAL_COMPANY_SEARCH: ICompanySearch = {
  TextSearch: '',
  TypeID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_COMPANY_SEARCH_VALIDATE: ICompanySearchValidate = {
  TextSearch: '',
  TypeID: '',
};

export const INITIAL_COMPANY: ICompany = {
  id: '',
  juristicNo: '',
  juristicName: '',
  establishDate: '',
  status: '',
  detail: '',
  statusDate: '',
  shareHolderNameList: [''] as string[],
  accessToken: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_COMPANY_SEARCH_TEXT: ICompanySearchText = {
  id: '',
  juristicNo: '',
  juristicName: '',
  establishDate: '',
  status: '',
  detail: '',
  statusDate: '',
  shareHolderNameList: [''] as string[],
  accessToken: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const INITIAL_COMPANY_VALIDATE: ICompanyValidate = {
  juristicNo: '',
  juristicName: '',
  // establishDate: '',
  // status: '',
  // detail: '',
  // statusDate: '',
};

export const INITIAL_COMPANY_CAPTCHA: ICompanyCaptcha = {
  imageCaptchaPath: '',
  userID: '',
  typeID: '',
  textSearch: '',
  searchText: '',
};

export const INITIAL_COMPANY_STATE: ICompanyState = {
  selected: {} as ICompany,
  searched: {} as ICompanySearchText,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  onSearchLoading: false,
  captcha: INITIAL_COMPANY_CAPTCHA,
};
