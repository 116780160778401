import { TCompanyActionTypes, ECompanyAction } from 'store/company/company.type';
import { ICompanyState, INITIAL_COMPANY_SEARCH_TEXT, INITIAL_COMPANY_STATE } from 'shared/modules/company';

export default (state: ICompanyState = INITIAL_COMPANY_STATE, action: TCompanyActionTypes): ICompanyState => {
  switch (action.type) {
    case ECompanyAction.SEARCH_TEXT_COMPANY_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case ECompanyAction.SEARCH_TEXT_COMPANY_SUCCESS:
      return {
        ...state,
        searched: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ECompanyAction.SEARCH_TEXT_COMPANY_FAILED:
      return {
        ...state,
        searched: { ...INITIAL_COMPANY_SEARCH_TEXT },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    default:
      return state;
  }
};
