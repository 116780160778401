import { INITIAL_COORDS } from 'components/ui/constant/google-maps.constant';
import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import {
  INITIAL_ATM_VALIDATE,
  INITIAL_BANK_ACCOUNT_VALIDATE,
  INITIAL_CAR_VALIDATE,
  INITIAL_DRUG_VALIDATE,
  INITIAL_MONEY_VALIDATE,
  INITIAL_PHONE_VALIDATE,
  INITIAL_PROPERTY,
  INITIAL_SIM_VALIDATE,
} from 'shared/modules/property';
import { IOfficerLeadList, IOfficerReportList } from '../officer';
import {
  IAccuseRelated,
  IAddressModalValidate,
  IArrestAtmPropertyValidate,
  IArrestBankPropertyValidate,
  IArrestCIResponse,
  IArrestCISearch,
  IArrestCarPropertyValidate,
  IArrestDrugPropertyValidate,
  IArrestForm,
  IArrestFormValidate,
  IArrestList,
  IArrestModalOfficerValidate,
  IArrestModalPDFValidate,
  IArrestModalSuspect,
  IArrestMoneyPropertyValidate,
  IArrestPersonPropertyValidate,
  IArrestPhonePropertyValidate,
  IArrestSearch,
  IArrestSearchValidate,
  IArrestSimPropertyValidate,
  IArrestState,
  ILeaderList,
  IOfficerLeadL,
  IOfficerLeadValidate,
  IOfficerList,
  IOfficerReport,
  IOfficerReportValidate,
  IOfficers,
  IPropertyValidate,
  ISuspectDetail,
  ISuspectDetailValidate,
} from './arrest.interface';

export const INITIAL_ARREST_SEARCH: IArrestSearch = {
  _caseFileID: null,
  caseFileID: '',
  accuse: '',
  recorderFullName: '',
  arrestEndDate: null,
  suspectFullName: '',
  arrestStartDate: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  //
  caseNo: '',
  caseName: '',
  year: moment(),
  place: '',
  arrestDate: moment(),
  keyword: '',
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
  //
  yearCase: moment(new Date()),
};

// NOTE: state
export const INITIAL_ARREST_STATE: IArrestState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IArrestForm,
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  fileUrl: '',
  arrestCI: {} as IArrestCIResponse,
};

export const INITIAL_ACCUSE_RELATED: IAccuseRelated = {
  accuseID: '',
  address: '',
  alley: '',
  birthDate: null,
  building: '',
  districtID: null,
  firstName: '',
  fullName: '',
  idCard: '',
  lastName: '',
  no: 0,
  postalCode: '',
  provinceID: null,
  remark: '',
  road: '',
  subDistrictID: null,
  titleID: null,
};

export const INITIAL_OFFICER_LEADLS: IOfficerLeadL = {
  teamID: null,
  officerLeadsList: [],
  otherLeads: '',
  officerHeadName: '',
  officerLeadFullID: null,
};

export const INITIAL_OFFICER_REPORTS: IOfficerReport = {
  teamID: null,
  officerReportsList: [],
  otherReports: '',
  officerHeadName: '',
  officerReportFullID: null,
};
//
export const INITIAL_OFFICER_LIST: IOfficerList = {
  userID: '',
  titleID: '',
  titleName: '',
  firstName: '',
  lastName: '',
  detail: '',
};

export const INITIAL_LEADER_LIST: ILeaderList = {
  unitName: '',
  officers: [INITIAL_OFFICER_LIST],
  detail: '',
};

export const INITIAL_OFFICERS: IOfficers = {
  leaderList: [INITIAL_LEADER_LIST],
  performerList: [INITIAL_LEADER_LIST],
};
//
export const INITIAL_ARREST_FORM: IArrestForm = {
  caseFileID: null,
  arrestCode: '',
  recordDate: moment(new Date()),
  recordPlace: '',
  arrestDateFrom: null,
  arrestTimeFrom: null,
  arrestDateTo: null,
  arrestTimeTo: null,
  arrestPlace: '',
  crimeScene: '',
  officerLeads: [],
  otherLeads: '',
  officerLeadLs: [INITIAL_OFFICER_LEADLS],
  officerReports: [INITIAL_OFFICER_REPORTS],
  suspects: [],
  isNotify: false,
  accuseList: [''],
  property: INITIAL_PROPERTY,
  propertyFoundingPlace: '',
  propertyFoundingLatitude: null,
  propertyFoundingLongitude: null,
  _propertyFoundingLatitude: INITIAL_COORDS.lat,
  _propertyFoundingLongitude: INITIAL_COORDS.lng,
  propertyFoundingLocation: '',
  circumstance: '',
  incidentPlaceAndDate: '',
  isInformToRelative: false,
  isNotWillingToInform: false,
  isCopyArrestReport: false,
  officerCountID: null,
  reporterID: null,
  searchLeaderName: '',
  detail: '',
  remark: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  isCourt: false,
  //
  dailyReportNo: '',
  arrestReportNo: '',
  criminalRecordNo: '',
  inquiryOfficerName: '',
  _inquiryRecordDate: moment(),
  inquiryRecordDate: {},
  inquiryTelephone: '',
  //
  officers: INITIAL_OFFICERS,
  arrestCondition: '',
  suspectCountID: null,
  witnessCountID: null,
  inquiryRecordDateTime: moment(),
  //
  arrestDocumentAmount: 0,
  arrestAssetAmount: 0,
  tempName: '',
  // RELATION DROPDOWN
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  createdByAgencyID: null,
  createdBySectorID: null,
  createdByDepartmentID: null,
  isSamePlace: false,
  //
  year: moment(new Date()),
  //
  lat: '',
  lng: '',
};

export const INITIAL_ARREST_SEARCH_VALIDATE: IArrestSearchValidate = {
  suspectFullName: '',
  recorderFullName: '',
};

export const INITIAL_OFFICER_LEADS_VALIDATE: IOfficerLeadValidate = {
  officerHeadName: '',
};

export const INITIAL_OFFICER_REPORTS_VALIDATE: IOfficerReportValidate = {
  teamID: '',
  officerHeadName: '',
};

export const INITIAL_PROPERTY_VALIDATE: IPropertyValidate = {
  propertyTypeID: '',
  phoneNumber: '',
};

export const INITIAL_ARREST_FORM_VALIDATE: IArrestFormValidate = {
  recordPlace: '',
  recordDate: '',
  arrestDateFrom: '',
  arrestTimeFrom: '',
  arrestDateTo: '',
  arrestTimeTo: '',
  arrestPlace: '',
  crimeScene: '',
  officerLeads: [INITIAL_OFFICER_LEADS_VALIDATE],
  officerReports: [INITIAL_OFFICER_REPORTS_VALIDATE],
  phone: [INITIAL_PHONE_VALIDATE],
  bankAccount: [INITIAL_BANK_ACCOUNT_VALIDATE],
  money: [INITIAL_MONEY_VALIDATE],
  drug: [INITIAL_DRUG_VALIDATE],
  car: [INITIAL_CAR_VALIDATE],
  atm: [INITIAL_ATM_VALIDATE],
  sim: [INITIAL_SIM_VALIDATE],
  dailyReportNo: '',
  inquiryTelephone: '',
  //
  arrestDocumentAmount: '',
  arrestAssetAmount: '',
  //
  lat: '',
  lng: '',
};

export const INITIAL_ADDRESS_MODAL_VALIDATE: IAddressModalValidate = {
  address: '',
  districtID: '',
  provinceID: '',
  subDistrictID: '',
};

export const INITIAL_SUSPECT_DETAIL: ISuspectDetail = {
  suspectIDCard: '',
  suspectTitleID: null,
  suspectTitleName: undefined,
  suspectFirstName: '',
  suspectLastName: '',
  birthDate: null,
  age: undefined,
  address: '',
  building: '',
  alley: '',
  road: '',
  provinceID: null,
  provinceName: '',
  districtID: null,
  districtName: '',
  subDistrictID: null,
  subDistrictName: '',
  postalCode: '',
  additionalAddressInfo: '',
  isAcknowledgeAccusation: null,
  isAcknowledgeAccusationString: null,
  acknowledgeAccusationDetail: '',
  remark: '',
};

export const INITIAL_SUSPECT_DETAIL_VALIDATE: ISuspectDetailValidate = {
  suspectIDCard: '',
  suspectTitleID: '',
  suspectFirstName: '',
  suspectLastName: '',
  postalCode: '',
};

export const INITIAL_ARREST_PERSON_PROPERTY_VALIDATE: IArrestPersonPropertyValidate = {
  firstName: '',
  lastName: '',
  idCard: '',
};
export const INITIAL_ARREST_SIM_PROPERTY_VALIDATE: IArrestSimPropertyValidate = {
  phoneNumber: '',
};

export const INITIAL_ARREST_DRUG_PROPERTY_VALIDATE: IArrestDrugPropertyValidate = {
  amount: '',
};

export const INITIAL_ARREST_PHONE_PROPERTY_VALIDATE: IArrestPhonePropertyValidate = {
  phoneNumber: '',
  // sim: [INITIAL_ARREST_SIM_PROPERTY_VALIDATE],
};

export const INITIAL_ARREST_BANK_PROPERTY_VALIDATE: IArrestBankPropertyValidate = {
  accountNo: '',
  accountPhoneNumber: '',
  sms: '',
  otp: '',
  promptPay: '',
  promptPayCitizen: '',
};
export const INITIAL_ARREST_MONEY_PROPERTY_VALIDATE: IArrestMoneyPropertyValidate = {
  totalAmount: '',
};
export const INITIAL_ARREST_ATM_PROPERTY_VALIDATE: IArrestAtmPropertyValidate = {
  cardOwner: '',
};
export const INITIAL_ARREST_CAR_PROPERTY_VALIDATE: IArrestCarPropertyValidate = {
  licensePlateNoLast: '',
  licensePlateNoFirst: '',
};

export const INITIAL_ARREST_MODAL_SUSPECT: IArrestModalSuspect = {
  titleID: null,
  titleName: '',
  fullName: '',
};

export const INITIAL_ARREST_MODAL_SUSPECT_VALIDATE: IArrestModalOfficerValidate = {
  fullName: '',
};

export const INITIAL_ARREST_MODAL_PDF_VALIDATE: IArrestModalPDFValidate = {
  caseName: '',
};

export const INITIAL_OFFICER_LEADL_MODAL: IOfficerLeadList = {
  id: '',
  officerLeadFullID: null,
  officerLeadFullName: '',
  no: 0,
  titleID: null,
  titleName: '',
  officerHeadName: '',
  detail: '',
  otherLeads: '',
  firstName: '',
  lastName: '',
};

export const INITIAL_OFFICER_REPORT_MODAL: IOfficerReportList = {
  id: '',
  officerReportFullID: null,
  officerReportFullName: '',
  no: 0,
  officerCode: '',
  positionName: '',
  headQuarterName: '',
  divisionName: '',
  policeStationName: '',
  subDivisionName: '',
  mobilePhoneNumber: '',
  titleID: null,
  titleName: '',
  officerHeadName: '',
  detail: '',
  otherReports: '',
  firstName: '',
  lastName: '',
};

// NOTE: form
export const INITIAL_ARREST_CI_SEARCH: IArrestCISearch = {
  currentPage: 1,
  pageSize: 5,
  caseNo: '',
  suspectFullName: '',
  accuse: '',
  recorderFullName: '',
  startDate: null,
  endDate: null,
};
