import { INITIAL_ARREST_STATE } from 'shared/modules/arrest';
import { IArrestForm, IArrestState } from 'shared/modules/arrest/arrest.interface';
import { TArrestActionTypes, EArrestAction } from './arrest.type';

export default (state: IArrestState = INITIAL_ARREST_STATE, action: TArrestActionTypes): IArrestState => {
  switch (action.type) {
    case EArrestAction.SEARCH_ARREST_REQUEST:
      return {
        ...state,
        isTableLoading: true,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case EArrestAction.SEARCH_ARREST_SUCCESS:
      return {
        ...state,
        all: action.data,
        isTableLoading: false,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.SEARCH_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isTableLoading: false,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.CREATE_ARREST_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EArrestAction.CREATE_ARREST_SUCCESS:
      return { ...state, isButtonLoading: false, isPageLoading: false };
    case EArrestAction.CREATE_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.GET_ARREST_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EArrestAction.GET_ARREST_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.GET_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.UPDATE_ARREST_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EArrestAction.UPDATE_ARREST_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.UPDATE_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.DELETE_ARREST_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EArrestAction.DELETE_ARREST_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.DELETE_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.GET_PDF_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EArrestAction.GET_PDF_SUCCESS:
      return {
        ...state,
        fileUrl: action.fileUrl,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.GET_PDF_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.CLEAR_SELECTED_ARREST:
      return {
        ...state,
        selected: {} as IArrestForm,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EArrestAction.GET_ARREST_CI_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EArrestAction.GET_ARREST_CI_SUCCESS:
      return {
        ...state,
        arrestCI: action.data,
        isPageLoading: false,
      };
    case EArrestAction.GET_ARREST_CI_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
