import { INITIAL_USER_PROFILE_STATE } from 'shared/modules/user-profile/user-profile.constant';
import { IUserProfileState } from 'shared/modules/user-profile/user-profile.interface';
import { EUserProfileAction, TUserProfileActionTypes } from './user-profile.type';

export default (
  state: IUserProfileState = INITIAL_USER_PROFILE_STATE,
  action: TUserProfileActionTypes
): IUserProfileState => {
  switch (action.type) {
    case EUserProfileAction.GET_USER_INFO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EUserProfileAction.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EUserProfileAction.GET_USER_INFO_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EUserProfileAction.EDIT_USER_PROFILE_REQUEST:
      return {
        ...state,
        isPageLoading: false,
        isButtonLoading: true,
      };
    case EUserProfileAction.EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EUserProfileAction.EDIT_USER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EUserProfileAction.CLEAR_USER_PROFILE:
      return { ...INITIAL_USER_PROFILE_STATE };
    default: {
      return state;
    }
  }
};
