import { ILinkageNSBState, INITIAL_LINKAGE_NSB_STATE } from 'shared/modules/linkage-nsb';
import { ELinkageNSBAction, TLinkageNSBAction } from './linkage-nsb.type';

export default (state: ILinkageNSBState = INITIAL_LINKAGE_NSB_STATE, action: TLinkageNSBAction): ILinkageNSBState => {
  switch (action.type) {
    case ELinkageNSBAction.GET_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELinkageNSBAction.GET_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ELinkageNSBAction.GET_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
