export enum ButtonPreset {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  OUTLINE = 'outline',
  DANGER = 'danger',
  LINK = 'link',
  DISABLED = 'disabled',
  SUCCESS = 'success',
  LIGHT_BLUE = '#1e50ae',
  DANGER_MINI = 'DANGER_MINI',
  SUCCESS_MINI = 'SUCCESS_MINI',
}

export enum ButtonColor {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Olive = 'olive',
  Green = 'green',
  Teal = 'teal',
  Blue = 'blue',
  Violet = 'violet',
  Purple = 'purple',
  Pink = 'pink',
  Brown = 'brown',
  Grey = 'grey',
  Black = 'black',
}

export enum ButtonSize {
  Mini = 'mini',
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Big = 'big',
  Huge = 'huge',
  Massive = 'massive',
}

export enum ButtonType {
  Primary = 'primary',
  Ghost = 'ghost',
  Dashed = 'dashed',
  Danger = 'danger',
  Link = 'link',
  Text = 'text',
  Default = 'default',
}
