import React, { FC } from 'react';
import TableContext from './TableContext';
import { TableBody as Body, TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';
import constants from 'shared/constants';
import { LargeText } from '..';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ShareOutlined as GraphIcon,
  InsertDriveFile as FileIcon,
  CloudDownloadOutlined as DownloadIcon,
  BackupOutlined as UploadIcon,
} from '@material-ui/icons';
import IconCaseFileRecord from '../../../assets/images/icon/Ic_case_file_record.svg';

const TableLoadingRow = styled(TableRow)`
  border-bottom: none;
`;
const TableLoadingColumn = styled(TableCell)`
  border-bottom: none !important;
`;
const TableBodyRow = styled(TableRow)`
  border-bottom: 2px solid ${constants.LIGHT_BLUE};
`;
const TableBodyColumn = styled(TableCell)`
  width: ${(props) => props.width};
`;
const TableBodyActionColumn = styled(TableBodyColumn)`
  width: 120px;
`;
const TableBodyText = styled(LargeText)`
  max-width: 100ch;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableActionWrapper = styled.div`
  margin-left: auto;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(3, max-content);

  > svg {
    cursor: pointer;
  }

  > div {
    width: 1em;
    height: 1em;
  }
`;
const ItemIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const TableBody: FC = () => (
  <TableContext.Consumer>
    {({ columns, isLoading, items, spaceCount, onGraph, onDownload, onUpload, onFile, onView, onEdit, onDelete, 
      onModal,
    }) => (
      <Body>
        {isLoading ? (
          <TableLoadingRow>
            <TableLoadingColumn>
              <TableBodyText>...กำลังโหลด</TableBodyText>
            </TableLoadingColumn>
          </TableLoadingRow>
        ) : (
          items.map((item: any) => {
            return (
              <TableBodyRow key={item.id}>
                {columns.map((column) => (
                  <TableBodyColumn key={column.key} align={column.align} width={column.width}>
                    <TableBodyText>{item[column.key]}</TableBodyText>
                  </TableBodyColumn>
                ))}
                {(onView || onEdit || onDelete || onGraph || onDownload || onFile || onUpload
                  || onModal
                ) && (
                  <TableBodyActionColumn>
                    <TableActionWrapper>
                      {onGraph && item.isShow && <GraphIcon onClick={() => onGraph(item.id)} />}
                      {onView && <VisibilityIcon onClick={() => onView(item.id)} />}
                      {onEdit && <EditIcon onClick={() => onEdit(item.id)} />}
                      {onUpload && <UploadIcon onClick={() => onUpload(item.id)} />}
                      {onDownload && item.filePath && <DownloadIcon onClick={() => onDownload(item.filePath)} />}
                      {onFile && <FileIcon onClick={() => onFile(item.id)} />}
                      {onDelete && item.isDelete && <DeleteIcon onClick={() => onDelete(item.id)} />}
                      {onModal && <ItemIcon src={IconCaseFileRecord} onClick={() => onModal(item)} />}
                    </TableActionWrapper>
                  </TableBodyActionColumn>
                )}
              </TableBodyRow>
            );
          })
        )}
      </Body>
    )}
  </TableContext.Consumer>
);

export default TableBody;
