import { SelectOptionKey } from 'components/ui/type/select.type';

const useOperatorDropdownApi = () => {
  const options = [
    { value: 1, key: '', text: 'True' },
    { value: 2, key: '', text: 'AIS' },
    { value: 3, key: '', text: 'Dtac' },
    { value: 4, key: 'cerebrite', text: 'Cerebrite' },
  ];

  const selectOption: SelectOptionKey[] = options.map((element) => {
    return {
      key: element.key,
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useOperatorDropdownApi;
