export default {
  HEADER_HEIGHT: 70, // 60
  NAVBAR_WIDTH: 75,
  NAVBAR_EXPANDED_WIDTH: 400,
  FOOTER_HEIGHT: 40,

  // font
  MINI_FONT: 12,
  SMALL_FONT: 14,
  MEDIUM_FONT: 16,
  LARGE_FONT: 18,
  HEADER_FONT: 24,
  TITLE_FONT: 28,

  // color
  PRIMARY_COLOR: '#0B2545',
  SECONDARY_COLOR: '#8DA9CD',
  DARK_BLUE: '#0B2545',
  BLUE: '#345995',
  LIGHT_BLUE: '#8DA9CD',
  WHITE: '#FDFFFC',
  BLACK: '#222',
  YELLOW: '#F7B801',
  RED: '#EC1F28',
  GREEN: '#408920',
  CREAM: '#EEF4ED',
  GREY: '#AAA',
  INVISIBLE: '#0000',
  LIGHT_GRAY: '#b4c6d3',
  ORANGE: '#FFB042',
  PURPLE: '#FF4DED',
  LIGHT_GREEN: '#a5ff8a',
  LIGHT_RED: '#ff9696',

  // ui
  INPUT_HEIGHT: 38,

  // table

  // modal
  MODAL_WIDTH: '60%',
  MODAL_HEIGHT: '50%',
  MODAL_PADDING: '4rem',

  // icon
  ICON_HEIGHT: 50,
  ICON_WIDTH: 50,
  ICON_HEIGHT_BUTTON: 25,
  ICON_WIDTH_BUTTON: 25,
};
