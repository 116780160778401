import { IInvestigationReportState, INITIAL_INVESTIGATION_REPORT_STATE } from 'shared/modules/investigate-report';
import { EInvestigationReportAction, TInvestigationReportActionTypes } from './investigation-report.type';

export default (
  state: IInvestigationReportState = INITIAL_INVESTIGATION_REPORT_STATE,
  action: TInvestigationReportActionTypes
): IInvestigationReportState => {
  switch (action.type) {
    case EInvestigationReportAction.SEARCH_INVESTIGATION_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationReportAction.SEARCH_INVESTIGATION_REPORT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.SEARCH_INVESTIGATION_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.CREATE_INVESTIGATION_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case EInvestigationReportAction.CREATE_INVESTIGATION_REPORT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.CREATE_INVESTIGATION_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.GET_INVESTIGATION_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case EInvestigationReportAction.GET_INVESTIGATION_REPORT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.GET_INVESTIGATION_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.UPDATE_INVESTIGATION_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case EInvestigationReportAction.UPDATE_INVESTIGATION_REPORT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInvestigationReportAction.UPDATE_INVESTIGATION_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };

    default:
      return { ...state };
  }
};
