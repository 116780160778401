import { SelectOption } from "components/ui/type";

export enum EScoreType {
  GET_SCORE_TYPE_REQUEST = 'GET_SCORE_TYPE_REQUEST',
  GET_SCORE_TYPE_SUCCESS = 'GET_SCORE_TYPE_SUCCESS',
  GET_SCORE_TYPE_FAILED = 'GET_SCORE_TYPE_FAILED',
  CLEAR_SCORE_TYPE = 'CLEAR_SCORE_TYPE',
}

export interface IGetScoreTypeRequest {
  type: EScoreType.GET_SCORE_TYPE_REQUEST;
}

export interface IGetScoreTypeSuccess {
  type: EScoreType.GET_SCORE_TYPE_SUCCESS;
  data: SelectOption[];
}

export interface IGetScoreTypeFailed {
  type: EScoreType.GET_SCORE_TYPE_FAILED;
  error: Error;
}

export interface IClearScoreType {
  type: EScoreType.CLEAR_SCORE_TYPE;
}

export type TScoreTypeAction = IGetScoreTypeRequest | IGetScoreTypeSuccess | IGetScoreTypeFailed | IClearScoreType;
