import { StatusAlert } from 'components/ui';
import { AlertStatus } from 'components/ui/enum';
import { ICreateUserForm } from 'shared/modules/user-management';
import axios from 'shared/utils/axios';
import { IUser, UserManagementAction, UserManagementActionTypes } from './user-management.type';
import { dataToParam, uploadFile } from 'shared/utils/api';
import _ from 'lodash';
import { IUserManagementSearchForm } from 'shared/modules/user-management/user-management.interface';
import { toQueryString } from 'shared/utils/query-string';
import { TDataTable } from 'shared/types';
import { idCardFormat } from 'shared/utils/string-format';
import { unFormattedIdCard } from 'shared/utils/converter';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorage } from 'shared/enum/local-storage.type';
import { fileURLToPath } from 'url';
import { dateThaiFormat } from 'shared/utils/date-format';
import { EDateFormat } from 'shared/enum/date-format.enum';
// import i18next from 'i18next';

// POST /user/register
export const register = (data: ICreateUserForm, cb?: Function) => {
  data.idCard = data.idCard.replaceAll('-', '');
  return async (dispatch: any) => {
    dispatch({ type: UserManagementAction.REGISTER_REQUEST });

    try {
      if (Array.isArray(data.newAttachments) && data.newAttachments.length) {
        const attachedDocuments = await uploadFile({
          routeName: '/user-management/create',
          attachments: data.newAttachments,
        });

        if (!_.isEmpty(attachedDocuments)) {
          data.attachedDocuments = data.attachedDocuments?.concat(attachedDocuments);
        }
      }
      if (data.imageFile) {
        const profileImageUrl = await uploadFile({
          routeName: '/user-management/create',
          attachments: [{ file: data.imageFile }],
        });

        if (!_.isEmpty(profileImageUrl)) {
          data.profileImgUrl = profileImageUrl[0];
        }

        delete data.imageFile;
      }

      const params = toQueryString(data)
      // console.log('params', params)
      await axios.post('/user', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(registerSuccess(cb));
    } catch (error) {
      dispatch(registerFailed(error));
    }
  };
};

const registerSuccess = (cb?: Function): UserManagementActionTypes => {
  StatusAlert('ลงทะเบียนสำเร็จ', AlertStatus.Success).then(() => {
    if (cb) cb();
  });

  return {
    type: UserManagementAction.REGISTER_SUCCESS,
  };
};

const registerFailed = (error: any): UserManagementActionTypes => {
  if (error.response.data === 'Duplicate data') {
    StatusAlert('ลงทะเบียนผิดพลาด พบข้อมูลซ้ำ', AlertStatus.Error);
  } else {
    StatusAlert('ลงทะเบียนผิดพลาด โปรดลองอีกครั้ง', AlertStatus.Error);
  }

  return {
    type: UserManagementAction.REGISTER_FAILED,
    error,
  };
};

// GET /user
export const searchUsers = (data?: IUserManagementSearchForm, cb?: Function) => {

  return async (dispatch) => {
    dispatch({ type: UserManagementAction.SEARCH_USERS_REQUEST });
    try {
      if (data?.idCard) {
        data.idCard = unFormattedIdCard(data.idCard);
      }
      const response = await axios.get('/user', {
        params: toQueryString(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const resolvedResponse: TDataTable<IUser> = {
        ...response.data,
        table: response.data?.table?.map((u: IUser) => ({
          ...u,
          idCard: idCardFormat(u.idCard),
        })),
      };

      dispatch(searchUsersSuccess(resolvedResponse, cb));
      reactLocalStorage.set(ELocalStorage.USERMANAGEMENT_CURRENTPAGE, response.data.currentPage);
      reactLocalStorage.set(ELocalStorage.USERMANAGEMENT_PAGESIZE, response.data.pageSize);
    } catch (error) {
      dispatch(searchUsersFailed(error));
    }
  };
};

const searchUsersSuccess = (users: TDataTable<IUser>, cb?: Function): UserManagementActionTypes => {
  if (cb) cb();
  return {
    type: UserManagementAction.SEARCH_USERS_SUCCESS,
    users,
  };
};

const searchUsersFailed = (error): UserManagementActionTypes => {
  return {
    type: UserManagementAction.SEARCH_USERS_FAILED,
    error,
  };
};

// GET /user/{id}
export const getUserById = (userId: string, cb?: Function) => {
  return async (dispatch: (arg0: UserManagementActionTypes) => void) => {
    dispatch({ type: UserManagementAction.GET_USER_BY_ID_REQUEST });
    try {
      const response = await axios.get(`/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const res = {
        ...response.data,
        userStatusIDTemp: response.data.userStatusID + 1,
        attachedDocuments: response.data?.attachedDocuments?.map((file) => ({
          ...file,
          fileDate: dateThaiFormat(file.createDateTime, EDateFormat.FullDateTime),
        })),
      }
      dispatch(getUserByIdSuccess(res, cb));
    } catch (error) {
      dispatch(getUserByIdFailed(error));
    }
  };
};

const getUserByIdSuccess = (user: ICreateUserForm, cb?: Function): UserManagementActionTypes => {
  if (cb) cb();
  return {
    type: UserManagementAction.GET_USER_BY_ID_SUCCESS,
    selectedUser: user,
  };
};

const getUserByIdFailed = (error): UserManagementActionTypes => {
  return {
    type: UserManagementAction.GET_USER_BY_ID_FAILED,
    error,
  };
};

// PUT /user/{id}
export const editUser = (userId: string) => (data: ICreateUserForm, cb?: Function) => {
  data.idCard = data.idCard.replaceAll('-', '');
  data.attachedDocuments = data.attachedDocuments.map((file, index) => {
    return {
      ...file,
      id: file.baseUploadID,
      fileDate: file.createDateTime,
      fileName: file.fileName,
    };
  }
  );
  return async (dispatch: any) => {
    dispatch({ type: UserManagementAction.EDIT_USER_REQUEST });
    try {
      if (Array.isArray(data.newAttachments) && data.newAttachments.length) {
        const attachedDocuments = await uploadFile({
          routeName: '/user-management/create',
          attachments: data.newAttachments,
        });

        if (!_.isEmpty(attachedDocuments)) {
          data.attachedDocuments = data.attachedDocuments?.concat(attachedDocuments);
        }
      }
      if (data.imageFile) {
        const profileImageUrl = await uploadFile({
          routeName: 'edit_user',
          attachments: [{ file: data.imageFile }],
        });

        if (!_.isEmpty(profileImageUrl)) {
          data.profileImgUrl = profileImageUrl[0];
        }

        delete data.imageFile;
      }

      // const params = toQueryString(data)
      // console.log('params', params)
      await axios.put(`/user/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(editUserSuccess(cb));
    } catch (error) {
      dispatch(editUserFailed(error));
    }
  };
};

const editUserSuccess = (cb?: Function): UserManagementActionTypes => {
  StatusAlert('แก้ไขสำเร็จ', AlertStatus.Success).then(() => {
    if (cb) cb();
  });
  return {
    type: UserManagementAction.REGISTER_SUCCESS,
  };
};

const editUserFailed = (error): UserManagementActionTypes => {
  StatusAlert('แก้ไขผิดพลาด โปรดลองอีกครั้ง', AlertStatus.Error);

  return {
    type: UserManagementAction.REGISTER_FAILED,
    error,
  };
};
//userExcel upload
export const uploadUserExcelFile = (file: File, cb?: Function) => {
  const formData = new FormData();
  formData.set('routeName', '/user-management/create');
  formData.append('attachment', file);
  return (dispatch) => {
    dispatch({ type: UserManagementAction.REGISTER_REQUEST });
    axios
      .post(`/attachment/uploadExcel/user`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(uploadUserExcelFileSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(uploadUserExcelFileFailed(error));
      });
  };
};
const uploadUserExcelFileSuccess = (data: any, cb?: Function): UserManagementActionTypes => {
  if (cb) cb();
  StatusAlert('อัพโหลดข้อมูลรายชื่อสมาชิกสำเร็จ', AlertStatus.Success);
  return {
    type: UserManagementAction.REGISTER_SUCCESS,
  };
};

const uploadUserExcelFileFailed = (error: Error): UserManagementActionTypes => {
  StatusAlert('อัพโหลดข้อมูลรายชื่อสมาชิกไม่สำเร็จ', AlertStatus.Error);
  return {
    type: UserManagementAction.REGISTER_FAILED,
    error,
  };
};
// utils
export const clearSelectedUserState = (): UserManagementActionTypes => {
  return {
    type: UserManagementAction.CLEAR_SELECTED_USER_STATE,
  };
};
