import { SelectOption } from "components/ui/type";

export enum EOfficerType {
  GET_OFFICER_TYPE_REQUEST = 'GET_OFFICER_TYPE_REQUEST',
  GET_OFFICER_TYPE_SUCCESS = 'GET_OFFICER_TYPE_SUCCESS',
  GET_OFFICER_TYPE_FAILED = 'GET_OFFICER_TYPE_FAILED',
  CLEAR_OFFICER_TYPE = 'CLEAR_OFFICER_TYPE',
}

export interface IGetOfficerTypeRequest {
  type: EOfficerType.GET_OFFICER_TYPE_REQUEST;
}

export interface IGetOfficerTypeSuccess {
  type: EOfficerType.GET_OFFICER_TYPE_SUCCESS;
  data: SelectOption[];
}

export interface IGetOfficerTypeFailed {
  type: EOfficerType.GET_OFFICER_TYPE_FAILED;
  error: Error;
}

export interface IClearOfficerType {
  type: EOfficerType.CLEAR_OFFICER_TYPE;
}

export type TOfficerTypeAction = IGetOfficerTypeRequest | IGetOfficerTypeSuccess | IGetOfficerTypeFailed | IClearOfficerType;
