import { TLicensePlateActionTypes, ELicensePlateAction } from 'store/license-plate/license-plate.type';
import { ILicensePlateState, INITIAL_LICENSE_PLATE_STATE, ILicensePlate } from 'shared/modules/license-plate';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import { IExportLicensePlateResponseDTO } from 'shared/modules/license-plate/license-plate.interface';

export default (
  state: ILicensePlateState = INITIAL_LICENSE_PLATE_STATE,
  action: TLicensePlateActionTypes
): ILicensePlateState => {
  switch (action.type) {
    case ELicensePlateAction.SEARCH_LICENSE_PLATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case ELicensePlateAction.SEARCH_LICENSE_PLATE_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ELicensePlateAction.SEARCH_LICENSE_PLATE_FAILED:
      return {
        ...state,
        all: INITIAL_DATA_TABLE_TYPE,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ELicensePlateAction.VIEW_LICENSE_PLATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELicensePlateAction.VIEW_LICENSE_PLATE_SUCCESS:
      return {
        ...state,
        view: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.VIEW_LICENSE_PLATE_FAILED:
      return {
        ...state,
        view: {} as ILicensePlate,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.GET_LICENSE_PLATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELicensePlateAction.GET_LICENSE_PLATE_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.GET_LICENSE_PLATE_FAILED:
      return {
        ...state,
        selected: {} as ILicensePlate,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.UPDATE_LICENSE_PLATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELicensePlateAction.UPDATE_LICENSE_PLATE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.UPDATE_LICENSE_PLATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.CLEAR_SELECT_LICENSE_PLATE:
      return {
        ...state,
        selected: {} as ILicensePlate,
        view: {} as ILicensePlate,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELicensePlateAction.SET_SEARCH_FORM:
      return {
        ...state,
        search: action.data,
      };
    case ELicensePlateAction.EXPORT_LICENSE_PLATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ELicensePlateAction.EXPORT_LICENSE_PLATE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
        export: {} as IExportLicensePlateResponseDTO,
      };
    case ELicensePlateAction.EXPORT_LICENSE_PLATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
