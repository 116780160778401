import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useProsecuteTypeDropdownApi = () => {
  const [prosecute, isLoading, isError] = useFetchApi<IDropdown[]>('/prosecuteType/getDropdownList', []);

  const prosecuteTypeSelectOptions: SelectOption[] = prosecute.map((prosecute) => {
    return {
      text: prosecute.text,
      value: prosecute.id,
    };
  });
  return [prosecuteTypeSelectOptions, isLoading, isError] as const;
};
export default useProsecuteTypeDropdownApi;
