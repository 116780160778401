import _ from 'lodash';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorageDropdown } from 'shared/enum/local-storage.type';
import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCaseStatusDropdownApi = () => {
  const dropdown = reactLocalStorage.getObject(ELocalStorageDropdown.DROPDOWN_CASESTATUS, []);
  const [caseStatus, isLoading, isError] = useFetchApi<IDropdown[]>(
    '/caseStatus/getDropdownList',
    [],
    '',
    null,
    _.isEmpty(dropdown)
  );

  const caseStatusSelectOptions: SelectOption[] = caseStatus.map((caseStatus) => {
    return {
      text: caseStatus.text,
      value: caseStatus.id,
    };
  });

  if (!isError && _.isEmpty(dropdown)) {
    reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_CASESTATUS, caseStatusSelectOptions);
    return [caseStatusSelectOptions, isLoading, isError];
  } else {
    return [dropdown, isLoading, false];
  }
  // return [caseStatusSelectOptions, isLoading, isError] as const;
};
export default useCaseStatusDropdownApi;
