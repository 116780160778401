export const imageToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = (error) => reject(error);
    }
  });
};

export const unFormattedIdCard = (idCard: string): string => {
  if (!idCard) {
    return '';
  }

  return idCard.replaceAll('-', '').replaceAll('/u2000', '');
};
