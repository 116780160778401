import { IInform, IInformList } from 'shared/modules/inform';
import { TDataTable } from 'shared/types';

export enum EInformAction {
  SEARCH_INFORM_REQUEST = 'SEARCH_INFORM_REQUEST',
  SEARCH_INFORM_SUCCESS = 'SEARCH_INFORM_SUCCESS',
  SEARCH_INFORM_FAILED = 'SEARCH_INFORM_FAILED',
  GET_INFORM_REQUEST = 'GET_INFORM_REQUEST',
  GET_INFORM_SUCCESS = 'GET_INFORM_SUCCESS',
  GET_INFORM_FAILED = 'GET_INFORM_FAILED',
  CREATE_INFORM_REQUEST = 'CREATE_INFORM_REQUEST',
  CREATE_INFORM_SUCCESS = 'CREATE_INFORM_SUCCESS',
  CREATE_INFORM_FAILED = 'CREATE_INFORM_FAILED',
  UPDATE_INFORM_REQUEST = 'UPDATE_INFORM_REQUEST',
  UPDATE_INFORM_SUCCESS = 'UPDATE_INFORM_SUCCESS',
  UPDATE_INFORM_FAILED = 'UPDATE_INFORM_FAILED',
  DELETE_INFORM_REQUEST = 'DELETE_INFORM_REQUEST',
  DELETE_INFORM_SUCCESS = 'DELETE_INFORM_SUCCESS',
  DELETE_INFORM_FAILED = 'DELETE_INFORM_FAILED',
  CLEAR_SEARCH_INFORM = 'CLEAR_SEARCH_INFORM',
  CLEAR_SELECT_INFORM = 'CLEAR_SELECT_INFORM',
}

interface ISearchInformRequest {
  type: EInformAction.SEARCH_INFORM_REQUEST;
}

interface ISearchInformSuccess {
  type: EInformAction.SEARCH_INFORM_SUCCESS;
  data: TDataTable<IInformList>;
}

interface ISearchInformFailed {
  type: EInformAction.SEARCH_INFORM_FAILED;
  error: Error;
}

interface IGetInformRequest {
  type: EInformAction.GET_INFORM_REQUEST;
}

interface IGetInformSuccess {
  type: EInformAction.GET_INFORM_SUCCESS;
  data: IInform;
}

interface IGetInformFailed {
  type: EInformAction.GET_INFORM_FAILED;
  error: Error;
}

interface ICreateInformRequest {
  type: EInformAction.CREATE_INFORM_REQUEST;
}

interface ICreateInformSuccess {
  type: EInformAction.CREATE_INFORM_SUCCESS;
}

interface ICreateInformFailed {
  type: EInformAction.CREATE_INFORM_FAILED;
  error: Error;
}

interface IUpdateInformRequest {
  type: EInformAction.UPDATE_INFORM_REQUEST;
}

interface IUpdateInformSuccess {
  type: EInformAction.UPDATE_INFORM_SUCCESS;
}

interface IUpdateInformFailed {
  type: EInformAction.UPDATE_INFORM_FAILED;
  error: Error;
}

interface IDeleteInformRequest {
  type: EInformAction.DELETE_INFORM_REQUEST;
}

interface IDeleteInformSuccess {
  type: EInformAction.DELETE_INFORM_SUCCESS;
}

interface IDeleteInformFailed {
  type: EInformAction.DELETE_INFORM_FAILED;
  error: Error;
}

interface IClearSelectInform {
  type: EInformAction.CLEAR_SELECT_INFORM;
}

export type TInformActionTypes =
  | ISearchInformRequest
  | ISearchInformSuccess
  | ISearchInformFailed
  | IGetInformRequest
  | IGetInformSuccess
  | IGetInformFailed
  | ICreateInformRequest
  | ICreateInformSuccess
  | ICreateInformFailed
  | IUpdateInformRequest
  | IUpdateInformSuccess
  | IUpdateInformFailed
  | IDeleteInformRequest
  | IDeleteInformSuccess
  | IDeleteInformFailed
  | IClearSelectInform;
