import { IArrestCIResponse, IArrestCITable, IArrestForm, IArrestList } from 'shared/modules/arrest';
import { TDataTable } from 'shared/types';

export enum EArrestAction {
  SEARCH_ARREST_REQUEST = 'SEARCH_ARREST_REQUEST',
  SEARCH_ARREST_SUCCESS = 'SEARCH_ARREST_SUCCESS',
  SEARCH_ARREST_FAILED = 'SEARCH_ARREST_FAILED',
  CREATE_ARREST_REQUEST = 'CREATE_ARREST_REQUEST',
  CREATE_ARREST_SUCCESS = 'CREATE_ARREST_SUCCESS',
  CREATE_ARREST_FAILED = 'CREATE_ARREST_FAILED',
  GET_ARREST_REQUEST = 'GET_ARREST_REQUEST',
  GET_ARREST_SUCCESS = 'GET_ARREST_SUCCESS',
  GET_ARREST_FAILED = 'GET_ARREST_FAILED',
  UPDATE_ARREST_REQUEST = 'UPDATE_ARREST_REQUEST',
  UPDATE_ARREST_SUCCESS = 'UPDATE_ARREST_SUCCESS',
  UPDATE_ARREST_FAILED = 'UPDATE_ARREST_FAILED',
  DELETE_ARREST_REQUEST = 'DELETE_ARREST_REQUEST',
  DELETE_ARREST_SUCCESS = 'DELETE_ARREST_SUCCESS',
  DELETE_ARREST_FAILED = 'DELETE_ARREST_FAILED',
  GET_PDF_REQUEST = 'GET_PDF_REQUEST',
  GET_PDF_SUCCESS = 'GET_PDF_SUCCESS',
  GET_PDF_FAILED = 'GET_PDF_FAILED',
  CLEAR_SELECTED_ARREST = 'CLEAR_SELECTED_ARREST',
  GET_ARREST_CI_REQUEST = 'GET_ARREST_CI_REQUEST',
  GET_ARREST_CI_SUCCESS = 'GET_ARREST_CI_SUCCESS',
  GET_ARREST_CI_FAILED = 'GET_ARREST_CI_FAILED',
}

interface ISearchArrestRequest {
  type: EArrestAction.SEARCH_ARREST_REQUEST;
}

interface ISearchArrestSuccess {
  type: EArrestAction.SEARCH_ARREST_SUCCESS;
  data: TDataTable<IArrestList>;
}

interface ISearchArrestFailed {
  type: EArrestAction.SEARCH_ARREST_FAILED;
  error: Error;
}

interface ICreateArrestRequest {
  type: EArrestAction.CREATE_ARREST_REQUEST;
}

interface ICreateArrestSuccess {
  type: EArrestAction.CREATE_ARREST_SUCCESS;
}

interface ICreateArrestFailed {
  type: EArrestAction.CREATE_ARREST_FAILED;
  error: Error;
}

interface IGetArrestRequest {
  type: EArrestAction.GET_ARREST_REQUEST;
}

interface IGetArrestSuccess {
  type: EArrestAction.GET_ARREST_SUCCESS;
  data: IArrestForm;
}

interface IGetArrestFailed {
  type: EArrestAction.GET_ARREST_FAILED;
  error: Error;
}

interface IUpdateArrestRequest {
  type: EArrestAction.UPDATE_ARREST_REQUEST;
}

interface IUpdateArrestSuccess {
  type: EArrestAction.UPDATE_ARREST_SUCCESS;
}

interface IUpdateArrestFailed {
  type: EArrestAction.UPDATE_ARREST_FAILED;
  error: Error;
}

interface IDeleteArrestRequest {
  type: EArrestAction.DELETE_ARREST_REQUEST;
}

interface IDeleteArrestSuccess {
  type: EArrestAction.DELETE_ARREST_SUCCESS;
}

interface IDeleteArrestFailed {
  type: EArrestAction.DELETE_ARREST_FAILED;
  error: Error;
}

interface IGetPdfRequest {
  type: EArrestAction.GET_PDF_REQUEST;
}

interface IGetPdfSuccess {
  type: EArrestAction.GET_PDF_SUCCESS;
  fileUrl: string;
}

interface IGetPdfFailed {
  type: EArrestAction.GET_PDF_FAILED;
  error: Error;
}

interface IClearSelectedArrest {
  type: EArrestAction.CLEAR_SELECTED_ARREST;
}

interface IGetArrestCIRequest {
  type: EArrestAction.GET_ARREST_CI_REQUEST;
}

interface IGetArrestCISuccess {
  type: EArrestAction.GET_ARREST_CI_SUCCESS;
  data: IArrestCIResponse;
}

interface IGetArrestCIFailed {
  type: EArrestAction.GET_ARREST_CI_FAILED;
  error: Error;
}

export type TArrestActionTypes =
  | ISearchArrestRequest
  | ISearchArrestSuccess
  | ISearchArrestFailed
  | ICreateArrestRequest
  | ICreateArrestSuccess
  | ICreateArrestFailed
  | IGetArrestRequest
  | IGetArrestSuccess
  | IGetArrestFailed
  | IUpdateArrestRequest
  | IUpdateArrestSuccess
  | IUpdateArrestFailed
  | IDeleteArrestRequest
  | IDeleteArrestSuccess
  | IDeleteArrestFailed
  | IGetPdfRequest
  | IGetPdfSuccess
  | IGetPdfFailed
  | IClearSelectedArrest
  | IGetArrestCIRequest
  | IGetArrestCISuccess
  | IGetArrestCIFailed;
