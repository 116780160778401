import { validate, ValidateRule } from 'shared/validate';
import {
  IModalInvestigateValidate,
} from 'shared/modules/modal-investigate';

export const validateList = {
    caseName: (e: string) => validate(e, [ValidateRule.isRequired]),
};

export const setErrorList = (form: IModalInvestigateValidate, setErrorState) => {
const errorList: IModalInvestigateValidate = {
    caseName: validateList.caseName(form.caseName),
};
setErrorState(errorList);
return errorList;
}
  