import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';
import { TSelect } from 'shared/types';

export interface IDropdownCode extends IDropdown {
  codeNo: string;
}

export interface SelectOptionCode extends SelectOption {
  code: string;
}

const useSectorByAgencyDropdownApi = (id: TSelect) => {
  const [policeStations, isLoading, isError] = useFetchApi<IDropdownCode[]>(
    `/Master/Agency/${id}/Sector/GetDropdownList`,
    [],
    'id',
    id
  );

  const sectorByAgencySelectOptions: SelectOptionCode[] = policeStations.map((o: IDropdownCode) => {
    return {
      text: o.text,
      value: o.id,
      code: o.codeNo,
    };
  });

  return [sectorByAgencySelectOptions, isLoading, isError] as const;
};

export default useSectorByAgencyDropdownApi;
