import React, { FC } from 'react';
import styled from 'styled-components';
import constants from 'shared/constants';

interface IDividerProps {
  className?: string;
}

const StyledDivider = styled.div`
  border-bottom: 1px solid ${constants.GREY};
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Divider: FC<IDividerProps> = ({ className }) => <StyledDivider className={className} />;

export default Divider;
