import { EOfficerType, TOfficerTypeAction } from './officer-type.type';
import { IDropdownState } from 'shared/interfaces/dropdown.interface';
import { INITIAL_DROPDOWN_STATE } from 'shared/constant/dropdown.constant';

export default (state: IDropdownState = INITIAL_DROPDOWN_STATE, action: TOfficerTypeAction): IDropdownState => {
  switch (action.type) {
    case EOfficerType.GET_OFFICER_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
      };
    case EOfficerType.GET_OFFICER_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.data,
      };
    case EOfficerType.GET_OFFICER_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};
