export enum EDataTableLayout {
  WIDTHHEADER = '5%',
  WIDTHSMALL = '50px',
  WIDTHMEDIUM = '200px',
  WIDTHLARGE = '400px',
}

export enum EDataScienceGraph {
  SUCCESS = 'เสร็จสิ้น',
  WAIT = 'กำลังสร้างความเชื่อมโยง',
  FAILED = 'สร้างความเชื่อมโยงไม่สำเร็จ',
}
