import { TNotificationActionTypes, ENotification } from './notification.type';
import { INotificationState, INITIAL_NOTIFICATION_STATE } from 'shared/modules/notification';

export default (
  state: INotificationState = INITIAL_NOTIFICATION_STATE,
  action: TNotificationActionTypes
): INotificationState => {
  switch (action.type) {
    case ENotification.GET_NOTIFICATION_COUNT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.GET_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        count: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.GET_NOTIFICATION_COUNT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.GET_NOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case ENotification.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.GET_NOTIFICATION_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.VIEW_NOTIFICATION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.VIEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ENotification.VIEW_NOTIFICATION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
