import {
  IDataVisualizeList,
  IDataVisualizeSuspectForm,
  IDataVisualizeAttachment,
  IDataVisualizeFileResponse,
} from 'shared/modules/data-visualize';
import { IGraph } from 'shared/modules/graph';

export enum EDataVisualizeAction {
  DATA_VISUALIZE_SEARCH_REQUEST = 'DATA_VISUALIZE_SEARCH_REQUEST',
  DATA_VISUALIZE_SEARCH_SUCESS = 'DATA_VISUALIZE_SEARCH_SUCESS',
  DATA_VISUALIZE_SEARCH_FAILED = 'DATA_VISUALIZE_SEARCH_FAILED',
  DATA_VISUALIZE_GET_REQUEST = 'DATA_VISUALIZE_GET_REQUEST',
  DATA_VISUALIZE_GET_SUCESS = 'DATA_VISUALIZE_GET_SUCESS',
  DATA_VISUALIZE_GET_FAILED = 'DATA_VISUALIZE_GET_FAILED',
  DATA_VISUALIZE_CREATE_REQUEST = 'DATA_VISUALIZE_CREATE_REQUEST',
  DATA_VISUALIZE_CREATE_SUCESS = 'DATA_VISUALIZE_CREATE_SUCESS',
  DATA_VISUALIZE_CREATE_FAILED = 'DATA_VISUALIZE_CREATE_FAILED',
  DATA_VISUALIZE_UPDATE_REQUEST = 'DATA_VISUALIZE_UPDATE_REQUEST',
  DATA_VISUALIZE_UPDATE_SUCESS = 'DATA_VISUALIZE_UPDATE_SUCESS',
  DATA_VISUALIZE_UPDATE_FAILED = 'DATA_VISUALIZE_UPDATE_FAILED',
  UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED',
  SUBMIT_FILE_REPONSE_REQUEST = 'SUBMIT_FILE_REPONSE_REQUEST',
  SUBMIT_FILE_REPONSE_SUCCESS = 'SUBMIT_FILE_REPONSE_SUCCESS',
  SUBMIT_FILE_REPONSE_FAILED = 'SUBMIT_FILE_REPONSE_FAILED',
  GET_FILE_RESPONSE_REQUEST = 'GET_FILE_RESPONSE_REQUEST',
  GET_FILE_RESPONSE_SUCCESS = 'GET_FILE_RESPONSE_SUCCESS',
  GET_FILE_RESPONSE_FAILED = 'GET_FILE_RESPONSE_FAILED',
  GET_GRAPH_REQUEST = 'GET_GRAPH_REQUEST',
  GET_GRAPH_SUCCESS = 'GET_GRAPH_SUCCESS',
  GET_GRAPH_FAILED = 'GET_GRAPH_FAILED',
  EXCEL_REQUEST = 'EXCEL_REQUEST',
  EXCEL_SUCCESS = 'EXCEL_SUCCESS',
  EXCEL_FAILED = 'EXCEL_FAILED',
  CLEAR_DATA_VISUALIZE_SELECT = 'CLEAR_DATA_VISUALIZE_SELECT',
  CLEAR_ATTACHMENT = 'CLEAR_ATTACHMENT',
}

interface IDataVisualizeSearchRequest {
  type: EDataVisualizeAction.DATA_VISUALIZE_SEARCH_REQUEST;
}

interface IDataVisualizeSearchSucess {
  type: EDataVisualizeAction.DATA_VISUALIZE_SEARCH_SUCESS;
  data: IDataVisualizeList[];
}

interface IDataVisualizeSearchFailed {
  type: EDataVisualizeAction.DATA_VISUALIZE_SEARCH_FAILED;
  error: Error;
}

interface IDataVisualizeGetRequest {
  type: EDataVisualizeAction.DATA_VISUALIZE_GET_REQUEST;
}

interface IDataVisualizeGetSuccess {
  type: EDataVisualizeAction.DATA_VISUALIZE_GET_SUCESS;
  data: IDataVisualizeSuspectForm;
}

interface IDataVisualizeGetFailed {
  type: EDataVisualizeAction.DATA_VISUALIZE_GET_FAILED;
  error: Error;
}

interface IDataVisualizeCreateRequest {
  type: EDataVisualizeAction.DATA_VISUALIZE_CREATE_REQUEST;
}

interface IDataVisualizeCreateSucess {
  type: EDataVisualizeAction.DATA_VISUALIZE_CREATE_SUCESS;
}

interface IDataVisualizeCreateFailed {
  type: EDataVisualizeAction.DATA_VISUALIZE_CREATE_FAILED;
  error: Error;
}

interface IDataVisualizeUpdateRequest {
  type: EDataVisualizeAction.DATA_VISUALIZE_UPDATE_REQUEST;
}

interface IDataVisualizeUpdatehSucess {
  type: EDataVisualizeAction.DATA_VISUALIZE_UPDATE_SUCESS;
}

interface IDataVisualizeUpdateFailed {
  type: EDataVisualizeAction.DATA_VISUALIZE_UPDATE_FAILED;
  error: Error;
}

interface IGetGraphRequest {
  type: EDataVisualizeAction.GET_GRAPH_REQUEST;
}

interface IGetGraphSuccess {
  type: EDataVisualizeAction.GET_GRAPH_SUCCESS;
  data: IGraph;
}

interface IGetGraphFailed {
  type: EDataVisualizeAction.GET_GRAPH_FAILED;
  error: Error;
}

interface IUploadFileRequest {
  type: EDataVisualizeAction.UPLOAD_FILE_REQUEST;
}

interface IUploadFileSuccess {
  type: EDataVisualizeAction.UPLOAD_FILE_SUCCESS;
  data: IDataVisualizeFileResponse;
}
interface IUploadFileFailed {
  type: EDataVisualizeAction.UPLOAD_FILE_FAILED;
  error: Error;
}

interface ISubmitFileResponseRequest {
  type: EDataVisualizeAction.SUBMIT_FILE_REPONSE_REQUEST;
}

interface ISubmitFileResponseSuccess {
  type: EDataVisualizeAction.SUBMIT_FILE_REPONSE_SUCCESS;
}

interface IGetFileResponseRequest {
  type: EDataVisualizeAction.GET_FILE_RESPONSE_REQUEST;
}

interface IGetFileResponseSuccess {
  type: EDataVisualizeAction.GET_FILE_RESPONSE_SUCCESS;
  data: IDataVisualizeAttachment;
}

interface IGetFileResponseFailed {
  type: EDataVisualizeAction.GET_FILE_RESPONSE_FAILED;
  error: Error;
}

interface ISubmitFileResponseFailed {
  type: EDataVisualizeAction.SUBMIT_FILE_REPONSE_FAILED;
  error: Error;
}

interface IExcelRequest {
  type: EDataVisualizeAction.EXCEL_REQUEST;
}

interface IExcelSuccess {
  type: EDataVisualizeAction.EXCEL_SUCCESS;
}

interface IExcelFailed {
  type: EDataVisualizeAction.EXCEL_FAILED;
  error: Error;
}

interface IClearDataVisualizeSelect {
  type: EDataVisualizeAction.CLEAR_DATA_VISUALIZE_SELECT;
}

interface IClearAttachment {
  type: EDataVisualizeAction.CLEAR_ATTACHMENT;
}

export type TDataVisualizeActionType =
  | IDataVisualizeSearchRequest
  | IDataVisualizeSearchSucess
  | IDataVisualizeSearchFailed
  | IDataVisualizeGetRequest
  | IDataVisualizeGetSuccess
  | IDataVisualizeGetFailed
  | IDataVisualizeCreateRequest
  | IDataVisualizeCreateSucess
  | IDataVisualizeCreateFailed
  | IDataVisualizeUpdateRequest
  | IDataVisualizeUpdatehSucess
  | IDataVisualizeUpdateFailed
  | IGetGraphRequest
  | IGetGraphSuccess
  | IGetGraphFailed
  | IClearDataVisualizeSelect
  | IUploadFileRequest
  | IUploadFileSuccess
  | IUploadFileFailed
  | ISubmitFileResponseRequest
  | ISubmitFileResponseSuccess
  | ISubmitFileResponseFailed
  | IGetFileResponseRequest
  | IGetFileResponseSuccess
  | IGetFileResponseFailed
  | IExcelRequest
  | IExcelSuccess
  | IExcelFailed
  | IClearAttachment;
