import { validate, ValidateRule } from 'shared/validate';
import {
  IInvestigationAttachmentForm,
  IInvestigationAttachmentValidate,
} from 'shared/modules/investigation-attachment';

export const validateInvestigation = {
  attachmentDetails: (value) => validate(value, [ValidateRule.isRequired]),
};

export const setErrorList = (form: IInvestigationAttachmentForm, setErrorState) => {
  const errorList: IInvestigationAttachmentValidate = {
    attachmentDetails: validateInvestigation.attachmentDetails(form.attachmentDetails),
  };
  setErrorState(errorList);
  return errorList;
};
