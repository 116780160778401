import { SelectOption } from 'components/ui/type';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { useFetchApi } from 'shared/utils/hooks';

const useUserDropdownApi = () => {
  const [users, isLoading, isError] = useFetchApi<IDropdown[]>('/userInfo/getUserDropdownList', []);

  const userSelectOptions: SelectOption[] = users.map((user: IDropdown) => {
    return {
      text: user.text,
      value: user.id,
    };
  });

  return [userSelectOptions, isLoading, isError] as const;
};

export default useUserDropdownApi;
