import { IMenu, IMenuPermission } from 'shared/modules/menu';

export enum EMenu {
  GET_MENU_REQUEST = 'GET_MENU_REQUEST',
  GET_MENU_SUCCESS = 'GET_MENU_SUCCESS',
  GET_MENU_FAILED = 'GET_MENU_FAILED',
  GET_MENU_PERMISSION_REQUEST = 'GET_MENU_PERMISSION_REQUEST',
  GET_MENU_PERMISSION_SUCCESS = 'GET_MENU_PERMISSION_SUCCESS',
  GET_MENU_PERMISSION_FAILED = 'GET_MENU_PERMISSION_FAILED',
}

interface IGetMenuRequest {
  type: EMenu.GET_MENU_REQUEST;
}

interface IGetMenuSuccess {
  type: EMenu.GET_MENU_SUCCESS;
  data: IMenu[];
}

interface IGetMenuFailed {
  type: EMenu.GET_MENU_FAILED;
  error: Error;
}

interface IGetMenuPermissionRequest {
  type: EMenu.GET_MENU_PERMISSION_REQUEST;
}

interface IGetMenuPermissionSuccess {
  type: EMenu.GET_MENU_PERMISSION_SUCCESS;
  data: IMenuPermission[];
}

interface IGetMenuPermissionFailed {
  type: EMenu.GET_MENU_PERMISSION_FAILED;
  error: Error;
}

export type TMenuActionTypes =
  | IGetMenuRequest
  | IGetMenuSuccess
  | IGetMenuFailed
  | IGetMenuPermissionRequest
  | IGetMenuPermissionSuccess
  | IGetMenuPermissionFailed;
