import { IDopaState, INITIAL_DOPA_STATE } from 'shared/modules/dopa';
import { EDopaAction, TDopa } from './dopa.type';

export default (state: IDopaState = INITIAL_DOPA_STATE, action: TDopa): IDopaState => {
  switch (action.type) {
    case EDopaAction.GET_DOPA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EDopaAction.GET_DOPA_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EDopaAction.GET_DOPA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EDopaAction.SEARCH_DOPA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EDopaAction.SEARCH_DOPA_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EDopaAction.SEARCH_DOPA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    // NOTE: get dopa log action
    case EDopaAction.GET_DOPA_LOG_ACTION_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EDopaAction.GET_DOPA_LOG_ACTION_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        log: action.data,
      };
    case EDopaAction.GET_DOPA_LOG_ACTION_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    // NOTE: get dopa log export
    case EDopaAction.GET_DOPA_LOG_EXPORT_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EDopaAction.GET_DOPA_LOG_EXPORT_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        export: action.data,
      };
    case EDopaAction.GET_DOPA_LOG_EXPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};

