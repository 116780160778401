import React, { FC } from 'react';
import TableContext from './TableContext';
import { TableHead as Head, TableRow, TableCell } from '@material-ui/core';
import { TTableColumn } from '../type';
import { LargeText } from '..';
import styled from 'styled-components';
import constants from 'shared/constants';

const TableHeaderRow = styled(TableRow)`
  background-color: ${constants.LIGHT_BLUE};
`;
const TableHeaderColumn = styled(TableCell)`
  text-align: left !important;
  span {
    width: max-content;
  }
`;
const TableHeaderText = styled(LargeText)`
  font-weight: bold;
  width: max-content;
`;

const TableHeader: FC = () => (
  <TableContext.Consumer>
    {({ columns, onView, onEdit, onDelete, onGraph, onDownload, onFile, onUpload }) => (
      <Head>
        <TableHeaderRow>
          {columns.map((column: TTableColumn) => (
            <TableHeaderColumn key={column.key} align={column.align}>
              <TableHeaderText>{column.title}</TableHeaderText>
            </TableHeaderColumn>
          ))}
          {(onView || onEdit || onDelete || onGraph || onDownload || onFile || onUpload) && <TableHeaderColumn />}
        </TableHeaderRow>
      </Head>
    )}
  </TableContext.Consumer>
);

export default TableHeader;
