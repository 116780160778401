import {
  IModelPeaResponse,
  IModelMeaResponse,
  IModelForm,
  IModelSearch,
  IModelState,
  IAuthen,
  ICAList,
  IBillList,
  IModelPwaResponse,
  IModelMwaResponse,
  IModelDbdResponse,
  IModelPpaResponse,
  ICustomerInfo,
  IUse12m,
  IUsageHistory,
  IAccountBillInfo,
  IModelPplResponse,
  IModelPpfResponse,
  IModelFormPassport,
  IData00095,
  IRequestInfoPpa,
  IRequestInfoPpf,
  IRequestInoPpl,
  IResponse,
  IMessage,
  IAccountAddressInfo,
  IModelDoaeResponse,
  IProfilePerson,
  IProfileActivity,
  IProfileArea,
  IModelOncbResponse,
  IModelConsulartResponse,
  IModelDsiResponse,
  IModelNhsoResponse,
  IModelCpdResponse,
  IModelDlaResponse,
  IModelDoeResponse,
  IModelDopaLastResponse,
  IData00281,
  IPassPortInfo,
  IData00149,
  IWsDATETIME,
  IData00980,
  IModelError,
  IModelDopaResponse,
  IRequestInfoDopa,
  IModelSearchValidate,
} from '.';

// NOTE: dto
const INITIAL_BILL_LIST: IBillList = {
  recordDate: '',
  invoiceNo: '',
  billPeriod: '',
  unit: '',
  cost: '',
  paydate: '',
  dueDate: '',
};

const INITIAL_CALIST: ICAList = {
  billList: [INITIAL_BILL_LIST] as IBillList[],
  name: '',
  caNo: '',
  companyName: '',
  homeNo: '',
  moo: '',
  villageName: '',
  floor: '',
  room: '',
  street: '',
  subDistrict: '',
  distict: '',
  province: '',
  postCode: '',
  class: '',
  meterSize: '',
  peaName: '',
  regisDate: null,
};

const INITIAL_AUTHEN: IAuthen = {
  pid: '',
  loginTime: '',
  loginTimeTick: '',
  res: '',
  resDes: '',
};

export const INITIAL_MODEL_PEA_RESPONSE: IModelPeaResponse = {
  authen: INITIAL_AUTHEN,
  caList: [INITIAL_CALIST] as ICAList[],
  personid: '',
  status: '',
  rowCount: '',
  res: '',
  resDes: '',
};

// ;; MEA
const INITIAL_USAGE_HISTORY: IUsageHistory = {
  kwh: '',
  billDate: '',
  billAmount: '',
};

const INITIAL_CUSTOMER_INFO: ICustomerInfo = {
  contractNumber: '',
  country: '',
  district: '',
  floor: '',
  houseNum1: '',
  houseNum2: '',
  houseSub: '',
  installationNumber: '',
  name1: '',
  name2: '',
  name3: '',
  postalCode: '',
  province: '',
  provinceCode: '',
  soi: '',
  street: '',
  subDistrict: '',
  title: '',
  usageHistory: [INITIAL_USAGE_HISTORY],
};

export const INITIAL_MODEL_MEA_RESPONSE: IModelMeaResponse = {
  authen: INITIAL_AUTHEN,
  customerInfo: [INITIAL_CUSTOMER_INFO] as ICustomerInfo[],
  empty: '',
  error: '',
  id: '',
  rowCount: '',
  status: '',
  statusDetail: '',
  res: '',
  resDes: '',
};

// ;; PWA
const INITIAL_USE_12_M: IUse12m = {
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
  10: '',
  11: '',
  12: '',
};

const INITIAL_DATA: IData00095 = {
  custcode: '',
  barcode: '',
  docno: '',
  amount: '',
  unit: '',
  custname: '',
  address: '',
  docdate: '',
  duedate: '',
  vat: '',
  use12m: INITIAL_USE_12_M,
};

export const INITIAL_MODEL_PWA_RESPONSE: IModelPwaResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  data00095: [INITIAL_DATA],
  res: '',
  resDes: '',
};

// ;; MWA
const INITIAL_ACCOUNT_BILL_INFO: IAccountBillInfo = {
  billDueDate: '',
  pyear: '',
  consumption: '',
  billDate: '',
  balanceGrossAmount: '',
  grossAmount: '',
  paidDate: '',
  paidGrossAmount: '',
  pmonth: '',
};

const INITIAL_ACCOUNT_ADDRESS_INFO: IAccountAddressInfo = {
  houseNo: '',
  address: '',
  houseId: '',
};

const INITIAL_MESSAGE: IMessage = {
  accountCode: '',
  accountName: '',
  numberOfDebtBill: '',
  accountBillInfo: [INITIAL_ACCOUNT_BILL_INFO],
  branchName: '',
  meterSizeDescription: '',
  accountClassDescription: '',
  branchCode: '',
  accountAddressInfo: [INITIAL_ACCOUNT_ADDRESS_INFO],
};

const INITIAL_RESPONSE: IResponse = {
  message: [INITIAL_MESSAGE],
  currentDate: '',
  status: '',
};

export const INITIAL_MODEL_MWA_RESPONSE: IModelMwaResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  response: INITIAL_RESPONSE,
  res: '',
  resDes: '',
};

// ;; DBD
export const INITIAL_MODEL_DBD_RESPONSE: IModelDbdResponse = {
  cmno: '',
  cmobjdesc: '',
  cmadd: '',
  cmownername: '',
  cmoffname: '',
  cmname: '',
  cmdate: '',
};

// ;; PPA
const INITIAL_REQUEST_INFO_PPA: IRequestInfoPpa = {
  borderName: '',
  bookNo: '',
  reqNo: '',
  dateOfReq: '',
  bookTypeDesc: '',
  personalID: '',
  thaiName: '',
  englistName: '',
  dateOfBirth: '',
  genderText: '',
  height: '',
  specialPeculiarities: '',
  houseNo: '',
  houseVillageNo: '',
  houseAlleyWayDesc: '',
  houseAlleyDesc: '',
  houseRoadDesc: '',
  houseSubdistrictDesc: '',
  houseDistrictDesc: '',
  houseProvinceDesc: '',
  travelPurpose: '',
  occupation: '',
  statusOfRequest: '',
  borderPassNo: '',
  expireDate: '',
};

export const INITIAL_MODEL_PPA_RESPONSE: IModelPpaResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  requestInfo: [INITIAL_REQUEST_INFO_PPA],
  res: '',
  resDes: '',
};

// ;; PPL
const INITIAL_REQUEST_INFO_PPL: IRequestInoPpl = {
  borderName: '',
  bookNo: '',
  reqNo: '',
  dateOfReq: '',
  bookTypeDesc: '',
  personalID: '',
  thaiName: '',
  englistName: '',
  dateOfBirth: '',
  genderText: '',
  height: '',
  specialPeculiarities: '',
  houseNo: '',
  houseVillageNo: '',
  houseAlleyWayDesc: '',
  houseAlleyDesc: '',
  houseRoadDesc: '',
  houseSubdistrictDesc: '',
  houseDistrictDesc: '',
  houseProvinceDesc: '',
  travelPurpose: '',
  occupation: '',
  statusOfRequest: '',
  borderPassNo: '',
  expireDate: '',
};

export const INITIAL_MODEL_PPL_RESPONSE: IModelPplResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  requestInfo: [INITIAL_REQUEST_INFO_PPL],
  res: '',
  resDes: '',
};

// ;; PPF
const INITIAL_REQUEST_INFO_PPF: IRequestInfoPpf = {
  age: '',
  bornPlace: '',
  dateofBirth: '',
  englistName: '',
  fatherName: '',
  genderText: '',
  motherName: '',
  personalID: '',
  thaiName: '',
};

export const INITIAL_MODEL_PPF_RESPONSE: IModelPpfResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  numFowllower: '',
  requestInfo: [INITIAL_REQUEST_INFO_PPF],
  res: '',
  resDes: '',
};

// ;; DOAE
const INITIAL_PROFILE_ACTIVITY: IProfileActivity = {
  lineItem3: null,
  activity_id: null,
  type_code: '',
  detail_code: '',
  detail_name: '',
  breed_code: '',
  breed_name: '',
  type_support: null,
  type_support_name: '',
  act_rai_ori: null,
  act_ngan_ori: null,
  act_wa_ori: null,
  act_rai: null,
  act_ngan: null,
  act_wa: null,
  plant_date: '',
  produce_date: '',
  year_act: null,
};

const INITIAL_PROFILE_AREA: IProfileArea = {
  lineItem2: null,
  area_num: null,
  area_type: '',
  profile_area_id: null,
  doc_type_id: null,
  doc_type_name: '',
  have_gov_doc: '',
  doc_no: '',
  doc_rai: null,
  doc_ngan: null,
  doc_wa: null,
  plant_moo: '',
  plant_province: '',
  plant_amphur: '',
  plant_tambon: '',
  area_year: null,
  profile_activity: INITIAL_PROFILE_ACTIVITY,
};

const INITIAL_PROFILE_PERSON: IProfilePerson = {
  lineItem: null,
  profile_center_id: null,
  profile_idcard: '',
  profile_prefix: '',
  profile_name: '',
  profile_surname: '',
  member_idcard: '',
  profile_code: '',
  member_flax: '',
  member_prefix: '',
  member_name: '',
  member_surname: '',
  moo: '',
  housenumber: '',
  province: '',
  amphur: '',
  tambon: '',
  mobile: '',
  telephone: '',
  profile_area: INITIAL_PROFILE_AREA,
};

export const INITIAL_MODEL_DOAE_RESPONSE: IModelDoaeResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  profilePerson: INITIAL_PROFILE_PERSON,
};

// ;; ONCB
const INITIAL_DATA00281: IData00281 = {
  casE_ID: null,
  pid: '',
  agencY_CASE_NO: '',
  reporT_YEAR: null,
  investigatE_AGENCYNAME: '',
  persoN_ID: '',
  thaI_NAME: '',
  thaI_SURNAME: '',
  sex: '',
  datE_OF_BIRTH: null,
  cdthaishort: '',
  provincethai: '',
  districtname: '',
  subdistrictname: '',
  villagename: '',
  arresT_AGENCYNAME: '',
  narcoticthainame: '',
  cdthainame: '',
  arresT_DATE: null,
};

export const INITIAL_MODEL_ONCB_RESPONSE: IModelOncbResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  numFowllower: '',
  data00281: [INITIAL_DATA00281],
};

// ;; CONSULART
const INITIAL_PASSPORTINFO: IPassPortInfo = {
  passportNo: '',
  passportStatus: '',
  passportType: '',
  citizenID: '',
  firstNameTH: '',
  lastNameTH: '',
  firstNameEN: '',
  lastNameEN: '',
  dob: '',
  sex: '',
  height: '',
  birthPlace: '',
  issueDate: '',
  expireDate: '',
};

export const INITIAL_MODEL_CONSULART_RESPONSE: IModelConsulartResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  isSuccess: '',
  errorDesc: '',
  passsportInfo: INITIAL_PASSPORTINFO,
};

// ;; DSI
const INITIAL_DATA00149: IData00149 = {
  id: '',
  status_record: null,
  lawsuit_id: '',
  case_no: '',
  barker_number: '',
  barker: '',
  court: '',
  date_enforcement: '',
  guilt: '',
  prescription: '',
  start_date: '',
  finish_date: '',
  note: '',
  status: '',
  id_card: '',
  accused: '',
  accused_address: '',
  arrested: '',
  date_arrest: '',
  proceedings: '',
  adjudge: '',
  escape: '',
  date_update: '',
  unit_create: '',
  num: '',
};

export const INITIAL_MODEL_DSI_RESPONSE: IModelDsiResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  data00149: [INITIAL_DATA00149],
};

// ;; NHSO
const INITIAL_WS_DATETIME: IWsDATETIME = {
  year: null,
  month: null,
  day: null,
  timezone: null,
  hour: null,
  minute: null,
  second: null,
};

export const INITIAL_MODEL_NHSO_RESPONSE: IModelNhsoResponse = {
  authen: INITIAL_AUTHEN,
  hmaiN_NAME: '',
  purchaseprovincE_NAME: '',
  ws_DATETIME: INITIAL_WS_DATETIME,
  startdate: '',
  hmaiN_OP_NAME: '',
  hmain: '',
  wsid: '',
  wS_STATUS: '',
  maininscL_NAME: '',
  mastercuP_ID: '',
  hsuB_NAME: '',
  cardid: '',
  purchaseprovince: '',
  subinscl: '',
  maininscl: '',
  persoN_ID: '',
  hmaiN_OP: '',
  subinscL_NAME: '',
  hsub: '',
  paiD_MODEL: '',
  res: '',
  resDes: '',
};

// ;; CPD
const INITIAL_DATA00980: IData00980 = {
  citizen_id: '',
  member_info_address: '',
  member_info_coop_name: '',
  member_info_coop_province_name: '',
  member_info_firstname: '',
  member_info_prefix: '',
  member_info_surname: '',
  result_code: '',
  result_description: '',
};

export const INITIAL_MODEL_CPD_RESPONSE: IModelCpdResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  data00980: [INITIAL_DATA00980],
};

// ;; DLA
export const INITIAL_MODEL_DLA_RESPONSE: IModelDlaResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  dlaOrg: '',
  firstName: '',
  lastName: '',
};

// ;; DOE
export const INITIAL_MODEL_DOE_RESPONSE: IModelDoeResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  res: '',
  resDes: '',
  addressOfPlaceOfWork: '',
  alienAddress: '',
  alienBirthDate: '',
  alienCitizenId: '',
  alienFullNmaeEn: '',
  alienFullNmaeTh: '',
  alienNation: '',
  alienPassportNumber: '',
  alienSex: '',
  chkResult: '',
  employerName: '',
  employerType: '',
  errorDesc: '',
  errorNumber: '',
  natureOfWork: '',
  occupation: '',
  periodOfPermitValidity: '',
  permittedCategoryOfWork: '',
  permittedLocalityOfWork: '',
  workPermitExpireDate: '',
  workPermitIssuedAt: '',
  workPermitIssuedDate: '',
  workPermitNo: '',
};

// ;; DOPA LAST
export const INITIAL_MODEL_DOPA_LAST_RESPONSE: IModelDopaLastResponse = {
  uuid: '',
  partitionName: '',
  apiCode: '',
  apiRequest: '',
  apiResponse: '',
  createdBy: '',
  createdDate: '',
  idnumber: '',
  updatedBy: '',
  updatedDate: '',
};

// ;; DOPA

export const INITIAL_REQUEST_INFO_DOPA: IRequestInfoDopa = {
  // population: INITIAL_DOPA_POPULATION_FORM,
  // address: INITIAL_DOPA_ADDRESS_FORM
  age: '',
  alleyCode: '',
  alleyWayCode: '',
  alleyWayDesc: '',
  createBy: '',
  createdDate: null,
  dateOfBirth: '',
  dateOfMoveIn: '',
  dateOfTerminate: '',
  districtCode: '',
  districtDesc: '',
  englishFirstName: '',
  englishLastName: '',
  englishMiddleName: '',
  englishTitleDesc: '',
  fatherName: '',
  fatherNationalityCode: '',
  fatherNationalityDesc: '',
  fatherPersonalID: '',
  firstName: '',
  fullNameAndRank: '',
  genderCode: '',
  genderDesc: '',
  houseID: '',
  houseNo: '',
  houseType: '',
  houseTypeDesc: '',
  image: '',
  lastName: '',
  middleName: '',
  mineType: '',
  motherName: '',
  motherNationalityCode: '',
  motherNationalityDesc: '',
  motherPersonalID: '',
  nationalityCode: '',
  nationalityDesc: '',
  ownerStatusDesc: '',
  personID: '',
  phoneNumber: '',
  provinceCode: '',
  provinceDesc: '',
  rcodeCode: '',
  rcodeDesc: '',
  roadCode: '',
  roadDesc: '',
  statusOfPersonCode: '',
  statusOfPersonDesc: '',
  subdistrictCode: '',
  subdistrictDesc: '',
  titleCode: '',
  titleDesc: '',
  titleName: '',
  titleSex: '',
  updateBy: '',
  updatedDate: '',
  uuid: '',
  villageNo: '',
};

export const INITIAL_MODEL_DOPA_RESPONSE: IModelDopaResponse = {
  authen: INITIAL_AUTHEN,
  personid: '',
  requestInfo: [INITIAL_REQUEST_INFO_DOPA],
  res: '',
  resDes: '',
};

// NOTE: state
export const INITIAL_MODEL_ERROR: IModelError = {
  PEA: '',
  MEA: '',
  PWA: '',
  MWA: '',
  DBD: '',
  PPA: '',
  PPL: '',
  PPF: '',
  DOAE: '',
  ONCB: '',
  CONSULART: '',
  DSI: '',
  NHSO: '',
  CPD: '',
  DLA: '',
  DOE: '',
  DOPA_LAST: '',
  DOPA: ''
};

export const INITIAL_MODEL_STATE: IModelState = {
  all: {},
  error: undefined,
  isPageLoading: false,
  dataPea: [INITIAL_MODEL_PEA_RESPONSE],
  dataMea: [INITIAL_MODEL_MEA_RESPONSE],
  dataPwa: [INITIAL_MODEL_PWA_RESPONSE],
  dataMwa: [INITIAL_MODEL_MWA_RESPONSE],
  dataDbd: [INITIAL_MODEL_DBD_RESPONSE],
  dataPpa: [INITIAL_MODEL_PPA_RESPONSE],
  dataPpl: [INITIAL_MODEL_PPL_RESPONSE],
  dataPpf: [INITIAL_MODEL_PPF_RESPONSE],
  dataDoae: [INITIAL_MODEL_DOAE_RESPONSE],
  dataOncb: [INITIAL_MODEL_ONCB_RESPONSE],
  dataConsulart: [INITIAL_MODEL_CONSULART_RESPONSE],
  dataDsi: [INITIAL_MODEL_DSI_RESPONSE],
  dataNhso: [INITIAL_MODEL_NHSO_RESPONSE],
  dataCpd: [INITIAL_MODEL_CPD_RESPONSE],
  dataDla: [INITIAL_MODEL_DLA_RESPONSE],
  dataDoe: [INITIAL_MODEL_DOE_RESPONSE],
  dataDopaLast: [INITIAL_MODEL_DOPA_LAST_RESPONSE],
  dataDopa: INITIAL_REQUEST_INFO_DOPA,
  modelError: INITIAL_MODEL_ERROR,
};

// NOTE: form
export const INITIAL_MODEL_SEARCH: IModelSearch = {
  keyword: '',
  sourceType: '',
  note: '',
};

export const INITIAL_MODEL_SEARCH_VALIDATE: IModelSearchValidate = {
  note: '',
  keyword: ''
};

export const INITIAL_MODEL_FORM: IModelForm = {
  keyword: '',
  note: ''
};

// ;; passport
export const INITIAL_MODEL_FORM_PASSPORT: IModelFormPassport = {};
