import { SelectOption } from 'components/ui/type';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import {
  IScoreCaseFileModalForm,
  IScoreCaseFileModalValidate,
  IGamificationState,
  IScoreCountingModalForm,
  IScoreCountingModalValidate,
  IScoreSummarizeModalForm,
  IScoreSummarizeModalValidate,
  IScoreCounting,
  IScoreSummarize,
  IScoreCaseFile,
  IGamificationSearchForm,
  IGamificationSetting,
} from './gamification.interface';

export const INITIAL_CREATE_SCORE_CASE_FILE_FORM: IScoreCaseFileModalForm = {
  officerTypeID: null,
  scorePoint: '',
  statusID: null,
};

export const INITIAL_CREATE_SCORE_CASE_FILE_VALIDATE: IScoreCaseFileModalValidate = {
  officerTypeID: '',
  scorePoint: '',
  statusID: '',
};

export const INITIAL_SCORE_COUNTING_MODAL_FORM: IScoreCountingModalForm = {
  scorePoint: '',
  scoreTypeID: null,
  statusID: null,
};

export const INITIAL_SCORE_COUNTING_MODAL_VALIDATE: IScoreCountingModalValidate = {
  scorePoint: '',
  scoreTypeID: '',
  statusID: '',
};

export const INITIAL_SCORE_SUMMARIZE_MODAL_FORM: IScoreSummarizeModalForm = {
  id: '',
  rankID: null,
  scorings: [
    { id: 1, totalScorePoint: 0, starPoint: 1 },
    { id: 2, totalScorePoint: 0, starPoint: 2 },
    { id: 3, totalScorePoint: 0, starPoint: 3 },
    { id: 4, totalScorePoint: 0, starPoint: 4 },
    { id: 5, totalScorePoint: 0, starPoint: 5 },
  ],
  statusID: null,
};

export const INITIAL_SCORE_SUMMARIZE_MODAL_VALIDATE: IScoreSummarizeModalValidate = {
  rankID: '',
  statusID: '',
};

export const INITIAL_GAMIFICATION_STATE: IGamificationState = {
  isButtonLoading: false,
  isPageLoading: false,
  scoreCaseFileList: { ...INITIAL_DATA_TABLE_TYPE },
  scoreCountingList: { ...INITIAL_DATA_TABLE_TYPE },
  selectedScoreCounting: {} as IScoreCounting,
  scoreSummarizeList: { ...INITIAL_DATA_TABLE_TYPE },
  selectedScoreSummarize: {} as IScoreSummarize,
  selectedScoreCaseFile: {} as IScoreCaseFile,
  error: undefined,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  isScoreCaseFileTableLoading: false,
  isScoreCountingTableLoading: false,
  isScoreSummarizeTableLoading: false,
  isGamificationSettingTableLoading: false,
};

export const GamificationStatusType: SelectOption[] = [
  { text: 'เปิดใช้งาน', value: 0 },
  { text: 'ปิดใช้งาน', value: 1 },
];

export const INITIAL_GAMIFICATION_SEARCH_FORM: IGamificationSearchForm = {
  divisionID: null,
  firstName: '',
  headQuarterID: null,
  idCard: '',
  lastName: '',
  officerCode: '',
  policeStationID: null,
  organizationID: null,
  // userStatusID: null,
  subDivisionID: null,
  mobilePhoneNumber: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  departmentID: null,
};

export const INITIAL_GAMIFICATION_SETTING: IGamificationSetting = {
  fullName: '',
  headQuarterName: '',
  id: '',
  idCard: '',
  officerCode: '',
  positionName: '',
  badge: {
    currentBadge: 0,
    maxBadge: 0,
  },
  totalScore: 0,
};
