import { IGetDopaLogActionResponse } from "shared/modules/dopalog";
import { TDataTable } from "shared/types";

export enum EDopaLogAction {
    GET_DOPA_LOG_REQUEST = 'GET_DOPA_LOG_REQUEST',
    GET_DOPA_LOG_SUCCESS = 'GET_DOPA_LOG_SUCCESS',
    GET_DOPA_LOG_FAILED = 'GET_DOPA_LOG_FAILED',
}

interface IGetDopaLogActionRequest {
    type: EDopaLogAction.GET_DOPA_LOG_REQUEST;
}

interface IGetDopaLogActionSuccess {
    type: EDopaLogAction.GET_DOPA_LOG_SUCCESS;
    data: any;
}

interface IGetDopaLogActionFailed {
    type: EDopaLogAction.GET_DOPA_LOG_FAILED;
    error: Error;
}

export type TDopaLog =
    | IGetDopaLogActionRequest
    | IGetDopaLogActionSuccess
    | IGetDopaLogActionFailed