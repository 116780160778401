import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';
import { TSelect } from 'shared/types';

const useSubDivisionDropdownApi = (divisionId?: TSelect) => {
  const [subDivisions, isLoading, isError] = useFetchApi<IDropdown[]>(
    `/subDivision/getDropdownList?divisionId=${divisionId}`,
    [],
    'divisionId',
    divisionId
  );

  const subDivisionSelectOptions: SelectOption[] = subDivisions.map((subDivision: IDropdown) => {
    return {
      text: subDivision.text,
      value: subDivision.id,
    };
  });

  return [subDivisionSelectOptions, isLoading, isError] as const;
};

export default useSubDivisionDropdownApi;
