export enum DatePickerSize {
  Small = 'small',
  Medium = 'default',
  Large = 'large',
}

export enum DateFormat {
  DD_MM_YYYY = 'DD/MM/YYYY',
  YYYY = 'YYYY',
}
