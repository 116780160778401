import _ from 'lodash';
import { useState, useEffect } from 'react';
import { axiosWithCache } from '../utils/axios';

const useFetchApi = <T>(
  url: string,
  defaultValue: T,
  qsKey?: string,
  qsValue?: string | number | null,
  active?: boolean
) => {
  const [data, setData] = useState<T>(defaultValue);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (qsKey && !qsValue) {
        setData(defaultValue);
        setIsLoading(false);
        setIsError(false);

        return;
      }

      setIsLoading(true);

      try {
        if (url.includes('null')) return;
        const response = await axiosWithCache.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };
    if (_.isNil(active) || active) {
      fetchData();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, qsValue, qsKey]);

  return [data, isLoading, isError] as const;
};

export { useFetchApi };
