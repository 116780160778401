import { SelectOption } from 'components/ui/type';

const useCountDropdownApi = () => {
  const options = [
    { value: 1, text: '5' },
    { value: 2, text: '10' },
    { value: 3, text: '20' },
    { value: 4, text: '50' },
    { value: 5, text: '100' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useCountDropdownApi;
