import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import main_th from 'locales/th/main.json';
import main_en from 'locales/en/main.json';
import auth_th from 'locales/th/auth.json';
import auth_en from 'locales/en/auth.json';
import inform_th from 'locales/th/inform.json';
import inform_en from 'locales/en/inform.json';
import suspect_th from 'locales/th/suspect.json';
import suspect_en from 'locales/en/suspect.json';
import license_plate_th from 'locales/th/license_plate.json';
import license_plate_en from 'locales/en/license_plate.json';
import lawsuit_th from 'locales/th/lawsuit.json';
import lawsuit_en from 'locales/en/lawsuit.json';
import bank_tracking_th from 'locales/th/bank_tracking.json';
import bank_tracking_en from 'locales/en/bank_tracking.json';
import user_management_th from 'locales/th/user_management.json';
import user_management_en from 'locales/en/user_management.json';
import arrest_th from 'locales/th/arrest.json';
import arrest_en from 'locales/en/arrest.json';
import dare_th from 'locales/th/dare.json';
import dare_en from 'locales/en/dare.json';
import case_file_th from 'locales/th/case_file.json';
import case_file_en from 'locales/en/case_file.json';
import investigation_th from 'locales/th/investigation.json';
import investigation_en from 'locales/en/investigation.json';
import user_profile_th from 'locales/th/user_profile.json';
import user_profile_en from 'locales/en/user_profile.json';
import gamification_th from 'locales/th/gamification.json';
import gamification_en from 'locales/en/gamification.json';
import confiscate_th from 'locales/th/confiscate.json';
import confiscate_en from 'locales/en/confiscate.json';
import searching_report_th from 'locales/th/searching_report.json';
import searching_report_en from 'locales/en/searching_report.json';
import access_pemission_management_th from 'locales/th/access_pemission_management.json';
import access_pemission_management_en from 'locales/en/access_pemission_management.json';
import home_th from 'locales/th/home.json';
import home_en from 'locales/en/home.json';
import ui_th from 'locales/th/ui.json';
import ui_en from 'locales/en/ui.json';
import phone_upload_th from 'locales/th/phone_upload.json';
import phone_upload_en from 'locales/en/phone_upload.json';
import bank_upload_th from 'locales/th/bank_upload.json';
import bank_upload_en from 'locales/en/bank_upload.json';
import suspect_search_th from 'locales/th/suspect_search.json';
import suspect_search_en from 'locales/en/suspect_search.json';
import role_setting_th from 'locales/th/role_setting.json';
import role_setting_en from 'locales/en/role_setting.json';
import suspect_engine_th from 'locales/th/suspect_engine.json';
import suspect_engine_en from 'locales/en/suspect_engine.json';
import investigation_attachment_th from 'locales/th/investigation_attachment.json';
import investigation_attachment_en from 'locales/en/investigation_attachment.json';
import investigation_report_th from 'locales/th/investigation_report.json';
import investigation_report_en from 'locales/en/investigation_report.json';
import graph_th from 'locales/th/graph.json';
import graph_en from 'locales/en/graph.json';
import model_th from 'locales/th/model.json';
import model_en from 'locales/en/model.json';
import dopa_th from 'locales/th/dopa.json';
import dopa_en from 'locales/en/dopa.json';


const resources = {
  th: {
    main: main_th,
    auth: auth_th,
    inform: inform_th,
    suspect: suspect_th,
    license_plate: license_plate_th,
    lawsuit: lawsuit_th,
    bank_tracking: bank_tracking_th,
    user_management: user_management_th,
    arrest: arrest_th,
    dare: dare_th,
    case_file: case_file_th,
    investigation: investigation_th,
    user_profile: user_profile_th,
    gamification: gamification_th,
    confiscate: confiscate_th,
    searching_report: searching_report_th,
    access_pemission_management: access_pemission_management_th,
    home: home_th,
    ui: ui_th,
    phone_upload: phone_upload_th,
    suspect_search: suspect_search_th,
    role_setting: role_setting_th,
    suspect_engine: suspect_engine_th,
    bank_upload: bank_upload_th,
    investigation_attachment: investigation_attachment_th,
    investigation_report: investigation_report_th,
    graph: graph_th,
    dopa: dopa_th,
    model: model_th,
  },
  en: {
    main: main_en,
    auth: auth_en,
    inform: inform_en,
    suspect: suspect_en,
    license_plate: license_plate_en,
    lawsuit: lawsuit_en,
    bank_tracking: bank_tracking_en,
    user_management: user_management_en,
    arrest: arrest_en,
    dare: dare_en,
    case_file: case_file_en,
    investigation: investigation_en,
    user_profile: user_profile_en,
    gamification: gamification_en,
    confiscate: confiscate_en,
    searching_report: searching_report_en,
    access_pemission_management: access_pemission_management_en,
    home: home_en,
    ui: ui_en,
    phone_upload: phone_upload_en,
    suspect_search: suspect_search_en,
    role_setting: role_setting_en,
    suspect_engine: suspect_engine_en,
    bank_upload: bank_upload_en,
    investigation_attachment: investigation_attachment_en,
    investigation_report: investigation_report_en,
    graph: graph_en,
    dopa: dopa_en,
    model: model_en,
  },
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'th',
    fallbackLng: 'th',
    ns: ['auth'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
