import React, { FC, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableContext from './TableContext';
// import TableToolbar from './TableToolbar';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TablePagination';
import { ETableOrder } from '../enum';
import { TTableOrder, TTableColumn } from '../type';
import styled from 'styled-components';
import { LargeText } from '../Font';

interface ITableProps {
  columns: TTableColumn[];
  dataSource: object[];
  title?: string;
  className?: string;
  spaceCount?: number;
  onGraph?: any;
  onDownload?: any;
  onUpload?: any;
  onFile?: any;
  onEdit?: any;
  onView?: any;
  onDelete?: any;
  onDeleteModal?: any;
}

const DEFAULT_ITEMS_PER_PAGE = 5;

const TableWrapper = styled.div`
  display: block;
`;

const StyledTable = styled.div`
  overflow: auto;
`;

const TableComponent: FC<ITableProps> = ({
  columns,
  dataSource: items,
  title,
  className,
  spaceCount,
  onGraph,
  onDownload,
  onUpload,
  onFile,
  onView,
  onEdit,
  onDelete,
  onDeleteModal,
}) => {
  const [order, setOrder] = useState<TTableOrder>(ETableOrder.ASCENDING);
  const [orderBy, setOrderBy] = useState('calories');
  const [selectedItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);

  const onChangeOrder = (property) => {
    const isAsc = orderBy === property && order === ETableOrder.ASCENDING;
    setOrder(isAsc ? ETableOrder.DESCENDING : ETableOrder.ASCENDING);
    setOrderBy(property);
  };

  const onChangePage = (newPage) => {
    setPage(newPage);
  };

  const onChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) setSelectedItems(items.map((item: any) => item.id));
  //   else setSelectedItems([]);
  // };

  // const onSelect = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
  //   if (event.target.checked) setSelectedItems([ ...selectedItems, id ]);
  //   else setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== id));
  // };

  const itemsEmptyCount = itemsPerPage - Math.min(itemsPerPage, items.length - page * itemsPerPage);

  return (
    <TableWrapper>
      <StyledTable>
        <TableContext.Provider
          value={{
            title: title,
            columns: columns,
            order: order,
            orderBy: orderBy,
            page: page,
            items: items,
            itemsCount: items.length,
            itemsEmptyCount: itemsEmptyCount,
            itemsPerPage: itemsPerPage,
            selectedItems: selectedItems,
            selectedItemsCount: selectedItems.length,
            onChangeOrder: onChangeOrder,
            onGraph: onGraph,
            onDownload: onDownload,
            onUpload: onUpload,
            onFile: onFile,
            onView: onView,
            onEdit: onEdit,
            onDelete: onDelete,
            spaceCount: spaceCount,
            // onSelect: onSelect,
            // onSelectAll: onSelectAll,
            onChangePage: onChangePage,
            onChangeItemsPerPage: onChangeItemsPerPage,
            onDeleteModal: onDeleteModal,
          }}
        >
          {/* <TableToolbar/> */}
          <Table className={className}>
            {/* <TableHeader /> */}
            <TableBody />
          </Table>
        </TableContext.Provider>
      </StyledTable>

      <TableContext.Provider
        value={{
          title: title,
          columns: columns,
          order: order,
          orderBy: orderBy,
          page: page,
          items: items,
          itemsCount: items.length,
          itemsEmptyCount: itemsEmptyCount,
          itemsPerPage: itemsPerPage,
          selectedItems: selectedItems,
          selectedItemsCount: selectedItems.length,
          onChangeOrder: onChangeOrder,
          onGraph: onGraph,
          onDownload: onDownload,
          onUpload: onUpload,
          onFile: onFile,
          onView: onView,
          onEdit: onEdit,
          onDelete: onDelete,
          spaceCount: spaceCount,
          // onSelect: onSelect,
          // onSelectAll: onSelectAll,
          onChangePage: onChangePage,
          onChangeItemsPerPage: onChangeItemsPerPage,
          onDeleteModal: onDeleteModal,
        }}
      >
        <TableFooter />
      </TableContext.Provider>
    </TableWrapper>
  );
};

export default TableComponent;
