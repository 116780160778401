import { StatusAlertWithHeader } from 'components/ui/Alert';
import { AlertStatus } from 'components/ui/enum/alert.enum';
import { Moment } from 'moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorageUser } from 'shared/enum/local-storage.type';
import { IUploadFileResponse } from 'shared/interfaces';
import { TSelect } from 'shared/types';
import axios from 'shared/utils/axios';
import { LocalStorageKey } from './authenticate';

// action
export enum GetUserInfoAction {
  REQUEST = 'GET_USER_INFO_REQUEST',
  SUCCESS = 'GET_USER_INFO_SUCCESS',
  FAILED = 'GET_USER_INFO_FAILED',
  ROLE_REQUEST = 'ROLE_REQUEST',
  ROLE_SUCCESS = 'ROLE_SUCCESS',
  ROLE_FAILED = 'ROLE_FAILED',
}

export interface IUserState {
  me: IUser;
  permission: IPermission[];
}

export interface IUser {
  id?: string;
  divisionName?: string;
  email?: string;
  firstName?: string;
  headQuarterID: TSelect;
  headQuarterName?: string;
  idCard?: string;
  lastName?: string;
  mobilePhoneNumber?: string;
  officerCode?: string;
  policeStationID: TSelect;
  policeStationName?: string;
  positionName?: string;
  profileImgUrl?: IUploadFileResponse;
  divisionID: TSelect;
  subDivisionID: TSelect;
  subDivisionName?: string;
  titleName?: string;
  roleID?: number;
  permission?: IPermission;
}

export interface IPermission {
  rolePermissionID: number;
  roleID: number;
  menuAccessID: number;
  rolePermissionName: string;
  viewPermission: boolean;
  createPermission: boolean;
  editPermission: boolean;
  deletePermission: boolean;
  exportPermission: boolean;
  createdDateTime: Moment;
  updatedDateTime: Moment;
}

export default (state = {}, action) => {
  switch (action.type) {
    case GetUserInfoAction.REQUEST:
      return {
        ...state,
      };
    case GetUserInfoAction.SUCCESS:
      return {
        ...state,
        me: action.data,
      };
    case GetUserInfoAction.FAILED:
      return {
        ...state,
        me: undefined,
        error: action.error,
      };
    case GetUserInfoAction.ROLE_REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case GetUserInfoAction.ROLE_SUCCESS:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
        permission: action.data,
      };
    case GetUserInfoAction.ROLE_FAILED:
      return {
        ...state,
        isAuthenticate: false,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};

export const getUserInfo = () => {
  return (dispatch) => {
    if (reactLocalStorage.getObject(ELocalStorageUser.USER_INFO, false))
      dispatch(getUserInfoSuccess(reactLocalStorage.getObject(ELocalStorageUser.USER_INFO, {})));
    else
      axios
        .get('/User/GetUserInfo', {
          headers: {
            Authorization: `bearer ${localStorage.getItem(LocalStorageKey.TOKEN)}`,
          },
        })
        .then((response) => {
          dispatch(getUserInfoSuccess(response.data));
        })
        .catch((error) => {
          dispatch(getUserInfoFailed(error));
        });
  };
};

export const getUserInfoSuccess = (data) => {
  reactLocalStorage.setObject(ELocalStorageUser.USER_INFO, data);
  return {
    type: GetUserInfoAction.SUCCESS,
    data,
  };
};

export const getUserInfoFailed = (error: Error) => {
  StatusAlertWithHeader('ระบบเกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้งในภายหลัง', AlertStatus.Error);
  return {
    type: GetUserInfoAction.FAILED,
    error,
  };
};

export const getRolePermission = (id) => {
  return (dispatch) => {
    dispatch({ type: GetUserInfoAction.ROLE_REQUEST });
    // if (reactLocalStorage.getObject(ELocalStorageUser.PERMISSION, false))
    //   dispatch(getRolePermissionSuccess(reactLocalStorage.getObject(ELocalStorageUser.PERMISSION, {})));
    // else
    axios
      // .get(`/RolePermission/${id}`, {
      .get(`/Role/${id}/Permission`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((resposne) => {
        dispatch(getRolePermissionSuccess(resposne.data));
      })
      .catch((error) => {
        dispatch(getRolePermissionFailed(error));
      });
  };
};

export const getRolePermissionSuccess = (data, cb?: Function) => {
  if (cb) cb();
  reactLocalStorage.setObject(ELocalStorageUser.PERMISSION, data);
  return {
    type: GetUserInfoAction.ROLE_SUCCESS,
    data,
  };
};

export const getRolePermissionFailed = (error) => {
  return {
    type: GetUserInfoAction.ROLE_FAILED,
    error,
  };
};
