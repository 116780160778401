import React, { FC } from 'react';
import styled from 'styled-components';

const StyledTab = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  border: 1px solid #ddd;
  flex-direction: column;
  max-height: calc(100vh - 5vh);
  height: 100%;
  overflow: scroll;
`;

interface TabProps {
  title: string;
  src: string;
  active: string;
}

const TabVerticalCheckbox: FC<TabProps> = ({ children }) => {
  return <StyledTab>{children}</StyledTab>;
};

export default TabVerticalCheckbox;
