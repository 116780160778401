import { IBankUploadAttachment } from 'shared/modules/bank-upload';
import { TDataTable } from 'shared/types';

export enum EBankUpload {
  UPLOAD_REQUEST = 'UPLOAD_REQUEST',
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  GET_REQUEST = 'GET_REQUEST',
  GET_SUCCESS = 'GET_SUCCESS',
  GET_FAILED = 'GET_FAILED',
  DELETE_REQUEST = 'DELETE_REQUEST',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  DELETE_FAILED = 'DELETE_FAILED',
  GET_GRAPH_SEARCH_REQUEST = 'GET_GRAPH_SEARCH_REQUEST',
  GET_GRAPH_SEARCH_SUCCESS = 'GET_GRAPH_SEARCH_SUCCESS',
  GET_GRAPH_SEARCH_FAILED = 'GET_GRAPH_SEARCH_FAILED',
  PUT_REQUEST = 'PUT_REQUEST',
  PUT_SUCCESS = 'PUT_SUCCESS',
  PUT_FAILED = 'PUT_FAILED',
  GET_REMARK_REQUEST = 'GET_REMARK_REQUEST',
  GET_REMARK_SUCCESS = 'GET_REMARK_SUCCESS',
  GET_REMARK_FAILED = 'GET_REMARK_FAILED',
}

interface IUploadRequest {
  type: EBankUpload.UPLOAD_REQUEST;
}

interface IUploadSuccess {
  type: EBankUpload.UPLOAD_SUCCESS;
}

interface IUploadFailed {
  type: EBankUpload.UPLOAD_FAILED;
  error;
}

interface IGetRequest {
  type: EBankUpload.GET_REQUEST;
}

interface IGetSuccess {
  type: EBankUpload.GET_SUCCESS;
  data: TDataTable<IBankUploadAttachment>;
}

interface IGetFailed {
  type: EBankUpload.GET_FAILED;
  error;
}

interface IDeleteRequest {
  type: EBankUpload.DELETE_REQUEST;
}

interface IDeleteSuccess {
  type: EBankUpload.DELETE_SUCCESS;
}

interface IDeleteFailed {
  type: EBankUpload.DELETE_FAILED;
  error;
}

interface IGetSearchRequest {
  type: EBankUpload.GET_GRAPH_SEARCH_REQUEST;
}

interface IGetSearchSuccess {
  type: EBankUpload.GET_GRAPH_SEARCH_SUCCESS;
  data: TDataTable<IBankUploadAttachment>;
}

interface IGetSearchFailed {
  type: EBankUpload.GET_GRAPH_SEARCH_FAILED;
  error;
}

interface IPutRequest {
  type: EBankUpload.PUT_REQUEST;
}

interface IPutSuccess {
  type: EBankUpload.PUT_SUCCESS;
}

interface IPutFailed {
  type: EBankUpload.PUT_FAILED;
  error;
}

interface IGetRemarkRequest {
  type: EBankUpload.GET_REMARK_REQUEST;
}

interface IGetRemarkSuccess {
  type: EBankUpload.GET_REMARK_SUCCESS;
  data: any;
}

interface IGetRemarkFailed {
  type: EBankUpload.GET_REMARK_FAILED;
  error;
}

export type TBankUpload =
  | IUploadRequest
  | IUploadSuccess
  | IUploadFailed
  | IGetRequest
  | IGetSuccess
  | IGetFailed
  | IDeleteRequest
  | IDeleteSuccess
  | IDeleteFailed
  | IGetSearchRequest
  | IGetSearchSuccess
  | IGetSearchFailed
  | IPutRequest
  | IPutSuccess
  | IPutFailed
  | IGetRemarkRequest
  | IGetRemarkSuccess
  | IGetRemarkFailed;
