import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import {
  IInform,
  IIncidentPlace,
  IInfromSearch,
  IInformState,
  IInfromSearchValidate,
  IInformValidate,
} from './inform.interface';

export const INITIAL_INCIDENT_PLACE: IIncidentPlace = {
  address: '',
  building: '',
  alley: '',
  road: '',
  subDistrictID: null,
  districtID: null,
  provinceID: null,
  postalCode: '',
};

export const INITIAL_INFORM_SEARCH: IInfromSearch = {
  policeStationID: null,
  divisionID: null,
  headQuarterID: null,
  organizationID: null,
  caseID: '',
  createdBy: '',
  informStartDate: null,
  informEndDate: null,
  complainantFullName: '',
  complainantIDCard: '',
  suspectFullName: '',
  address: '',
  building: '',
  alley: '',
  road: '',
  subDistrictID: null,
  districtID: null,
  provinceID: null,
  postalCode: '',
  incidentDate: null,
  onFilterMore: false,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  departmentID: null,
  subDivisionID: null,
};

export const INITIAL_INFORM_SEARCH_VALIDATE: IInfromSearchValidate = {
  complainantFullName: '',
  complainantIDCard: '',
  suspectFullName: '',
  address: '',
  building: '',
  alley: '',
  road: '',
};

export const INITIAL_INFORM: IInform = {
  id: '',
  organizationID: null,
  policeStationID: null,
  divisionID: null,
  headQuarterID: null,
  complainantTitleID: null,
  complainantFirstName: '',
  complainantLastName: '',
  complainantIDCard: '',
  birthDate: null,
  complainLocation: '',
  suspectTitleID: null,
  suspectFirstName: '',
  suspectLastName: '',
  accuseDetail: '',
  incidentPlace: INITIAL_INCIDENT_PLACE,
  incidentDate: null,
  informDate: null,
  caseID: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  departmentID: null,
  subDivisionID: null,
};

export const INITIAL_INFORM_VALIDATE: IInformValidate = {
  policeStationID: '',
  divisionID: '',
  headQuarterID: '',
  complainantTitleID: '',
  complainantFirstName: '',
  complainantLastName: '',
  complainantIDCard: '',
  birthDate: '',
  suspectTitleID: '',
  suspectFirstName: '',
  suspectLastName: '',
  accuseDetail: '',
  incidentDate: '',
  informDate: '',
  caseID: '',
};

export const INITIAL_INFORM_STATE: IInformState = {
  selected: {} as IInform,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isPageLoading: false,
  isButtonLoading: false,
  isTableLoading: false,
};
