import { IPagination } from 'shared/interfaces/pagination.interface';
import { ITeamManagementForm } from 'shared/modules/team-management';
import { TDataTable } from 'shared/types';
import { SelectOption } from 'components/ui/type';

export interface ITeamArrestManagementList {
  id: string;
  officerHeadFullName: string;
  no: string;
  officerCode: string;
  positionName: string;
  headQuarterName: string;
  divisionName: string;
  policeStationName: string;
  subDivisionName: string;
  mobilePhoneNumber: string;
}
export interface ITeamSequesterManagementList extends IPagination {
  id: string;
  officerHeadFullName: string;
  no: string;
  officerCode: string;
  positionName: string;
  headQuarterName: string;
  divisionName: string;
  policeStationName: string;
  subDivisionName: string;
  mobilePhoneNumber: string;
}
export interface ITeamManagementState {
  all: TDataTable<ITeamArrestManagementList>;
  selected: ITeamManagementForm;
  dropdownArres: SelectOption[];
  error?: Error;
  isPageLoading: boolean;
  isButtonLoading: boolean;
  isTableLoading: boolean;
}
export interface ITeamSequesterManagementState {
  all: TDataTable<ITeamSequesterManagementList>;
  selected: ITeamManagementForm;
  dropdownSequester: SelectOption[];
  error?: Error;
  isPageLoading: boolean;
  isButtonLoading: boolean;
  isTableLoading: boolean;
}
export enum TeamManagementAction {
  SEARCH_TEAMS_REQUEST = 'SEARCH_TEAM_REQUEST',
  SEARCH_TEAMS_SUCCESS = 'SEARCH_TEAM_SUCCESS',
  SEARCH_TEAMS_FAILED = 'SEARCH_TEAM_FAILED',
  SEARCH_TEAMS_SEQUESTERS_REQUEST = 'SEARCH_TEAMS_SEQUESTER_REQUEST',
  SEARCH_TEAMS_SEQUESTERS_SUCCESS = 'SEARCH_TEAMS_SEQUESTER_SUCCESS',
  SEARCH_TEAMS_SEQUESTERS_FAILED = 'SEARCH_TEAMS_SEQUESTER_FAILED',
  ADD_ARREST_TEAMS_REQUEST = 'ADD_ARREST_TEAM_REQUEST',
  ADD_ARREST_TEAMS_SUCCESS = 'ADD_ARREST_TEAM_SUCCESS',
  ADD_ARREST_TEAMS_FAILED = 'ADD_ARREST_TEAM_FAILED',
  ADD_SEQUESTER_TEAMS_REQUEST = 'ADD_SEQUESTER_TEAM_REQUEST',
  ADD_SEQUESTER_TEAMS_SUCCESS = 'ADD_SEQUESTER_TEAM_SUCCESS',
  ADD_SEQUESTER_TEAMS_FAILED = 'ADD_SEQUESTER_TEAM_FAILED',
  DELETE_OFFICERHEADERS_REQUEST = 'DELETE_OFFICERHEADER_REQUEST',
  DELETE_OFFICERHEADERS_SUCCESS = 'DELETE_OFFICERHEADER_SUCCESS',
  DELETE_OFFICERHEADERS_FAILED = 'DELETE_OFFICERHEADER_FAILED',
  DELETE_CONFISCATE_REQUEST = 'DELETE_CONFISCATE_REQUEST',
  DELETE_CONFISCATE_SUCCESS = 'DELETE_CONFISCATE_SUCCESS',
  DELETE_CONFISCATE_FAILED = 'DELETE_CONFISCATE_FAILED',
}
interface ISearchTeamRequestAction {
  type: TeamManagementAction.SEARCH_TEAMS_REQUEST;
}
interface ISearchTeamSuccessAction {
  type: TeamManagementAction.SEARCH_TEAMS_SUCCESS;
  Teams: TDataTable<ITeamArrestManagementList>;
  dropdownMap: SelectOption[];
}
interface ISearchTeamErrorAction {
  type: TeamManagementAction.SEARCH_TEAMS_FAILED;
  error: Error;
}
interface ISearchTeamSequesterRequestAction {
  type: TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_REQUEST;
}
interface ISearchTeamSequesterSuccessAction {
  type: TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_SUCCESS;
  TeamsSequester: TDataTable<ITeamSequesterManagementList>;
  dropdownMap: SelectOption[];
}

interface ISearchTeamSequesterErrorAction {
  type: TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_FAILED;
  error: Error;
}

interface IAddArrestTeamRequestAction {
  type: TeamManagementAction.ADD_ARREST_TEAMS_REQUEST;
}
interface IAddArrestTeamSuccessAction {
  type: TeamManagementAction.ADD_ARREST_TEAMS_SUCCESS;
}
interface IAddArrestTeamErrorAction {
  type: TeamManagementAction.ADD_ARREST_TEAMS_FAILED;
  error: Error;
}
interface IAddSequesterTeamRequestAction {
  type: TeamManagementAction.ADD_SEQUESTER_TEAMS_REQUEST;
}
interface IAddSequesterTeamSuccessAction {
  type: TeamManagementAction.ADD_SEQUESTER_TEAMS_SUCCESS;
}
interface IAddSequesterTeamErrorAction {
  type: TeamManagementAction.ADD_SEQUESTER_TEAMS_FAILED;
  error: Error;
}
interface IDeleteOfficerHeaderRequest {
  type: TeamManagementAction.DELETE_OFFICERHEADERS_REQUEST;
}

interface IDeleteOfficerHeaderSuccess {
  type: TeamManagementAction.DELETE_OFFICERHEADERS_SUCCESS;
}

interface IDeleteOfficerHeaderFailed {
  type: TeamManagementAction.DELETE_OFFICERHEADERS_FAILED;
  error: Error;
}
interface IDeleteOfficerHeaderConfiscateRequest {
  type: TeamManagementAction.DELETE_CONFISCATE_REQUEST;
}

interface IDeleteOfficerHeaderConfiscateSuccess {
  type: TeamManagementAction.DELETE_CONFISCATE_SUCCESS;
}

interface IDeleteOfficerHeaderConfiscateFailed {
  type: TeamManagementAction.DELETE_CONFISCATE_FAILED;
  error: Error;
}

export type TeamManagementActionTypes =
  | ISearchTeamRequestAction
  | ISearchTeamSuccessAction
  | ISearchTeamErrorAction
  | ISearchTeamSequesterRequestAction
  | ISearchTeamSequesterSuccessAction
  | ISearchTeamSequesterErrorAction
  | IAddArrestTeamRequestAction
  | IAddArrestTeamSuccessAction
  | IAddArrestTeamErrorAction
  | IAddSequesterTeamRequestAction
  | IAddSequesterTeamSuccessAction
  | IAddSequesterTeamErrorAction
  | IDeleteOfficerHeaderRequest
  | IDeleteOfficerHeaderSuccess
  | IDeleteOfficerHeaderFailed
  | IDeleteOfficerHeaderConfiscateRequest
  | IDeleteOfficerHeaderConfiscateSuccess
  | IDeleteOfficerHeaderConfiscateFailed;
