import { TMenuActionTypes, EMenu } from './menu.type';
import { IMenuState, INITIAL_MENU_STATE } from 'shared/modules/menu';

export default (state: IMenuState = INITIAL_MENU_STATE, action: TMenuActionTypes): IMenuState => {
  switch (action.type) {
    case EMenu.GET_MENU_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EMenu.GET_MENU_SUCCESS:
      return {
        ...state,
        menu: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EMenu.GET_MENU_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EMenu.GET_MENU_PERMISSION_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EMenu.GET_MENU_PERMISSION_SUCCESS:
      return {
        ...state,
        menuPermission: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EMenu.GET_MENU_PERMISSION_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
