import {
  ITeamManagementForm,
  ITeamManagementFormValidate,
  ITeamArrestManagementForm,
} from './team-management.interface';
import { ITeamManagementState, ITeamSequesterManagementState } from 'store/team-management';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { ITeamSequesterManagementList } from 'store/team-management/team-management.type';

export const INITIAL_TEAM_MANAGEMENT_FORM: ITeamManagementForm = {
  userID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};
export const INITIAL_TEAM_ARRESTER_MANAGEMENT_FORM: ITeamArrestManagementForm = {
  divisionID: null,
  headQuarterID: null,
  organizationID: null,
  mobilePhoneNumber: '',
  officerCode: '',
  policeStationID: null,
  positionID: null,
  subDivisionID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  departmentID: null,
};

export const INITIAL_TEAM_SEQUESTER_MANAGEMENT: ITeamSequesterManagementList = {
  divisionName: '',
  headQuarterName: '',
  id: '',
  mobilePhoneNumber: '',
  no: '',
  officerCode: '',
  officerHeadFullName: '',
  policeStationName: '',
  positionName: '',
  subDivisionName: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_TEAM_MANAGEMENT_FORM_VALIDATE: ITeamManagementFormValidate = {
  userID: '',
};

export const INITIAL_TEAM_MANAGEMENT_FORM_STATE: ITeamManagementState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as ITeamManagementForm,
  dropdownArres: [],
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};
export const INITIAL_TEAM_SEQUESTER_MANAGEMENT_FORM_STATE: ITeamSequesterManagementState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as ITeamManagementForm,
  dropdownSequester: [],
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};
