import { TBankTrackingActionType, EBankTrackingAction } from 'store/bank-tracking/banking-tracking.type';
import {
  IBankTrackingState,
  IBankTrackingSuspectForm,
  INITIAL_BANK_TRACKING_STATE,
} from 'shared/modules/bank-tracking';
import { IGraph } from 'shared/modules/graph';

export default (
  state: IBankTrackingState = INITIAL_BANK_TRACKING_STATE,
  action: TBankTrackingActionType
): IBankTrackingState => {
  switch (action.type) {
    case EBankTrackingAction.BANK_TRACKING_SEARCH_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case EBankTrackingAction.BANK_TRACKING_SEARCH_SUCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EBankTrackingAction.BANK_TRACKING_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EBankTrackingAction.BANK_TRACKING_GET_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EBankTrackingAction.BANK_TRACKING_GET_SUCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EBankTrackingAction.BANK_TRACKING_GET_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EBankTrackingAction.BANK_TRACKING_CREATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EBankTrackingAction.BANK_TRACKING_CREATE_SUCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EBankTrackingAction.BANK_TRACKING_CREATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EBankTrackingAction.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EBankTrackingAction.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        excelResponse: action.data,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EBankTrackingAction.UPLOAD_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EBankTrackingAction.SUBMIT_FILE_REPONSE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EBankTrackingAction.SUBMIT_FILE_REPONSE_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EBankTrackingAction.SUBMIT_FILE_REPONSE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EBankTrackingAction.GET_FILE_RESPONSE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EBankTrackingAction.GET_FILE_RESPONSE_SUCCESS:
      return {
        ...state,
        fileResponse: action.data,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EBankTrackingAction.GET_FILE_RESPONSE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EBankTrackingAction.GET_GRAPH_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankTrackingAction.GET_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.data,
        isPageLoading: false,
      };
    case EBankTrackingAction.GET_GRAPH_FAILED:
      return {
        ...state,
        graph: {} as IGraph,
        error: action.error,
        isPageLoading: false,
      };
    case EBankTrackingAction.EXCEL_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankTrackingAction.EXCEL_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EBankTrackingAction.EXCEL_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EBankTrackingAction.CLEAR_BANK_TRACKING_SELECT:
      return {
        ...state,
        selected: {} as IBankTrackingSuspectForm,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EBankTrackingAction.CLEAR_ATTACHMENT:
      return {
        ...state,
        excelResponse: {
          ...state.excelResponse,
          fileDataList: [],
        },
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
