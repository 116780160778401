export default {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'https://api.rtpnsb.com',
  BASE_API_URL_DS: process.env.REACT_APP_BASE_API_URL || 'https://pro-ds-api.rtpnsb.com',
  SSO: false,
};

export enum SsoServiceId {
  bigData = 'fc40dc8d-bb67-4cf3-b786-6afe5070233f',
  licencePlate = 'f9ff91db-c342-4048-9d38-a3b663a33e9c',
}
