import { TSuspectActionTypes, ESuspectAction } from 'store/suspect-search/suspect-search.type';
import { ISuspectSearchState, INITIAL_SUSPECT_STATE } from 'shared/modules/suspect-search';
import { ISuspectSearchForm } from 'shared/modules/suspect-search';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';

export default (
  state: ISuspectSearchState = INITIAL_SUSPECT_STATE,
  action: TSuspectActionTypes
): ISuspectSearchState => {
  switch (action.type) {
    case ESuspectAction.SEARCH_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case ESuspectAction.SEARCH_SUSPECT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESuspectAction.SEARCH_SUSPECT_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_FAILED:
      return {
        ...state,
        selected: {} as ISuspectSearchForm,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_ADDRESS_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_ADDRESS_SUCCESS:
      return {
        ...state,
        addressResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_ADDRESS_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_PHONE_SUCCESS:
      return {
        ...state,
        phoneResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_PHONE_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_BANK_SUCCESS:
      return {
        ...state,
        bankResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_BANK_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_BANK_INSERT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_BANK_INSERT_SUCCESS:
      return {
        ...state,
        bankResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_BANK_INSERT_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_VEHICLE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_VEHICLE_SUCCESS:
      return {
        ...state,
        selectedVehicle: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_VEHICLE_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_SOCIAL_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_SOCIAL_SUCCESS:
      return {
        ...state,
        selectedSocial: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_SOCIAL_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_SUSPECT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SUSPECT_FAILED:
      return {
        ...state,
        selected: {} as ISuspectSearchForm,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_ADDRESS_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_PHONE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_BANK_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_VEHICLE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_VEHICLE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SOCIAL_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_SOCIAL_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SOCIAL_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_VEHICLE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ESuspectAction.UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_VEHICLE_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_SOCIAL_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ESuspectAction.UPDATE_SOCIAL_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_SOCIAL_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_ADDRESS_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_PHONE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_BANK_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_VEHICLE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_VEHICLE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_SOCIAL_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_SOCIAL_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_SOCIAL_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_DOPA_IMAGE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ESuspectAction.UPDATE_DOPA_IMAGE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_DOPA_IMAGE_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    // ;; create suspect-upload-file
    case ESuspectAction.CREATE_SUSPECT_UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_SUSPECT_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SUSPECT_UPLOAD_FILE_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };

    // ;; delete suspect-upload-file
    case ESuspectAction.DELETE_SUSPECT_UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_SUSPECT_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_SUSPECT_UPLOAD_FILE_FAILED:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
