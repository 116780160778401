import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Checkbox, Grid } from '@material-ui/core';
import _ from 'lodash';
import { EModel } from 'shared/modules/model/model.enum';

const StyledTabTitle = styled.li`
  list-style-type: none;
  display: inline;
  padding: 10px 15px;
  border: 1px solid #ddd;
  cursor: pointer;
  &&:not(:first-child) {
    margin-left: -1px;
  }
  &&.selected {
    border-bottom-color: #fff;
    background-color: #4b77be;
  }
`;

const StyledTabTitleButton = styled.h3`
  font-weight: bold;
  width: 100%;
  margin-top: 0.5em;
`;
const MenuIcon = styled.img`
  border-radius: 5px;
  width: 3em;
  float: left;
`;

interface TabTitleProps {
  title: string;
  index: number;
  src?: string | undefined;
  active?: string | undefined;
  icon: string;
  iconActive?: string;
  selected: number[];
  setSelected(selected: number[]): void;
  hideIcon?: boolean;
}

const TabVerticalCheckboxTitle: FC<TabTitleProps> = ({ title, index, icon, selected, setSelected, hideIcon = false, iconActive }) => {
  const onClick = useCallback(() => {
    if (selected.includes(index)) {
      setSelected(selected.filter((item) => item != index));
      return;
    }
    setSelected([...selected, index]);
  }, [index, selected, setSelected]);

  const isSelected = React.useMemo(() => {
    return selected.includes(index) ? 'selected' : '';
  }, [selected]);


  return (
    <StyledTabTitle key={`tab-vertical-checkbox-title-sub-${index}`} onClick={onClick} className={isSelected} >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={2}>
          <Checkbox checked={selected.includes(index)} />
        </Grid>
        <Grid item xs={10}>

          <MenuIcon src={isSelected !== 'selected' ? icon : iconActive}></MenuIcon>
          <StyledTabTitleButton>{title}</StyledTabTitleButton>

        </Grid>
      </Grid>
    </StyledTabTitle >
  );
};

export default TabVerticalCheckboxTitle;
