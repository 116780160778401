import {
  IDataVisualizeSuspectForm,
  IDataVisualizeSearchForm,
  IDataVisualizeState,
  IDataVisualizeSearchFormValidate,
  IDataVisualizeSuspectFormValidate,
  IDataVisualizeAttachment,
} from './data-visualize.interface';
import { IUploadFileResponse } from 'shared/interfaces';
import { IGraph } from 'shared/modules/graph';

export const INITIAL_DATA_VISUALIZE_SUSPECT_FORM: IDataVisualizeSuspectForm = {
  id: '',
  bankID: null,
  suspectTitleID: null,
  suspectFirstName: '',
  suspectLastName: '',
  suspectIDCard: '',
  subject: '',
  descript: '',
  status: false,
  accountNo: '',
  caseFileID: null,
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  attachmentsFromBank: [] as IUploadFileResponse[],
  newAttachmentsFromBank: null,
};
export const INITIAL_DATA_VISUALIZE_SUSPECT_CREATE_FORM_VALIDATE: IDataVisualizeSuspectFormValidate = {
  bankID: '',
  suspectTitleID: '',
  suspectFirstName: '',
  suspectLastName: '',
  subject: '',
  descript: '',
};

export const INITIAL_DATA_VISUALIZE_SEARCH_FORM: IDataVisualizeSearchForm = {
  createdBy: '',
  suspectFullName: '',
  createdDateStart: null,
  createdDateEnd: null,
  bankID: null,
  caseFileID: null,
  accountNo: '',
};

export const INITIAL_DATA_VISUALIZE_SUSPECT_FORM_VALIDATE: IDataVisualizeSearchFormValidate = {
  createdBy: '',
  suspectFullName: '',
};

export const INITIAL_DATA_VISUALIZE_STATE: IDataVisualizeState = {
  all: [],
  selected: {} as IDataVisualizeSuspectForm,
  fileResponse: {} as IDataVisualizeAttachment,
  excelResponse: {
    attachment: {} as IUploadFileResponse,
    fileDataList: [],
  },
  graph: {} as IGraph,
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
};

export const INITIAL_DATA_VISUALIZE_ATTACHMENT: IDataVisualizeAttachment = {
  bankID: null,
  bankName: '',
  suspectFullName: '',
  submitterFullName: '',
  submitDate: null,
  attachment: {} as IUploadFileResponse,
  fileDataList: [],
};
