import i18next from 'i18n';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  accountNoPattern,
  addressNoPattern,
  alleyPattern,
  buildingVillagePattern,
  codePattern,
  emailPattern,
  firstNamePatter,
  fullNamePattern,
  idCardPattern,
  isNotNumberPattern,
  lastNamePattern,
  mobileNoPattern,
  // mobileNoPattern,
  passwordPattern,
  roadPattern,
  searchKeyword,
} from 'shared/pattern';
import LatLngValue, { LatLng } from 'shared/pattern/latlng';
import { TRadio } from 'shared/types';
import { isContainsOnlyDigit } from 'shared/utils/comparator';

export const ValidateRule = {
  isRequired: (value, text: string) => {
    if (value) return '';
    else return i18next.t('main:error_message.required', 'กรุณาระบุ{{text}}', { text: text });
  },
  isRequiredRadio: (value: TRadio, text: string) => {
    if (!_.isNil(value)) return '';
    else return i18next.t('main:error_message.required', 'กรุณาระบุ{{text}}', { text: text });
  },
  isRequiredSelect: (value, text: string) => {
    if (!_.isEmpty(value) || !_.isNil(value)) return '';
    else return i18next.t('main:error_message.required', 'กรุณาระบุ{{text}}', { text: text });
  },
  isRequiredDate: (value, text: string) => {
    if (moment(value).isValid()) return '';
    else return i18next.t('main:error_message.required', 'กรุณาระบุ{{text}}', { text: text });
  },
  isRequiredMultiple: (value, text: string) => {
    if (_.some(value)) return '';
    else return i18next.t('main:error_message.required', 'กรุณาระบุ{{text}}', { text: text });
  },
  isNotFuture: (value, text: string) => {
    if (moment(value) <= moment(new Date())) return '';
    else return i18next.t('main:error_message.not_future', 'ไม่สามารถเลือกเวลาล่วงหน้าได้');
  },
  isNotPast: (value, text: string) => {
    if (moment(value) >= moment(new Date())) return '';
    else return i18next.t('main:error_message.not_past', 'ไม่สามารถเลือกเวลาย้อนหลังได้');
  },
  maximumDate: (value, text: string, maxDate: Moment | null | undefined = null) => {
    if (maxDate && moment(value) <= moment(maxDate)) return '';
    else if (maxDate) return i18next.t('main:error_message.maximun_date', 'ไม่สามารถเลือกวันที่ก่อนถึงวันที่ได้');
    else return '';
  },
  minimumDate: (value, text: string, minDate: Moment | null | undefined = null) => {
    if (minDate && moment(value) >= moment(minDate)) return '';
    else if (minDate)
      return i18next.t('main:error_message.minimun_date', 'ไม่สามารถเลือกถึงวันที่ก่อนวันที่เริ่มต้นได้');
    else return '';
  },
  isEmail: (value, text: string) => {
    if (value === '' || value === null) return '';
    if (emailPattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_email', 'กรุณากรอกอีเมลให้ถูกต้อง');
  },
  isIdCard: (value, text: string) => {
    if (_.isEmpty(value) || idCardPattern(value)) return '';
    else return i18next.t('main:error_message.invalid_id_card_format', 'กรุณาระบุเลขบัตรประจำตัวประชาชนให้ถูกต้อง');
  },
  isIdCardLenght: (value: string, text: string) => {
    const unFormattedIdCard = value.replaceAll('-', '');

    if (unFormattedIdCard && unFormattedIdCard.length === 13) {
      return '';
    }

    return i18next.t('main:error_message.invalid_id_card_length', 'กรุณาระบุเลขบัตรประจำตัวประชาชนให้ครบ 13 หลัก');
  },
  isValidIdCard: (value: string, _defaultText?: string): string => {
    const unFormattedIdCard = (value || '').replaceAll('-', '').replaceAll('\u2000', '');

    if (!unFormattedIdCard || unFormattedIdCard.length !== 13) {
      return i18next.t('main:error_message.invalid_id_card_length', 'กรุณาระบุเลขบัตรประจำตัวประชาชนให้ครบ 13 หลัก');
    }

    if (!idCardPattern(unFormattedIdCard)) {
      return i18next.t('main:error_message.invalid_id_card_format', 'กรุณาระบุเลขบัตรประจำตัวประชาชนให้ถูกต้อง');
    }

    return '';
  },
  isValidSuspectIdCard: (value: string, _defaultText?: string): string => {
    const unFormattedIdCard = (value || '').replaceAll('-', '').replaceAll('\u2000', '');

    if (!unFormattedIdCard) {
      return '';
    }

    if (unFormattedIdCard.length !== 13) {
      return i18next.t('main:error_message.invalid_id_card_length', 'กรุณาระบุเลขบัตรประจำตัวประชาชนให้ครบ 13 หลัก');
    }

    if (!idCardPattern(unFormattedIdCard)) {
      return i18next.t('main:error_message.invalid_id_card_format', 'กรุณาระบุเลขบัตรประจำตัวประชาชนให้ถูกต้อง');
    }

    return '';
  },
  isFirstName: (value, text: string) => {
    if (_.isEmpty(value) || firstNamePatter.test(value)) return '';
    else return i18next.t('main:error_message.invalid_first_name', 'กรุณาระบุ{{text}}ให้ถูกต้อง', { text: text });
  },
  isLastName: (value, text: string) => {
    if (_.isEmpty(value) || lastNamePattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_last_name', 'กรุณาระบุนามสกุลให้ถูกต้อง');
  },
  isFullName: (value, text: string) => {
    if (value || _.isEmpty(value) || fullNamePattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_full_name', 'กรุณาระบุ{{text}}ให้ถูกต้อง', { text: text });
  },
  isAddress: (value, text: string) => {
    if (_.isEmpty(value) || addressNoPattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_address', 'กรุณาระบุที่อยู่ให้ถูกต้อง');
  },
  isBuildingVillage: (value, text: string) => {
    if (_.isEmpty(value) || buildingVillagePattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_building_village', 'กรุณาระบุอาคาร / หมู่บ้านให้ถูกต้อง');
  },
  isAlley: (value, text: string) => {
    if (_.isEmpty(value) || alleyPattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_alley', 'กรุณาระบุซอยให้ถูกต้อง');
  },
  isRoad: (value, text: string) => {
    if (_.isEmpty(value) || roadPattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_road', 'กรุณาระบุถนนให้ถูกต้อง');
  },
  isCode: (value, text: string) => {
    if (_.isEmpty(value) || codePattern.test(value)) return '';
    else return i18next.t('main:error_message.invalid_format', 'กรุณาระบุ{{text}}ให้ถูกต้อง', { text: text });
  },
  isMobileNo: (value, text: string) => {
    if (_.isEmpty(value) || mobileNoPattern.test(value.replaceAll('-', ''))) return '';
    else return i18next.t('main:error_message.invalid_mobile_no', 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง', { text: text });
  },
  isAccountNo: (value, text: string) => {
    if (_.isEmpty(value) || accountNoPattern.test(value.replaceAll('-', ''))) return '';
    else return i18next.t('กรุณาระบุเลขที่บัญชีให้ถูกต้อง', { text: text });
  },
  isBank: (value, text: string) => {
    if (_.isEmpty(value) || accountNoPattern.test(value.replaceAll('-', ''))) return '';
    // else return i18next.t('กรุณาระบุเลขที่บัญชีให้ถูกต้อง', { text: text });
  },
  isCheckNumber: (value, text: string) => {
    // console.log('isNotNumberPattern', isNotNumberPattern.test(value));
    if ((_.isEmpty(value) || !isNaN(value)) && !isNotNumberPattern.test(value))
      return ''; //_.isNumber(_.toNumber(value))
    else return i18next.t('กรุณาระบุเฉพาะตัวเลข 0-9');
  },

  isNumber: (value, text: string) => {
    // console.log('isNotNumberPattern', isNotNumberPattern.test(value));
    if (_.isEmpty(value) || !isNotNumberPattern.test(value))
      return ''; //_.isNumber(_.toNumber(value))
    else return i18next.t('กรุณาระบุเฉพาะตัวเลข 0-9');
  },

  isText: (value, text: string) => {
    if (_.isEmpty(value) || lastNamePattern.test(value)) return '';
    else return i18next.t('กรุณาระบุเฉพาะตัวอักษร');
  },
  isPassword: (value, text: string) => {
    if (_.isEmpty(value) || passwordPattern.test(value)) return '';
    else
      return i18next.t(
        'main:error_message.invalid_password',
        'รหัสผ่านต้องมีอย่างน้อย 8 หลัก, a-z, A-Z, 0-9 และตัวอักขระพิเศษ'
      );
  },
  isMaxLength:
    (maxLength: number) =>
      (value: string, _text: string): string => {
        if (!value || value.length <= maxLength) {
          return '';
        }

        return i18next.t('main:error_message.invalid_max_length', `กรุณากรอกข้อมูลให้น้อยกว่า ${maxLength} ตัวอักษร`);
      },
  isArrayWithSpecificFieldRequired:
    (field: string) =>
      (value: any[], errorMessage?: string): string => {
        const arrayWithSpecificField = value.map((v) => _.get(v, field, '')).filter((v) => !v);

        if (_.isEmpty(arrayWithSpecificField)) {
          return '';
        }

        return i18next.t('main:error_message.array_with_specific_field_required', 'กรุณากรอกข้อมูลให้ครบถ้วน', {
          text: errorMessage,
        });
      },
  isInRange:
    (start: number, end: number) =>
      (value: string | number, _defaultText: string): string => {
        if (!value) {
          return '';
        }

        const replacedValue = value.toString().replaceAll(',', '');
        const parsedValue = parseInt(replacedValue, 10);

        if (!parsedValue || !isContainsOnlyDigit(replacedValue)) {
          return i18next.t('main:error_message.invalid_number', 'กรุณากรอกเฉพาะตัวเลข 0-9');
        }

        if (parsedValue < start || parsedValue > end) {
          return i18next.t('main:error_message.invalid_range', `กรุณากรอกข้อมูลในช่วงระหว่าง ${start}-${end}`);
        }

        return '';
      },
  isAttachment: (value, text: string) => {
    if (_.isEmpty(value)) return '';
    else return i18next.t('กรุณาเลือกไฟล์', { text: text });
  },
  isSearch: (value, text: string) => {
    if (_.isEmpty(value) || searchKeyword.test(value)) return '';
    else return i18next.t('กรุณาระบุคำค้นหาเป็นตัวอักษรหรือตัวเลขเท่านั้น ไม่อนุญาตให้กรอกสัญลักษณ์หรืออักขระพิเศษ');
  },
  isLicensePlate: (value, text: string) => {
    if (/\s/g.test(value)) return i18next.t('เฉพาะตัวอักษรหรือตัวเลขเท่านั้น', { text: text });
    else if (_.isEmpty(value) || searchKeyword.test(value)) return '';
    else return i18next.t('เฉพาะตัวอักษรหรือตัวเลขเท่านั้น', { text: text });
  },
  isLatLng: (value, text: string) => {
    const latlngMap = new RegExp('[^0-9^\,^\.]', 'g');
    if ((_.isEmpty(value) || LatLngValue.test(value)) && !latlngMap.test(value)) return '';
    else return i18next.t('กรุณาระบุละติจูต ลองติจูตให้ถูกต้อง เช่น 13.868801 , 100.574318');
  },
};

export const validate = (value, rules, text = '', dateExpression: Moment | null | undefined = null) => {
  for (const rule of rules) {
    if (rule(value, text, dateExpression)) return rule(value, text, dateExpression);
  }
};
