import { IAccessPermissionManagementList } from 'shared/modules/access-permission-management';
import { TDataTable } from 'shared/types';

export enum EAccessPermissionManagementAction {
  SEARCH_ACCESS_PERMISSION_MANAGEMENT_REQUEST = 'SEARCH_ACCESS_PERMISSION_MANAGEMENT_REQUEST',
  SEARCH_ACCESS_PERMISSION_MANAGEMENT_SUCCESS = 'SEARCH_ACCESS_PERMISSION_MANAGEMENT_SUCCESS',
  SEARCH_ACCESS_PERMISSION_MANAGEMENT_FAILED = 'SEARCH_ACCESS_PERMISSION_MANAGEMENT_FAILED',
  UPDATE_ACCESS_PERMISSION_MANAGEMENT_REQUEST = 'UPDATE_ACCESS_PERMISSION_MANAGEMENT_REQUEST',
  UPDATE_ACCESS_PERMISSION_MANAGEMENT_SUCCESS = 'UPDATE_ACCESS_PERMISSION_MANAGEMENT_SUCCESS',
  UPDATE_ACCESS_PERMISSION_MANAGEMENT_FAILED = 'UPDATE_ACCESS_PERMISSION_MANAGEMENT_FAILED',
}

interface ISearchAccessPermissionManagementRequest {
  type: EAccessPermissionManagementAction.SEARCH_ACCESS_PERMISSION_MANAGEMENT_REQUEST;
}

interface ISearchAccessPermissionManagementSuccess {
  type: EAccessPermissionManagementAction.SEARCH_ACCESS_PERMISSION_MANAGEMENT_SUCCESS;
  data: TDataTable<IAccessPermissionManagementList>;
}

interface ISearchAccessPermissionManagementFailed {
  type: EAccessPermissionManagementAction.SEARCH_ACCESS_PERMISSION_MANAGEMENT_FAILED;
  error: Error;
}

interface IUpdateAccessPermissionManagementRequest {
  type: EAccessPermissionManagementAction.UPDATE_ACCESS_PERMISSION_MANAGEMENT_REQUEST;
}

interface IUpdateAccessPermissionManagementSuccess {
  type: EAccessPermissionManagementAction.UPDATE_ACCESS_PERMISSION_MANAGEMENT_SUCCESS;
  data: TDataTable<IAccessPermissionManagementList>;
}

interface IUpdateAccessPermissionManagementFailed {
  type: EAccessPermissionManagementAction.UPDATE_ACCESS_PERMISSION_MANAGEMENT_FAILED;
  error: Error;
}

export type TAccessPermissionManagementActionTypes =
  | ISearchAccessPermissionManagementRequest
  | ISearchAccessPermissionManagementSuccess
  | ISearchAccessPermissionManagementFailed
  | IUpdateAccessPermissionManagementRequest
  | IUpdateAccessPermissionManagementSuccess
  | IUpdateAccessPermissionManagementFailed;
