// import MomentUtils from '@date-io/moment';
import { TimePicker as TimePickerAnt } from 'antd';
import 'antd/dist/antd.css';
import {
  // MuiPickersUtilsProvider,
  TimePicker as MUITimePicker,
} from 'material-ui-thai-datepickers';
import moment from 'moment';
import 'moment/locale/th';
import React, { FC, FocusEvent } from 'react';
import { TDateTime } from 'shared/types';
import styled from 'styled-components';
import { ETimeFormat, InputSize, InputVariant } from './enum';
import InputTemplate from './InputTemplate';

const TimePickerBody = styled(MUITimePicker)`
  width: 100%;
  .ant-picker-disabled {
    border: 1px solid #9b9b9b;
  }
`;

const TimePickerAntBody = styled(TimePickerAnt)<{ error: boolean }>`
  height: 3rem;
  border-color: ${(props) => (props.error ? 'red' : '#d9d9d9')};
  :hover {
    border-color: ${(props) => (props.error ? 'red' : '#d9d9d9')};
  }
`;

interface ITimePickerProps {
  label?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  info?: string;
  variant?: InputVariant;
  size?: InputSize;
  disabled?: boolean;
  value?: TDateTime;
  onChange(time: TDateTime): void;
  onBlur?(event: FocusEvent<HTMLDivElement>): void;
  format?: ETimeFormat;
  required?: boolean;
  id?: string;
}

const TimePicker: FC<ITimePickerProps> = ({
  label,
  placeholder,
  className,
  error,
  info,
  size = InputSize.SMALL,
  variant = InputVariant.OUTLINED,
  disabled,
  value,
  onChange,
  onBlur,
  format = ETimeFormat.HH_MM,
  required,
  id,
}) => {
  return (
    <>
      <InputTemplate className={className} label={label} info={info} error={error} required={required} id={id}>
        {/* <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
          <TimePickerBody
            error={error ? true : false}
            placeholder={placeholder}
            disabled={disabled}
            size={size}
            inputVariant={variant}
            value={value ? moment(value) : null}
            onBlurCapture={onBlur}
            onChange={onChange}
            format={format}
            // variant={'inline'}
            ampm={false}
            clearable
          />
        </MuiPickersUtilsProvider> */}
        <TimePickerAntBody
          error={error ? true : false}
          format="HH:mm"
          value={value ? moment(value) : null}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
        />
      </InputTemplate>
    </>
  );
};

export default TimePicker;
