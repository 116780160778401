import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCompanyTypeDropdownApi = () => {
  const [companyTypes, isLoading, isError] = useFetchApi<IDropdown[]>('/CompanyType/getDropdownList', []);

  const companyTypeSelectOptions: SelectOption[] = companyTypes.map((companyType) => {
    return {
      text: companyType.text,
      value: companyType.id,
    };
  });
  return [companyTypeSelectOptions, isLoading, isError] as const;
};
export default useCompanyTypeDropdownApi;
