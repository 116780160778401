export enum EDateFormat {
  SlashDate = 'L', // Do/MM/YYYY
  SlashShortDate = 'l', // Do/M/YYYY
  FullDate = 'LL', // Do MMMM YYYY
  ShortDate = 'll', // Do MMM YYYY
  FullDateTime = 'LLL', // Do MMMM YYYY เวลา hh:mm
  ShortDateTime = 'lll', // Do MMM YYYY เวลา hh:mm
  FullDayDateTime = 'LLLL', // dddd Do MMMM YYYY เวลา hh:mm
  ShortDayDateTime = 'llll', // dddd Do MMM YYYY เวลา hh:mm
  FullTime = 'LT', // HH:mm
  FullTimeWithSuffix = 'LS',
  FullTimeWithoutSuffix = 'HH:mm',
}
