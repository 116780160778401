import { Button, GoogleMaps, HeaderText, Modal } from 'components/ui';
import React, { useEffect, useState } from 'react';
import { TCoords } from 'components/ui/type';
import { PageHeader } from 'components/layout';
import { ButtonPreset } from 'components/ui/enum';
import { INITIAL_COORDS } from 'components/ui/constant/google-maps.constant';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IGoogleMapValidate, INITIAL_GOOGLE_MAP_VALIDATE } from 'shared/modules/searching-report';
import { setErrorList, validateMap } from 'components/ui/GoogleMaps/latlng.validate';
import _ from 'lodash';
import GoogleMapsContainer from 'components/ui/GoogleMaps/GoogleMapsContainer';

const MapsModalWrapper = styled(Modal)`
  display: 'flex';
  flex-direction: column;
  padding: 1rem 2rem 2rem 2rem;
  height: auto;
  width: 70%;
`;

// const StyledPageHeader = styled(PageHeader)`
//   display: flex;
//   justify-content: center;
//   line-height: 40px;
// `;

const ButtonWrapper = styled.div`
  width: 100%;
  // display: flex;
  display: grid;
  column-gap: 16px;
  justify-content: flex-end;
  margin-top: 24px;
  grid-template-columns: min-content min-content;
`;

const BackButton = styled(Button)`
  margin-right: 8px;
`;

interface IMapsModalProps {
  isVisible?: boolean;
  defaultCoords: TCoords;
  defaultPlace: string;
  onCoordsChange(coords: TCoords, place: string): void;
  onCloseModal(): void;
}

const ImpoundMapModal: React.FC<IMapsModalProps> = ({
  isVisible = false,
  defaultCoords,
  defaultPlace,
  onCoordsChange,
  onCloseModal,
}) => {
  const { t } = useTranslation('investigation_attachment');
  const [coords, setCoords] = useState<TCoords>(INITIAL_COORDS);
  const [place, setPlace] = useState<string>('');
  const [latlng, setLatlng] = useState<any>('')
  const [center, setCenter] = useState<TCoords>(INITIAL_COORDS);
  const [errorState, setErrorState] = useState<IGoogleMapValidate>(INITIAL_GOOGLE_MAP_VALIDATE);

  useEffect(() => {
    setCoords(defaultCoords);
  }, [defaultCoords]);

  useEffect(() => {
    setPlace(defaultPlace);
  }, [defaultPlace]);

  useEffect(() => {
    if (latlng) {
      setPlace('');
    }
  });

  const onSubmitClick = (): void => {
    const coordsLatlng: TCoords = {
      lat: latlng !== '' ? parseFloat(latlng?.split(',')[0]) : coords.lat,
      lng: latlng !== '' ? latlng?.split(',')[1] !== '' ? parseFloat(latlng?.split(',')[1]) : 0 : coords.lng,
    }

    if (!validateModalMap()) {
      onCoordsChange(!place ? coordsLatlng : coords, place);
      onCloseModal();
    } else {
      const divToScrollTo = document.getElementById(`divToScrollTo`);
      divToScrollTo?.scrollIntoView();
    }

  };

  const validateModalMap = () => {
    const errorList = setErrorList(latlng, setErrorState);
    if (_.some(errorList) || _.some(errorState)) {
      return true;
    }
    return false;
  };

  const validateOnBlur = (field: string) => () => {
    setErrorState({
      ...errorState,
      [field]: validateMap[field](latlng),
    });
  };

  return (
    <MapsModalWrapper visible={isVisible} onClose={onCloseModal}>
      <PageHeader>
        <HeaderText>{t('modal.map.title')}</HeaderText>
      </PageHeader>
      <GoogleMaps
        coords={coords}
        onCoordsChange={setCoords}
        containerElement={<GoogleMapsContainer />}
        place={place}
        setPlace={setPlace}
        errorState={errorState}
        setErrorState={setErrorState}
        validateOnBlur={validateOnBlur}
        latlng={latlng}
        setLatlng={setLatlng}
      />
      <ButtonWrapper>
        <BackButton preset={ButtonPreset.SECONDARY} onClick={onCloseModal}>
          {t('main:button.cancel')}
        </BackButton>
        <Button preset={!latlng && !place || _.some(errorState) ? ButtonPreset.DISABLED : ButtonPreset.PRIMARY} onClick={onSubmitClick} disabled={!latlng && !place || _.some(errorState)}>ยืนยัน</Button>
      </ButtonWrapper>
    </MapsModalWrapper>
  );
};

export default ImpoundMapModal;
