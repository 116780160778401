import React, { FC } from 'react';
import { Divider } from 'components/ui';
import styled from 'styled-components';

interface IPageHeaderProps {
  className?: string;
  isDivider?: boolean;
}

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-bottom: 8px;
  // margin-bottom: 16px;
  position: -webkit-sticky;
  position: sticky;
  /* top: -1px; */
  background-color: #ffffff;
  z-index: 1;
  padding: 20px 0px 20px 0px;
  margin-right: 2rem;
`;

const PageHeaderDivider = styled(Divider)`
  margin: 0;
  margin-bottom: 16px;
`;

const PageHeader: FC<IPageHeaderProps> = ({ children, className, isDivider = true }) => {
  return (
    <>
      <PageHeaderWrapper id="divToScrollTo" className={className}>
        {children}
      </PageHeaderWrapper>
      {isDivider && <PageHeaderDivider />}
    </>
  );
};

export default PageHeader;
