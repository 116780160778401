import {
  IModelState,
  INITIAL_MODEL_CONSULART_RESPONSE,
  INITIAL_MODEL_CPD_RESPONSE,
  INITIAL_MODEL_DBD_RESPONSE,
  INITIAL_MODEL_DLA_RESPONSE,
  INITIAL_MODEL_DOAE_RESPONSE,
  INITIAL_MODEL_DOE_RESPONSE,
  INITIAL_MODEL_DOPA_LAST_RESPONSE,
  INITIAL_MODEL_DSI_RESPONSE,
  INITIAL_MODEL_ERROR,
  INITIAL_MODEL_MEA_RESPONSE,
  INITIAL_MODEL_MWA_RESPONSE,
  INITIAL_MODEL_NHSO_RESPONSE,
  INITIAL_MODEL_ONCB_RESPONSE,
  INITIAL_MODEL_PEA_RESPONSE,
  INITIAL_MODEL_PPA_RESPONSE,
  INITIAL_MODEL_PPF_RESPONSE,
  INITIAL_MODEL_PPL_RESPONSE,
  INITIAL_MODEL_PWA_RESPONSE,
  INITIAL_MODEL_STATE,
  INITIAL_REQUEST_INFO_DOPA,
} from 'shared/modules/model';
import { EModelAction, TModel } from './model.type';

export default (state: IModelState = INITIAL_MODEL_STATE, action: TModel): IModelState => {
  switch (action.type) {
    case EModelAction.GET_LIST_DOPA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_LIST_DOPA_SUCCESS:
      return {
        ...state,
        all: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_LIST_DOPA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_PEA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_PEA_SUCCESS:
      return {
        ...state,
        dataPea: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_PEA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_MEA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_MEA_SUCCESS:
      return {
        ...state,
        dataMea: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_MEA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_PWA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_PWA_SUCCESS:
      return {
        ...state,
        dataPwa: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_PWA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_MWA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_MWA_SUCCESS:
      return {
        ...state,
        dataMwa: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_MWA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_DBD_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DBD_SUCCESS:
      return {
        ...state,
        dataDbd: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DBD_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_PPA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_PPA_SUCCESS:
      return {
        ...state,
        dataPpa: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_PPA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_PPL_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_PPL_SUCCESS:
      return {
        ...state,
        dataPpl: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_PPL_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_PPF_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_PPF_SUCCESS:
      return {
        ...state,
        dataPpf: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_PPF_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_DOAE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DOAE_SUCCESS:
      return {
        ...state,
        dataDoae: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DOAE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_ONCB_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_ONCB_SUCCESS:
      return {
        ...state,
        dataOncb: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_ONCB_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_CONSULART_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_CONSULART_SUCCESS:
      return {
        ...state,
        dataConsulart: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_CONSULART_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_DSI_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DSI_SUCCESS:
      return {
        ...state,
        dataDsi: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DSI_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_NHSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_NHSO_SUCCESS:
      return {
        ...state,
        dataNhso: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_NHSO_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_CPD_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_CPD_SUCCESS:
      return {
        ...state,
        dataCpd: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_CPD_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_DLA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DLA_SUCCESS:
      return {
        ...state,
        dataDla: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DLA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_DOE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DOE_SUCCESS:
      return {
        ...state,
        dataDoe: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DOE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.GET_DOPA_LAST_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DOPA_LAST_SUCCESS:
      return {
        ...state,
        dataDopaLast: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DOPA_LAST_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };

    case EModelAction.GET_DOPA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EModelAction.GET_DOPA_SUCCESS:
      return {
        ...state,
        dataDopa: action.data,
        isPageLoading: false,
      };
    case EModelAction.GET_DOPA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EModelAction.CLEAR:
      return {
        ...state,
        dataPea: [INITIAL_MODEL_PEA_RESPONSE],
        dataMea: [INITIAL_MODEL_MEA_RESPONSE],
        dataPwa: [INITIAL_MODEL_PWA_RESPONSE],
        dataMwa: [INITIAL_MODEL_MWA_RESPONSE],
        dataDbd: [INITIAL_MODEL_DBD_RESPONSE],
        dataPpa: [INITIAL_MODEL_PPA_RESPONSE],
        dataPpl: [INITIAL_MODEL_PPL_RESPONSE],
        dataPpf: [INITIAL_MODEL_PPF_RESPONSE],
        dataDoae: [INITIAL_MODEL_DOAE_RESPONSE],
        dataOncb: [INITIAL_MODEL_ONCB_RESPONSE],
        dataConsulart: [INITIAL_MODEL_CONSULART_RESPONSE],
        dataDsi: [INITIAL_MODEL_DSI_RESPONSE],
        dataNhso: [INITIAL_MODEL_NHSO_RESPONSE],
        dataCpd: [INITIAL_MODEL_CPD_RESPONSE],
        dataDla: [INITIAL_MODEL_DLA_RESPONSE],
        dataDoe: [INITIAL_MODEL_DOE_RESPONSE],
        dataDopaLast: [INITIAL_MODEL_DOPA_LAST_RESPONSE],
        dataDopa: INITIAL_REQUEST_INFO_DOPA,
        modelError: INITIAL_MODEL_ERROR,
      };
    case EModelAction.GET_FAILED:
      return {
        ...state,
        modelError: {
          ...state.modelError,
          [action.modelType]: action.message,
        },
      };
    default: {
      return { ...state };
    }
  }
};
