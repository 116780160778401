import { ESuspectEngine, TSuspectEngine } from './suspect-engine.type';
import { ISuspectEngineState, INITIAL_SUSPECT_ENGINE_STATE } from 'shared/modules/suspect-engine';

export default (
  state: ISuspectEngineState = INITIAL_SUSPECT_ENGINE_STATE,
  action: TSuspectEngine
): ISuspectEngineState => {
  switch (action.type) {
    case ESuspectEngine.GLOBAL_SEARCH_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
        isTableLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
        isTableSuspectedLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SUSPECT_SUCCESS:
      return {
        ...state,
        suspectedResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableSuspectedLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SUSPECT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableSuspectedLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_ADDRESS_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableAddressLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        addressResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableAddressLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_ADDRESS_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableAddressLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTablePhoneLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_PHONE_SUCCESS:
      return {
        ...state,
        phoneResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTablePhoneLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTablePhoneLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableBankLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_BANK_SUCCESS:
      return {
        ...state,
        bankResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableBankLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableBankLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_VEHICLE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableVehicleLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicleResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableVehicleLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_VEHICLE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableVehicleLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SOCIAL_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableSocialLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SOCIAL_SUCCESS:
      return {
        ...state,
        socialResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableSocialLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SOCIAL_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableSocialLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_REMARK_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableRemarkLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_REMARK_SUCCESS:
      return {
        ...state,
        remarkResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableRemarkLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_REMARK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableRemarkLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_CIRCUMSTANCE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableCircumstanceLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_CIRCUMSTANCE_SUCCESS:
      return {
        ...state,
        circumstanceResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableCircumstanceLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_CIRCUMSTANCE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableCircumstanceLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_INVESTIGATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableInvestigateLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_INVESTIGATE_SUCCESS:
      return {
        ...state,
        investigateResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableInvestigateLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_INVESTIGATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableInvestigateLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableReportLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_REPORT_SUCCESS:
      return {
        ...state,
        reportResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableReportLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableReportLoading: false,
      };
    case ESuspectEngine.GLOBAL_ALL_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableAllReportLoading: true,
      };
    case ESuspectEngine.GLOBAL_ALL_REPORT_SUCCESS:
      return {
        ...state,
        allReportResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableAllReportLoading: false,
      };
    case ESuspectEngine.GLOBAL_ALL_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableAllReportLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_INVESTIGATION_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableInvestigationLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_INVESTIGATION_REPORT_SUCCESS:
      return {
        ...state,
        investigationReportResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableInvestigationLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_INVESTIGATION_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableInvestigationLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_ARREST_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableArrestLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_ARREST_SUCCESS:
      return {
        ...state,
        arrestResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableArrestLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_ARREST_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableArrestLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SEARCHING_REPORT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableSearchingReportLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SEARCHING_REPORT_SUCCESS:
      return {
        ...state,
        searchingReportResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableSearchingReportLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_SEARCHING_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableSearchingReportLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_CONFISCATE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableconfiscateLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_CONFISCATE_SUCCESS:
      return {
        ...state,
        confiscateResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableconfiscateLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_CONFISCATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableconfiscateLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_PHONE_UPLOAD_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTablePhoneUploadLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_PHONE_UPLOAD_SUCCESS:
      return {
        ...state,
        phoneUploadResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTablePhoneUploadLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_PHONE_UPLOAD_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTablePhoneUploadLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_BANK_UPLOAD_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableBankUploadLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_BANK_UPLOAD_SUCCESS:
      return {
        ...state,
        bankUploadResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableBankUploadLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_BANK_UPLOAD_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableBankUploadLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_PHONE_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableFormRequestPhoneLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_PHONE_SUCCESS:
      return {
        ...state,
        formRequestPhoneResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableFormRequestPhoneLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_PHONE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableFormRequestPhoneLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_BANK_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableFormRequestBankLoading: true,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_BANK_SUCCESS:
      return {
        ...state,
        formRequestBankResponse: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableFormRequestBankLoading: false,
      };
    case ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_BANK_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableFormRequestBankLoading: false,
      };
    default:
      return state;
  }
};
