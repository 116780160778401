import { Tooltip as MaterialTooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { EPlacement } from './enum';

const StyledMaterialtTooltip = styled(MaterialTooltip)``;

const HeaderText = styled.div`
  color: white;
  margin: 0;
  font-size: 18px;
  user-select: none;
  height: 1em;
  font-size: 16px;
  flex: 3;
  margin: 3px;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderTextUpdate = styled.div`
  font-family: 'THSarabunNew';
  margin: 0;
  color: white;
  user-select: none;
  font-size: 22px;
  flex: 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ITooltipProps {
  className?: string;
  title: any;
  placement?: EPlacement;
  baseText: string;
}

export const Tooltip: React.FC<ITooltipProps> = ({ className, title, placement, baseText }) => {
  return (
    <StyledMaterialtTooltip className={className} title={title} placement={placement}>
      <HeaderText>{baseText}</HeaderText>
    </StyledMaterialtTooltip>
  );
};

export const TooltipUpdate: React.FC<ITooltipProps> = ({ className, title, placement, baseText }) => {
  return (
    <StyledMaterialtTooltip className={className} title={title} placement="top">
      <HeaderTextUpdate>{baseText}</HeaderTextUpdate>
    </StyledMaterialtTooltip>
  );
};
