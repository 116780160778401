import React, { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Button } from 'components/ui';
import { ButtonPreset } from 'components/ui/enum';
import styled from 'styled-components';
import constants from 'shared/constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPdfModal {
  fileUrl: string;
  isVisible?: boolean;
  onCloseModal(): void;
}

const StyledModal = styled(Modal)`
  padding: 4rem;
  height: 90vh;
  width: 90vw;
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - ${constants.HEADER_HEIGHT + constants.FOOTER_HEIGHT}px);
  overflow: auto;
  overflow-y: unset;
  position: relative;
  margin: 0;
  overflow-y: unset;
  align-items: center;
  nav {
    justify-content: space-between;
  }
`;

const StyledButton = styled(Button)<{ display: number }>`
  display: ${(props) => (props.display ? 'inline-flex' : 'none')};
`;

const StyledDocument = styled(Document)`
  border-style: solid;
  border-width: 1px;
`;

const PDFViewer: FC<IPdfModal> = ({ fileUrl, isVisible, onCloseModal }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const goToPrevPage = () => setPageNumber(pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1);

  const file = {
    url: fileUrl,
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <StyledModal visible={isVisible} onClose={onCloseModal}>
      <StyledWrapper>
        <nav>
          <StyledButton
            preset={ButtonPreset.LINK}
            display={numPages ? (pageNumber <= 1 ? 0 : 1) : 0}
            onClick={goToPrevPage}
          >
            {'ย้อนกลับ'}
          </StyledButton>
          <StyledButton
            preset={ButtonPreset.LINK}
            display={numPages ? (pageNumber >= numPages ? 0 : 1) : 0}
            onClick={goToNextPage}
          >
            {'ถัดไป'}
          </StyledButton>
        </nav>
        <StyledDocument file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
          <Page pageNumber={pageNumber} width={1000} />
        </StyledDocument>
        <p>
          หน้า {pageNumber} จาก {numPages}
        </p>
      </StyledWrapper>
    </StyledModal>
  );
};

export default PDFViewer;
