import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useUnitDropdownApi = () => {
  const [units, isLoading, isError] = useFetchApi<IDropdown[]>('/Unit/GetDropdownList', []);

  const unitSelectOptions: SelectOption[] = units.map((unit) => {
    return {
      text: unit.text,
      value: unit.id,
    };
  });
  return [unitSelectOptions, isLoading, isError] as const;
};
export default useUnitDropdownApi;
