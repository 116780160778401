import React, { FC, ChangeEvent, FocusEvent } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import styled from 'styled-components';
import InputTemplate from './InputTemplateA4';
import constants from 'shared/constants';

const TextAreaTemplate = styled(InputTemplate)``;

interface ITextAreaProps {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: string | null;
  resizable?: boolean;
  disabled?: boolean;
  minRows?: number;
  rowsMax?: number;
  maxLength?: number;
  error?: string;
  info?: string;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  required?: boolean;
}

const TextAreaBody = styled(TextareaAutosize)`
  resize: ${(props: ITextAreaProps) => (props.resizable ? '' : 'none')};
  border-color: ${(props: ITextAreaProps) => (props.error ? constants.RED : 'rgba(0, 0, 0, 0.23)')};
  border-radius: 4px;
  font-size: 18px !important;
  font-family: 'ChulabhornLikitText-Light' !important;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
  &:focus {
    outline-color: ${(props: ITextAreaProps) => (props.error ? constants.RED : '#3F51B5')};
  }
  width: 21cm !important;
  height: 29.7cm !important;
  text-indent: 2rem;
  padding-left: 19mm;
  padding-right: 9mm;
`;

const TextArea: FC<ITextAreaProps> = ({
  className,
  label,
  placeholder,
  value,
  resizable,
  disabled,
  minRows,
  rowsMax,
  maxLength,
  error,
  info,
  onChange,
  onBlur,
  required,
}) => {
  return (
    <TextAreaTemplate className={className} label={label} error={error} info={info} required={required}>
      <TextAreaBody
        error={error}
        minRows={minRows}
        rowsMax={rowsMax}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        value={value ? value : ''}
        resizable={resizable}
        onChange={onChange}
        onBlur={onBlur}
      />
    </TextAreaTemplate>
  );
};

export default TextArea;
