import React, { FC } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';
import Footer from './Footer';

interface IPageWrapperProps {
  className?: string;
  isAuthenticate?: boolean;
}

const StylePageWrapper = styled.div<{ isAuthenticate: boolean }>`
  position: relative;
  width: 100%;
  padding: 0rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${(props) => (props.isAuthenticate === false ? '0px' : '70px')};
  height: calc(100vh - ${constants.HEADER_HEIGHT + constants.FOOTER_HEIGHT}px);
  overflow: auto;
  overflow-y: unset;
  position: relative;
  z-index: 1;
`;

const PageWrapper: FC<IPageWrapperProps> = ({ children, className, isAuthenticate = true }) => {
  const pageWrapperRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <StylePageWrapper isAuthenticate={isAuthenticate} className={`${className}`} ref={pageWrapperRef}>
        {children}
      </StylePageWrapper>
      <Footer pageWrapperRef={pageWrapperRef} />
    </>
  );
};

export default PageWrapper;
