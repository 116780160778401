import { TFunction } from 'i18next';
import React, { ChangeEvent, Dispatch, FocusEvent, SetStateAction, SyntheticEvent } from 'react';
import { IArrestForm, EArrestViewMode, IArrestFormValidate } from 'shared/modules/arrest';
import { IConfiscateFormValidate } from 'shared/modules/confiscate';
import { TDateTime, TSelect } from 'shared/types';

interface IArrestFormContext {
  arrestForm: IArrestForm;
  setArrestForm: Dispatch<SetStateAction<IArrestForm>>;
  errorState: IArrestFormValidate;
  setErrorState: Dispatch<SetStateAction<IArrestFormValidate>>;
  viewMode: EArrestViewMode;
  onInputChange(field: keyof IArrestForm): (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectChange(field: keyof IArrestForm): (value: TSelect) => void;
  onDateChange: (field: keyof IArrestForm) => (value: TDateTime) => void;
  onTextAreaChange(field: keyof IArrestForm): (value: ChangeEvent<HTMLTextAreaElement>) => void;
  onCheckboxChange(field: keyof IArrestForm, cb?: Function): (event: ChangeEvent<HTMLInputElement>) => void;
  validateOnBlur(field: keyof IArrestFormValidate): (event) => void;
  validateArray: (
    index: number
  ) => (
    field: keyof IArrestFormValidate,
    fieldInArray: string
  ) => (_event: FocusEvent<HTMLInputElement | HTMLDivElement>) => void;
  onDeletePoliceLs(index: number): () => void;
  onDeletePolices(index: number): () => void;
  onFileUpload: (event: SyntheticEvent<Element, Event>) => void;
  onDeleteFile: (index: number, field: string) => void;
  onDownload: (filePath: string) => void;
  arrestOldFileList;
  arrestNewFileList;
  t: TFunction;
  onModalCasefile: () => void;
  onClearDate: (field: string) => void;
  suspectCountList: any[];
  witnessCountList: any[];
  onClearMap(): void;
}

const ArrestFormContext = React.createContext({} as IArrestFormContext);

export default ArrestFormContext;
