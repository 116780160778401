import { IBankUploadState, INITIAL_BANK_UPLOAD_STATE } from 'shared/modules/bank-upload';
import { EBankUpload, TBankUpload } from './bank-upload.type';

export default (state: IBankUploadState = INITIAL_BANK_UPLOAD_STATE, action: TBankUpload): IBankUploadState => {
  switch (action.type) {
    case EBankUpload.GET_REQUEST:
      return {
        ...state,
        isTableLoading: true,
      };
    case EBankUpload.GET_SUCCESS:
      return {
        ...state,
        all: action.data,
        isTableLoading: false,
      };
    case EBankUpload.GET_FAILED:
      return {
        ...state,
        error: action.error,
        isTableLoading: false,
      };
    case EBankUpload.UPLOAD_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankUpload.UPLOAD_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EBankUpload.UPLOAD_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EBankUpload.DELETE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankUpload.DELETE_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EBankUpload.DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EBankUpload.GET_GRAPH_SEARCH_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankUpload.GET_GRAPH_SEARCH_SUCCESS:
      return {
        ...state,
        all: action.data,
        isPageLoading: false,
      };
    case EBankUpload.GET_GRAPH_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EBankUpload.PUT_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankUpload.PUT_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EBankUpload.PUT_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EBankUpload.GET_REMARK_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EBankUpload.GET_REMARK_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        remark: action.data,
      };
    case EBankUpload.GET_REMARK_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
