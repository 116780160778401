import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React, { ChangeEvent, FC, FocusEvent } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';
import { LargeText } from './Font';
import InputTemplate from './InputTemplate';

const TextAreaTemplate = styled(InputTemplate)``;

interface ITextAreaProps {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: string | null;
  resizable?: boolean;
  disabled?: boolean;
  minRows?: number;
  rowsMax?: number;
  maxLength?: number;
  error?: string;
  info?: string;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  required?: boolean;
  id?: string;
  labelremark?: string;
  fontWeight?: string;
  fontSize?: string;
}

const TextAreaBody = styled(TextareaAutosize)`
  width: 100%;
  /* overflow: scroll !important; */
  /* margin-bottom: 0px; */
  resize: ${(props: ITextAreaProps) => (props.resizable ? '' : 'none')};
  padding: 10.5px 14px;
  border-color: ${(props: ITextAreaProps) => (props.error ? constants.RED : 'rgba(0, 0, 0, 0.23)')};
  border-radius: 4px;
  font-size: ${(props) => props.fontSize || '24px'} !important;

  font-weight: ${(props) => props.fontWeight || 'normal'}; // Apply fontWeight here
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
  &:focus {
    outline-color: ${(props: ITextAreaProps) => (props.error ? constants.RED : '#3F51B5')};
  }
`;

const RemarkLabel = styled(LargeText)`
  color: red;
`;

const TextArea: FC<ITextAreaProps> = ({
  className,
  label,
  placeholder,
  value,
  resizable,
  disabled,
  minRows,
  rowsMax,
  maxLength,
  error,
  info,
  onChange,
  onBlur,
  required,
  id,
  labelremark,
  fontWeight,
  fontSize,
}) => {
  return (
    <TextAreaTemplate
      className={className}
      label={label}
      labelremark={labelremark}
      error={error}
      info={info}
      required={required}
      id={id}
    >
      <TextAreaBody
        error={error}
        minRows={minRows}
        rowsMax={rowsMax}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        value={value ? value : ''}
        resizable={resizable}
        onChange={onChange}
        onBlur={onBlur}
        fontWeight={fontWeight} // Add this line
        fontSize={fontSize}
      />
    </TextAreaTemplate>
  );
};

export default TextArea;
