import { ICreateUserForm } from 'shared/modules/user-management';
import { TDataTable } from 'shared/types';

export interface IUser {
  id: string;
  fullName: string;
  idCard: string;
  officerCode: string;
  headQuarterName: string;
  positionName: string;
  userStatusName: string;
}

export interface IUserManagementState {
  all: TDataTable<IUser>;
  selected: ICreateUserForm;
  error?: Error;
  isPageLoading: boolean;
  isButtonLoading: boolean;
  isTableLoading: boolean;
}

export enum UserManagementAction {
  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILED = 'REGISTER_FAILED',
  SEARCH_USERS_REQUEST = 'SEARCH_USER_REQUEST',
  SEARCH_USERS_SUCCESS = 'SEARCH_USER_SUCCESS',
  SEARCH_USERS_FAILED = 'SEARCH_USER_FAILED',
  GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST',
  GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_FAILED = 'GET_USER_BY_ID_FAILED',
  EDIT_USER_REQUEST = 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILED = 'EDIT_USER_FAILED',
  CLEAR_SELECTED_USER_STATE = 'CLEAR_SELECTED_USER_STATE',
}

interface IRegisterRequestAction {
  type: UserManagementAction.REGISTER_REQUEST;
}

interface IRegisterSuccessAction {
  type: UserManagementAction.REGISTER_SUCCESS;
}

interface IRegisterFailedAction {
  type: UserManagementAction.REGISTER_FAILED;
  error: Error;
}

interface ISearchUsersRequestAction {
  type: UserManagementAction.SEARCH_USERS_REQUEST;
}

interface ISearchUsersSuccessAction {
  type: UserManagementAction.SEARCH_USERS_SUCCESS;
  users: TDataTable<IUser>;
}

interface ISearchUsersErrorAction {
  type: UserManagementAction.SEARCH_USERS_FAILED;
  error: Error;
}

interface IGetUserByIdRequestAction {
  type: UserManagementAction.GET_USER_BY_ID_REQUEST;
}

interface IGetUserByIdSuccessAction {
  type: UserManagementAction.GET_USER_BY_ID_SUCCESS;
  selectedUser: ICreateUserForm;
}

interface IGetUserByIdErrorAction {
  type: UserManagementAction.GET_USER_BY_ID_FAILED;
  error: Error;
}

interface IEditUserRequestAction {
  type: UserManagementAction.EDIT_USER_REQUEST;
}

interface IEditUserSuccessAction {
  type: UserManagementAction.EDIT_USER_SUCCESS;
  selectedUser: ICreateUserForm;
}

interface IEditUserErrorAction {
  type: UserManagementAction.EDIT_USER_FAILED;
  error: Error;
}

interface IClearSelectedUserAction {
  type: UserManagementAction.CLEAR_SELECTED_USER_STATE;
}

export type UserManagementActionTypes =
  | IRegisterRequestAction
  | IRegisterSuccessAction
  | IRegisterFailedAction
  | ISearchUsersRequestAction
  | ISearchUsersSuccessAction
  | ISearchUsersErrorAction
  | IGetUserByIdRequestAction
  | IGetUserByIdSuccessAction
  | IGetUserByIdErrorAction
  | IEditUserRequestAction
  | IEditUserSuccessAction
  | IEditUserErrorAction
  | IClearSelectedUserAction;
