import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import { INITIAL_RECIVE_PHONE_VALIDATE } from '../property';
import {
  IRecivePhoneSearch,
  IRelational,
  IReportRecivePhone,
  IReportRecivePhoneList,
  IReportRecivePhoneModalFileUploadPhone,
  IReportRecivePhoneModalFileUploadPhoneValidate,
  IReportRecivePhoneState,
  IReportRecivePhoneValidate,
} from './recive-phone.interface';

export const INITIAL_RECIVE_PHONE_SEARCH: IRecivePhoneSearch = {
  keyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
  startDate: null,
  endDate: null,
  status: null,
  statusTemp: null,
};

export const INITIAL_REPORT_RECIVE_PHONE: IReportRecivePhone = {
  id: '',
  formTo: '',
  requestPhoneID: '',
  bookAgencyID: null,
  bookDate: moment(new Date()),
  commanderDate: moment(new Date()),
  commanderSectorID: null,
  commanderDepartmentID: null,
  commanderAgencyID: null,
  recipientDate: moment(new Date()),
  recipientSectorID: null,
  recipientDepartmentID: null,
  recipientAgencyID: null,
  inviteDate: moment(new Date()),
  inviteSectorID: null,
  inviteDepartmentID: null,
  inviteAgencyID: null,
  requesterName: '',
  requesterPhone: '',
  requesterMore: '',
  checkType: 0,
  inviteName: null,
  recipientName: null,
  checkPhoneList: [INITIAL_RECIVE_PHONE_VALIDATE],
  commander: null,
  fileAttactment: [] as IUploadFileResponse[],
  // bookDepartmentID: null,
  bookNumber: '',
  bookSectorID: null,
  bookHeadQuarterID: null,
  bookDivisionID: null,
  bookSubDivisionID: null,
  bookDepartmentID: null,
  bookPoliceStationID: null,
  bookOrganizationID: null,
  circumstance: '',
  // attachment: [] as IUploadFileResponse[],
  attachments: null,
  fileName: null,
  newAttachments: null,
  fileNameAtt: '',
  firstName: '',
  lastName: '',
  idCard: '',
  detail: '',
  detailCase: '',
  detailCheck: '',
  remark: '',
  detailBehavior: '',
  annotation: '',
  requestDetail: '',
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
};

export const INITIAL_RECIVE_PHONE_STATE: IReportRecivePhoneState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IReportRecivePhone,
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  fileUrl: '',
  fileUploadPhone: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_RECIVE_PHONE_LIST: IReportRecivePhoneList = {
  id: '',
  createdDate: moment(new Date()),
  bookNumber: '',
  name: '',
  idCard: '',
  passport: '',
  firstName: '',
  lastName: '',
  requestPhoneID: '',
  phoneNumber: '',
  createdBy: '',
};

export const INITIAL_REPORT_RECIVE_PHONE_VALIDATE: IReportRecivePhoneValidate = {
  bookNumber: '',
  bookDate: '',
  requesterPhone: '',
  detailPhone: '',
  circumstance: '',
  inviteDate: '',
  recipientDate: '',
  commanderDate: '',
  requesterName: '',
  requestDetail: '',
};

const INITIAL_RELATIONAL: IRelational = {
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
};

export const INITIAL_REPORT_RECIVE_PHONE_MODAL_FILE_UPLOAD_PHONE: IReportRecivePhoneModalFileUploadPhone = {
  searchKeyword: '',
  caseNo: null,
  caseName: '',
  telephoneNo: '',
  providerID: null,
  providerName: '',
  registryStatus: 1,
  idCard: '',
  titleID: null,
  titleName: '',
  firstName: '',
  lastName: '',
  fileName: '',
  attachment: null,
  ownerTitleID: null,
  ownerIDCard: '',
  ownerFirstName: '',
  ownerLastName: '',
  remark: '',
  etlType: 0,
  etlTypeName: '',
  imageFile: undefined,
  profileImg: undefined,
  imageFileOwner: undefined,
  profileImgOwner: undefined,
  isPrivateDelete: false,
  isPrivateDownload: false,
  isEnableDelete: null,
  isEnableDownload: null,
  formRequestPhoneID: '',
  //
  year: moment(new Date()),
};

export const INITIAL_REPORT_RECIVE_PHONE_MODAL_FILE_UPLOAD_PHONE_VALIDATE: IReportRecivePhoneModalFileUploadPhoneValidate =
  {
    telephoneNo: '',
    providerID: '',
    idCard: '',
    firstName: '',
    lastName: '',
    ownerIDCard: '',
    ownerFirstName: '',
    ownerLastName: '',
  };
