import { ILogger } from 'shared/modules/logger';

export enum ELogger {
  GET_LOGGER_REQUEST = 'GET_LOGGER_REQUEST',
  GET_LOGGER_SUCCESS = 'GET_LOGGER_SUCCESS',
  GET_LOGGER_FAILED = 'GET_LOGGER_FAILED',
  CLEAR_LOGGER = 'CLEAR_LOGGER',
}

export interface IGetLoggerRequest {
  type: ELogger.GET_LOGGER_REQUEST;
}

export interface IGetLoggerSuccess {
  type: ELogger.GET_LOGGER_SUCCESS;
  data: ILogger[];
}

export interface IGetLoggerFailed {
  type: ELogger.GET_LOGGER_FAILED;
  error: Error;
}

export interface IClearLogger {
  type: ELogger.CLEAR_LOGGER;
}

export type TLoggerAction = IGetLoggerRequest | IGetLoggerSuccess | IGetLoggerFailed | IClearLogger;
