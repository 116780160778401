import { IDopaForm, IGetDopaLogActionResponse, IGetDopaLogExportResponse } from 'shared/modules/dopa';
import { TDataTable } from 'shared/types';

export enum EDopaAction {
  GET_DOPA_REQUEST = 'GET_DOPA_REQUEST',
  GET_DOPA_SUCCESS = 'GET_DOPA_SUCCESS',
  GET_DOPA_FAILED = 'GET_DOPA_FAILED',
  SEARCH_DOPA_REQUEST = 'SEARCH_DOPA_REQUEST',
  SEARCH_DOPA_SUCCESS = 'SEARCH_DOPA_SUCCESS',
  SEARCH_DOPA_FAILED = 'SEARCH_DOPA_FAILED',
  GET_DOPA_LOG_ACTION_REQUEST = 'GET_DOPA_LOG_ACTION_REQUEST',
  GET_DOPA_LOG_ACTION_SUCCESS = 'GET_DOPA_LOG_ACTION_SUCCESS',
  GET_DOPA_LOG_ACTION_FAILED = 'GET_DOPA_LOG_ACTION_FAILED',
  GET_DOPA_LOG_EXPORT_REQUEST = 'GET_DOPA_LOG_EXPORT_REQUEST',
  GET_DOPA_LOG_EXPORT_SUCCESS = 'GET_DOPA_LOG_EXPORT_SUCCESS',
  GET_DOPA_LOG_EXPORT_FAILED = 'GET_DOPA_LOG_EXPORT_FAILED',
}

interface IGetDopaRequest {
  type: EDopaAction.GET_DOPA_REQUEST;
}

interface IGetDopaSuccess {
  type: EDopaAction.GET_DOPA_SUCCESS;
  data: IDopaForm;
}

interface IGetDopaFailed {
  type: EDopaAction.GET_DOPA_FAILED;
  error: Error;
}

interface ISearchDopaRequest {
  type: EDopaAction.SEARCH_DOPA_REQUEST;
}

interface ISearchDopaSuccess {
  type: EDopaAction.SEARCH_DOPA_SUCCESS;
  data: any;
}

interface ISearchDopaFailed {
  type: EDopaAction.SEARCH_DOPA_FAILED;
  error: Error;
}

interface IGetDopaLogActionRequest {
  type: EDopaAction.GET_DOPA_LOG_ACTION_REQUEST;
}

interface IGetDopaLogActionSuccess {
  type: EDopaAction.GET_DOPA_LOG_ACTION_SUCCESS;
  data: TDataTable<IGetDopaLogActionResponse>;
}

interface IGetDopaLogActionFailed {
  type: EDopaAction.GET_DOPA_LOG_ACTION_FAILED;
  error: Error;
}

interface IGetDopaLogExportRequest {
  type: EDopaAction.GET_DOPA_LOG_EXPORT_REQUEST;
}

interface IGetDopaLogExportSuccess {
  type: EDopaAction.GET_DOPA_LOG_EXPORT_SUCCESS;
  data: IGetDopaLogExportResponse;
}

interface IGetDopaLogExportFailed {
  type: EDopaAction.GET_DOPA_LOG_EXPORT_FAILED;
  error: Error;
}

export type TDopa =
  | IGetDopaRequest
  | IGetDopaSuccess
  | IGetDopaFailed
  | ISearchDopaRequest
  | ISearchDopaSuccess
  | ISearchDopaFailed
  | IGetDopaLogActionRequest
  | IGetDopaLogActionSuccess
  | IGetDopaLogActionFailed
  | IGetDopaLogExportRequest
  | IGetDopaLogExportSuccess
  | IGetDopaLogExportFailed;
