import _ from 'lodash';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorageDropdown } from 'shared/enum/local-storage.type';
import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useTeamDropdownApi = () => {
  const dropdown = reactLocalStorage.getObject(ELocalStorageDropdown.DROPDOWN_TEAM, []);
  const [teams, isLoading, isError] = useFetchApi<IDropdown[]>(
    '/team/getDropdownList',
    [],
    '',
    null,
    _.isEmpty(dropdown)
  );

  const teamSelectOptions: SelectOption[] = teams.map((team) => {
    return {
      text: team.text,
      value: team.id,
    };
  });

  if (!isError && _.isEmpty(dropdown)) {
    reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_TEAM, teamSelectOptions);
    return [teamSelectOptions, isLoading, isError];
  } else {
    return [dropdown, isLoading, false];
  }
  // return [teamSelectOptions, isLoading, isError] as const;
};

export default useTeamDropdownApi;
