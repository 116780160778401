import { SelectOption } from 'components/ui/type';

const useLayoutDropdownApi = () => {
  const options = [
    { value: 1, text: 'Dynamic' },
    { value: 2, text: 'Radial' },
    { value: 3, text: 'Hierarchy' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useLayoutDropdownApi;
