import { TCoords } from 'components/ui/type';
import { IDTOPlaceNearbyResponse } from 'shared/interfaces/google-maps.interface';
import axios from './axios';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDwKs5XAmH0wjPpvIQYyfB7flm0Et0EzqU';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyBm47MxbDA0Qo5wCCANJfv0oo14Z-oc3pc';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyD8E2N2D9N8lnMoyQiwN-eDoivtE-8HRCI';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyC-0I0RAWjHmRfkDHk3PfiII9EcYVnPuo0';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyBsiMhiER5RjkCm9W1S6UPaMt3T2RfbNuI';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyAdNE-XHHFtQx4DzF6g5kS7YaXgAqIJ66g';
// const GOOGLE_MAPS_API_KEY = 'AIzaSyDc8PU-b66jP3xLxQ8LpBb99OjPa9F4Kxs';

export const getPlaceByCoords = async (coords: TCoords): Promise<string> => {
  const googleMapsUrl = `https://maps.googleapis.com/maps/api/geocode/json?v=beta&latlng=${coords.lat},${coords.lng}&key=${GOOGLE_MAPS_API_KEY}`;
  // const googleMapsUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${GOOGLE_MAPS_API_KEY}`;
  try {
    const places = await axios.get(googleMapsUrl);
    // await fetch(googleMapsUrl, {
    //   method: 'GET',
    //   // mode: 'no-cors',
    // })
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // console.log('🚀 : getPlaceByCoords : result', result);
    //   })
    //   .catch((error) => {
    //     // console.log('🚀 : getPlaceByCoords : error', error);
    //   });
    // return '';
    return places.data.results.find((p: any) => p.types.includes('street_address')).formatted_address || '';
  } catch (e) {
    return '';
  }
};

export const getPlaceByCoordsNearby = async (coords: TCoords): Promise<IDTOPlaceNearbyResponse[]> => {
  const googleMapsUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${GOOGLE_MAPS_API_KEY}`;
  try {
    const response = await fetch(googleMapsUrl, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log('🚀 : getPlaceByCoords : result', result);
        return result.results;
      })
      .catch((error) => {
        // console.log('🚀 : getPlaceByCoords : error', error);
      });
    // console.log('🚀 : getPlaceByCoordsNearby : response', response);

    return response;
  } catch (e) {
    return [];
  }
};
