import React, { FC } from 'react';
import TableContext from './TableContext';
import { TableBody as Body, TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';
import constants from 'shared/constants';
import { LargeText } from '..';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ShareOutlined as GraphIcon,
  InsertDriveFile as FileIcon,
  CloudDownloadOutlined as DownloadIcon,
  BackupOutlined as UploadIcon,
} from '@material-ui/icons';
import Checkbox from '../Checkbox';
import IconGraph from '../../../assets/images/icon/Ic_graph.svg';
import IconBankAccount from '../../../assets/images/icon/Ic_bank_account.svg';
import IconMobileNumber from '../../../assets/images/icon/Ic_mobile_number.svg';
import IconCaseFileRecord from '../../../assets/images/icon/Ic_case_file_record.svg';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';
import { EDataScienceGraph, EDataTableLayout } from 'shared/enum/data-table.enum';

const TableLoadingRow = styled(TableRow)`
  border-bottom: none;
`;
const TableLoadingColumn = styled(TableCell)`
  border-bottom: none !important;
`;
const TableBodyRow = styled(TableRow)<{ select?: string }>`
  border-bottom: 2px solid ${constants.DARK_BLUE};
  .MuiTableCell-root {
    padding: 0px 0px;
  }
`;
// ${(props) => (props.width ? `width: ${props.width}` : `width: ${EDataTableLayout.WIDTHSMALL}`)};
const TableBodyColumn = styled(TableCell)<{ select?: string }>`
  ${(props) => (props.select ? `border: 2px solid ${props.select}` : ``)};
`;
const TableBodyActionColumn = styled(TableBodyColumn)`
  margin-left: 1em;
  width: ${EDataTableLayout.WIDTHSMALL};
`;
const TableBodyText = styled(LargeText)<{ overFlow?: boolean; width?: string }>`
  height: 2em;
  max-width: 100ch;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => (props.width ? `min-width: ${props.width}` : `min-width: ${EDataTableLayout.WIDTHSMALL}`)};
  ${(props) => (props.overFlow ? `max-width: max-content` : ``)};
  ${(props) => (props.overFlow ? `overflow: unset` : `overflow: hidden`)};
  padding: 0.3em 1em; // HARD CODE
`;

const TableActionWrapper = styled.div<{ tab: number }>`
  height: 2em;
  td:not(:first-child) {
    border-bottom: 2px solid ${constants.LIGHT_BLUE};
  }
  :not(:last-child) {
    border-bottom: 2px solid ${constants.LIGHT_BLUE};
  }
  margin-left: auto;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(${(props) => props.tab}, max-content); // 3

  > svg {
    cursor: pointer;
  }

  > div {
    width: 1em;
    height: 1em;
  }
  > svg {
    height: 2em;
  }
`;

const ItemIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
`;

const TableBodyWrapper = styled.div`
  display: none;
`;

const TableBodyTextExpand = styled(LargeText)<{ width?: string }>`
  height: 2em;
  max-width: 100ch;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => (props.width ? `min-width: ${props.width}` : `min-width: ${EDataTableLayout.WIDTHSMALL}`)};
  :not(:last-child) {
    border-bottom: 2px solid ${constants.LIGHT_BLUE};
  }
  padding: 0.3em 1em; // HARD CODE
  overflow: hidden;
`;

const TableBodyRowExpand = styled.div`
  border-bottom: 2px solid ${constants.LIGHT_BLUE};
  background-color: ${constants.LIGHT_GRAY};
`;

const comparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  else if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'asc' ? (a, b) => comparator(a, b, orderBy) : (a, b) => -comparator(a, b, orderBy);
};

const stableOrder = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const onExpand = (item: any) => {
  const elements = document.querySelectorAll('.list' + item.id + '');
  if (!_.isNull(elements)) {
    elements.forEach((item: any) => {
      if (!_.isNil(item)) {
        if (item.style.display === 'block') {
          item.style.display = 'none';
        } else {
          item.style.display = 'block';
        }
      }
    });
    // if (elements.style.display === 'none') {
    //   elements.style.display = 'block';
    // } else {
    //   elements.style.display = 'none';
    // }
  }
};

const ExpandableTableRow = ({ children, expandComponent, item, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const onExpanded = React.useCallback(() => {
    onExpand(item);
  }, [isExpanded]);

  return (
    <>
      <TableBodyRowExpand
        {...otherProps}
        onClick={() => {
          setIsExpanded(!isExpanded);
          onExpanded();
        }}
      >
        {children}
      </TableBodyRowExpand>
      <TableBodyWrapper className={'list' + item.id + ''}>{expandComponent}</TableBodyWrapper>
    </>
  );
};

const TableBody: FC = () => (
  <TableContext.Consumer>
    {({
      columns,
      isLoading,
      items,
      spaceCount,
      fixCheckbox,
      disabled,
      onGraph,
      onDownload,
      onUpload,
      onFile,
      onView,
      onEdit,
      onDelete,
      onGraphDS,
      onMobile,
      onBank,
      onItem,
      onCheckBox,
      onViewForm,
      onEditForm,
      onModal,
      onSwitch,
      hideOnViewForm = false,
      hideOnEditForm,
      type = '',
      order,
      orderBy,
      expandKey,
    }) => (
      <Body>
        {isLoading ? (
          <TableLoadingRow>
            <TableLoadingColumn>
              <TableBodyText>...กำลังโหลด</TableBodyText>
            </TableLoadingColumn>
          </TableLoadingRow>
        ) : (
          <>
            {_.isEmpty(items) ? (
              <TableLoadingRow>
                <TableLoadingColumn>
                  <TableBodyText>{'ไม่พบข้อมูล'}</TableBodyText>
                </TableLoadingColumn>
              </TableLoadingRow>
            ) : (
              <>
                {stableOrder(items, getComparator(order, orderBy)).map((item: any) => {
                  return (
                    <>
                      {/* <TableBodyRow key={item.id} id={type + '' + item.id} onClick={() => onExpand(item)}> */}
                      <TableBodyRow key={item.id} id={type + '' + item.id}>
                        {onCheckBox && (
                          <>
                            {(onCheckBox &&
                              (item.processStatus === EDataScienceGraph.SUCCESS ||
                                item.processStatus === EDataScienceGraph.WAIT)) ||
                            fixCheckbox ? (
                              <TableBodyColumn width={EDataTableLayout.WIDTHSMALL}>
                                <TableBodyText>
                                  <Checkbox
                                    checked={item.checkBox}
                                    onChange={() => {
                                      onCheckBox(item);
                                    }}
                                  />
                                </TableBodyText>
                              </TableBodyColumn>
                            ) : (
                              <TableBodyColumn width={EDataTableLayout.WIDTHSMALL}>
                                <TableBodyText>
                                  <></>
                                </TableBodyText>
                              </TableBodyColumn>
                            )}
                          </>
                        )}
                        {columns.map((column, index) => (
                          <TableBodyColumn key={column.key} align={column.align} width={column.width}>
                            {/* <TableBodyText width={column.width}>{item[column.key]}</TableBodyText> */}
                            <ExpandableTableRow
                              expandComponent={
                                <>
                                  {item.list.map((itemA) => (
                                    <>
                                      <TableBodyTextExpand width={column.width}>
                                        {itemA[column.key]}
                                      </TableBodyTextExpand>
                                    </>
                                  ))}
                                </>
                              }
                              item={item}
                            >
                              {index === 0 ? (
                                <TableBodyText width={column.width}>{item[expandKey]}</TableBodyText>
                              ) : (
                                <TableBodyText width={column.width}></TableBodyText>
                              )}
                            </ExpandableTableRow>
                          </TableBodyColumn>
                        ))}
                        {(onView ||
                          onViewForm ||
                          onEdit ||
                          onDelete ||
                          onGraph ||
                          onDownload ||
                          onFile ||
                          onUpload ||
                          onGraphDS ||
                          onMobile ||
                          onBank ||
                          onModal ||
                          onSwitch ||
                          onEditForm) && (
                          <TableBodyActionColumn>
                            <ExpandableTableRow
                              expandComponent={
                                <>
                                  {item.list.map((itemA) => (
                                    <TableActionWrapper tab={onItem || 3}>
                                      {onView && <VisibilityIcon onClick={() => onView(itemA.id)} />}
                                      {onViewForm && <VisibilityIcon onClick={() => onViewForm(itemA)} />}
                                      {onEdit && <EditIcon onClick={() => onEdit(itemA.id)} />}
                                      {onEditForm && <EditIcon onClick={() => onEditForm(itemA)} />}
                                      {/* order */}
                                      {onGraph && item.isShow && <GraphIcon onClick={() => onGraph(itemA.id)} />}
                                      {onDownload && item.filePath && (
                                        <DownloadIcon onClick={() => onDownload(itemA.filePath)} />
                                      )}
                                      {onUpload && <UploadIcon onClick={() => onUpload(itemA.id)} />}
                                      {onFile && <FileIcon onClick={() => onFile(itemA.id)} />}
                                      {onGraphDS && <ItemIcon src={IconGraph} onClick={() => onGraphDS(itemA)} />}
                                      {onMobile && item.phoneNo && (
                                        <ItemIcon src={IconMobileNumber} onClick={() => onMobile(itemA)} />
                                      )}
                                      {onBank && item.bankAccount && (
                                        <ItemIcon src={IconBankAccount} onClick={() => onBank(itemA)} />
                                      )}
                                      {onModal && <ItemIcon src={IconCaseFileRecord} onClick={() => onModal(itemA)} />}
                                      {onSwitch && (
                                        <Switch checked={item.userStatusID} onClick={() => onSwitch(itemA)} />
                                      )}
                                      {/* order */}
                                      {onDelete && <DeleteIcon onClick={() => onDelete(itemA.id)} />}
                                    </TableActionWrapper>
                                  ))}
                                </>
                              }
                              item={item}
                            >
                              <TableActionWrapper tab={onItem || 3}>
                                {onView && <VisibilityIcon onClick={() => onView(item.id)} />}
                                {/* {onViewForm && <VisibilityIcon onClick={() => onViewForm(item)} />} */}
                                {onEdit && <EditIcon onClick={() => onEdit(item.id)} />}
                                {onEditForm && <EditIcon onClick={() => onEditForm(item)} />}
                                {/* order */}
                                {onGraph && item.isShow && <GraphIcon onClick={() => onGraph(item.id)} />}
                                {onDownload && item.filePath && (
                                  <DownloadIcon onClick={() => onDownload(item.filePath)} />
                                )}
                                {onUpload && <UploadIcon onClick={() => onUpload(item.id)} />}
                                {onFile && <FileIcon onClick={() => onFile(item.id)} />}
                                {onGraphDS && <ItemIcon src={IconGraph} onClick={() => onGraphDS(item)} />}
                                {onMobile && item.phoneNo && (
                                  <ItemIcon src={IconMobileNumber} onClick={() => onMobile(item)} />
                                )}
                                {onBank && item.bankAccount && (
                                  <ItemIcon src={IconBankAccount} onClick={() => onBank(item)} />
                                )}
                                {onModal && <ItemIcon src={IconCaseFileRecord} onClick={() => onModal(item)} />}
                                {onSwitch && <Switch checked={item.userStatusID} onClick={() => onSwitch(item)} />}
                                {/* order */}
                                {/* {onDelete && <DeleteIcon onClick={() => onDelete(item.id)} />} */}
                              </TableActionWrapper>
                            </ExpandableTableRow>
                          </TableBodyActionColumn>
                        )}
                      </TableBodyRow>
                    </>
                  );
                })}
              </>
            )}
          </>
        )}
      </Body>
    )}
  </TableContext.Consumer>
);

export default TableBody;
