import { validate, ValidateRule } from 'shared/validate';
import { IGoogleMap, IGoogleMapValidate } from 'shared/modules/searching-report';

export const validateMap = {
  latlng: (id) => validate(id, [ValidateRule.isLatLng]),
};

export const setErrorList = (
  googleMap: IGoogleMap,
  setErrorState
) => {
  const errorList: IGoogleMapValidate = {
    latlng: validateMap.latlng(googleMap),
  };
  setErrorState(errorList);
  return errorList;
};

