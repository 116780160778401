import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const CircularProgressStyles = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  top: 40%;
  @media (max-width: 998px) {
    left: 45%;
    top: 45%;
  }
  @media (max-width: 694px) {
    left: 40%;
    top: 50%;
  }
`;

const StyledPageLoading = styled.div<{ isLoading: boolean }>`
  display: ${(props) => (props.isLoading ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

interface IPageLoadingProps {
  loading?: boolean;
}

const PageLoading: FC<IPageLoadingProps> = ({ loading = false }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) setIsLoading(true);
    else setIsLoading(false);
  }, [loading]);

  return (
    <StyledPageLoading isLoading={isLoading}>
      <CircularProgressStyles size={70} />
    </StyledPageLoading>
  );
};

export default PageLoading;
