import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IGraphPhoneUploadSearch } from '../phone-upload';
import {
  IBankUploadForm,
  IBankUploadFormValidate,
  IBankUploadSearch,
  IBankUploadState,
  IColumn,
  IFile,
  IMatch,
  IModalBankUploadCheckForm,
  IModalBankUploadRemarkForm,
  ISheet,
} from './bank-upload.interface';

export const INITIAL_BANK_UPLOAD_STATE: IBankUploadState = {
  all: INITIAL_DATA_TABLE_TYPE,
  error: undefined,
  isPageLoading: false,
  isTableLoading: false,
  remark: {},
  isRemarkLoading: false,
};

export const INITIAL_FORM_BANK_UPLOAD: IBankUploadForm = {
  searchKeyword: '',
  caseNo: null,
  caseName: '',
  accountNo: '',
  bankID: null,
  bankName: '',
  telephoneSMS: '',
  telephoneApplication: '',
  telephonePromptpay: '',
  citizenIDPromptpay: '',
  checkTelephoneSMS: false,
  checkTelephoneApplication: false,
  checkTelephonePromptpay: false,
  checkCitizenIDPromptpay: false,
  checkPhoneBank: false,
  idCard: '',
  titleID: null,
  titleName: '',
  firstName: '',
  lastName: '',
  fileName: '',
  attachment: null,
  ownerTitleID: null,
  ownerIDCard: '',
  ownerFirstName: '',
  ownerLastName: '',
  remark: '',
  phoneBank: '',
  imageFile: undefined,
  profileImg: undefined,
  imageFileOwner: undefined,
  profileImgOwner: undefined,
  isPrivateDelete: false,
  isPrivateDownload: false,
  isEnableDelete: null,
  isEnableDownload: null,
  formRequestBankID: '',
  //
  year: moment(new Date()),
};

export const INITIAL_FORM_BANK_UPLOAD_VALIDATE: IBankUploadFormValidate = {
  accountNo: '',
  bankID: '',
  telephoneSMS: '',
  telephoneApplication: '',
  telephonePromptpay: '',
  citizenIDPromptpay: '',
  idCard: '',
  firstName: '',
  lastName: '',
  ownerIDCard: '',
  ownerFirstName: '',
  ownerLastName: '',
  phoneBank: '',
};

export const INITIAL_BANK_UPLOAD_SEARCH: IBankUploadSearch = {
  keyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,

  CreateDateFrom: null,
  CreateDateTo: null,
};

export const INITIAL_GRAPH_BANK_UPLOAD_SEARCH: IGraphPhoneUploadSearch = {
  idList: [],
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_MATCH: IMatch = {
  file: '',
  system: '',
};

export const INITIAL_COLUMN: IColumn = {
  match: [[INITIAL_MATCH]],
  ignore: [''],
  missing: [''],
  status: '',
};

export const INITITAL_SHEET: ISheet = {
  sheetName: '',
  column: INITIAL_COLUMN,
};

export const INITIAL_RESPONSE_CHECK_FILE: IFile = {
  fileName: '',
  sheet: [INITITAL_SHEET],
};

export const INITIAL_FORM_MODAL_BANK_UPLOAD_MODAL_CHECK: IModalBankUploadCheckForm = {
  id: '',
  data: [INITIAL_RESPONSE_CHECK_FILE],
  columnMain: [[]],
  columnMissing: [[]],
  time: '',
  loading: true,
};

export const INITIAL_FORM_MODAL_BANK_UPLOAD_MODAL_REMARK: IModalBankUploadRemarkForm = {
  id: '',
  caseNo: '',
  caseName: '',
  bankID: null,
  bankName: '',
  idCard: '',
  titleID: null,
  titleName: '',
  firstName: '',
  lastName: '',
  telephoneSMS: '',
  telephoneApplication: '',
  telephonePromptpay: '',
  citizenIDPromptpay: '',
  ownerIDCard: '',
  ownerTitleID: null,
  ownerTitleName: '',
  ownerFirstName: '',
  ownerLastName: '',
  remark: '',
  fileName: '',
  accountNo: '',
  checkTelephoneSMS: false,
  checkTelephoneApplication: false,
  checkCitizenIDPromptpay: false,
  checkTelephonePromptpay: false,
  phoneBank: '',
  isPrivateDelete: false,
  isPrivateDownload: false,
  isEnableDelete: false,
  isEnableDownload: false,
  etlType: '',
  registerName: '',
  isEditable: false,
  img: '',
  ownerImg: '',
};
