import React, { FC } from 'react';
import TableContext from './TableContext';
import PaginationTable from '@material-ui/core/TablePagination';

const TablePagination: FC = () => (
  <TableContext.Consumer>
    {({ items, itemsPerPage, page, totalCount, onChangePage, onChangeItemsPerPage, hidePage }) => (
      <>
        {!hidePage && (
          <PaginationTable
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={'ข้อมูลต่อหน้า'}
            component={'div'}
            count={totalCount}
            rowsPerPage={itemsPerPage}
            page={page}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeItemsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeItemsPerPage}
          />
        )}
      </>
    )}
  </TableContext.Consumer>
);

export default TablePagination;
