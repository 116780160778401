import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

interface ISubdistrictDropdown extends IDropdown {
  postalCode: string;
}

export interface ISubdistrict extends SelectOption {
  postalCode: string;
}

const useSubDistrictDropdownApi = (
  provinceId: string | number | null | undefined,
  districtId: string | number | null | undefined
) => {
  const requestData = {
    ...(provinceId ? { provinceId } : { provinceId: '0' }),
    ...(districtId ? { districtId } : { districtId: '0' }),
  };
  const urlParams =
    '?' +
    Object.keys(requestData)
      .map((k) => k + '=' + requestData[k])
      .join('&');
  const [subDistricts, isLoading, isError] = useFetchApi<ISubdistrictDropdown[]>(
    '/subdistrict/getDropdownList' + urlParams,
    []
  );

  const subDistrictSelectOptions: ISubdistrict[] = subDistricts.map((subdistrict: ISubdistrictDropdown) => {
    return {
      postalCode: subdistrict.postalCode,
      text: subdistrict.text,
      value: subdistrict.id,
    };
  });

  return [subDistrictSelectOptions, isLoading, isError] as const;
};

export default useSubDistrictDropdownApi;
