export enum ELinkageNSBAction {
  GET_REQUEST = 'GET_REQUEST',
  GET_SUCCESS = 'GET_SUCCESS',
  GET_FAILED = 'GET_FAILED',
}

export interface IGetRequest {
  type: ELinkageNSBAction.GET_REQUEST;
}

export interface IGetSuccess {
  type: ELinkageNSBAction.GET_SUCCESS;
  data: any;
}

export interface IGetFailed {
  type: ELinkageNSBAction.GET_FAILED;
  error: Error;
}

export type TLinkageNSBAction = IGetRequest | IGetSuccess | IGetFailed;
