import { IConfiscateForm, IConfiscateList } from 'shared/modules/confiscate';
import { TDataTable } from 'shared/types';

export enum EConfiscateAction {
  SEARCH_CONFISCATE_REQUEST = 'SEARCH_CONFISCATE_REQUEST',
  SEARCH_CONFISCATE_SUCCESS = 'SEARCH_CONFISCATE_SUCCESS',
  SEARCH_CONFISCATE_FAILED = 'SEARCH_CONFISCATE_FAILED',
  CREATE_CONFISCATE_REQUEST = 'CREATE_CONFISCATE_REQUEST',
  CREATE_CONFISCATE_SUCCESS = 'CREATE_CONFISCATE_SUCCESS',
  CREATE_CONFISCATE_FAILED = 'CREATE_CONFISCATE_FAILED',
  GET_CONFISCATE_REQUEST = 'GET_CONFISCATE_REQUEST',
  GET_CONFISCATE_SUCCESS = 'GET_CONFISCATE_SUCCESS',
  GET_CONFISCATE_FAILED = 'GET_CONFISCATE_FAILED',
  UPDATE_CONFISCATE_REQUEST = 'UPDATE_CONFISCATE_REQUEST',
  UPDATE_CONFISCATE_SUCCESS = 'UPDATE_CONFISCATE_SUCCESS',
  UPDATE_CONFISCATE_FAILED = 'UPDATE_CONFISCATE_FAILED',
  DELETE_CONFISCATE_REQUEST = 'DELETE_CONFISCATE_REQUEST',
  DELETE_CONFISCATE_SUCCESS = 'DELETE_CONFISCATE_SUCCESS',
  DELETE_CONFISCATE_FAILED = 'DELETE_CONFISCATE_FAILED',
  GET_PDF_REQUEST = 'GET_PDF_REQUEST',
  GET_PDF_SUCCESS = 'GET_PDF_SUCCESS',
  GET_PDF_FAILED = 'GET_PDF_FAILED',
  CLEAR_SELECTED_CONFISCATE = 'CLEAR_SELECTED_CONFISCATE',
}

interface ISearchConfiscateRequest {
  type: EConfiscateAction.SEARCH_CONFISCATE_REQUEST;
}

interface ISearchConfiscateSuccess {
  type: EConfiscateAction.SEARCH_CONFISCATE_SUCCESS;
  data: TDataTable<IConfiscateList>;
}

interface ISearchConfiscateFailed {
  type: EConfiscateAction.SEARCH_CONFISCATE_FAILED;
  error: Error;
}

interface ICreateConfiscateRequest {
  type: EConfiscateAction.CREATE_CONFISCATE_REQUEST;
}

interface ICreateConfiscateSuccess {
  type: EConfiscateAction.CREATE_CONFISCATE_SUCCESS;
}

interface ICreateConfiscateFailed {
  type: EConfiscateAction.CREATE_CONFISCATE_FAILED;
  error: Error;
}

interface IGetConfiscateRequest {
  type: EConfiscateAction.GET_CONFISCATE_REQUEST;
}

interface IGetConfiscateSuccess {
  type: EConfiscateAction.GET_CONFISCATE_SUCCESS;
  data: IConfiscateForm;
}

interface IGetConfiscateFailed {
  type: EConfiscateAction.GET_CONFISCATE_FAILED;
  error: Error;
}

interface IUpdateConfiscateRequest {
  type: EConfiscateAction.UPDATE_CONFISCATE_REQUEST;
}

interface IUpdateConfiscateSuccess {
  type: EConfiscateAction.UPDATE_CONFISCATE_SUCCESS;
}

interface IUpdateConfiscateFailed {
  type: EConfiscateAction.UPDATE_CONFISCATE_FAILED;
  error: Error;
}

interface IDeleteConfiscateRequest {
  type: EConfiscateAction.DELETE_CONFISCATE_REQUEST;
}

interface IDeleteConfiscateSuccess {
  type: EConfiscateAction.DELETE_CONFISCATE_SUCCESS;
}

interface IDeleteConfiscateFailed {
  type: EConfiscateAction.DELETE_CONFISCATE_FAILED;
  error: Error;
}

interface IGetPdfRequest {
  type: EConfiscateAction.GET_PDF_REQUEST;
}

interface IGetPdfSuccess {
  type: EConfiscateAction.GET_PDF_SUCCESS;
  fileUrl: string;
}

interface IGetPdfFailed {
  type: EConfiscateAction.GET_PDF_FAILED;
  error: Error;
}

interface IClearSelectedConfiscate {
  type: EConfiscateAction.CLEAR_SELECTED_CONFISCATE;
}

export type TConfiscateActionTypes =
  | ISearchConfiscateRequest
  | ISearchConfiscateSuccess
  | ISearchConfiscateFailed
  | ICreateConfiscateRequest
  | ICreateConfiscateSuccess
  | ICreateConfiscateFailed
  | IGetConfiscateRequest
  | IGetConfiscateSuccess
  | IGetConfiscateFailed
  | IUpdateConfiscateRequest
  | IUpdateConfiscateSuccess
  | IUpdateConfiscateFailed
  | IDeleteConfiscateRequest
  | IDeleteConfiscateSuccess
  | IDeleteConfiscateFailed
  | IGetPdfRequest
  | IGetPdfSuccess
  | IGetPdfFailed
  | IClearSelectedConfiscate;
