import {
  ICaseFileFolderAllReportGetResponse,
  ICaseFileFolderArrestGetResponse,
  ICaseFileFolderConfiscateGetResponse,
  ICaseFileFolderInvestigationReportGetResponse,
  ICaseFileFolderSearchingReportGetResponse,
  ICaseFileForm,
  ICaseFileInvestigation,
  ICaseFileList,
  ICaseFileOfficerForm,
  TOfficerTableData,
} from 'shared/modules/case-file';
import { TDataTable } from 'shared/types';

export enum ECaseFileAction {
  SEARCH_CASE_FILE_REQUEST = 'SEARCH_CASE_FILE_REQUEST',
  SEARCH_CASE_FILE_SUCCESS = 'SEARCH_CASE_FILE_SUCCESS',
  SEARCH_CASE_FILE_FAILED = 'SEARCH_CASE_FILE_FAILED',
  GET_CASE_FILE_REQUEST = 'GET_CASE_FILE_REQUEST',
  GET_CASE_FILE_SUCCESS = 'GET_CASE_FILE_SUCCESS',
  GET_CASE_FILE_FAILED = 'GET_CASE_FILE_FAILED',
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAILED = 'GET_USER_FAILED',
  CREATE_CASE_FILE_REQUEST = 'CREATE_CASE_FILE_REQUEST',
  CREATE_CASE_FILE_SUCCESS = 'CREATE_CASE_FILE_SUCCESS',
  CREATE_CASE_FILE_FAILED = 'CREATE_CASE_FILE_FAILED',
  CLEAR_CASE_FILE_FORM = 'CLEAR_CASE_FILE_FORM',
  EDIT_CASE_FILE_REQUEST = 'EDIT_CASE_FILE_REQUEST',
  EDIT_CASE_FILE_SUCCESS = 'EDIT_CASE_FILE_SUCCESS',
  EDIT_CASE_FILE_FAILED = 'EDIT_CASE_FILE_FAILED',
  GET_INVESTIGATION_REQUEST = 'GET_INVESTIGATION_REQUEST',
  GET_INVESTIGATION_SUCCESS = 'GET_INVESTIGATION_SUCCESS',
  GET_INVESTIGATION_FAILED = 'GET_INVESTIGATION_FAILED',
  GET_PDF_REQUEST = 'GET_PDF_REQUEST',
  GET_PDF_SUCCESS = 'GET_PDF_SUCCESS',
  GET_PDF_FAILED = 'GET_PDF_FAILED',
  GET_INITIAL_OFFICER_REQUEST = 'GET_INITIAL_OFFICER_REQUEST',
  GET_INITIAL_OFFICER_SUCCESS = 'GET_INITIAL_OFFICER_SUCCESS',
  GET_INITIAL_OFFICER_FAILED = 'GET_INITIAL_OFFICER_FAILED',
  CLEAR_CASE_FILE_USER = 'CLEAR_CASE_FILE_USER',
  DELETE_CASE_FILE_REQUEST = 'DELETE_CASE_FILE_REQUEST',
  DELETE_CASE_FILE_SUCCESS = 'DELETE_CASE_FILE_SUCCESS',
  DELETE_CASE_FILE_FAILED = 'DELETE_CASE_FILE_FAILED',
  GET_CASE_FILE_FOLDER_ALL_REPORT_REQUEST = 'GET_CASE_FILE_FOLDER_ALL_REPORT_REQUEST',
  GET_CASE_FILE_FOLDER_ALL_REPORT_SUCCESS = 'GET_CASE_FILE_FOLDER_ALL_REPORT_SUCCESS',
  GET_CASE_FILE_FOLDER_ALL_REPORT_FAILED = 'GET_CASE_FILE_FOLDER_ALL_REPORT_FAILED',
  GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_REQUEST = 'GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_REQUEST',
  GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS = 'GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS',
  GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_FAILED = 'GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_FAILED',
  GET_CASE_FILE_FOLDER_ARREST_REQUEST = 'GET_CASE_FILE_FOLDER_ARREST_REQUEST',
  GET_CASE_FILE_FOLDER_ARREST_SUCCESS = 'GET_CASE_FILE_FOLDER_ARREST_SUCCESS',
  GET_CASE_FILE_FOLDER_ARREST_FAILED = 'GET_CASE_FILE_FOLDER_ARREST_FAILED',
  GET_CASE_FILE_FOLDER_CONFISCATE_REQUEST = 'GET_CASE_FILE_FOLDER_CONFISCATE_REQUEST',
  GET_CASE_FILE_FOLDER_CONFISCATE_SUCCESS = 'GET_CASE_FILE_FOLDER_CONFISCATE_SUCCESS',
  GET_CASE_FILE_FOLDER_CONFISCATE_FAILED = 'GET_CASE_FILE_FOLDER_CONFISCATE_FAILED',
  GET_CASE_FILE_FOLDER_SEARCHING_REPORT_REQUEST = 'GET_CASE_FILE_FOLDER_SEARCHING_REPORT_REQUEST',
  GET_CASE_FILE_FOLDER_SEARCHING_REPORT_SUCCESS = 'GET_CASE_FILE_FOLDER_SEARCHING_REPORT_SUCCESS',
  GET_CASE_FILE_FOLDER_SEARCHING_REPORT_FAILED = 'GET_CASE_FILE_FOLDER_SEARCHING_REPORT_FAILED',
  GET_CASE_FILE_TEMPLATE_REQUEST = 'GET_CASE_FILE_TEMPLATE_REQUEST',
  GET_CASE_FILE_TEMPLATE_SUCCESS = 'GET_CASE_FILE_TEMPLATE_SUCCESS',
  GET_CASE_FILE_TEMPLATE_FAILED = 'GET_CASE_FILE_TEMPLATE_FAILED',
}

interface ISearchCaseFileRequest {
  type: ECaseFileAction.SEARCH_CASE_FILE_REQUEST;
}

interface ISearchCaseFileSuccess {
  type: ECaseFileAction.SEARCH_CASE_FILE_SUCCESS;
  data: TDataTable<ICaseFileList>;
}

interface ISearchCaseFileFailed {
  type: ECaseFileAction.SEARCH_CASE_FILE_FAILED;
  error: Error;
}

interface IGetCaseFileRequest {
  type: ECaseFileAction.GET_CASE_FILE_REQUEST;
}

interface IGetCaseFileSuccess {
  type: ECaseFileAction.GET_CASE_FILE_SUCCESS;
  data: ICaseFileForm & { id: string };
}

interface IGetCaseFileFailed {
  type: ECaseFileAction.GET_CASE_FILE_FAILED;
  error: Error;
  id: string;
}

interface IGetUserRequest {
  type: ECaseFileAction.GET_USER_REQUEST;
}

interface IGetUserSuccess {
  type: ECaseFileAction.GET_USER_SUCCESS;
  data: ICaseFileOfficerForm;
}

interface IGetUserFailed {
  type: ECaseFileAction.GET_USER_FAILED;
  error: Error;
}

interface ICreateCaseFileRequest {
  type: ECaseFileAction.CREATE_CASE_FILE_REQUEST;
}

interface ICreateCaseFileSuccess {
  type: ECaseFileAction.CREATE_CASE_FILE_SUCCESS;
}

interface ICreateCaseFileFailed {
  type: ECaseFileAction.CREATE_CASE_FILE_FAILED;
  error: Error;
}

interface IClearCaseFileForm {
  type: ECaseFileAction.CLEAR_CASE_FILE_FORM;
}

interface IEditCaseFileRequest {
  type: ECaseFileAction.EDIT_CASE_FILE_REQUEST;
}

interface IEditCaseFileSuccess {
  type: ECaseFileAction.EDIT_CASE_FILE_SUCCESS;
}

interface IEditCaseFileFailed {
  type: ECaseFileAction.EDIT_CASE_FILE_FAILED;
  error: Error;
}

interface IGetInvestigationRequest {
  type: ECaseFileAction.GET_INVESTIGATION_REQUEST;
}

interface IGetInvestigationSuccess {
  type: ECaseFileAction.GET_INVESTIGATION_SUCCESS;
  data: TDataTable<ICaseFileInvestigation>;
}

interface IGetInvestigationFailed {
  type: ECaseFileAction.GET_INVESTIGATION_FAILED;
  error: Error;
}

interface IGetPdfRequest {
  type: ECaseFileAction.GET_PDF_REQUEST;
}

interface IGetPdfSuccess {
  type: ECaseFileAction.GET_PDF_SUCCESS;
  fileUrl: string;
}

interface IGetPdfFailed {
  type: ECaseFileAction.GET_PDF_FAILED;
  error: Error;
}

interface IGetInitialOfficerRequest {
  type: ECaseFileAction.GET_INITIAL_OFFICER_REQUEST;
}

interface IGetInitialOfficerSuccess {
  type: ECaseFileAction.GET_INITIAL_OFFICER_SUCCESS;
  initialOfficer: TOfficerTableData;
}

interface IGetInitialOfficerFailed {
  type: ECaseFileAction.GET_INITIAL_OFFICER_FAILED;
  error: Error;
}

interface IClearCaseFileUser {
  type: ECaseFileAction.CLEAR_CASE_FILE_USER;
}

interface IDeleteCaseFileRequest {
  type: ECaseFileAction.DELETE_CASE_FILE_REQUEST;
}

interface IDeleteCaseFileSuccess {
  type: ECaseFileAction.DELETE_CASE_FILE_SUCCESS;
}

interface IDeleteCaseFileFailed {
  type: ECaseFileAction.DELETE_CASE_FILE_FAILED;
  error: Error;
}

interface IGetCaseFileFolderAllReportRequest {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_REQUEST;
}

interface IGetCaseFileFolderAllReportSuccess {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_SUCCESS;
  data: TDataTable<ICaseFileFolderAllReportGetResponse>;
}

interface IGetCaseFileFolderAllReportFailed {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_FAILED;
  error: Error;
}

interface IGetCaseFileFolderInvestigationReportRequest {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_REQUEST;
}

interface IGetCaseFileFolderInvestigationReportSuccess {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS;
  data: TDataTable<ICaseFileFolderInvestigationReportGetResponse>;
}

interface IGetCaseFileFolderInvestigationReportFailed {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_FAILED;
  error: Error;
}

interface IGetCaseFileFolderArrestRequest {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_REQUEST;
}

interface IGetCaseFileFolderArrestSuccess {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_SUCCESS;
  data: TDataTable<ICaseFileFolderArrestGetResponse>;
}

interface IGetCaseFileFolderArrestFailed {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_FAILED;
  error: Error;
}

interface IGetCaseFileFolderConfiscateRequest {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_REQUEST;
}

interface IGetCaseFileFolderConfiscateSuccess {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_SUCCESS;
  data: TDataTable<ICaseFileFolderConfiscateGetResponse>;
}

interface IGetCaseFileFolderConfiscateFailed {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_FAILED;
  error: Error;
}

interface IGetCaseFileFolderSearchingReportRequest {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_REQUEST;
}

interface IGetCaseFileFolderSearchingReportSuccess {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_SUCCESS;
  data: TDataTable<ICaseFileFolderSearchingReportGetResponse>;
}

interface IGetCaseFileFolderSearchingReportFailed {
  type: ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_FAILED;
  error: Error;
}

interface IGetCaseFileTemplateRequest {
  type: ECaseFileAction.GET_CASE_FILE_TEMPLATE_REQUEST;
}

interface IGetCaseFileTemplateSuccess {
  type: ECaseFileAction.GET_CASE_FILE_TEMPLATE_SUCCESS;
  data: string;
}

interface IGetCaseFileTemplateFailed {
  type: ECaseFileAction.GET_CASE_FILE_TEMPLATE_FAILED;
  error: Error;
}

export type TCaseFileActionTypes =
  | ISearchCaseFileRequest
  | ISearchCaseFileSuccess
  | ISearchCaseFileFailed
  | IGetCaseFileRequest
  | IGetCaseFileSuccess
  | IGetCaseFileFailed
  | IGetUserRequest
  | IGetUserSuccess
  | IGetUserFailed
  | ICreateCaseFileRequest
  | ICreateCaseFileSuccess
  | ICreateCaseFileFailed
  | IClearCaseFileForm
  | IEditCaseFileRequest
  | IEditCaseFileSuccess
  | IEditCaseFileFailed
  | IGetInvestigationRequest
  | IGetInvestigationSuccess
  | IGetInvestigationFailed
  | IGetPdfRequest
  | IGetPdfSuccess
  | IGetPdfFailed
  | IGetInitialOfficerRequest
  | IGetInitialOfficerSuccess
  | IGetInitialOfficerFailed
  | IClearCaseFileUser
  | IDeleteCaseFileRequest
  | IDeleteCaseFileSuccess
  | IDeleteCaseFileFailed
  | IGetCaseFileFolderAllReportRequest
  | IGetCaseFileFolderAllReportSuccess
  | IGetCaseFileFolderAllReportFailed
  | IGetCaseFileFolderInvestigationReportRequest
  | IGetCaseFileFolderInvestigationReportSuccess
  | IGetCaseFileFolderInvestigationReportFailed
  | IGetCaseFileFolderArrestRequest
  | IGetCaseFileFolderArrestSuccess
  | IGetCaseFileFolderArrestFailed
  | IGetCaseFileFolderConfiscateRequest
  | IGetCaseFileFolderConfiscateSuccess
  | IGetCaseFileFolderConfiscateFailed
  | IGetCaseFileFolderSearchingReportRequest
  | IGetCaseFileFolderSearchingReportSuccess
  | IGetCaseFileFolderSearchingReportFailed
  | IGetCaseFileTemplateRequest
  | IGetCaseFileTemplateSuccess
  | IGetCaseFileTemplateFailed;
