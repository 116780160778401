import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import {
  IAccessPermissionManagementState,
  IAccessPermissionManagementForm,
} from './access-permission-management.interface';

export const INITIAL_ACCESS_PERMISSION_MANAGEMENT_FORM: IAccessPermissionManagementForm = {
  isActive: false,
  id: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  departmentID: null,
  divisionID: null,
  organizationID: null,
  headQuarterID: null,
  policeStationID: null,
  subDivisionID: null,
};

export const INITIAL_ACCESS_PERMISSION_MANAGEMENT_STATE: IAccessPermissionManagementState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};
