import { PageHeader, PageWrapper } from 'components/layout';
import { Button, HeaderText, Modal } from 'components/ui';
import { ButtonPreset } from 'components/ui/enum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useClearCacheCtx } from 'react-clear-cache';

const ModalStyle = styled(Modal)`
  height: fit-content;
  width: 20rem;
  padding: 1rem;
`;

interface IUpdateVersionProps {}

const UpdateVersion: React.FC<IUpdateVersionProps> = (props) => {
  const {} = props;
  const { t } = useTranslation('main');
  const { emptyCacheStorage } = useClearCacheCtx();

  const onUpdate = () => {
    emptyCacheStorage();
  };

  return (
    <ModalStyle visible>
      <PageHeader>
        <HeaderText>{t('', 'อัพเดตเวอร์ชั่น')}</HeaderText>
      </PageHeader>
      <Button preset={ButtonPreset.WARNING} onClick={onUpdate} fullWidth>
        {t('', 'อัพเดต')}
      </Button>
    </ModalStyle>
  );
};

export default UpdateVersion;
