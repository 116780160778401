import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useOrganizationDropdownApi = () => {
  const [organizations, isLoading, isError] = useFetchApi<IDropdown[]>('/Master/Organization/GetDropdownList', []);

  const organizationSelectOptions: SelectOption[] = organizations.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [organizationSelectOptions, isLoading, isError] as const;
};

export default useOrganizationDropdownApi;
