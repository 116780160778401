import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useSuspectTypeDropdownApi = () => {
  const [suspectTypes, isLoading, isError] = useFetchApi<IDropdown[]>('/suspectType/getDropdownList', []);

  const suspectTypeSelectOptions: SelectOption[] = suspectTypes.map((suspectType) => {
    return {
      text: suspectType.text,
      value: suspectType.id,
    };
  });

  return [suspectTypeSelectOptions, isLoading, isError] as const;
};

export default useSuspectTypeDropdownApi;
