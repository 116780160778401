import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IDopaLogActionForm, IDopaSearch, IDopaSearchValidate } from '.';
import {
  IAddressForm,
  IDopaForm,
  IDopaSearchForm,
  IDopaState,
  IGetDopaLogExportResponse,
  IPicture,
  IPopulationForm,
} from './dopa.interface';

export const INITIAL_DOPA_SEARCH: IDopaSearch = {
  keyword: '',
};

export const INITIAL_DOPA_SEARCH_VALIDATE: IDopaSearchValidate = {
  searchKeyword: '',
};

export const INITIAL_DOPA_SEARCH_FORM: IDopaSearchForm = {
  searchKeyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  personID: '',
  firstName: '',
  lastName: '',
  address: '',
  province: '',
  provinceID: null,
};

export const INITIAL_PICTURE: IPicture = {
  image: '',
  mineType: '',
};

export const INITIAL_DOPA_POPULATION_FORM: IPopulationForm = {
  photo: INITIAL_PICTURE,
  idCard: '',
  sex: '',
  birth: '',
  age: '',
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  titleEng: '',
  firstNameEng: '',
  middleNameEng: '',
  lastNameEng: '',
  nation: '',
  livingDate: '',
  personStatus: '',
  livingStatus: '',
};

export const INITIAL_DOPA_ADDRESS_FORM: IAddressForm = {
  idHouse: '',
  typeHouse: '',
  address: '',
  building: '',
  alley: '',
  subAlley: '',
  road: '',
  province: '',
  district: '',
  subDisrict: '',
  postalCode: '',
};

/* export const INITIAL_DOPA_STATE: IDopaState = {
  all: null,
  error: undefined,
  isPageLoading: true,
}; */

export const INITIAL_DOPA_FORM: IDopaForm = {
  // population: INITIAL_DOPA_POPULATION_FORM,
  // address: INITIAL_DOPA_ADDRESS_FORM
  age: '',
  alleyCode: '',
  alleyWayCode: '',
  alleyWayDesc: '',
  createBy: '',
  createdDate: null,
  dateOfBirth: '',
  dateOfMoveIn: '',
  dateOfTerminate: '',
  districtCode: '',
  districtDesc: '',
  englishFirstName: '',
  englishLastName: '',
  englishMiddleName: '',
  englishTitleDesc: '',
  fatherName: '',
  fatherNationalityCode: '',
  fatherNationalityDesc: '',
  fatherPersonalID: '',
  firstName: '',
  fullNameAndRank: '',
  genderCode: '',
  genderDesc: '',
  houseID: '',
  houseNo: '',
  houseType: '',
  houseTypeDesc: '',
  image: '',
  lastName: '',
  middleName: '',
  mineType: '',
  motherName: '',
  motherNationalityCode: '',
  motherNationalityDesc: '',
  motherPersonalID: '',
  nationalityCode: '',
  nationalityDesc: '',
  ownerStatusDesc: '',
  personID: '',
  phoneNumber: '',
  provinceCode: '',
  provinceDesc: '',
  rcodeCode: '',
  rcodeDesc: '',
  roadCode: '',
  roadDesc: '',
  statusOfPersonCode: '',
  statusOfPersonDesc: '',
  subdistrictCode: '',
  subdistrictDesc: '',
  titleCode: '',
  titleDesc: '',
  titleName: '',
  titleSex: '',
  updateBy: '',
  updatedDate: '',
  uuid: '',
  villageNo: '',
};

// NOTE: state
export const INITIAL_DOPA_STATE: IDopaState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IDopaForm,
  error: undefined,
  fileUrl: '',
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  log: { ...INITIAL_DATA_TABLE_TYPE },
  export: {} as IGetDopaLogExportResponse,
};

// NOTE: form log action
export const INITIAL_DOPA_LOG_ACTION_FORM: IDopaLogActionForm = {
  keyword: '',
  currentPage: 1,
  pageSize: 5,
};
