import { useState } from 'react';

const useModal = (defaultd: boolean = false) => {
  const [visible, setVisible] = useState(defaultd);

  const onOpen = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onToggle = () => {
    setVisible(!visible);
  };

  return { visible, onOpen, onClose, onToggle };
};

export default useModal;
