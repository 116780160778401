import { SelectOption } from 'components/ui/type';
import _ from 'lodash';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorageDropdown } from 'shared/enum/local-storage.type';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { useFetchApi } from 'shared/utils/hooks';

const useCaseFileDropdownApi = () => {
  const dropdown = reactLocalStorage.getObject(ELocalStorageDropdown.DROPDOWN_CASEFILE, []);
  const [caseFiles, isLoading, isError] = useFetchApi<IDropdown[]>(
    '/caseFileFolder/getDropdownList',
    [],
    '',
    null,
    true
  );

  const caseFileSelectOptions: SelectOption[] = caseFiles.map((caseFile) => {
    return {
      text: caseFile.text,
      value: caseFile.id,
    };
  });

  if (!isError && true) {
    reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_CASEFILE, caseFileSelectOptions);
    return [caseFileSelectOptions, isLoading, isError];
  } else {
    return [dropdown, isLoading, false];
  }
};
export default useCaseFileDropdownApi;
