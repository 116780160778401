import React, { FC, useEffect, useMemo } from 'react';
import TextArea from './TextArea';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'store';
import { getModuleLoggerById } from 'store/logger/logger.action';
import _ from 'lodash';
import { LargeText } from 'components/ui';
import { ELoggerModule } from 'components/ui/enum';
import { dateThaiFormat, getTime } from 'shared/utils/date-format';
import { EDateFormat } from 'shared/enum/date-format.enum';

interface ILoggerProps {
  module: ELoggerModule;
  id: string;
}

const LoggerWrapper = styled.div<{ display: number }>`
  width: 100%;
  flex-direction: column;
  padding: 24px;
  display: ${(props) => (props.display ? 'flex' : 'none')};
`;

const Logger: FC<ILoggerProps> = ({ module, id }) => {
  const dispatch = useDispatch();
  const loggersSelected = useSelector((o: IReducer) => o.logger.selected);

  useEffect(() => {
    if (!_.isEmpty(id)) dispatch(getModuleLoggerById(module, id));
  }, [dispatch, module, id]);

  const logger = useMemo(() => {
    try {
      return loggersSelected?.reduce((result, log, index) => {
        return `${result}${index !== 0 ? '\n' : ''}${index + 1}. ${dateThaiFormat(
          log.createdDate,
          EDateFormat.SlashDate
        )} ${getTime(log.createdDate, EDateFormat.FullTimeWithoutSuffix)} ${log.fullName} ${log.positionName} ${
          log.mobileNo
        } ${log.email}`;
      }, '');
    } catch {
      return '';
    }
  }, [loggersSelected]);

  return (
    <LoggerWrapper display={!_.isEmpty(id) ? 1 : 0}>
      <LargeText>ประวัติการแก้ไข</LargeText>
      <TextArea value={logger} disabled={true} />
    </LoggerWrapper>
  );
};

export default Logger;
