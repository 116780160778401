import { INITIAL_COORDS } from 'components/ui/constant/google-maps.constant';
import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import {
  INITIAL_BANK_ACCOUNT_VALIDATE,
  INITIAL_CAR_VALIDATE,
  INITIAL_DRUG_VALIDATE,
  INITIAL_MONEY_VALIDATE,
  INITIAL_PHONE_VALIDATE,
  INITIAL_PROPERTY,
} from 'shared/modules/property';
import { IConfiscateDrugPropertyValidate } from '.';
import { IOfficerLeadList, IOfficerReportList } from '../officer';
import {
  IAddressModalValidate,
  IConfiscateAtmPropertyValidate,
  IConfiscateBankPropertyValidate,
  IConfiscateCarPropertyValidate,
  IConfiscateForm,
  IConfiscateFormValidate,
  IConfiscateList,
  IConfiscateModalOfficerValidate,
  IConfiscateMoneyPropertyValidate,
  IConfiscatePersonPropertyValidate,
  IConfiscatePhonePropertyValidate,
  IConfiscateSearch,
  IConfiscateSearchValidate,
  IConfiscateState,
  ILeaderList,
  IOfficerLeadL,
  IOfficerLeadValidate,
  IOfficerList,
  IOfficerReport,
  IOfficerReportValidate,
  IOfficers,
  IPropertyValidate,
  ISuspectDetail,
  ISuspectDetailValidate,
} from './confiscate.interface';

export const INITIAL_CONFISCATE_SEARCH: IConfiscateSearch = {
  _caseFileID: null,
  caseFileID: '',
  confiscatePlace: '',
  recorderFullName: '',
  suspectFullName: '',
  confiscateStartDate: null,
  confiscateEndDate: null,
  CreateDateFrom: null,
  CreateDateTo: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  caseNo: '',
  caseName: '',
  year: moment(),
  place: '',
  confiscateDate: moment(),
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
  //
  yearCase: moment(new Date()),
};

export const INITIAL_CONFISCATE_STATE: IConfiscateState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IConfiscateForm,
  error: undefined,
  fileUrl: '',
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};

export const INITIAL_OFFICER_LEADLS: IOfficerLeadL = {
  teamID: null,
  officerLeadsList: [],
  otherLeads: '',
  officerHeadName: '',
  officerLeadFullID: null,
};

export const INITIAL_OFFICER_REPORTS: IOfficerReport = {
  teamID: null,
  officerReportsList: [],
  otherReports: '',
  officerHeadName: '',
  officerReportFullID: null,
};
//
export const INITIAL_OFFICER_LIST: IOfficerList = {
  userID: '',
  titleID: '',
  titleName: '',
  firstName: '',
  lastName: '',
  detail: '',
};

export const INITIAL_LEADER_LIST: ILeaderList = {
  unitName: '',
  officers: [INITIAL_OFFICER_LIST],
  detail: '',
};

export const INITIAL_OFFICERS: IOfficers = {
  leaderList: [INITIAL_LEADER_LIST],
  performerList: [INITIAL_LEADER_LIST],
};
//
export const INITIAL_CONFISCATE_FORM: IConfiscateForm = {
  caseNo: '',
  caseFileID: null,
  confiscateCode: '',
  recordDate: moment(new Date()),
  recordPlace: '',
  confiscateDateFrom: null,
  confiscateDateTo: null,
  confiscateTimeFrom: null,
  confiscateTimeTo: null,
  confiscatePlace: '',
  officerLeads: [],
  otherLeads: '',
  officerLeadLs: [INITIAL_OFFICER_LEADLS],
  officerReports: [INITIAL_OFFICER_REPORTS],
  suspects: [],
  accuseList: [''],
  property: INITIAL_PROPERTY,
  propertyFoundingPlace: '',
  propertyFoundingLatitude: null,
  propertyFoundingLongitude: null,
  _propertyFoundingLatitude: INITIAL_COORDS.lat,
  _propertyFoundingLongitude: INITIAL_COORDS.lng,
  propertyFoundingLocation: '',
  circumstance: '',
  incidentPlaceAndDate: '',
  isInformToRelative: false,
  isNotWillingToInform: false,
  isCopyConfiscateReport: false,
  officerCountID: null,
  searchLeaderName: '',
  reporterID: null,
  detail: '',
  remark: '',
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  //
  dailyReportNo: '',
  arrestReportNo: '',
  criminalRecordNo: '',
  inquiryOfficerName: '',
  _inquiryRecordDate: moment(),
  inquiryRecordDate: {},
  inquiryTelephone: '',
  //
  officers: INITIAL_OFFICERS,
  officerHeadName: '',
  tempName: '',
  // RELATION DROPDOWN
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  createdByAgencyID: null,
  createdBySectorID: null,
  createdByDepartmentID: null,
  //
  year: moment(new Date()),
  //
  lat: '',
  lng: '',
};

export const INITIAL_CONFISCATE_SEARCH_VALIDATE: IConfiscateSearchValidate = {
  suspectFullName: '',
  recorderFullName: '',
};

export const INITIAL_ADDRESS_MODAL_VALIDATE: IAddressModalValidate = {
  address: '',
  districtID: '',
  provinceID: '',
  subDistrictID: '',
};

export const INITIAL_SUSPECT_DETAIL: ISuspectDetail = {
  suspectIDCard: '',
  suspectTitleID: null,
  suspectTitleName: undefined,
  suspectFirstName: '',
  suspectLastName: '',
  birthDate: null,
  age: undefined,
  address: '',
  building: '',
  alley: '',
  road: '',
  provinceID: null,
  districtID: null,
  subDistrictID: null,
  postalCode: '',
  additionalAddressInfo: '',
  isAcknowledgeAccusation: null,
  isAcknowledgeAccusationString: null,
  acknowledgeAccusationDetail: '',
  remark: '',
};

export const INITIAL_SUSPECT_DETAIL_VALIDATE: ISuspectDetailValidate = {
  suspectIDCard: '',
  suspectTitleID: '',
  suspectFirstName: '',
  suspectLastName: '',
  postalCode: '',
};

export const INITIAL_OFFICER_LEADS_VALIDATE: IOfficerLeadValidate = {
  officerHeadName: '',
};

export const INITIAL_OFFICER_REPORTS_VALIDATE: IOfficerReportValidate = {
  teamID: '',
  officerHeadName: '',
};
export const INITIAL_PROPERTY_VALIDATE: IPropertyValidate = {
  propertyTypeID: '',
  phoneNumber: '',
};
export const INITIAL_CONFISCATE_FORM_VALIDATE: IConfiscateFormValidate = {
  recordPlace: '',
  recordDate: '',
  confiscateDateFrom: '',
  confiscateTimeFrom: '',
  confiscateDateTo: '',
  confiscateTimeTo: '',
  confiscatePlace: '',
  officerLeads: [INITIAL_OFFICER_LEADS_VALIDATE],
  officerReports: [INITIAL_OFFICER_REPORTS_VALIDATE],
  phone: [INITIAL_PHONE_VALIDATE],
  bankAccount: [INITIAL_BANK_ACCOUNT_VALIDATE],
  money: [INITIAL_MONEY_VALIDATE],
  drug: [INITIAL_DRUG_VALIDATE],
  car: [INITIAL_CAR_VALIDATE],
  dailyReportNo: '',
  inquiryTelephone: '',
  lat: '',
  lng: '',
};

export const INITIAL_CONFISCATE_PERSON_PROPERTY_VALIDATE: IConfiscatePersonPropertyValidate = {
  firstName: '',
  lastName: '',
  idCard: '',
};
export const INITIAL_CONFISCATE_PHONE_PROPERTY_VALIDATE: IConfiscatePhonePropertyValidate = {
  phoneNumber: '',
};

export const INITIAL_CONFISCATE_BANK_PROPERTY_VALIDATE: IConfiscateBankPropertyValidate = {
  accountNo: '',
  accountPhoneNumber: '',
  sms: '',
  otp: '',
};
export const INITIAL_CONFISCATE_MONEY_PROPERTY_VALIDATE: IConfiscateMoneyPropertyValidate = {
  totalAmount: '',
};

export const INITIAL_CONFISCATE_DRUG_PROPERTY_VALIDATE: IConfiscateDrugPropertyValidate = {
  amount: '',
};

export const INITIAL_CONFISCATE_CAR_PROPERTY_VALIDATE: IConfiscateCarPropertyValidate = {
  licensePlateNoLast: '',
  licensePlateNoFirst: '',
};

export const INITIAL_ARREST_MODAL_SUSPECT_VALIDATE: IConfiscateModalOfficerValidate = {
  fullName: '',
};

export const INITIAL_CONFISCATE_ATM_PROPERTY_VALIDATE: IConfiscateAtmPropertyValidate = {
  cardOwner: '',
};

export const INITIAL_OFFICER_LEADL_MODAL: IOfficerLeadList = {
  id: '',
  officerLeadFullID: null,
  officerLeadFullName: '',
  no: 0,
  titleID: null,
  titleName: '',
  officerHeadName: '',
  detail: '',
  otherLeads: '',
  firstName: '',
  lastName: '',
};

export const INITIAL_OFFICER_REPORT_MODAL: IOfficerReportList = {
  id: '',
  officerReportFullID: null,
  officerReportFullName: '',
  no: 0,
  officerCode: '',
  positionName: '',
  headQuarterName: '',
  divisionName: '',
  policeStationName: '',
  subDivisionName: '',
  mobilePhoneNumber: '',
  titleID: null,
  titleName: '',
  officerHeadName: '',
  detail: '',
  otherReports: '',
  firstName: '',
  lastName: '',
};
