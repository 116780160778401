import {
  ISuspectSearchAddressAttachment,
  ISuspectSearchBankAttachment,
  ISuspectSearchForm,
  ISuspectSearchList,
  ISuspectSearchModalSocial,
  ISuspectSearchModalAnnotation,
  ISuspectSearchModalBehaviour,
  ISuspectSearchModalVehicle,
  ISuspectSearchPhoneAttachment,
  ISuspectSearchVehicleAttachment,
} from 'shared/modules/suspect-search';
import { TDataTable } from 'shared/types';

export enum ESuspectAction {
  SEARCH_SUSPECT_REQUEST = 'SEARCH_SUSPECT_REQUEST',
  SEARCH_SUSPECT_SUCCESS = 'SEARCH_SUSPECT_SUCCESS',
  SEARCH_SUSPECT_FAILED = 'SEARCH_SUSPECT_FAILED',
  GET_SUSPECT_REQUEST = 'GET_SUSPECT_REQUEST',
  GET_SUSPECT_SUCCESS = 'GET_SUSPECT_SUCCESS',
  GET_SUSPECT_FAILED = 'GET_SUSPECT_FAILED',
  GET_SUSPECT_ADDRESS_REQUEST = 'GET_SUSPECT_ADDRESS_REQUEST',
  GET_SUSPECT_ADDRESS_SUCCESS = 'GET_SUSPECT_ADDRESS_SUCCESS',
  GET_SUSPECT_ADDRESS_FAILED = 'GET_SUSPECT_ADDRESS_FAILED',
  GET_SUSPECT_PHONE_REQUEST = 'GET_SUSPECT_PHONE_REQUEST',
  GET_SUSPECT_PHONE_SUCCESS = 'GET_SUSPECT_PHONE_SUCCESS',
  GET_SUSPECT_PHONE_FAILED = 'GET_SUSPECT_PHONE_FAILED',
  GET_SUSPECT_BANK_REQUEST = 'GET_SUSPECT_BANK_REQUEST',
  GET_SUSPECT_BANK_SUCCESS = 'GET_SUSPECT_BANK_SUCCESS',
  GET_SUSPECT_BANK_FAILED = 'GET_SUSPECT_BANK_FAILED',
  GET_SUSPECT_BANK_INSERT_REQUEST = 'GET_SUSPECT_BANK_INSERT_REQUEST',
  GET_SUSPECT_BANK_INSERT_SUCCESS = 'GET_SUSPECT_BANK_INSERT_SUCCESS',
  GET_SUSPECT_BANK_INSERT_FAILED = 'GET_SUSPECT_BANK_INSERT_FAILED',
  GET_SUSPECT_PHONE_INSERT_REQUEST = 'GET_SUSPECT_PHONE_INSERT_REQUEST',
  GET_SUSPECT_PHONE_INSERT_SUCCESS = 'GET_SUSPECT_PHONE_INSERT_SUCCESS',
  GET_SUSPECT_PHONE_INSERT_FAILED = 'GET_SUSPECT_PHONE_INSERT_FAILED',
  GET_SUSPECT_VEHICLE_REQUEST = 'GET_SUSPECT_VEHICLE_REQUEST',
  GET_SUSPECT_VEHICLE_SUCCESS = 'GET_SUSPECT_VEHICLE_SUCCESS',
  GET_SUSPECT_VEHICLE_FAILED = 'GET_SUSPECT_VEHICLE_FAILED',
  GET_SUSPECT_SOCIAL_REQUEST = 'GET_SUSPECT_SOCIAL_REQUEST',
  GET_SUSPECT_SOCIAL_SUCCESS = 'GET_SUSPECT_SOCIAL_SUCCESS',
  GET_SUSPECT_SOCIAL_FAILED = 'GET_SUSPECT_SOCIAL_FAILED',
  GET_SUSPECT_ANNOTATION_REQUEST = 'GET_SUSPECT_ANNOTATION_REQUEST',
  GET_SUSPECT_ANNOTATION_SUCCESS = 'GET_SUSPECT_ANNOTATION_SUCCESS',
  GET_SUSPECT_ANNOTATION_FAILED = 'GET_SUSPECT_ANNOTATION_FAILED',
  GET_SUSPECT_BEHAVIOUR_REQUEST = 'GET_SUSPECT_BEHAVIOUR_REQUEST',
  GET_SUSPECT_BEHAVIOUR_SUCCESS = 'GET_SUSPECT_BEHAVIOUR_SUCCESS',
  GET_SUSPECT_BEHAVIOUR_FAILED = 'GET_SUSPECT_BEHAVIOUR_FAILED',
  CREATE_SUSPECT_REQUEST = 'CREATE_SUSPECT_REQUEST',
  CREATE_SUSPECT_SUCCESS = 'CREATE_SUSPECT_SUCCESS',
  CREATE_SUSPECT_FAILED = 'CREATE_SUSPECT_FAILED',
  CREATE_ADDRESS_REQUEST = 'CREATE_ADDRESS_REQUEST',
  CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS',
  CREATE_ADDRESS_FAILED = 'CREATE_ADDRESS_FAILED',
  CREATE_ANNOTATION_REQUEST = 'CREATE_ANNOTATION_REQUEST',
  CREATE_ANNOTATION_SUCCESS = 'CREATE_ANNOTATION_SUCCESS',
  CREATE_ANNOTATION_FAILED = 'CREATE_ANNOTATION_FAILED',
  CREATE_BEHAVIOUR_REQUEST = 'CREATE_BEHAVIOUR_REQUEST',
  CREATE_BEHAVIOUR_SUCCESS = 'CREATE_BEHAVIOUR_SUCCESS',
  CREATE_BEHAVIOUR_FAILED = 'CREATE_BEHAVIOUR_FAILED',
  CREATE_PHONE_REQUEST = 'CREATE_PHONE_REQUEST',
  CREATE_PHONE_SUCCESS = 'CREATE_PHONE_SUCCESS',
  CREATE_PHONE_FAILED = 'CREATE_PHONE_FAILED',
  CREATE_BANK_REQUEST = 'CREATE_BANK_REQUEST',
  CREATE_BANK_SUCCESS = 'CREATE_BANK_SUCCESS',
  CREATE_BANK_FAILED = 'CREATE_BANK_FAILED',
  CREATE_VEHICLE_REQUEST = 'CREATE_VEHICLE_REQUEST',
  CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS',
  CREATE_VEHICLE_FAILED = 'CREATE_VEHICLE_FAILED',
  CREATE_SOCIAL_REQUEST = 'CREATE_SOCIAL_REQUEST',
  CREATE_SOCIAL_SUCCESS = 'CREATE_SOCIAL_SUCCESS',
  CREATE_SOCIAL_FAILED = 'CREATE_SOCIAL_FAILED',
  UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILED = 'UPDATE_ADDRESS_FAILED',
  UPDATE_PHONE_REQUEST = 'UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAILED = 'UPDATE_PHONE_FAILED',
  UPDATE_BANK_REQUEST = 'UPDATE_BANK_REQUEST',
  UPDATE_BANK_SUCCESS = 'UPDATE_BANK_SUCCESS',
  UPDATE_BANK_FAILED = 'UPDATE_BANK_FAILED',
  UPDATE_ANNOTATION_REQUEST = 'UPDATE_ANNOTATION_REQUEST',
  UPDATE_ANNOTATION_SUCCESS = 'UPDATE_ANNOTATION_SUCCESS',
  UPDATE_ANNOTATION_FAILED = 'UPDATE_ANNOTATION_FAILED',
  UPDATE_BEHAVIOUR_REQUEST = 'UPDATE_BEHAVIOUR_REQUEST',
  UPDATE_BEHAVIOUR_SUCCESS = 'UPDATE_BEHAVIOUR_SUCCESS',
  UPDATE_BEHAVIOUR_FAILED = 'UPDATE_BEHAVIOUR_FAILED',
  UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST',
  UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_FAILED = 'UPDATE_VEHICLE_FAILED',
  UPDATE_SOCIAL_REQUEST = 'UPDATE_SOCIAL_REQUEST',
  UPDATE_SOCIAL_SUCCESS = 'UPDATE_SOCIAL_SUCCESS',
  UPDATE_SOCIAL_FAILED = 'UPDATE_SOCIAL_FAILED',
  UPDATE_SUSPECT_REQUEST = 'UPDATE_SUSPECT_REQUEST',
  UPDATE_SUSPECT_SUCCESS = 'UPDATE_SUSPECT_SUCCESS',
  UPDATE_SUSPECT_FAILED = 'UPDATE_SUSPECT_FAILED',
  DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST',
  DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILED = 'DELETE_ADDRESS_FAILED',
  DELETE_PHONE_REQUEST = 'DELETE_PHONE_REQUEST',
  DELETE_PHONE_SUCCESS = 'DELETE_PHONE_SUCCESS',
  DELETE_PHONE_FAILED = 'DELETE_PHONE_FAILED',
  DELETE_BANK_REQUEST = 'DELETE_BANK_REQUEST',
  DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS',
  DELETE_BANK_FAILED = 'DELETE_BANK_FAILED',
  DELETE_VEHICLE_REQUEST = 'DELETE_VEHICLE_REQUEST',
  DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILED = 'DELETE_SOCIAL_FAILED',
  DELETE_SOCIAL_REQUEST = 'DELETE_SOCIAL_REQUEST',
  DELETE_SOCIAL_SUCCESS = 'DELETE_SOCIAL_SUCCESS',
  DELETE_SOCIAL_FAILED = 'DELETE_SOCIAL_FAILED',
  DELETE_ANNOTATION_REQUEST = 'DELETE_ANNOTATION_REQUEST',
  DELETE_ANNOTATION_SUCCESS = 'DELETE_ANNOTATION_SUCCESS',
  DELETE_ANNOTATION_FAILED = 'DELETE_ANNOTATION_FAILED',
  DELETE_BEHAVIOUR_REQUEST = 'DELETE_BEHAVIOUR_REQUEST',
  DELETE_BEHAVIOUR_SUCCESS = 'DELETE_BEHAVIOUR_SUCCESS',
  DELETE_BEHAVIOUR_FAILED = 'DELETE_BEHAVIOUR_FAILED',
  UPDATE_DOPA_IMAGE_REQUEST = 'UPDATE_DOPA_IMAGE_REQUEST',
  UPDATE_DOPA_IMAGE_SUCCESS = 'UPDATE_DOPA_IMAGE_SUCCESS',
  UPDATE_DOPA_IMAGE_FAILED = 'UPDATE_DOPA_IMAGE_FAILED',
  // ;; create suspect-upload-file
  CREATE_SUSPECT_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_UPLOAD_FILE_FAILED',
  CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_FAILED',
  CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_FAILED',
  CREATE_SUSPECT_BANK_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_BANK_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_BANK_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_BANK_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_BANK_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_BANK_UPLOAD_FILE_FAILED',
  CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_FAILED',
  CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_FAILED',
  CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_REQUEST = 'CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_REQUEST',
  CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_SUCCESS = 'CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_SUCCESS',
  CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_FAILED = 'CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_FAILED',

  // ;; delete suspect-upload-file
  DELETE_SUSPECT_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_BANK_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_BANK_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_BANK_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_BANK_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_BANK_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_BANK_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_FAILED',
  DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_REQUEST = 'DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_REQUEST',
  DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_SUCCESS = 'DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_SUCCESS',
  DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_FAILED = 'DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_FAILED',
}

interface ISearchSuspectRequest {
  type: ESuspectAction.SEARCH_SUSPECT_REQUEST;
}

interface ISearchSuspectSuccess {
  type: ESuspectAction.SEARCH_SUSPECT_SUCCESS;
  data: TDataTable<ISuspectSearchList>;
}

interface ISearchSuspectFailed {
  type: ESuspectAction.SEARCH_SUSPECT_FAILED;
  error: Error;
}

interface IGetSuspectRequest {
  type: ESuspectAction.GET_SUSPECT_REQUEST;
}

interface IGetSuspectSuccess {
  type: ESuspectAction.GET_SUSPECT_SUCCESS;
  data: ISuspectSearchForm;
}

interface IGetSuspectFailed {
  type: ESuspectAction.GET_SUSPECT_FAILED;
  error: Error;
}

interface IGetSuspectAddressRequest {
  type: ESuspectAction.GET_SUSPECT_ADDRESS_REQUEST;
}

interface IGetSuspectAddressSuccess {
  type: ESuspectAction.GET_SUSPECT_ADDRESS_SUCCESS;
  data: TDataTable<ISuspectSearchAddressAttachment>;
}

interface IGetSuspectAddressFailed {
  type: ESuspectAction.GET_SUSPECT_ADDRESS_FAILED;
  error: Error;
}

interface IGetSuspectPhoneRequest {
  type: ESuspectAction.GET_SUSPECT_PHONE_REQUEST;
}

interface IGetSuspectPhoneSuccess {
  type: ESuspectAction.GET_SUSPECT_PHONE_SUCCESS;
  data: TDataTable<ISuspectSearchPhoneAttachment>;
}

interface IGetSuspectPhoneFailed {
  type: ESuspectAction.GET_SUSPECT_PHONE_FAILED;
  error: Error;
}

interface IGetSuspectBankRequest {
  type: ESuspectAction.GET_SUSPECT_BANK_REQUEST;
}

interface IGetSuspectBankSuccess {
  type: ESuspectAction.GET_SUSPECT_BANK_SUCCESS;
  data: TDataTable<ISuspectSearchBankAttachment>;
}

interface IGetSuspectBankFailed {
  type: ESuspectAction.GET_SUSPECT_BANK_FAILED;
  error: Error;
}

interface IGetSuspectBankInsertRequest {
  type: ESuspectAction.GET_SUSPECT_BANK_INSERT_REQUEST;
}

interface IGetSuspectBankInsertSuccess {
  type: ESuspectAction.GET_SUSPECT_BANK_INSERT_SUCCESS;
  data: TDataTable<ISuspectSearchBankAttachment>;
}

interface IGetSuspectBankInsertFailed {
  type: ESuspectAction.GET_SUSPECT_BANK_INSERT_FAILED;
  error: Error;
}

interface IGetSuspectVehicleRequest {
  type: ESuspectAction.GET_SUSPECT_VEHICLE_REQUEST;
}

interface IGetSuspectVehicleSuccess {
  type: ESuspectAction.GET_SUSPECT_VEHICLE_SUCCESS;
  data: ISuspectSearchModalVehicle;
}

interface IGetSuspectVehicleFailed {
  type: ESuspectAction.GET_SUSPECT_VEHICLE_FAILED;
  error: Error;
}

interface IGetSuspectSocialRequest {
  type: ESuspectAction.GET_SUSPECT_SOCIAL_REQUEST;
}

interface IGetSuspectSocialSuccess {
  type: ESuspectAction.GET_SUSPECT_SOCIAL_SUCCESS;
  data: ISuspectSearchModalSocial;
}

interface IGetSuspectSocialFailed {
  type: ESuspectAction.GET_SUSPECT_SOCIAL_FAILED;
  error: Error;
}

interface IGetSuspectPhoneInsertRequest {
  type: ESuspectAction.GET_SUSPECT_PHONE_INSERT_REQUEST;
}

interface IGetSuspectPhoneInsertSuccess {
  type: ESuspectAction.GET_SUSPECT_PHONE_INSERT_SUCCESS;
  data: TDataTable<ISuspectSearchPhoneAttachment>;
}

interface IGetSuspectPhoneInsertFailed {
  type: ESuspectAction.GET_SUSPECT_PHONE_INSERT_FAILED;
  error: Error;
}

interface IGetSuspectAnnotationRequest {
  type: ESuspectAction.GET_SUSPECT_ANNOTATION_REQUEST;
}

interface IGetSuspectAnnotationSuccess {
  type: ESuspectAction.GET_SUSPECT_ANNOTATION_SUCCESS;
  data: ISuspectSearchModalAnnotation;
}

interface IGetSuspectAnnotationFailed {
  type: ESuspectAction.GET_SUSPECT_ANNOTATION_FAILED;
  error: Error;
}

interface IGetSuspectBehaviourRequest {
  type: ESuspectAction.GET_SUSPECT_BEHAVIOUR_REQUEST;
}

interface IGetSuspectBehaviourSuccess {
  type: ESuspectAction.GET_SUSPECT_BEHAVIOUR_SUCCESS;
  data: ISuspectSearchModalBehaviour;
}

interface IGetSuspectBehaviourFailed {
  type: ESuspectAction.GET_SUSPECT_BEHAVIOUR_FAILED;
  error: Error;
}

interface ICreateSuspectRequest {
  type: ESuspectAction.CREATE_SUSPECT_REQUEST;
}

interface ICreateSuspectSuccess {
  type: ESuspectAction.CREATE_SUSPECT_SUCCESS;
  data: ISuspectSearchForm;
}

interface ICreateSuspectFailed {
  type: ESuspectAction.CREATE_SUSPECT_FAILED;
  error: Error;
}

interface ICreateAddressRequest {
  type: ESuspectAction.CREATE_ADDRESS_REQUEST;
}

interface ICreateAddressSuccess {
  type: ESuspectAction.CREATE_ADDRESS_SUCCESS;
}

interface ICreateAddressFailed {
  type: ESuspectAction.CREATE_ADDRESS_FAILED;
  error: Error;
}

interface ICreatePhoneRequest {
  type: ESuspectAction.CREATE_PHONE_REQUEST;
}

interface ICreatePhoneSuccess {
  type: ESuspectAction.CREATE_PHONE_SUCCESS;
}

interface ICreatePhoneFailed {
  type: ESuspectAction.CREATE_PHONE_FAILED;
  error: Error;
}

interface ICreateBankRequest {
  type: ESuspectAction.CREATE_BANK_REQUEST;
}

interface ICreateBankSuccess {
  type: ESuspectAction.CREATE_BANK_SUCCESS;
}

interface ICreateBankFailed {
  type: ESuspectAction.CREATE_BANK_FAILED;
  error: Error;
}

interface ICreateVehicleRequest {
  type: ESuspectAction.CREATE_VEHICLE_REQUEST;
}

interface ICreateVehicleSuccess {
  type: ESuspectAction.CREATE_VEHICLE_SUCCESS;
}

interface ICreateVehicleFailed {
  type: ESuspectAction.CREATE_VEHICLE_FAILED;
  error: Error;
}

interface ICreateSocialRequest {
  type: ESuspectAction.CREATE_SOCIAL_REQUEST;
}

interface ICreateSocialSuccess {
  type: ESuspectAction.CREATE_SOCIAL_SUCCESS;
}

interface ICreateSocialFailed {
  type: ESuspectAction.CREATE_SOCIAL_FAILED;
  error: Error;
}
interface ICreateAnnotaionRequest {
  type: ESuspectAction.CREATE_ANNOTATION_REQUEST;
}

interface ICreateAnnotaionSuccess {
  type: ESuspectAction.CREATE_ANNOTATION_SUCCESS;
}

interface ICreateAnnotaionFailed {
  type: ESuspectAction.CREATE_ANNOTATION_FAILED;
  error: Error;
}

interface ICreateBehaviourRequest {
  type: ESuspectAction.CREATE_BEHAVIOUR_REQUEST;
}

interface ICreateBehaviourSuccess {
  type: ESuspectAction.CREATE_BEHAVIOUR_SUCCESS;
}

interface ICreateBehaviourFailed {
  type: ESuspectAction.CREATE_BEHAVIOUR_FAILED;
  error: Error;
}

interface IUpdateAddressRequest {
  type: ESuspectAction.UPDATE_ADDRESS_REQUEST;
}

interface IUpdateAddressSuccess {
  type: ESuspectAction.UPDATE_ADDRESS_SUCCESS;
}

interface IUpdateAddressFailed {
  type: ESuspectAction.UPDATE_ADDRESS_FAILED;
  error: Error;
}

interface IUpdatePhoneRequest {
  type: ESuspectAction.UPDATE_PHONE_REQUEST;
}

interface IUpdatePhoneSuccess {
  type: ESuspectAction.UPDATE_PHONE_SUCCESS;
}

interface IUpdatePhoneFailed {
  type: ESuspectAction.UPDATE_PHONE_FAILED;
  error: Error;
}

interface IUpdateBankRequest {
  type: ESuspectAction.UPDATE_BANK_REQUEST;
}

interface IUpdateBankSuccess {
  type: ESuspectAction.UPDATE_BANK_SUCCESS;
}

interface IUpdateBankFailed {
  type: ESuspectAction.UPDATE_BANK_FAILED;
  error: Error;
}

interface IUpdateVehicleRequest {
  type: ESuspectAction.UPDATE_VEHICLE_REQUEST;
}

interface IUpdateVehicleSuccess {
  type: ESuspectAction.UPDATE_VEHICLE_SUCCESS;
}

interface IUpdateVehicleFailed {
  type: ESuspectAction.UPDATE_VEHICLE_FAILED;
  error: Error;
}

interface IUpdateSocialRequest {
  type: ESuspectAction.UPDATE_SOCIAL_REQUEST;
}

interface IUpdateSocialSuccess {
  type: ESuspectAction.UPDATE_SOCIAL_SUCCESS;
}

interface IUpdateSocialFailed {
  type: ESuspectAction.UPDATE_SOCIAL_FAILED;
  error: Error;
}

interface IUpdateSuspectRequest {
  type: ESuspectAction.UPDATE_SUSPECT_REQUEST;
}

interface IUpdateSuspectSuccess {
  type: ESuspectAction.UPDATE_SUSPECT_SUCCESS;
}

interface IUpdateSuspectFailed {
  type: ESuspectAction.UPDATE_SUSPECT_FAILED;
  error: Error;
}

interface IUpdateAnnotaionRequest {
  type: ESuspectAction.UPDATE_ANNOTATION_REQUEST;
}

interface IUpdateAnnotaionSuccess {
  type: ESuspectAction.UPDATE_ANNOTATION_SUCCESS;
}

interface IUpdateAnnotaionFailed {
  type: ESuspectAction.UPDATE_ANNOTATION_FAILED;
  error: Error;
}

interface IUpdateBehaviourRequest {
  type: ESuspectAction.UPDATE_BEHAVIOUR_REQUEST;
}

interface IUpdateBehaviourSuccess {
  type: ESuspectAction.UPDATE_BEHAVIOUR_SUCCESS;
}

interface IUpdateBehaviourFailed {
  type: ESuspectAction.UPDATE_BEHAVIOUR_FAILED;
  error: Error;
}

interface IDeleteAddressRequest {
  type: ESuspectAction.DELETE_ADDRESS_REQUEST;
}

interface IDeleteAddressSuccess {
  type: ESuspectAction.DELETE_ADDRESS_SUCCESS;
}

interface IDeleteAddressFailed {
  type: ESuspectAction.DELETE_ADDRESS_FAILED;
  error: Error;
}

interface IDeletePhoneRequest {
  type: ESuspectAction.DELETE_PHONE_REQUEST;
}

interface IDeletePhoneSuccess {
  type: ESuspectAction.DELETE_PHONE_SUCCESS;
}

interface IDeletePhoneFailed {
  type: ESuspectAction.DELETE_PHONE_FAILED;
  error: Error;
}

interface IDeleteBankRequest {
  type: ESuspectAction.DELETE_BANK_REQUEST;
}

interface IDeleteBankSuccess {
  type: ESuspectAction.DELETE_BANK_SUCCESS;
}

interface IDeleteBankFailed {
  type: ESuspectAction.DELETE_BANK_FAILED;
  error: Error;
}

interface IDeleteVehicleRequest {
  type: ESuspectAction.DELETE_VEHICLE_REQUEST;
}

interface IDeleteVehicleSuccess {
  type: ESuspectAction.DELETE_VEHICLE_SUCCESS;
}

interface IDeleteVehicleFailed {
  type: ESuspectAction.DELETE_VEHICLE_FAILED;
  error: Error;
}

interface IDeleteSocialRequest {
  type: ESuspectAction.DELETE_SOCIAL_REQUEST;
}

interface IDeleteSocialSuccess {
  type: ESuspectAction.DELETE_SOCIAL_SUCCESS;
}

interface IDeleteSocialFailed {
  type: ESuspectAction.DELETE_SOCIAL_FAILED;
  error: Error;
}
interface IDeleteAnnotaionRequest {
  type: ESuspectAction.DELETE_ANNOTATION_REQUEST;
}

interface IDeleteAnnotaionSuccess {
  type: ESuspectAction.DELETE_ANNOTATION_SUCCESS;
}

interface IDeleteAnnotaionFailed {
  type: ESuspectAction.DELETE_ANNOTATION_FAILED;
  error: Error;
}

interface IDeleteBehaviourRequest {
  type: ESuspectAction.DELETE_BEHAVIOUR_REQUEST;
}

interface IDeleteBehaviourSuccess {
  type: ESuspectAction.DELETE_BEHAVIOUR_SUCCESS;
}

interface IDeleteBehaviourFailed {
  type: ESuspectAction.DELETE_BEHAVIOUR_FAILED;
  error: Error;
}

interface IUpdateDopaImageRequest {
  type: ESuspectAction.UPDATE_DOPA_IMAGE_REQUEST;
}

interface IUpdateDopaImageSuccess {
  type: ESuspectAction.UPDATE_DOPA_IMAGE_SUCCESS;
}

interface IUpdateDopaImageFailed {
  type: ESuspectAction.UPDATE_DOPA_IMAGE_FAILED;
  error: Error;
}

// ;; create suspect-upload-file
interface ICreateSuspectUploadFileRequest {
  type: ESuspectAction.CREATE_SUSPECT_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileSuccess {
  type: ESuspectAction.CREATE_SUSPECT_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileFailed {
  type: ESuspectAction.CREATE_SUSPECT_UPLOAD_FILE_FAILED;
  error: Error;
}

interface ICreateSuspectUploadFileVehicleRequest {
  type: ESuspectAction.CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileVehicleSuccess {
  type: ESuspectAction.CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileVehicleFailed {
  type: ESuspectAction.CREATE_SUSPECT_VEHICLE_UPLOAD_FILE_FAILED
  error: Error;
}

interface ICreateSuspectUploadFileAnnotationRequest {
  type: ESuspectAction.CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileAnnotationSuccess {
  type: ESuspectAction.CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileAnnotationFailed {
  type: ESuspectAction.CREATE_SUSPECT_ANNOTATION_UPLOAD_FILE_FAILED
  error: Error;
}

interface ICreateSuspectUploadFileBankRequest {
  type: ESuspectAction.CREATE_SUSPECT_BANK_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileBankSuccess {
  type: ESuspectAction.CREATE_SUSPECT_BANK_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileBankFailed {
  type: ESuspectAction.CREATE_SUSPECT_BANK_UPLOAD_FILE_FAILED
  error: Error;
}

interface ICreateSuspectUploadFileBehaviourRequest {
  type: ESuspectAction.CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileBehaviourSuccess {
  type: ESuspectAction.CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileBehaviourFailed {
  type: ESuspectAction.CREATE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_FAILED
  error: Error;
}

interface ICreateSuspectUploadFileSocialRequest {
  type: ESuspectAction.CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileSocialSuccess {
  type: ESuspectAction.CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileSocialFailed {
  type: ESuspectAction.CREATE_SUSPECT_SOCIAL_UPLOAD_FILE_FAILED
  error: Error;
}
interface ICreateSuspectUploadFileTelephoneRequest {
  type: ESuspectAction.CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_REQUEST;
}

interface ICreateSuspectUploadFileTelephoneSuccess {
  type: ESuspectAction.CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_SUCCESS;
}

interface ICreateSuspectUploadFileTelephoneFailed {
  type: ESuspectAction.CREATE_SUSPECT_TELEPHONE_UPLOAD_FILE_FAILED
  error: Error;
}

// ;; delete suspect-upload-file
interface IDeleteSuspectUploadFileRequest {
  type: ESuspectAction.DELETE_SUSPECT_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileSuccess {
  type: ESuspectAction.DELETE_SUSPECT_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileFailed {
  type: ESuspectAction.DELETE_SUSPECT_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileVehicleRequest {
  type: ESuspectAction.DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileVehicleSuccess {
  type: ESuspectAction.DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileVehicleFailed {
  type: ESuspectAction.DELETE_SUSPECT_VEHICLE_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileAnnotationRequest {
  type: ESuspectAction.DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileAnnotationSuccess {
  type: ESuspectAction.DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileAnnotationFailed {
  type: ESuspectAction.DELETE_SUSPECT_ANNOTATION_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileBankRequest {
  type: ESuspectAction.DELETE_SUSPECT_BANK_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileBankSuccess {
  type: ESuspectAction.DELETE_SUSPECT_BANK_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileBankFailed {
  type: ESuspectAction.DELETE_SUSPECT_BANK_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileBehaviourRequest {
  type: ESuspectAction.DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileBehaviourSuccess {
  type: ESuspectAction.DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileBehaviourFailed {
  type: ESuspectAction.DELETE_SUSPECT_BEHAVIOUR_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileSocialRequest {
  type: ESuspectAction.DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileSocialSuccess {
  type: ESuspectAction.DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileSocialFailed {
  type: ESuspectAction.DELETE_SUSPECT_SOCIAL_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileTelephoneRequest {
  type: ESuspectAction.DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileTelephoneSuccess {
  type: ESuspectAction.DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileTelephoneFailed {
  type: ESuspectAction.DELETE_SUSPECT_TELEPHONE_UPLOAD_FILE_FAILED;
  error: Error;
}

interface IDeleteSuspectUploadFileAddressRequest {
  type: ESuspectAction.DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_REQUEST;
}

interface IDeleteSuspectUploadFileAddressSuccess {
  type: ESuspectAction.DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_SUCCESS;
}

interface IDeleteSuspectUploadFileAddressFailed {
  type: ESuspectAction.DELETE_SUSPECT_ADDRESS_UPLOAD_FILE_FAILED;
  error: Error;
}

export type TSuspectActionTypes =
  | ISearchSuspectRequest
  | ISearchSuspectSuccess
  | ISearchSuspectFailed
  | IGetSuspectRequest
  | IGetSuspectSuccess
  | IGetSuspectFailed
  | IGetSuspectAddressRequest
  | IGetSuspectAddressSuccess
  | IGetSuspectAddressFailed
  | IGetSuspectPhoneRequest
  | IGetSuspectPhoneSuccess
  | IGetSuspectPhoneFailed
  | IGetSuspectBankRequest
  | IGetSuspectBankSuccess
  | IGetSuspectBankFailed
  | IGetSuspectBankInsertRequest
  | IGetSuspectBankInsertSuccess
  | IGetSuspectBankInsertFailed
  | IGetSuspectVehicleRequest
  | IGetSuspectVehicleSuccess
  | IGetSuspectVehicleFailed
  | IGetSuspectSocialRequest
  | IGetSuspectSocialSuccess
  | IGetSuspectSocialFailed
  | IGetSuspectAnnotationRequest
  | IGetSuspectAnnotationSuccess
  | IGetSuspectAnnotationFailed
  | IGetSuspectBehaviourRequest
  | IGetSuspectBehaviourSuccess
  | IGetSuspectBehaviourFailed
  | ICreateSuspectRequest
  | ICreateSuspectSuccess
  | ICreateSuspectFailed
  | ICreateAddressRequest
  | ICreateAddressSuccess
  | ICreateAddressFailed
  | ICreatePhoneRequest
  | ICreatePhoneSuccess
  | ICreatePhoneFailed
  | ICreateBankRequest
  | ICreateBankSuccess
  | ICreateBankFailed
  | ICreateVehicleRequest
  | ICreateVehicleSuccess
  | ICreateVehicleFailed
  | ICreateSocialRequest
  | ICreateSocialSuccess
  | ICreateSocialFailed
  | ICreateAnnotaionRequest
  | ICreateAnnotaionSuccess
  | ICreateAnnotaionFailed
  | ICreateBehaviourRequest
  | ICreateBehaviourSuccess
  | ICreateBehaviourFailed
  | IUpdateAddressRequest
  | IUpdateAddressSuccess
  | IUpdateAddressFailed
  | IUpdatePhoneRequest
  | IUpdatePhoneSuccess
  | IUpdatePhoneFailed
  | IUpdateBankRequest
  | IUpdateBankSuccess
  | IUpdateBankFailed
  | IUpdateVehicleRequest
  | IUpdateVehicleSuccess
  | IUpdateVehicleFailed
  | IUpdateSocialRequest
  | IUpdateSocialSuccess
  | IUpdateSocialFailed
  | IUpdateSuspectRequest
  | IUpdateSuspectSuccess
  | IUpdateSuspectFailed
  | IUpdateAnnotaionRequest
  | IUpdateAnnotaionSuccess
  | IUpdateAnnotaionFailed
  | IUpdateBehaviourRequest
  | IUpdateBehaviourSuccess
  | IUpdateBehaviourFailed
  | IDeleteAddressRequest
  | IDeleteAddressSuccess
  | IDeleteAddressFailed
  | IDeletePhoneRequest
  | IDeletePhoneSuccess
  | IDeletePhoneFailed
  | IDeleteBankRequest
  | IDeleteBankSuccess
  | IDeleteBankFailed
  | IDeleteVehicleRequest
  | IDeleteVehicleSuccess
  | IDeleteVehicleFailed
  | IDeleteSocialRequest
  | IDeleteSocialSuccess
  | IDeleteSocialFailed
  | IDeleteAnnotaionRequest
  | IDeleteAnnotaionSuccess
  | IDeleteAnnotaionFailed
  | IDeleteBehaviourRequest
  | IDeleteBehaviourSuccess
  | IDeleteBehaviourFailed
  | IUpdateDopaImageRequest
  | IUpdateDopaImageSuccess
  | IUpdateDopaImageFailed
  | ICreateSuspectUploadFileRequest
  | ICreateSuspectUploadFileSuccess
  | ICreateSuspectUploadFileFailed
  | ICreateSuspectUploadFileAnnotationRequest
  | ICreateSuspectUploadFileAnnotationSuccess
  | ICreateSuspectUploadFileAnnotationFailed
  | ICreateSuspectUploadFileBankRequest
  | ICreateSuspectUploadFileBankSuccess
  | ICreateSuspectUploadFileBankFailed
  | ICreateSuspectUploadFileBehaviourRequest
  | ICreateSuspectUploadFileBehaviourSuccess
  | ICreateSuspectUploadFileBehaviourFailed
  | ICreateSuspectUploadFileSocialRequest
  | ICreateSuspectUploadFileSocialSuccess
  | ICreateSuspectUploadFileSocialFailed
  | ICreateSuspectUploadFileTelephoneRequest
  | ICreateSuspectUploadFileTelephoneSuccess
  | ICreateSuspectUploadFileTelephoneFailed
  | ICreateSuspectUploadFileVehicleRequest
  | ICreateSuspectUploadFileVehicleSuccess
  | ICreateSuspectUploadFileVehicleFailed
  | IDeleteSuspectUploadFileRequest
  | IDeleteSuspectUploadFileSuccess
  | IDeleteSuspectUploadFileFailed
  | IDeleteSuspectUploadFileAnnotationRequest
  | IDeleteSuspectUploadFileAnnotationSuccess
  | IDeleteSuspectUploadFileAnnotationFailed
  | IDeleteSuspectUploadFileBankRequest
  | IDeleteSuspectUploadFileBankSuccess
  | IDeleteSuspectUploadFileBankFailed
  | IDeleteSuspectUploadFileBehaviourRequest
  | IDeleteSuspectUploadFileBehaviourSuccess
  | IDeleteSuspectUploadFileBehaviourFailed
  | IDeleteSuspectUploadFileSocialRequest
  | IDeleteSuspectUploadFileSocialSuccess
  | IDeleteSuspectUploadFileSocialFailed
  | IDeleteSuspectUploadFileTelephoneRequest
  | IDeleteSuspectUploadFileTelephoneSuccess
  | IDeleteSuspectUploadFileTelephoneFailed
  | IDeleteSuspectUploadFileVehicleRequest
  | IDeleteSuspectUploadFileVehicleSuccess
  | IDeleteSuspectUploadFileVehicleFailed
  | IDeleteSuspectUploadFileAddressRequest
  | IDeleteSuspectUploadFileAddressSuccess
  | IDeleteSuspectUploadFileAddressFailed;
