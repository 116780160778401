import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { MediumText, LargeText } from './Font';
import constants from 'shared/constants';
import { Grid } from '@material-ui/core';

const InputWrapper = styled.div`
   display: flex;
//   flex-direction: column;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;
    padding-right: 1em;
    // padding-left: 1em;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;
//   flex: 1;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
//   flex: 1;
`;

const InputError = styled(MediumText)`
  height: 24px;
  color: ${constants.RED};
  white-space: nowrap;
`;

const InputInfo = styled(MediumText)`
  color: ${constants.GREY};
`;

interface IInputLabelTemplateProps {
    label?: string;
    className?: string;
    error?: string;
    info?: string;
    required?: boolean;
}

const InputLabelTemplateShort: FC<IInputLabelTemplateProps> = ({
    label,
    className,
    error,
    info,
    required,
    children,
}) => {
    return (
        <StylesProvider injectFirst>

            <LabelWrapper>
                {required && <RequiredLabel className="RequiredLabel">*</RequiredLabel>}
                {label && <InputLabel className="InputLabel">{label}</InputLabel>}
            </LabelWrapper>

            <InputWrapper className={className}>
                {children}
                <InputError className="InputError">{error}</InputError>
                {info && <InputInfo>{info}</InputInfo>}
            </InputWrapper>

        </StylesProvider>
    );
};

export default InputLabelTemplateShort;
