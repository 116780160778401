import { SelectOption } from 'components/ui/type';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { useFetchApi } from 'shared/utils/hooks';

const useMasterVehicleListDropdownApi = () => {
  const [vehicle, isLoading, isError] = useFetchApi<IDropdown[]>('/Master/Vehicle/GetTextDropdownList', []);

  const vehicleSelectOptions: SelectOption[] = vehicle.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [vehicleSelectOptions, isLoading, isError] as const;
};

export default useMasterVehicleListDropdownApi;
