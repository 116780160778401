import { IGraph, IGraphState } from './graph.interface';

export const INITIAL_GRAPH: IGraph = {
  nodes: [],
  links: [],
};

export const INITIAL_GRAPH_STATE: IGraphState = {
  selected: {} as IGraph,
  error: undefined,
  isPageLoading: false,
};
