import { SelectOption } from "components/ui/type";

export enum ERank {
  GET_RANK_REQUEST = 'GET_RANK_REQUEST',
  GET_RANK_SUCCESS = 'GET_RANK_SUCCESS',
  GET_RANK_FAILED = 'GET_RANK_FAILED',
  CLEAR_RANK = 'CLEAR_RANK',
}

export interface IGetRankRequest {
  type: ERank.GET_RANK_REQUEST;
}

export interface IGetRankSuccess {
  type: ERank.GET_RANK_SUCCESS;
  data: SelectOption[];
}

export interface IGetRankFailed {
  type: ERank.GET_RANK_FAILED;
  error: Error;
}

export interface IClearRank {
  type: ERank.CLEAR_RANK;
}

export type TRankAction = IGetRankRequest | IGetRankSuccess | IGetRankFailed | IClearRank;
