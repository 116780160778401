import React from 'react';
import { TTableOrder } from '../type';

type TTableContext = {
  title?: string;
  columns: any;
  order?: 'asc' | 'desc' | undefined;
  orderBy?: any;
  setOrder?: any;
  setOrderBy?: any;
  page: number;
  items: object[];
  totalCount: number;
  itemsCount: number;
  itemsPerPage: number;
  itemsEmptyCount: number;
  selectedItems: string[];
  selectedItemsCount: number;
  onChangeOrder: (property: string) => void;
  spaceCount?: number;
  onGraph: any;
  onHeadline: any;
  onDownload: any;
  onUpload: any;
  onFile: any;
  onView: any;
  onEdit: any;
  onDelete: any;
  // onSelect: (event: React.ChangeEvent<HTMLInputElement>, _id: string) => void,
  // onSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  // onChangePage: (newPage: number) => void;
  // onChangeItemsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  onDeleteModal?: any;
  hidePage?: boolean;
  onDownloadFileByType: any;
};

const TableContext = React.createContext({} as TTableContext);

export default TableContext;
