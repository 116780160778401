import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useSubjectDropdownApi = () => {
  const [subjects, isLoading, isError] = useFetchApi<IDropdown[]>('/bankTracking/subject/getDropdownList', []);

  const subjectSelectOptions: SelectOption[] = subjects.map((subject) => {
    return {
      text: subject.text,
      value: subject.id,
    };
  });

  return [subjectSelectOptions, isLoading, isError] as const;
};

export default useSubjectDropdownApi;
