import UserImage from 'assets/images/user_suspect.svg';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ESuspectFormMode } from 'shared/modules/suspect';
import { imageToBase64 } from 'shared/utils/converter';
import styled from 'styled-components';
import swal from 'sweetalert';
import { EInputAccept } from './enum/image.enum';
import Image from './Image';

const ImageUploaderWrapper = styled.div``;
const FileInput = styled.input`
  display: none;
`;
const StyledImage = styled(Image)`
  cls-1 {
    fill: #88a8c5;
  }
`;

interface IImageUploaderProps {
  className?: string;
  imageBase64?: string;
  imageFile?: Blob;
  imagePath?: string;
  disabled?: boolean;
  showMiniIcon?: boolean;
  setImageBase64?(base64: string): void;
  setImageFile?(file: Blob): void;
  isDisabled?: boolean;
  viewMode?: ESuspectFormMode;
}

const ImageUploader: React.FC<IImageUploaderProps> = ({
  className,
  imageBase64,
  imageFile,
  imagePath,
  disabled = false,
  showMiniIcon = true,
  isDisabled,
  setImageBase64,
  setImageFile,
  viewMode,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  // states
  const [imageSource, setImageSource] = useState<string | undefined>(undefined);

  // use effects
  useEffect(() => {
    if (imageFile) {
      setImageSource(URL.createObjectURL(imageFile));
    } else if (imageBase64) {
      setImageSource('data:image/png;base64,' + imageBase64);
    } else if (imagePath) {
      setImageSource(imagePath);
    } else {
      setImageSource(UserImage);
    }
  }, [imageBase64, imageFile, imagePath]);

  // handlers
  const onFileInputClick = () => {
    if (!disabled && fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.item(0);

    if (!file) {
      return;
    }

    // Validate the file type
    if (!file.name.match(/\.(gif|jpe?g|tiff?|png|PNG|webp|bmp|JPG)/gm)) {
      swal({
        icon: 'error',
        title: 'ประเภทของไฟล์ไม่ถูกต้อง',
        text: 'กรุณาเลือกไฟล์ประเภทรูปภาพ',
      });

      return;
    }

    if (setImageBase64) {
      setImageBase64(await imageToBase64(file));
    }

    if (setImageFile) {
      setImageFile(file);
    }
  };

  return (
    <>
      {(viewMode === ESuspectFormMode.EDIT && !isDisabled) || viewMode === ESuspectFormMode.VIEW ? (
        <ImageUploaderWrapper className={className}>
          {/* <FileInput type="file" accept={EInputAccept.AllImages} onChange={onFileChange} ref={fileInputRef} value={''} /> */}
          <StyledImage
            src={imageSource || ''}
            viewMode={viewMode}
            isDisabled={isDisabled}
            showMiniIcon={showMiniIcon}
            onMiniIconClick={onFileInputClick}
          />
        </ImageUploaderWrapper>
      ) : (
        <ImageUploaderWrapper className={className}>
          <FileInput
            type="file"
            accept={EInputAccept.AllImages}
            onChange={onFileChange}
            ref={fileInputRef}
            value={''}
          />
          <StyledImage
            src={imageSource || ''}
            viewMode={viewMode}
            isDisabled={isDisabled}
            showMiniIcon={showMiniIcon}
            onMiniIconClick={onFileInputClick}
          />
        </ImageUploaderWrapper>
      )}
    </>
  );
};

export default ImageUploader;
