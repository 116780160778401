import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';
import { TSelect } from 'shared/types';

const useDivisionDropdownApi = (headquarterId: TSelect) => {
  const [divisions, isLoading, isError] = useFetchApi<IDropdown[]>(
    `/division/getDropdownList?headquarterId=${headquarterId}`,
    [],
    'headquarterId',
    headquarterId
  );

  const divisionSelectOptions: SelectOption[] = divisions.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [divisionSelectOptions, isLoading, isError] as const;
};

export default useDivisionDropdownApi;
