export enum ECaseFileViewMode {
  EDIT = 0,
  VIEW,
  CREATE,
}

export enum ECaseFileOfficerType {
  REPORT = 0,
  LEAD,
}
