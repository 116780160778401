import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import {
  IExportForm,
  IExportLicensePlateResponseDTO,
  ILicensePlate,
  ILicensePlateSearch,
  ILicensePlateState,
  ILicensePlateValidate,
  IPassengerValidate,
  // IDriverInfoValidate,
} from 'shared/modules/license-plate/license-plate.interface';

export const INITIAL_LICENSE_PLATE_SEARCH: ILicensePlateSearch = {
  licensePlateNo: '',
  detectedStartDate: null,
  detectedEndDate: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_LICENSE_PLATE: ILicensePlate = {
  id: '',
  licensePlateNo: '',
  licensePlateProvinceID: null,
  licensePlateProvinceName: '',
  licensePlateImgUrl: undefined,
  carTypeID: null,
  carTypeName: '',
  carBrandID: null,
  carBrandName: '',
  carSeriesID: null,
  carSeriesName: '',
  carDetail: '',
  detectedDate: null,
  ownerIDCard: '',
  ownerTitleID: null,
  ownerTitleName: '',
  ownerFirstName: '',
  ownerLastName: '',
  address: '',
  building: '',
  alley: '',
  road: '',
  provinceID: null,
  provinceName: '',
  districtID: null,
  districtName: '',
  subDistrictID: null,
  subDistrictName: '',
  postalCode: '',
  contactPhoneNumber: '',
  driverInfo: {
    idCard: '',
    titleID: null,
    titleName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  passengerInfo: [
    {
      idCard: '',
      titleID: null,
      titleName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
  ],
  latitude: null,
  longitude: null,
  checkPoint: '',
};
export const INITIAL_PASSENGER_VALIDATE: IPassengerValidate = {
  idCard: '',
  // titleID: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};
// export const INITIAL_DRIVER_VALIDATE: IDriverInfoValidate = {
//   // titleID: '',
//   firstName: '',
//   lastName: '',
//   phoneNumber: '',
// };
export const INITIAL_LICENSE_PLATE_VILIDATE: ILicensePlateValidate = {
  ownerIDCard: '',
  ownerTitleID: '',
  ownerFirstName: '',
  ownerLastName: '',
  postalCode: '',
  contactPhoneNumber: '',
  driverInfoIdCard: '',
  driverInfoFirstName: '',
  driverInfoLastName: '',
  driverInfoPhoneNumber: '',
  passengerInfo: [INITIAL_PASSENGER_VALIDATE],
};
export const INITIAL_LICENSE_PLATE_STATE: ILicensePlateState = {
  selected: {} as ILicensePlate,
  view: {} as ILicensePlate,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  search: { ...INITIAL_LICENSE_PLATE_SEARCH },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  export: {} as IExportLicensePlateResponseDTO,
};

export const INITIAL_EXPORT_FORM: IExportForm = {
  startDate: null,
  endDate: null,
  checkpoint: [],
};
