import { SelectOption } from 'components/ui/type';

const useAddressTypeDropdownApi = () => {
  const options = [
    { value: 1, text: 'ที่อยู่ตามทะเบียนบ้าน(ทร14)' },
    { value: 2, text: 'ที่อยู่ปัจจุบัน' },
    { value: 3, text: 'ที่อยู่ตามยานพาหนะ' },
    { value: 4, text: 'ที่อยู่ตามบัญชีธนาคาร' },
    { value: 5, text: 'ที่อยู่ตามข้อมูลอื่น' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useAddressTypeDropdownApi;
