import React, { FC, useMemo, useState, useEffect } from 'react';
import { IUseModal } from 'shared/utils/useModal/type';
import styled from 'styled-components';
import { Modal, HeaderText, Button, DataTable } from 'components/ui';
import { FormContainer, PageHeader } from 'components/layout';
import { Grid } from '@material-ui/core';
import { ButtonPreset } from 'components/ui/enum';
import { useTranslation } from 'react-i18next';
import { TTableColumn } from 'components/ui/type';
import { TDataTable } from 'shared/types';
import { imageToBase64 } from 'shared/utils/converter';
import _ from 'lodash';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import { EInvestigationMode, IInvestigationList } from 'shared/modules/investigation';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorage } from 'shared/enum/local-storage.type';
import { IPermission } from 'store/user';
import { useHistory } from 'react-router';
import usePermission from 'shared/utils/usePermission';
import { useSelector } from 'react-redux';
import { IReducer } from 'store';

const ModalWrapper = styled(Modal)`
  padding: 2rem;
  height: max-content;
`;

interface IProps {
  modal: IUseModal;
  onSubmit(selected: TDataTable<IInvestigationList>): void;
  columns: TTableColumn[];
  data: TDataTable<IInvestigationList>;
  dataBy: IInvestigationList[];
}

const ModalInvestigateTable: FC<IProps> = ({ modal, onSubmit, columns, data, dataBy }) => {
  const { t } = useTranslation('investigation');
  const history = useHistory();
  const [selected, setSelected] = useState<TDataTable<IInvestigationList>>(INITIAL_DATA_TABLE_TYPE);
  const [selectedChange, setSelectedChange] = useState<IInvestigationList[]>([]);
  const user = useSelector((store: IReducer) => store.user);
  const menu = 5;
  const { viewPermission, editPermission, deletePermission } = usePermission(menu, user);

  useEffect(() => {
    setSelected({ ...selected, table: dataBy });
  }, [dataBy]);

  const onView = (item) => {
    history.push(`/investigation/${item.id}`, { mode: EInvestigationMode.VIEW });
    reactLocalStorage.set(ELocalStorage.PREVIOUS_SELECTED, ELocalStorage.PREVIOUS_SELECTED + item.id);
  };

  const onCheckBox = async (item: any) => {
    const { table } = selected;
    const tableChange = selectedChange;
    if (!item.checkBox) {
      table.push(item);
      tableChange.push(item);
      setSelected({ ...selected, table: table });
      setSelectedChange(tableChange);
    } else {
      setSelected({ ...selected, table: table.filter((itemA) => itemA.id !== item.id) });
      setSelectedChange(tableChange.filter((itemA) => itemA.id !== item.id));
    }
  };

  const dataTable: IInvestigationList[] = useMemo(() => {
    const table = data.table;
    const formatTable = (table || []).map((item) => {
      return {
        ...item,
        checkBox: _.some(selected.table.filter((itemA) => itemA.id === item.id)),
        viewPermission: false,
      };
    });
    return formatTable;
  }, [data, selected]);

  return (
    <>
      <ModalWrapper visible={modal.visible} onClose={modal.onClose}>
        <PageHeader>
          <HeaderText>{t('modal.edit.title')}</HeaderText>
        </PageHeader>
        <Grid container>
          <Grid item xs={12}>
            <FormContainer></FormContainer>
            <DataTable
              columns={columns}
              dataSource={{ ...data, table: dataTable }}
              isLoading={true}
              onChangePage={() => {}}
              onChangeItemsPerPage={() => {}}
              onViewForm={onView}
              onCheckBox={onCheckBox}
              fixCheckbox={true}
              hideOnViewForm={true}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Grid item>
              <Button preset={ButtonPreset.PRIMARY} onClick={() => onSubmit(selected)}>
                {t('modal.edit.button.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ModalWrapper>
    </>
  );
};

export default ModalInvestigateTable;
