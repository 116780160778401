import { IModalRoleSettingForm, IRoleSettingAttachment, IRoleSettingForm } from "shared/modules/role-setting";
import { TDataTable } from "shared/types";

export enum ERoleSetting {
  GET_REQUEST = 'GET_REQUEST',
  GET_SUCCESS = 'GET_SUCCESS',
  GET_FAILED = 'GET_FAILED',
  CREATE_ROLE_SETTING_REQUEST = 'CREATE_ROLE_SETTING_REQUEST',
  CREATE_ROLE_SETTING_SUCCESS = 'CREATE_ROLE_SETTING_SUCCESS',
  CREATE_ROLE_SETTING_FAILED = 'CREATE_ROLE_SETTING_FAILED',
}

interface IGetRequest {
  type: ERoleSetting.GET_REQUEST;
}

interface IGetSuccess {
  type: ERoleSetting.GET_SUCCESS;
  data: TDataTable<IRoleSettingAttachment>;
}

interface IGetFailed {
  type: ERoleSetting.GET_FAILED;
  error;
}

interface ICreateRoleSettingRequest {
  type: ERoleSetting.CREATE_ROLE_SETTING_REQUEST;
}

interface ICreateRoleSettingSuccess {
  type: ERoleSetting.CREATE_ROLE_SETTING_SUCCESS;
}

interface ICreateRoleSettingFailed {
  type: ERoleSetting.CREATE_ROLE_SETTING_FAILED;
  error;
}

export type TRoleSetting =
  | IGetRequest
  | IGetSuccess
  | IGetFailed
  | ICreateRoleSettingRequest
  | ICreateRoleSettingSuccess
  | ICreateRoleSettingFailed