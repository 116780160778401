import {
  ILicensePlateSearch,
  ILicensePlate,
  ILicensePlateList,
  ILicensePlateState,
  ILicensePlateValidate,
  IPassengerValidate,
  IPassengerInfo,
} from './license-plate.interface';

import {
  INITIAL_LICENSE_PLATE_SEARCH,
  INITIAL_LICENSE_PLATE,
  INITIAL_LICENSE_PLATE_STATE,
  INITIAL_LICENSE_PLATE_VILIDATE,
  INITIAL_PASSENGER_VALIDATE,
} from './license-plate.constants';

export {
  INITIAL_LICENSE_PLATE_SEARCH,
  INITIAL_LICENSE_PLATE,
  INITIAL_LICENSE_PLATE_STATE,
  INITIAL_LICENSE_PLATE_VILIDATE,
  INITIAL_PASSENGER_VALIDATE,
};

export type {
  ILicensePlateSearch,
  IPassengerValidate,
  ILicensePlate,
  ILicensePlateList,
  ILicensePlateState,
  ILicensePlateValidate,
  IPassengerInfo,
};
