import axios from 'axios';
import config from 'shared/config/app.env';
import { setup } from 'axios-cache-adapter';

const TIME_OUT = 180_000;

export const axiosWithCache = setup({
  baseURL: config.BASE_API_URL,
  timeout: TIME_OUT,
  headers: {
    'Content-Type': 'application/json;utf-8',
    Accept: 'application/json',
  },
  cache: { maxAge: 15 * 60 * 1000 },
});

export default axios.create({
  baseURL: config.BASE_API_URL,
  timeout: TIME_OUT,
  headers: {
    'Content-Type': 'application/json;utf-8',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});
