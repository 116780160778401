import React, { FC, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableContext from './TableContext';
// import TableToolbar from './TableToolbar';
import TableHeader from './TableHeader';
import TableBody from '.';
import TableFooter from './TablePagination';
import { ETableOrder } from '../enum';
import { TTableOrder, TTableColumn } from '../type';
import styled from 'styled-components';
import { LargeText } from '../Font';
import _ from 'lodash';
import { TDataTable } from 'shared/types/data-table.type';
import { TableOrder } from '../constant/table.constant';

interface ITableProps {
  columns: TTableColumn[];
  dataSource: TDataTable<any>;
  title?: string;
  className?: string;
  spaceCount?: number;
  onGraph?: any;
  onHeadline?: any;
  onDownload?: any;
  onUpload?: any;
  onFile?: any;
  onEdit?: any;
  onView?: any;
  onDelete?: any;
  onDeleteModal?: any;
  order?: 'asc' | 'desc' | undefined;
  orderBy?: any;
  setOrder?: any;
  setOrderBy?: any;
  hidePage?: boolean;
  onDownloadFileByType?: any;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onHandleChangePage?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const DEFAULT_ITEMS_PER_PAGE = 5;

const TableWrapper = styled.div`
  display: block;
`;

const StyledTable = styled.div`
  overflow: auto;
`;

const TableFileComponent: FC<ITableProps> = ({
  columns,
  dataSource: data,
  title,
  className,
  spaceCount,
  onGraph,
  onHeadline,
  onDownload,
  onUpload,
  onFile,
  onView,
  onEdit,
  onDelete,
  onDeleteModal,
  order,
  orderBy,
  hidePage,
  setOrder,
  setOrderBy,
  onDownloadFileByType,
  onChangePage,
  onChangeItemsPerPage,
  onHandleChangePage
}) => {

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const onChangeOrder = (property) => {
    const isAsc = orderBy === property && order === TableOrder.ASCENDING;
    setOrder(isAsc ? TableOrder.DESCENDING : TableOrder.ASCENDING);
    setOrderBy(property);
  };

  // const onChangePage = (newPage) => {
  //   setPage(newPage);
  // };

  // const onChangeItemsPerPage = (event) => {
  //   setItemsPerPage(parseInt(event.target.value));
  //   setPage(0);
  // };

  // const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) setSelectedItems(items.map((item: any) => item.id));
  //   else setSelectedItems([]);
  // };

  // const onSelect = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
  //   if (event.target.checked) setSelectedItems([...selectedItems, id]);
  //   else setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== id));
  // };

  const itemsEmptyCount = data.pageSize - Math.min(data.pageSize, _.get(data, 'table.length', 0) - (data.currentPage - 1) * data.pageSize);

  return (
    <TableWrapper>
      <StyledTable>
        <TableContext.Provider
          value={{
            title: title,
            columns: columns,
            order: order,
            orderBy: orderBy,
            page: data.currentPage - 1,
            totalCount: data.totalCount,
            items: _.get(data, 'table', []),
            itemsCount: _.get(data, 'table.length', 0),
            itemsEmptyCount: itemsEmptyCount,
            itemsPerPage: itemsPerPage,
            selectedItems: selectedItems,
            selectedItemsCount: _.get(selectedItems, 'length', 0),
            onChangeOrder: onChangeOrder,
            onGraph: onGraph,
            onHeadline: onHeadline,
            onDownload: onDownload,
            onUpload: onUpload,
            onFile: onFile,
            onView: onView,
            onEdit: onEdit,
            onDelete: onDelete,
            spaceCount: spaceCount,
            // onSelect: onSelect,
            // onSelectAll: onSelectAll,
            onChangePage: onChangePage,
            onChangeItemsPerPage: onChangeItemsPerPage,
            onDeleteModal: onDeleteModal,
            hidePage: hidePage,
            onDownloadFileByType: onDownloadFileByType,
          }}
        >
          {/* <TableToolbar/> */}
          <Table className={className}>
            <TableHeader />
            <TableBody />
          </Table>
        </TableContext.Provider>
      </StyledTable>

      <TableContext.Provider
        value={{
          title: title,
          columns: columns,
          order: order,
          orderBy: orderBy,
          page: page,
          totalCount: data.totalCount,
          items: _.get(data, 'table', []),
          itemsCount: _.get(data, 'table.length', 0),
          itemsEmptyCount: itemsEmptyCount,
          itemsPerPage: itemsPerPage,
          selectedItems: selectedItems,
          selectedItemsCount: selectedItems.length,
          onChangeOrder: onChangeOrder,
          onGraph: onGraph,
          onHeadline: onHeadline,
          onDownload: onDownload,
          onUpload: onUpload,
          onFile: onFile,
          onView: onView,
          onEdit: onEdit,
          onDelete: onDelete,
          spaceCount: spaceCount,
          // onSelect: onSelect,
          // onSelectAll: onSelectAll,
          onChangePage: onChangePage,
          onChangeItemsPerPage: onChangeItemsPerPage,
          onDeleteModal: onDeleteModal,
          hidePage: hidePage,
          onDownloadFileByType: onDownloadFileByType
        }}
      >
        <TableFooter />
      </TableContext.Provider>
    </TableWrapper>
  );
};

export default TableFileComponent;
