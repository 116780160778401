import { Box, Grid, IconButton } from '@material-ui/core';
import { FormContainer, PageHeader } from 'components/layout';
import _ from 'lodash';
import React, { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EDateFormat } from 'shared/enum/date-format.enum';
import { IAttachment } from 'shared/interfaces';
import {
  EInvestigationAttachmentMode,
  IInvestigationAttachment,
  IInvestigationAttachmentForm,
  IInvestigationAttachmentPostResponse,
  IInvestigationAttachmentValidate,
  INITIAL_INVESTIGATION_ATTACHMENT_FORM,
  INITIAL_INVESTIGATION_ATTACHMENT_VALIDATE,
  IUploadFileResponseInvestigationAttachment,
} from 'shared/modules/investigation-attachment';
import { dateThaiFormat } from 'shared/utils/date-format';
import { onDateChange, onInputChange, onTextChangeHandle, validateOnBlurChange } from 'shared/utils/event-handle';
import useModal from 'shared/utils/useModal';
import { IUseModal } from 'shared/utils/useModal/type';
import { IReducer } from 'store';
import {
  createInvestigateAttachment,
  getInvestigateAttachment,
  updateInvestigateAttachment,
} from 'store/investigation-attachment/investigation-attachment.action';
import styled from 'styled-components';
import { LargeText } from '..';
import { StatusAlertWithHeader } from '../Alert';
import Button from '../Button';
import { INITIAL_COORDS } from '../constant/google-maps.constant';
import DatePicker from '../Datepicker';
import { AlertStatus, ButtonPreset } from '../enum';
import FileUploader from '../FileUploader';
import { HeaderText } from '../Font';
import Input from '../Input';
import Modal from '../Modal';
import PageLoading from '../PageLoding';
import TextArea from '../TextArea';
import TimePicker from '../Timepicker';
import { setErrorList } from './InvestigationAttachmentForn.vaildate';
import PropertyMapModal from './components/ModalMap';
import ClearIcon from '@material-ui/icons/Clear';

const ModalWrapper = styled(Modal)`
  padding: 2rem;
  overflow-y: scroll;
  // height: 80vh;
  height: max-content;
  max-height: 80vh;
`;

const ButtonWrapper = styled.div`
  // text-decoration-line: underline;
  cursor: pointer;
  // align-self: center;
  margin-top: 28px;
  display: grid;
  flex-direction: row;
  grid-template-columns: 8fr 1fr;
`;

const PropertyImageUploadWrapper = styled.div`
  width: 100%;
  // align-items: center;
  // display: flex;
  column-gap: 16px;
  display: grid;
  flex-direction: row;
  grid-template-columns: 8fr 1fr;
`;

const PropertyImageUploadpInput = styled(Input)`
  width: 100%;
`;

const EditPropertyImageUploadButton = styled(Button)`
  margin-top: 0.6rem !important;
  margin-left: 15px;
`;

const PropertyMapWrapper = styled.div`
  width: 100%;
  // align-items: center;
  // display: flex;
  column-gap: 16px;
  display: grid;
  flex-direction: row;
  grid-template-columns: 8fr 1fr;
`;

const PropertyMapInput = styled(Input)`
  width: 100%;
  height: 0px;
`;

const EditPropertyMapButton = styled(Button)`
  margin-top: 0.6rem !important;
  margin-left: 15px;
`;

const LatLongWrapper = styled.div`
  margin: 15px;
  font-size: 18px;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 40vh;
  max-width: 100%;
`;

const GridWrapper = styled(Grid)`
  align-self: center;
`;

const IconButtonWrapper = styled(IconButton)`
  height: 2.5rem;
  width: 2.5rem;
`;

interface IModalInvestigationAttachment {
  modal: IUseModal;
  formHandle?: any;
  setFormHandle?(formHandle: any): void;
  onSubmitHandle?(field?: string, data?: any): void;
  field?: string;
  viewMode: EInvestigationAttachmentMode;
  setViewMode(viewMode: EInvestigationAttachmentMode): void;
  id: string;
  setId(id: string): void;
  handleViewMode: boolean;
  historyLength: number;
}

const ModalInvestigationAttachment: FC<IModalInvestigationAttachment> = (props) => {
  const {
    modal,
    formHandle,
    setFormHandle,
    onSubmitHandle,
    field,
    viewMode,
    setViewMode,
    id,
    setId,
    handleViewMode,
    historyLength,
  } = props;
  const { t } = useTranslation('investigation_attachment');
  const dispatch = useDispatch();
  const { visible, onOpen, onClose } = useModal();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [form, setForm] = useState<IInvestigationAttachmentForm>(INITIAL_INVESTIGATION_ATTACHMENT_FORM);
  const [errorState, setErrorState] = useState<IInvestigationAttachmentValidate>(
    INITIAL_INVESTIGATION_ATTACHMENT_VALIDATE
  );
  const store = useSelector((store: IReducer) => store.investigationAttachment);

  useEffect(() => {
    setForm({
      ...INITIAL_INVESTIGATION_ATTACHMENT_FORM,
      attachmentName: '',
      latitude: null,
      longitude: null,
    });
  }, [modal]);

  useEffect(() => {
    if (id !== '') {
      dispatch(getInvestigateAttachment(id));
    }
  }, [dispatch, id, viewMode]);

  useEffect(() => {
    if (!_.isEmpty(store.selected)) {
      setForm({ ...store.selected });
    }
  }, [store]);

  const onCloseModal = () => {
    modal.onClose();
    setForm({
      ...INITIAL_INVESTIGATION_ATTACHMENT_FORM,
      attachmentName: '',
      latitude: null,
      longitude: null,
      attachmentLocation: ''
    });
    setId('');
  };

  const onImageUpload = (_event: MouseEvent) => {
    fileInputRef.current?.click();
  };

  const onAddImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: IAttachment[] = [];
    const files: FileList = event.target.files as FileList;
    Array.from(files).forEach((file: File) => {
      if (file.type.split('/')[0] !== 'image') {
        throw StatusAlertWithHeader(t('main:alert.failed'), t('modal.attachment.alert.error'), AlertStatus.Error);
      }
      fileList?.push({
        file,
        fileName: file.name,
      });
      setForm({
        ...form,
        attachmentDetails: file.name,
        newAttachments: fileList,
      });
    });
  };

  const onSubmit = () => {
    if (!validateInvestigationAttachmentForm()) {
      if (!id) dispatch(createInvestigateAttachment(form, onCallBack));
      else dispatch(updateInvestigateAttachment(id, form, onCloseModal));
    }
  };

  const onCallBack = (data: IInvestigationAttachmentPostResponse) => {
    onCloseModal();
    setForm({
      ...INITIAL_INVESTIGATION_ATTACHMENT_FORM,
      attachmentName: '',
      attachments: [],
      newAttachments: [],
    });
    if (setFormHandle) {
      const attachments: IUploadFileResponseInvestigationAttachment[] = formHandle.attachments;
      attachments.push({
        ...data.attachmentDetails,
        investigationAttachmentId: data.id,
        attachmentKey: `(image${historyLength})`,
      });
      const investigationAttachments: IInvestigationAttachment[] = formHandle.investigationAttachments;
      investigationAttachments.push({ id: data.id, attachmentKey: `(image${historyLength})` });
      setFormHandle({
        ...formHandle,
        attachments: attachments,
        investigationAttachments: investigationAttachments,
      });
    }
    if (onSubmitHandle) onSubmitHandle(field, data);
  };

  const validateInvestigationAttachmentForm = () => {
    const errorList = setErrorList(form, setErrorState);
    if (_.some(errorList) || _.some(errorState)) {
      return true;
    }
    return false;
  };

  const validateOnBlur = (field: string) => (_event) => {
    setErrorState({
      ...errorState,
      [field]: validateInvestigationAttachmentForm[field](form[field]),
    });
  };

  const onEdit = () => {
    setViewMode(EInvestigationAttachmentMode.EDIT);
  };

  const onClearMap = () => {
    setForm({
      ...form,
      place: '',
      latitude: null,
      longitude: null,
      attachmentLocation: ''
    })
  };

  return (
    <>
      <ModalWrapper visible={modal.visible} onClose={onCloseModal}>
        <PageLoading loading={store.isPageLoading} />
        <PageHeader>
          <HeaderText>
            {viewMode === EInvestigationAttachmentMode.VIEW
              ? t('modal.attachment.form.attachment_name')
              : t('modal.attachment.title')}
          </HeaderText>
        </PageHeader>
        {viewMode !== EInvestigationAttachmentMode.VIEW ? (
          <>
            <Input
              label={t('modal.attachment.form.attachment_name')}
              value={form.attachmentName}
              onChange={onInputChange('attachmentName', form, setForm)}
            />
            <PropertyImageUploadWrapper>
              <PropertyImageUploadpInput
                label={t('modal.attachment.form.attachment_detail')}
                value={form.attachmentDetails}
                onBlur={validateOnBlur('attachmentDetails')}
                error={errorState.attachmentDetails}
                required={true}
                disabled
              />
              <ButtonWrapper>
                <EditPropertyImageUploadButton
                  preset={ButtonPreset.SECONDARY}
                  onClick={onImageUpload}
                // disabled={!_.isEmpty(form.attachments) || !_.isEmpty(form.newAttachments)}
                >
                  {t('modal.attachment.button.browse')}
                </EditPropertyImageUploadButton>
              </ButtonWrapper>

              <FileUploader fileInputRef={fileInputRef} onAddFile={onAddImage} />
            </PropertyImageUploadWrapper>
            <TextArea
              label={t('modal.attachment.form.attachment_desc')}
              value={form.attachmentDesc}
              onChange={onTextChangeHandle('attachmentDesc', form, setForm)}
              minRows={5}
            />
            <PropertyMapWrapper>
              <PropertyMapInput
                label={t('modal.attachment.form.place')}
                // value={
                //   form.latitude !== null && form.longitude !== null
                //     ? form.latitude?.toFixed(6) + ' , ' + form.longitude?.toFixed(6)
                //     : t('modal.attachment.form.place')
                // }
                value={
                  (!(form.latitude === INITIAL_COORDS.lat) &&
                    !(form.longitude === INITIAL_COORDS.lng))
                    ? form.place === '' && !form.place
                      ? !form.attachmentLocation
                        ? form.latitude && form.longitude
                          ? form.latitude?.toFixed(6) + ' , ' + form.longitude?.toFixed(6)
                          : t('modal.attachment.form.place')
                        : form.latitude?.toFixed(6) + ' , ' + form.longitude?.toFixed(6)
                      : (!form.place ? form.attachmentLocation ? form.attachmentLocation : form.latitude && form.longitude ? form.latitude?.toFixed(6) + ' , ' + form.longitude?.toFixed(6) : t('modal.attachment.form.place') : form.place)
                    : t('modal.attachment.form.place')
                }
                disabled
              />
              <ButtonWrapper>
                <Grid item xs={2}>
                  <IconButtonWrapper onClick={onClearMap}>
                    <ClearIcon />
                  </IconButtonWrapper>
                </Grid>
                <EditPropertyMapButton preset={ButtonPreset.SECONDARY} onClick={onOpen}>
                  {t('modal.attachment.button.map')}
                </EditPropertyMapButton>
              </ButtonWrapper>
              <LatLongWrapper>
                {(!(form.latitude === INITIAL_COORDS.lat) &&
                  !(form.longitude === INITIAL_COORDS.lng))
                  ? form.latitude && form.longitude
                    ? form.latitude?.toFixed(6) + ' , ' + form.longitude?.toFixed(6)
                    : ''
                  : ''}
              </LatLongWrapper>
              <PropertyMapModal
                isVisible={visible}
                defaultCoords={{
                  lat: form.latitude ? form.latitude : INITIAL_COORDS.lat,
                  lng: form.longitude ? form.longitude : INITIAL_COORDS.lng,
                }}
                onCoordsChange={(coords, place) => {
                  setForm({
                    ...form,
                    latitude: coords.lat,
                    longitude: coords.lng,
                    place: place,
                  });
                }}
                defaultPlace={form.place ? form.place : form.attachmentLocation}
                onCloseModal={onClose}
              />
            </PropertyMapWrapper>
            <FormContainer column={2}>
              <DatePicker
                label={t('modal.attachment.form.attachment_date')}
                value={form.attachmentDate}
                onChange={onDateChange('attachmentDate', form, setForm)}
                disableFuture
              />
              <TimePicker
                label={t('modal.attachment.form.attachment_time')}
                value={form.attachmentTime}
                onChange={onDateChange('attachmentTime', form, setForm)}
              />
            </FormContainer>
            <Input
              label={t('modal.attachment.form.attachment_by')}
              value={form.attachmentBy}
              onChange={onInputChange('attachmentBy', form, setForm)}
            />
            <Box textAlign="right">
              <Button preset={ButtonPreset.PRIMARY} onClick={onSubmit}>
                {t('modal.attachment.button.submit')}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Grid container xs={12}>
              <GridWrapper item xs={7}>
                <Box margin={2}>
                  <ImageWrapper>
                    {!_.isNil(form.attachment) && <Image src={form.attachment.filePath} alt={'form.fileName'} />}
                  </ImageWrapper>
                </Box>
              </GridWrapper>
              <Grid item xs={5}>
                <Box margin={2}>
                  <FormContainer column={1}>
                    <LargeText>
                      {t('modal.attachment.form.attachment_name') + ' : '}
                      {form.attachmentName}
                    </LargeText>
                    <LargeText>
                      {t('modal.attachment.form.attachment_desc') + ' : '}
                      {form.attachmentDesc}
                    </LargeText>
                    <LargeText>
                      {t('modal.attachment.form.place') + ' : '}
                      {!_.isNil(form.attachmentLocation) ? form.attachmentLocation : ''}
                      {!_.isNil(form.longitude)
                        ? ' (' + form.latitude?.toFixed(6) + ', ' + form.longitude?.toFixed(6) + ')'
                        : ''}
                    </LargeText>
                    <LargeText>
                      {t('modal.attachment.form.attachment_date') + ' : '}
                      {dateThaiFormat(form.attachmentDate, EDateFormat.FullDate)}
                    </LargeText>
                    <LargeText>
                      {t('modal.attachment.form.attachment_by') + ' : '}
                      {form.attachmentBy}
                    </LargeText>
                  </FormContainer>
                </Box>
              </Grid>
            </Grid>
            <Box textAlign="right">
              {handleViewMode && (
                <Button preset={ButtonPreset.PRIMARY} onClick={onEdit}>
                  {t('modal.attachment.button.edit')}
                </Button>
              )}
            </Box>
          </>
        )}
      </ModalWrapper>
    </>
  );
};

export default ModalInvestigationAttachment;
