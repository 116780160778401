import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useHeadquarterDropdownApi = () => {
  const [headquarters, isLoading, isError] = useFetchApi<IDropdown[]>('/headquarter/getDropdownList', []);

  const headquarterSelectOptions: SelectOption[] = headquarters.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [headquarterSelectOptions, isLoading, isError] as const;
};

export default useHeadquarterDropdownApi;
