import { TableHead as Head, IconButton, TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Icon_menu from 'assets/images/icon/menu-vertical-svgrepo-com.svg';
import React, { FC } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import constants from 'shared/constants';
import { EDataTableLayout } from 'shared/enum/data-table.enum';
import styled from 'styled-components';
import { LargeText } from '../';
import { TTableColumn } from '../type';
import TableContext from './TableContext';

const TableHeaderRow = styled(TableRow)<{ disabled?: boolean; stickyposition?: number }>`
  ${(props) => (props.disabled ? `background-color: ${constants.GREY}` : `background-color: ${constants.LIGHT_BLUE}`)};
  position: sticky;
  @media (min-width: 545px) {
    top: ${(props) => (props.stickyposition !== undefined ? `${props.stickyposition}px` : '0')};
  }
  z-index: 1;
  .MuiTableCell-root {
    padding: 5px 0px 5px 3px !important;
    border: 1.8px solid #e6e6e6; /* Add border to each cell */
  }
`;

const TableHeaderColumn = styled(TableCell)<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : 'auto')};

  overflow: hidden; /* Prevent content from overflowing */
  text-overflow: ellipsis; /* Add ellipsis for overflowed content */
  white-space: nowrap; /* Prevent text from wrapping */
`;

const TableHeaderText = styled(LargeText)`
  font-weight: bold;
  text-align: center;
  @media (max-width: 1480px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const IconStatusUpload = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: -3px;
`;
const TableHeaderOrder = styled(TableSortLabel)``;

const TableHeader: FC = () => (
  <TableContext.Consumer>
    {({
      columns,
      fixCheckbox,
      disabled,
      onGraph,
      onDownload,
      onUpload,
      onFile,
      onView,
      onEdit,
      onDelete,
      onGraphDS,
      onMobile,
      onBank,
      onItem,
      onCheckBox,
      onViewForm,
      onEditForm,
      onModal,
      onSwitch,
      hideHeader,
      hideOnEditForm,
      order = 'asc',
      orderBy,
      setOrder,
      setOrderBy,
      onChangeOrder,
      stickyPosition,
      handleMenuClick,
    }) => (
      <Head>
        {!hideHeader && (
          <TableHeaderRow disabled={disabled} stickyposition={stickyPosition}>
            {onCheckBox && (
              <TableHeaderColumn width={EDataTableLayout.WIDTHSMALL}>
                <TableHeaderText>{}</TableHeaderText>
              </TableHeaderColumn>
            )}
            {columns.map((column: TTableColumn, index: number) => (
              <TableHeaderColumn key={column.key || index} align={column.align} width={column.width}>
                {column.key && orderBy && order && onChangeOrder && (
                  <TableHeaderOrder
                    active={orderBy === column.key}
                    direction={orderBy === column.key ? order : 'asc'}
                    onClick={() => onChangeOrder(column.key)}
                  >
                    <TableHeaderText>{column.title}</TableHeaderText>
                  </TableHeaderOrder>
                )}
                {!(orderBy && order) && <TableHeaderText>{column.title}</TableHeaderText>}
              </TableHeaderColumn>
            ))}
            {(onGraph ||
              onDownload ||
              onUpload ||
              onFile ||
              onView ||
              onEdit ||
              onDelete ||
              onGraphDS ||
              onMobile ||
              onBank ||
              onItem ||
              onViewForm ||
              onEditForm ||
              onModal ||
              onSwitch) && (
              <TableHeaderColumn width={EDataTableLayout.WIDTHHEADER}>
                <TableHeaderText>
                  {'จัดการ'}
                  {/* <IconStatusUpload src={Icon_menu} onClick={handleMenuClick} /> */}
                  {/* <IconButton aria-controls="column-menu" aria-haspopup="true" onClick={handleMenuClick}>
                        <MoreVertIcon />
                      </IconButton> */}
                </TableHeaderText>
              </TableHeaderColumn>
            )}
          </TableHeaderRow>
        )}
        {/* {provided.placeholder} */}
      </Head>
    )}
  </TableContext.Consumer>
);

export default TableHeader;
