import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import { ILawsuit, ILawsuitSearch, ILawsuitState, ILawsuitSearchValidate, ILawsuitValidate } from './lawsuit.interface';

export const INITIAL_LAWSUIT_SEARCH: ILawsuitSearch = {
  caseID: '',
  createdBy: '',
  divisionID: null,
  headQuarterID: null,
  notifyDateEnd: null,
  notifyDateStart: null,
  notifyNo: '',
  policeStationID: null,
  prosecuteTypeID: null,
  responsibleFullName: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  departmentID: null,
  subDivisionID: null,
  organizationID: null,
};

export const INITIAL_LAWSUIT_SEARCH_VALIDATE: ILawsuitSearchValidate = {
  createdBy: '',
  responsibleFullName: '',
};

export const INITIAL_LAWSUIT: ILawsuit = {
  id: '',
  policeStationID: null,
  divisionID: null,
  headQuarterID: null,
  organizationID: null,
  caseID: '',
  notifyDate: null,
  accusation: '',
  accuseTitleID: null,
  accuseFirstName: '',
  accuseLastName: '',
  responsibleTitleID: null,
  responsibleFirstName: '',
  responsibleLastName: '',
  exhibitAccountNo: '',
  notifyNo: '',
  petitionDate: null,
  caseStatusID: null,
  prosecuteTypeID: null,
  behavior: '',
  reason: '',
  caseTypeID: null,
  attachments: [] as IUploadFileResponse[],
  newAttachments: null,
  departmentID: null,
  subDivisionID: null,
};

export const INITIAL_LAWSUIT_VALIDATE: ILawsuitValidate = {
  policeStationID: '',
  divisionID: '',
  headQuarterID: '',
  caseID: '',
  notifyDate: '',
  accusation: '',
  accuseTitleID: '',
  accuseFirstName: '',
  accuseLastName: '',
  responsibleTitleID: '',
  responsibleFirstName: '',
  responsibleLastName: '',
  exhibitAccountNo: '',
  notifyNo: '',
  petitionDate: '',
  caseStatusID: '',
  prosecuteTypeID: '',
  behavior: '',
  reason: '',
  caseTypeID: '',
};

export const INITIAL_LAWSUIT_STATE: ILawsuitState = {
  selected: {} as ILawsuit,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};
