import {
  IAccessPermissionManagementState,
  INITIAL_ACCESS_PERMISSION_MANAGEMENT_STATE,
} from 'shared/modules/access-permission-management';
import {
  TAccessPermissionManagementActionTypes,
  EAccessPermissionManagementAction,
} from './access-permission-management.type';

export default (
  state: IAccessPermissionManagementState = INITIAL_ACCESS_PERMISSION_MANAGEMENT_STATE,
  action: TAccessPermissionManagementActionTypes
): IAccessPermissionManagementState => {
  switch (action.type) {
    case EAccessPermissionManagementAction.SEARCH_ACCESS_PERMISSION_MANAGEMENT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case EAccessPermissionManagementAction.SEARCH_ACCESS_PERMISSION_MANAGEMENT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EAccessPermissionManagementAction.SEARCH_ACCESS_PERMISSION_MANAGEMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EAccessPermissionManagementAction.UPDATE_ACCESS_PERMISSION_MANAGEMENT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EAccessPermissionManagementAction.UPDATE_ACCESS_PERMISSION_MANAGEMENT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EAccessPermissionManagementAction.UPDATE_ACCESS_PERMISSION_MANAGEMENT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
