import { SelectOption } from 'components/ui/type';

const useCarColorDropdownApi = () => {
  const options = [
    { value: 1, text: 'สีแดง' },
    { value: 2, text: 'สีน้ำเงิน' },
    { value: 3, text: 'สีเหลือง' },
    { value: 4, text: 'สีขาว' },
    { value: 5, text: 'สีดำ' },
    { value: 6, text: 'สีม่วง' },
    { value: 7, text: 'สีเขียว' },
    { value: 8, text: 'สีส้ม' },
    { value: 9, text: 'สีน้ำตาล' },
    { value: 10, text: 'สีชมพู' },
    { value: 11, text: 'สีเทา' },
    { value: 12, text: 'สีฟ้า' },
  ];

  const selectOption: SelectOption[] = options.map((element) => {
    return {
      text: element.text,
      value: element.value,
    };
  });
  return [selectOption, false, false] as const;
};
export default useCarColorDropdownApi;
