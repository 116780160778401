import UserImage from 'assets/images/user_suspect.svg';
import i18next from 'i18next';
import swal from 'sweetalert';
import { AlertStatus } from './enum/alert.enum';

export const StatusAlertWithHeader = (header: string, message: string, status: AlertStatus) => {
  return swal({
    title: header,
    text: message,
    icon: status,
  });
};

export const StatusAlert = (message: string, status: AlertStatus) => {
  return swal({
    title: message,
    icon: status,
    timer: 6000,
    buttons: {
      confirm: {
        text: i18next.t('main:button.submit', 'ยืนยัน'),
        visible: false,
      },
    },
  });
};

export const StatusAlertWithTimer = (message: string, status: AlertStatus, timer: number) => {
  return swal({
    title: message,
    icon: status,
    timer: timer,
    buttons: {
      confirm: {
        text: i18next.t('main:button.submit', 'ยืนยัน'),
        visible: false,
      },
    },
  });
};

export const StatusAlertNoTimer = (message: string, status: AlertStatus) => {
  return swal({
    title: message,
    icon: status,
    buttons: {
      confirm: {
        text: i18next.t('main:button.submit', 'ยืนยัน'),
        visible: true,
      },
    },
  });
};

export const AlertMessageWithHeader = (header: string, message: string) => {
  return swal({
    title: header,
    text: message,
  });
};

export const AlertMessage = (message: string) => {
  return swal({
    text: message,
  });
};

export const ConfirmMessage = (title: string, icon: AlertStatus, text?: string, dangerMode: boolean = true) => {
  return swal({
    title: title,
    text: text,
    icon: icon,
    dangerMode: dangerMode,
    buttons: {
      confirm: {
        text: i18next.t('main:button.submit', 'ยืนยัน'),
        value: true,
        visible: true,
      },
      cancel: {
        text: i18next.t('main:button.cancel', 'ยกเลิก'),
        value: null,
        visible: true,
      },
    },
  });
};

export const DetectScreenCapture = () => {
  return swal({
    title: 'ตรวจจับการบันทึกจอภาพ',
    text: 'ไม่อนุญาตให้บันทึกจอภาพ',
    icon: 'error',
    className: 'swalDetectScreen',
    buttons: {
      confirm: {
        text: 'ปิด',
        value: true,
        visible: false,
      },
    },
    timer: 5000,
  });
};

export const ConfirmIdentifySuspectId = () => {
  var form = document.createElement('div');
  form.innerHTML = `
    <img style="margin-bottom: 22px;" src="${UserImage}"width="100" height="100">
    <span style="font-size: 64px; color: red">!</span>
    <p style="font-size: 26px; color: red;">เลขบัตรประชาชน" นี้มีในระบบแล้ว<br>ต้องการนำ ชื่อ - สกุล เดิมที่มีในระบบมากรอกหรือไม่</p>

`;
  return swal({
    // text: "How was your experience getting help with this issue?",
    buttons: {
      confirm: {
        text: i18next.t('main:button.submit', 'ยืนยัน'),
        value: true,
        visible: true,
        className: 'swal-button--violet',
      },
      cancel: {
        text: i18next.t('main:button.cancel', 'ยกเลิก'),
        value: null,
        visible: true,
      },
    },
    content: {
      element: form,
    },
  });
};
