import { ILawsuit, ILawsuitList } from 'shared/modules/lawsuit';
import { TDataTable } from 'shared/types';

export enum ELawsuitAction {
  SEARCH_LAWSUIT_REQUEST = 'SEARCH_LAWSUIT_REQUEST',
  SEARCH_LAWSUIT_SUCCESS = 'SEARCH_LAWSUIT_SUCCESS',
  SEARCH_LAWSUIT_FAILED = 'SEARCH_LAWSUIT_FAILED',
  GET_LAWSUIT_REQUEST = 'GET_LAWSUIT_REQUEST',
  GET_LAWSUIT_SUCCESS = 'GET_LAWSUIT_SUCCESS',
  GET_LAWSUIT_FAILED = 'GET_LAWSUIT_FAILED',
  CREATE_LAWSUIT_REQUEST = 'CREATE_LAWSUIT_REQUEST',
  CREATE_LAWSUIT_SUCCESS = 'CREATE_LAWSUIT_SUCCESS',
  CREATE_LAWSUIT_FAILED = 'CREATE_LAWSUIT_FAILED',
  UPDATE_LAWSUIT_REQUEST = 'UPDATE_LAWSUIT_REQUEST',
  UPDATE_LAWSUIT_SUCCESS = 'UPDATE_LAWSUIT_SUCCESS',
  UPDATE_LAWSUIT_FAILED = 'UPDATE_LAWSUIT_FAILED',
  DELETE_LAWSUIT_REQUEST = 'DELETE_LAWSUIT_REQUEST',
  DELETE_LAWSUIT_SUCCESS = 'DELETE_LAWSUIT_SUCCESS',
  DELETE_LAWSUIT_FAILED = 'DELETE_LAWSUIT_FAILED',
  CLEAR_SELECT_LAWSUIT = 'CLEAR_SELECT_LAWSUIT',
}

interface ISearchLawsuituRequest {
  type: ELawsuitAction.SEARCH_LAWSUIT_REQUEST;
}

interface ISearchLawsuitSuccess {
  type: ELawsuitAction.SEARCH_LAWSUIT_SUCCESS;
  data: TDataTable<ILawsuitList>;
}

interface ISearchLawsuitFailed {
  type: ELawsuitAction.SEARCH_LAWSUIT_FAILED;
  error: Error;
}

interface IGetLawsuitRequest {
  type: ELawsuitAction.GET_LAWSUIT_REQUEST;
}

interface IGetLawsuitSuccess {
  type: ELawsuitAction.GET_LAWSUIT_SUCCESS;
  data: ILawsuit;
}

interface IGetLawsuitFailed {
  type: ELawsuitAction.GET_LAWSUIT_FAILED;
  error: Error;
}

interface ICreateLawsuitRequest {
  type: ELawsuitAction.CREATE_LAWSUIT_REQUEST;
}

interface ICreateLawsuitSuccess {
  type: ELawsuitAction.CREATE_LAWSUIT_SUCCESS;
}

interface ICreateLawsuitFailed {
  type: ELawsuitAction.CREATE_LAWSUIT_FAILED;
  error: Error;
}

interface IUpdateLawsuitRequest {
  type: ELawsuitAction.UPDATE_LAWSUIT_REQUEST;
}

interface IUpdateLawsuitSuccess {
  type: ELawsuitAction.UPDATE_LAWSUIT_SUCCESS;
}

interface IUpdateLawsuitFailed {
  type: ELawsuitAction.UPDATE_LAWSUIT_FAILED;
  error: Error;
}

interface IDeleteLawsuitRequest {
  type: ELawsuitAction.DELETE_LAWSUIT_REQUEST;
}

interface IDeleteLawsuitSuccess {
  type: ELawsuitAction.DELETE_LAWSUIT_SUCCESS;
}

interface IDeleteLawsuitFailed {
  type: ELawsuitAction.DELETE_LAWSUIT_FAILED;
  error: Error;
}

interface IClearSelectLawsuit {
  type: ELawsuitAction.CLEAR_SELECT_LAWSUIT;
}

export type TLawsuitActionTypes =
  | ISearchLawsuituRequest
  | ISearchLawsuitSuccess
  | ISearchLawsuitFailed
  | IGetLawsuitRequest
  | IGetLawsuitSuccess
  | IGetLawsuitFailed
  | ICreateLawsuitRequest
  | ICreateLawsuitSuccess
  | ICreateLawsuitFailed
  | IUpdateLawsuitRequest
  | IUpdateLawsuitSuccess
  | IUpdateLawsuitFailed
  | IDeleteLawsuitRequest
  | IDeleteLawsuitSuccess
  | IDeleteLawsuitFailed
  | IClearSelectLawsuit;
