import axios from 'shared/utils/axios';
import { TNotificationActionTypes, ENotification } from './notification.type';
import { INotification } from 'shared/modules/notification';
import { toQueryString } from 'shared/utils/query-string';

export const getNotificationCount = (cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: ENotification.GET_NOTIFICATION_COUNT_REQUEST });
    axios
      .get('/webNotification/count', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(getNotificationCountSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(getNotificationCountFailed(error));
      });
  };
};

export const getNotificationCountSuccess = (data: string | number, cb?: Function): TNotificationActionTypes => {
  if (cb) cb();
  return {
    type: ENotification.GET_NOTIFICATION_COUNT_SUCCESS,
    data,
  };
};

export const getNotificationCountFailed = (error: Error): TNotificationActionTypes => {
  return {
    type: ENotification.GET_NOTIFICATION_COUNT_FAILED,
    error,
  };
};

export const getNotificationList = (currentPage: number, pageSize: number, cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: ENotification.GET_NOTIFICATION_LIST_REQUEST });
    const params = toQueryString({ currentPage: currentPage, pageSize: pageSize });
    axios
      .get('/webNotification', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params,
      })
      .then((response) => {
        dispatch(getNotificationListSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(getNotificationListFailed(error));
      });
  };
};

export const getNotificationListSuccess = (data: INotification, cb?: Function): TNotificationActionTypes => {
  if (cb) cb();
  return {
    type: ENotification.GET_NOTIFICATION_LIST_SUCCESS,
    data,
  };
};

export const getNotificationListFailed = (error: Error): TNotificationActionTypes => {
  return {
    type: ENotification.GET_NOTIFICATION_LIST_FAILED,
    error,
  };
};

export const viewNotification = (id: string, cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: ENotification.VIEW_NOTIFICATION_REQUEST });
    axios
      .get(`/webNotification/view/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((_response) => {
        dispatch(viewNotificationSuccess(cb));
      })
      .catch((error) => {
        dispatch(viewNotificationFailed(error));
      });
  };
};

export const viewNotificationSuccess = (cb?: Function): TNotificationActionTypes => {
  if (cb) cb();
  return {
    type: ENotification.VIEW_NOTIFICATION_SUCCESS,
  };
};

export const viewNotificationFailed = (error: Error): TNotificationActionTypes => {
  return {
    type: ENotification.VIEW_NOTIFICATION_FAILED,
    error,
  };
};
