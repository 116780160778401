import { INotificationState, INotification } from './notification.interface';

export const INITIAL_NOTIFICATION: INotification = {
  hasNext: true,
  cuurentPage: 1,
  pageSize: 0,
  table: [],
};

export const INITIAL_NOTIFICATION_STATE: INotificationState = {
  count: 0,
  all: INITIAL_NOTIFICATION,
  error: undefined,
  isPageLoading: false,
  isButtonLoading: false,
};
