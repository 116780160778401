import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useUserStatusDropdownApi = () => {
  const [users, isLoading, isError] = useFetchApi<IDropdown[]>('/user/getUserStatusDropdownList', []);

  const userSelectOptions: SelectOption[] = users.map((user) => {
    return {
      text: user.text,
      value: user.id + 1
    };
  });
  return [userSelectOptions, isLoading, isError] as const;
};

export default useUserStatusDropdownApi;
