import { IScore } from 'shared/modules/score';

export enum EScoreAction {
  GET_SCORE_REQUEST = 'GET_SCORE_REQUEST',
  GET_SCORE_SUCCESS = 'GET_SCORE_SUCCESS',
  GET_SCORE_FAILED = 'GET_SCORE_FAILED',
}

interface IGetScoreRequest {
  type: EScoreAction.GET_SCORE_REQUEST;
}

interface IGetScoreSuccess {
  type: EScoreAction.GET_SCORE_SUCCESS;
  data: IScore;
}

interface IGetScoreFailed {
  type: EScoreAction.GET_SCORE_FAILED;
  error: Error;
}

export type TScoreActionTypes = IGetScoreRequest | IGetScoreSuccess | IGetScoreFailed;
