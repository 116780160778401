export const getUri = () => {
  var dns = window.location;

  // if (checkFrom) return dns.replace('sit.', '');
  // if (dns.origin === 'https://rtpnsb.com') {
  //   dns = 'https://rtpnsb.com';
  // } else {
  //   dns = 'https://sit.rtpnsb.com';
  // }

  dns = 'https://rtpnsb.com';
  return dns;
};

const checkFrom = () => {
  const dns = window.location;
  return dns.includes('sit') || dns.includes('localhost');
};
