import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import IconPerson from 'assets/images/menu/Ic_personal.svg';
import { Grid } from '@material-ui/core';

const StyledTabTitle = styled.li`
  list-style-type: none;
  display: inline;
  padding: 10px 15px;
  border: 1px solid #ddd;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  cursor: pointer;
  &&:not(:first-child) {
    margin-left: -1px;
  }
  &&.selected {
    border-bottom-color: #fff;
    background-color: #4b77be;
  }
`;

const StyledTabTitleButton = styled.h3`
  font-weight: bold;
  width: auto;
  margin: 0.3em;
`;

const MenuIcon = styled.img`
  border-radius: 5px;
  width: 2em;
  float: left;
`;

interface TabTitleProps {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selectedTab: number;
  src?: string | undefined;
  icon: string;
  setSelected?(selected: string): void;
  hideIcon?: boolean;
}

const TabVerticalTitle: FC<TabTitleProps> = ({
  title,
  setSelectedTab,
  index,
  selectedTab,
  icon,
  setSelected,
  hideIcon,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
    setSelected && setSelected(index + '');
  }, [setSelectedTab, index, selectedTab]);

  const isSelected = selectedTab === index ? 'selected' : '';

  return (
    <StyledTabTitle onClick={onClick} className={isSelected}>
      <Grid container>
        {!hideIcon && <MenuIcon src={icon}></MenuIcon>}
        <StyledTabTitleButton>{title}</StyledTabTitleButton>
      </Grid>
    </StyledTabTitle>
  );
};

export default TabVerticalTitle;
