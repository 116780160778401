import { ILicensePlate, ILicensePlateList, ILicensePlateSearch } from 'shared/modules/license-plate';
import { IExportLicensePlateResponseDTO } from 'shared/modules/license-plate/license-plate.interface';
import { TDataTable } from 'shared/types';

export enum ELicensePlateAction {
  SEARCH_LICENSE_PLATE_REQUEST = 'SEARCH_LICENSE_PLATE_REQUEST',
  SEARCH_LICENSE_PLATE_SUCCESS = 'SEARCH_LICENSE_PLATE_SUCCESS',
  SEARCH_LICENSE_PLATE_FAILED = 'SEARCH_LICENSE_PLATE_FAILED',
  GET_LICENSE_PLATE_REQUEST = 'GET_LICENSE_PLATE_REQUEST',
  GET_LICENSE_PLATE_SUCCESS = 'GET_LICENSE_PLATE_SUCCESS',
  GET_LICENSE_PLATE_FAILED = 'GET_LICENSE_PLATE_FAILED',
  GET_LICENSE_PLATE_CLEAR = 'GET_LICENSE_PLATE_CLEAR',
  VIEW_LICENSE_PLATE_REQUEST = 'VIEW_LICENSE_PLATE_REQUEST',
  VIEW_LICENSE_PLATE_SUCCESS = 'VIEW_LICENSE_PLATE_SUCCESS',
  VIEW_LICENSE_PLATE_FAILED = 'VIEW_LICENSE_PLATE_FAILED',
  UPDATE_LICENSE_PLATE_REQUEST = 'UPDATE_LICENSE_PLATE_REQUEST',
  UPDATE_LICENSE_PLATE_SUCCESS = 'UPDATE_LICENSE_PLATE_SUCCESS',
  UPDATE_LICENSE_PLATE_FAILED = 'UPDATE_LICENSE_PLATE_FAILED',
  DELETE_LICENSE_PLATE_REQUEST = 'DELETE_LICENSE_PLATE_REQUEST',
  DELETE_LICENSE_PLATE_SUCCESS = 'DELETE_LICENSE_PLATE_SUCCESS',
  DELETE_LICENSE_PLATE_FAILED = 'DELETE_LICENSE_PLATE_FAILED',
  CLEAR_SELECT_LICENSE_PLATE = 'CLEAR_SELECT_LICENSE_PLATE',
  SET_SEARCH_FORM = 'SET_SEARCH_FORM',
  EXPORT_LICENSE_PLATE_REQUEST = 'EXPORT_LICENSE_PLATE_REQUEST',
  EXPORT_LICENSE_PLATE_SUCCESS = 'EXPORT_LICENSE_PLATE_SUCCESS',
  EXPORT_LICENSE_PLATE_FAILED = 'EXPORT_LICENSE_PLATE_FAILED',
}

interface ISearchLicensePlateRequest {
  type: ELicensePlateAction.SEARCH_LICENSE_PLATE_REQUEST;
}

interface ISearchLicensePlateSuccess {
  type: ELicensePlateAction.SEARCH_LICENSE_PLATE_SUCCESS;
  data: TDataTable<ILicensePlateList>;
}

interface ISearchLicensePlateFailed {
  type: ELicensePlateAction.SEARCH_LICENSE_PLATE_FAILED;
  error: Error;
}

interface IGetLicensePlateRequest {
  type: ELicensePlateAction.GET_LICENSE_PLATE_REQUEST;
}

interface IGetLicensePlateSuccess {
  type: ELicensePlateAction.GET_LICENSE_PLATE_SUCCESS;
  data: ILicensePlate;
}

interface IGetLicensePlateFailed {
  type: ELicensePlateAction.GET_LICENSE_PLATE_FAILED;
  error: Error;
}

interface IViewLicensePlateRequest {
  type: ELicensePlateAction.VIEW_LICENSE_PLATE_REQUEST;
}

interface IViewLicensePlateSuccess {
  type: ELicensePlateAction.VIEW_LICENSE_PLATE_SUCCESS;
  data: ILicensePlate;
}

interface IViewLicensePlateFailed {
  type: ELicensePlateAction.VIEW_LICENSE_PLATE_FAILED;
  error: Error;
}

interface IUpdateLicensePlateRequest {
  type: ELicensePlateAction.UPDATE_LICENSE_PLATE_REQUEST;
}

interface IUpdateLicensePlateSuccess {
  type: ELicensePlateAction.UPDATE_LICENSE_PLATE_SUCCESS;
}

interface IUpdateLicensePlateFailed {
  type: ELicensePlateAction.UPDATE_LICENSE_PLATE_FAILED;
  error: Error;
}

interface IDeleteLicensePlateRequest {
  type: ELicensePlateAction.DELETE_LICENSE_PLATE_REQUEST;
}

interface IDeleteLicensePlateSuccess {
  type: ELicensePlateAction.DELETE_LICENSE_PLATE_SUCCESS;
}

interface IDeleteLicensePlateFailed {
  type: ELicensePlateAction.DELETE_LICENSE_PLATE_FAILED;
  error: Error;
}

interface IClearSelectLicensePlate {
  type: ELicensePlateAction.CLEAR_SELECT_LICENSE_PLATE;
}

interface ISetSearchForm {
  type: ELicensePlateAction.SET_SEARCH_FORM;
  data: ILicensePlateSearch;
}

interface IExportLicensePlateRequest {
  type: ELicensePlateAction.EXPORT_LICENSE_PLATE_REQUEST;
}

interface IExportLicensePlateSuccess {
  type: ELicensePlateAction.EXPORT_LICENSE_PLATE_SUCCESS;
  export: IExportLicensePlateResponseDTO;
}

interface IExportLicensePlateFailed {
  type: ELicensePlateAction.EXPORT_LICENSE_PLATE_FAILED;
  error: Error;
}

export type TLicensePlateActionTypes =
  | ISearchLicensePlateRequest
  | ISearchLicensePlateSuccess
  | ISearchLicensePlateFailed
  | IGetLicensePlateRequest
  | IGetLicensePlateSuccess
  | IGetLicensePlateFailed
  | IViewLicensePlateRequest
  | IViewLicensePlateSuccess
  | IViewLicensePlateFailed
  | IUpdateLicensePlateRequest
  | IUpdateLicensePlateSuccess
  | IUpdateLicensePlateFailed
  | IDeleteLicensePlateRequest
  | IDeleteLicensePlateSuccess
  | IDeleteLicensePlateFailed
  | IClearSelectLicensePlate
  | ISetSearchForm
  | IExportLicensePlateRequest
  | IExportLicensePlateSuccess
  | IExportLicensePlateFailed;
