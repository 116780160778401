import styled from 'styled-components';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export const RestorePageIconStyle = styled(RestorePageIcon)`
  width: auto !important;
  height: 3rem !important;
`;

export const RoleSettingPageIconStyle = styled(AccountCircleIcon)`
  width: auto !important;
  height: 3rem !important;
`;
