import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { FC } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';

const FooterWrapper = styled.div<{ open: boolean }>`
  width: 100vw;
  height: ${constants.FOOTER_HEIGHT}px;
  background-color: #0b2545;
  position: fixed;

  @media (max-width: 1000px) {
    bottom: ${(p) => (p.open ? '0' : `-${constants.FOOTER_HEIGHT}px`)};
  }
  bottom: 0;
  transition: bottom 0.3s;

  z-index: 5;
`;

interface IFooterProps {
  pageWrapperRef: React.RefObject<HTMLDivElement>;
}

const Footer: FC<IFooterProps> = ({ pageWrapperRef }) => {
  const [isFixed, setIsFixed] = React.useState(true);

  React.useEffect(() => {
    const handleScroll = () => {
      if (pageWrapperRef.current) {
        const footerHeight = constants.FOOTER_HEIGHT;
        const scrollHeight = pageWrapperRef.current.scrollHeight;
        const scrollTop = pageWrapperRef.current.scrollTop;
        const clientHeight = pageWrapperRef.current.clientHeight;
        const scrollPosition = scrollTop + clientHeight;

        if (scrollPosition > scrollHeight - footerHeight - 30) {
          setIsFixed(false);
        } else {
          setIsFixed(true);
        }
      }
    };

    const currentRef = pageWrapperRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [pageWrapperRef]);

  return <FooterWrapper open={isFixed} />;
};

export default Footer;
