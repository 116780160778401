import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useMasterBankDropdownApi = () => {
  const [banks, isLoading, isError] = useFetchApi<IDropdown[]>('/Master/BankLists/GetDropdownList', []);

  const bankSelectOptions: SelectOption[] = banks.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [bankSelectOptions, isLoading, isError] as const;
};

export default useMasterBankDropdownApi;
