import { TLawsuitActionTypes, ELawsuitAction } from 'store/lawsuit/lawsuit.type';
import { ILawsuitState, INITIAL_LAWSUIT_STATE } from 'shared/modules/lawsuit';
import { ILawsuit } from 'shared/modules/lawsuit';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';

export default (state: ILawsuitState = INITIAL_LAWSUIT_STATE, action: TLawsuitActionTypes): ILawsuitState => {
  switch (action.type) {
    case ELawsuitAction.SEARCH_LAWSUIT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case ELawsuitAction.SEARCH_LAWSUIT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ELawsuitAction.SEARCH_LAWSUIT_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ELawsuitAction.GET_LAWSUIT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELawsuitAction.GET_LAWSUIT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.GET_LAWSUIT_FAILED:
      return {
        ...state,
        selected: {} as ILawsuit,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.CREATE_LAWSUIT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELawsuitAction.CREATE_LAWSUIT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.CREATE_LAWSUIT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.UPDATE_LAWSUIT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELawsuitAction.UPDATE_LAWSUIT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.UPDATE_LAWSUIT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.DELETE_LAWSUIT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ELawsuitAction.DELETE_LAWSUIT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.DELETE_LAWSUIT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ELawsuitAction.CLEAR_SELECT_LAWSUIT:
      return {
        ...state,
        selected: {} as ILawsuit,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
