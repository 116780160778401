import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { MediumText, LargeText } from './Font';
import constants from 'shared/constants';
import Checkbox from './Checkbox';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;
  align-self: center;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
`;

const InputError = styled(MediumText)`
  height: 24px;
  color: ${constants.RED};
  white-space: nowrap;
`;

const InputInfo = styled(MediumText)`
  color: ${constants.GREY};
`;

const CheckboxWrapper = styled(Checkbox)`
`;

interface IInputTemplateProps {
  label?: string;
  className?: string;
  error?: string;
  info?: string;
  required?: boolean;
  labelCheckBox?: string;
  checked?: boolean;
  disabled?: boolean;
  onChangeCheckBox?(event: ChangeEvent<HTMLInputElement>): void;
}

const InputCheckBoxTemplate: FC<IInputTemplateProps> = ({ 
  label, 
  className, 
  error, 
  info,
  required, 
  children,
  labelCheckBox,
  checked,
  disabled,
  onChangeCheckBox,
}) => {
  return (
    <StylesProvider injectFirst>
      <InputWrapper className={className}>
        <LabelWrapper>
          {required && <RequiredLabel className="RequiredLabel">*</RequiredLabel>}
          {label && <InputLabel className="InputLabel">{label}</InputLabel>}
          <CheckboxWrapper className="Checkbox" 
            label={labelCheckBox}
            disabled={disabled}
            onChange={onChangeCheckBox}
            checked={checked}
          />
        </LabelWrapper>
        {children}
        <InputError className="InputError">{error}</InputError>
        {info && <InputInfo>{info}</InputInfo>}
      </InputWrapper>
    </StylesProvider>
  );
};

export default InputCheckBoxTemplate;
