import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';
import { TSelect } from 'shared/types';
import _ from 'lodash';

export interface IDropdownCode extends IDropdown {
  codeNo: string;
}

export interface SelectOptionCode extends SelectOption {
  code: string;
}

const useDepartmentBySectorDropdownApi = (id: TSelect) => {
  const [options, isLoading, isError] = useFetchApi<IDropdownCode[]>(
    `/master/sector/${id}/department/getDropdownList`,
    []
  );
  const selectOptions: SelectOptionCode[] = options.map((o: IDropdownCode) => {
    return {
      text: o.text,
      value: o.id,
      code: o.codeNo,
    };
  });
  return [selectOptions, isLoading, isError] as const;
};

export default useDepartmentBySectorDropdownApi;
