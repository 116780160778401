import { INotification } from 'shared/modules/notification';

export enum ENotification {
  GET_NOTIFICATION_COUNT_REQUEST = 'GET_NOTIFICATION_COUNT_REQUEST',
  GET_NOTIFICATION_COUNT_SUCCESS = 'GET_NOTIFICATION_COUNT_SUCCESS',
  GET_NOTIFICATION_COUNT_FAILED = 'GET_NOTIFICATION_COUNT_FAILED',
  GET_NOTIFICATION_LIST_REQUEST = 'GET_NOTIFICATION_LIST_REQUEST',
  GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS',
  GET_NOTIFICATION_LIST_FAILED = 'GET_NOTIFICATION_LIST_FAILED',
  VIEW_NOTIFICATION_REQUEST = 'VIEW_NOTIFICATION_REQUEST',
  VIEW_NOTIFICATION_SUCCESS = 'VIEW_NOTIFICATION_SUCCESS',
  VIEW_NOTIFICATION_FAILED = 'VIEW_NOTIFICATION_FAILED',
}

interface IGetNotificationCountRequest {
  type: ENotification.GET_NOTIFICATION_COUNT_REQUEST;
}

interface IGetNotificationCountSuccess {
  type: ENotification.GET_NOTIFICATION_COUNT_SUCCESS;
  data: string | number;
}

interface IGetNotificationCountFailed {
  type: ENotification.GET_NOTIFICATION_COUNT_FAILED;
  error: Error;
}

interface IGetNotificationListRequest {
  type: ENotification.GET_NOTIFICATION_LIST_REQUEST;
}

interface IGetNotificationListSuccess {
  type: ENotification.GET_NOTIFICATION_LIST_SUCCESS;
  data: INotification;
}

interface IGetNotificationListFailed {
  type: ENotification.GET_NOTIFICATION_LIST_FAILED;
  error: Error;
}

interface IViewNotificationRequest {
  type: ENotification.VIEW_NOTIFICATION_REQUEST;
}

interface IViewNotificationSuccess {
  type: ENotification.VIEW_NOTIFICATION_SUCCESS;
}

interface IViewNotificationFailed {
  type: ENotification.VIEW_NOTIFICATION_FAILED;
  error: Error;
}

export type TNotificationActionTypes =
  | IGetNotificationCountRequest
  | IGetNotificationCountSuccess
  | IGetNotificationCountFailed
  | IGetNotificationListRequest
  | IGetNotificationListSuccess
  | IGetNotificationListFailed
  | IViewNotificationRequest
  | IViewNotificationSuccess
  | IViewNotificationFailed;
