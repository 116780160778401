import { ICompany, ICompanyList, ICompanyCaptcha } from 'shared/modules/company';
import { ICompanySearchText } from 'shared/modules/company/company.interface';
import { TDataTable } from 'shared/types';

export enum ECompanyAction {
  SEARCH_COMPANY_REQUEST = 'SEARCH_COMPANY_REQUEST',
  SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS',
  SEARCH_COMPANY_FAILED = 'SEARCH_COMPANY_FAILED',
  SEARCH_TEXT_COMPANY_REQUEST = 'SEARCH_TEXT_COMPANY_REQUEST',
  SEARCH_TEXT_COMPANY_SUCCESS = 'SEARCH_TEXT_COMPANY_SUCCESS',
  SEARCH_TEXT_COMPANY_FAILED = 'SEARCH_TEXT_COMPANY_FAILED',
  GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILED = 'GET_COMPANY_FAILED',
  CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED',
  UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED',
  DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED',
  CLEAR_SELECT_COMPANY = 'CLEAR_SELECT_COMPANY',
  GET_COMPANY_BY_ID_CARD_REQUEST = 'GET_COMPANY_BY_ID_CARD_REQUEST',
  GET_COMPANY_BY_ID_CARD_SUCCESS = 'GET_COMPANY_BY_ID_CARD_SUCCESS',
  GET_COMPANY_BY_ID_CARD_FAILED = 'GET_COMPANY_BY_ID_CARD_FAILED',
  GET_ARREST_COMPANY_BY_ID_CARD_REQUEST = 'GET_ARREST_COMPANY_BY_ID_CARD_REQUEST',
  GET_ARREST_COMPANY_BY_ID_CARD_SUCCESS = 'GET_ARREST_COMPANY_BY_ID_CARD_SUCCESS',
  GET_ARREST_COMPANY_BY_ID_CARD_FAILED = 'GET_ARREST_COMPANY_BY_ID_CARD_FAILED',
  GET_CAPTCHA_REQUEST = 'GET_CAPTCHA_REQUEST',
  GET_CAPTCHA_SUCCESS = 'GET_CAPTCHA_SUCCESS',
  GET_CAPTCHA_FAILED = 'GET_CAPTCHA_FAILED',
  PUT_CAPTCHA_REQUEST = 'PUT_CAPTCHA_REQUEST',
  PUT_CAPTCHA_SUCCESS = 'PUT_CAPTCHA_SUCCESS',
  PUT_CAPTCHA_FAILED = 'PUT_CAPTCHA_FAILED',
}

interface ISearchCompanyRequest {
  type: ECompanyAction.SEARCH_COMPANY_REQUEST;
}

interface ISearchCompanySuccess {
  type: ECompanyAction.SEARCH_COMPANY_SUCCESS;
  data: TDataTable<ICompanyList>;
}

interface ISearchCompanyFailed {
  type: ECompanyAction.SEARCH_COMPANY_FAILED;
  error: Error;
}

interface IGetCompanyRequest {
  type: ECompanyAction.GET_COMPANY_REQUEST;
}

interface IGetCompanySuccess {
  type: ECompanyAction.GET_COMPANY_SUCCESS;
  data: ICompany;
}

interface IGetCompanyFailed {
  type: ECompanyAction.GET_COMPANY_FAILED;
  error: Error;
}

interface ISearchTextCompanyRequest {
  type: ECompanyAction.SEARCH_TEXT_COMPANY_REQUEST;
}

interface ISearchTextCompanySuccess {
  type: ECompanyAction.SEARCH_TEXT_COMPANY_SUCCESS;
  data: ICompanySearchText;
}

interface ISearchTextCompanyFailed {
  type: ECompanyAction.SEARCH_TEXT_COMPANY_FAILED;
  error: Error;
}
interface ICreateCompanyRequest {
  type: ECompanyAction.CREATE_COMPANY_REQUEST;
}

interface ICreateCompanySuccess {
  type: ECompanyAction.CREATE_COMPANY_SUCCESS;
}

interface ICreateCompanyFailed {
  type: ECompanyAction.CREATE_COMPANY_FAILED;
  error: Error;
}

interface IUpdateCompanyRequest {
  type: ECompanyAction.UPDATE_COMPANY_REQUEST;
}

interface IUpdateCompanySuccess {
  type: ECompanyAction.UPDATE_COMPANY_SUCCESS;
}

interface IUpdateCompanyFailed {
  type: ECompanyAction.UPDATE_COMPANY_FAILED;
  error: Error;
}

interface IDeleteCompanyRequest {
  type: ECompanyAction.DELETE_COMPANY_REQUEST;
}

interface IDeleteCompanySuccess {
  type: ECompanyAction.DELETE_COMPANY_SUCCESS;
}

interface IDeleteCompanyFailed {
  type: ECompanyAction.DELETE_COMPANY_FAILED;
  error: Error;
}

interface IClearSelectCompany {
  type: ECompanyAction.CLEAR_SELECT_COMPANY;
}

interface IGetCompanyByIdCardRequest {
  type: ECompanyAction.GET_COMPANY_BY_ID_CARD_REQUEST;
}

interface IGetCompanyByIdCardSuccess {
  type: ECompanyAction.GET_COMPANY_BY_ID_CARD_SUCCESS;
  data: ICompany;
}

interface IGetCompanyByIdCardFailed {
  type: ECompanyAction.GET_COMPANY_BY_ID_CARD_FAILED;
  error: Error;
}

interface IGetArrestCompanyByIdCardRequest {
  type: ECompanyAction.GET_ARREST_COMPANY_BY_ID_CARD_REQUEST;
}

interface IGetArrestCompanyByIdCardFailed {
  type: ECompanyAction.GET_ARREST_COMPANY_BY_ID_CARD_FAILED;
  error: Error;
}

interface IGetCaptchaRequest {
  type: ECompanyAction.GET_CAPTCHA_REQUEST;
}

interface IGetCaptchaSuccess {
  type: ECompanyAction.GET_CAPTCHA_SUCCESS;
  data: ICompanyCaptcha;
}

interface IGetCaptchaFailed {
  type: ECompanyAction.GET_CAPTCHA_FAILED;
  error: Error;
}

interface IPutCaptchaRequest {
  type: ECompanyAction.PUT_CAPTCHA_REQUEST;
}

interface IPutCaptchaSuccess {
  type: ECompanyAction.PUT_CAPTCHA_SUCCESS;
}

interface IPutCaptchaFailed {
  type: ECompanyAction.PUT_CAPTCHA_FAILED;
  error: Error;
}

export type TCompanyActionTypes =
  | ISearchCompanyRequest
  | ISearchCompanySuccess
  | ISearchCompanyFailed
  | IGetCompanyRequest
  | IGetCompanySuccess
  | IGetCompanyFailed
  | ICreateCompanyRequest
  | ICreateCompanySuccess
  | ICreateCompanyFailed
  | IUpdateCompanyRequest
  | IUpdateCompanySuccess
  | IUpdateCompanyFailed
  | IDeleteCompanyRequest
  | IDeleteCompanySuccess
  | IDeleteCompanyFailed
  | IClearSelectCompany
  | IGetCompanyByIdCardRequest
  | IGetCompanyByIdCardSuccess
  | IGetCompanyByIdCardFailed
  | IGetArrestCompanyByIdCardRequest
  | IGetArrestCompanyByIdCardFailed
  | ISearchTextCompanyRequest
  | ISearchTextCompanySuccess
  | ISearchTextCompanyFailed
  | IGetCaptchaRequest
  | IGetCaptchaSuccess
  | IGetCaptchaFailed
  | IPutCaptchaRequest
  | IPutCaptchaSuccess
  | IPutCaptchaFailed;
