import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption, SelectOptionKey } from 'components/ui/type';
import _ from 'lodash';

interface ILprCheckPointDropdown {
  checkPointID: string;
  checkPoint: string;
  latitude: number;
  longitude: number;
}

const useLprCheckpointDropdownApi = () => {
  const [options, isLoading, isError] = useFetchApi<ILprCheckPointDropdown[]>('/LprCheckPoint', []);

  // const options = [
  //   { value: 1, text: '43I_บ้านม่วง', key: '43I_บ้านม่วง' },
  //   { value: 2, text: '44I_บ้านม่วง', key: '44I_บ้านม่วง' },
  //   { value: 3, text: '45I_บ้านม่วง', key: '45I_บ้านม่วง' },
  // ];

  const lprCheckpointSelectOptions: SelectOptionKey[] = options.map((item, index) => {
    return {
      text: item.checkPoint,
      value: index,
      key: item.checkPointID,
    };
  });
  return [lprCheckpointSelectOptions, isLoading, isError] as const;
};
export default useLprCheckpointDropdownApi;
