import { IGraph } from 'shared/modules/graph';

export enum EGraphAction {
  GET_GRAPH_REQUEST = 'GET_GRAPH_REQUEST',
  GET_GRAPH_SUCCESS = 'GET_GRAPH_SUCCESS',
  GET_GRAPH_FAILED = 'GET_GRAPH_FAILED',
  POST_LOG_GRAPH_REQUEST = 'POST_LOG_GRAPH_REQUEST',
  POST_LOG_GRAPH_SUCCESS = 'POST_LOG_GRAPH_SUCCESS',
  POST_LOG_GRAPH_FAILED = 'POST_LOG_GRAPH_FAILED',
}

interface IGetGraphRequest {
  type: EGraphAction.GET_GRAPH_REQUEST;
}

interface IGetGraphSuccess {
  type: EGraphAction.GET_GRAPH_SUCCESS;
  data: IGraph;
}

interface IGetGraphFailed {
  type: EGraphAction.GET_GRAPH_FAILED;
  error: Error;
}

interface IPostLogGraphRequest {
  type: EGraphAction.POST_LOG_GRAPH_REQUEST;
}

interface IPostLogGraphSuccess {
  type: EGraphAction.POST_LOG_GRAPH_SUCCESS;
  data: IGraph;
}

interface IPostLogGraphFailed {
  type: EGraphAction.POST_LOG_GRAPH_FAILED;
  error: Error;
}

export type TGraphActionTypes =
  | IGetGraphRequest
  | IGetGraphSuccess
  | IGetGraphFailed
  | IPostLogGraphRequest
  | IPostLogGraphSuccess
  | IPostLogGraphFailed;
