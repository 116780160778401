import {
  IGlobalSearchAddressAttachment,
  IGlobalSearchAllReportAttachment,
  IGlobalSearchArrestAttachment,
  IGlobalSearchBankAttachment,
  IGlobalSearchBankUploadAttachment,
  IGlobalSearchCircumstanceAttachment,
  IGlobalSearchConfiscateAttachment,
  IGlobalSearchFormRequestBankAttachment,
  IGlobalSearchFormRequestPhoneAttachment,
  IGlobalSearchInvestigateAttachment,
  IGlobalSearchInvestigationReportAttachment,
  IGlobalSearchPhoneAttachment,
  IGlobalSearchPhoneUploadAttachment,
  IGlobalSearchRemarkAttachment,
  IGlobalSearchReportAttachment,
  IGlobalSearchSearchingReportAttachment,
  IGlobalSearchSocialAttachment,
  IGlobalSearchSuspectAttachment,
  IGlobalSearchVehicleAttachment,
  ISuspectEngineAttachment,
} from 'shared/modules/suspect-engine';
import { TDataTable } from 'shared/types';

export enum ESuspectEngine {
  GLOBAL_SEARCH_REQUEST = 'GLOBAL_SEARCH_REQUEST',
  GLOBAL_SEARCH_SUCCESS = 'GLOBAL_SEARCH_SUCCESS',
  GLOBAL_SEARCH_FAILED = 'GLOBAL_SEARCH_FAILED',
  GLOBAL_SEARCH_SUSPECT_REQUEST = 'GLOBAL_SEARCH_SUSPECT_REQUEST',
  GLOBAL_SEARCH_SUSPECT_SUCCESS = 'GLOBAL_SEARCH_SUSPECT_SUCCESS',
  GLOBAL_SEARCH_SUSPECT_FAILED = 'GLOBAL_SEARCH_SUSPECT_FAILED',
  GLOBAL_SEARCH_ADDRESS_REQUEST = 'GLOBAL_SEARCH_ADDRESS_REQUEST',
  GLOBAL_SEARCH_ADDRESS_SUCCESS = 'GLOBAL_SEARCH_ADDRESS_SUCCESS',
  GLOBAL_SEARCH_ADDRESS_FAILED = 'GLOBAL_SEARCH_ADDRESS_FAILED',
  GLOBAL_SEARCH_PHONE_REQUEST = 'GLOBAL_SEARCH_PHONE_REQUEST',
  GLOBAL_SEARCH_PHONE_SUCCESS = 'GLOBAL_SEARCH_PHONE_SUCCESS',
  GLOBAL_SEARCH_PHONE_FAILED = 'GLOBAL_SEARCH_PHONE_FAILED',
  GLOBAL_SEARCH_BANK_REQUEST = 'GLOBAL_SEARCH_BANK_REQUEST',
  GLOBAL_SEARCH_BANK_SUCCESS = 'GLOBAL_SEARCH_BANK_SUCCESS',
  GLOBAL_SEARCH_BANK_FAILED = 'GLOBAL_SEARCH_BANK_FAILED',
  GLOBAL_SEARCH_VEHICLE_REQUEST = 'GLOBAL_SEARCH_VEHICLE_REQUEST',
  GLOBAL_SEARCH_VEHICLE_SUCCESS = 'GLOBAL_SEARCH_VEHICLE_SUCCESS',
  GLOBAL_SEARCH_VEHICLE_FAILED = 'GLOBAL_SEARCH_VEHICLE_FAILED',
  GLOBAL_SEARCH_SOCIAL_REQUEST = 'GLOBAL_SEARCH_SOCIAL_REQUEST',
  GLOBAL_SEARCH_SOCIAL_SUCCESS = 'GLOBAL_SEARCH_SOCIAL_SUCCESS',
  GLOBAL_SEARCH_SOCIAL_FAILED = 'GLOBAL_SEARCH_SOCIAL_FAILED',
  GLOBAL_SEARCH_REMARK_REQUEST = 'GLOBAL_SEARCH_REMARK_REQUEST',
  GLOBAL_SEARCH_REMARK_SUCCESS = 'GLOBAL_SEARCH_REMARK_SUCCESS',
  GLOBAL_SEARCH_REMARK_FAILED = 'GLOBAL_SEARCH_REMARK_FAILED',
  GLOBAL_SEARCH_CIRCUMSTANCE_REQUEST = 'GLOBAL_SEARCH_CIRCUMSTANCE_REQUEST',
  GLOBAL_SEARCH_CIRCUMSTANCE_SUCCESS = 'GLOBAL_SEARCH_CIRCUMSTANCE_SUCCESS',
  GLOBAL_SEARCH_CIRCUMSTANCE_FAILED = 'GLOBAL_SEARCH_CIRCUMSTANCE_FAILED',
  GLOBAL_SEARCH_INVESTIGATE_REQUEST = 'GLOBAL_SEARCH_INVESTIGATE_REQUEST',
  GLOBAL_SEARCH_INVESTIGATE_SUCCESS = 'GLOBAL_SEARCH_INVESTIGATE_SUCCESS',
  GLOBAL_SEARCH_INVESTIGATE_FAILED = 'GLOBAL_SEARCH_INVESTIGATE_FAILED',
  GLOBAL_SEARCH_REPORT_REQUEST = 'GLOBAL_SEARCH_REPORT_REQUEST',
  GLOBAL_SEARCH_REPORT_SUCCESS = 'GLOBAL_SEARCH_REPORT_SUCCESS',
  GLOBAL_SEARCH_REPORT_FAILED = 'GLOBAL_SEARCH_REPORT_FAILED',
  GLOBAL_ALL_REPORT_REQUEST = 'GLOBAL_ALL_REPORT_REQUEST',
  GLOBAL_ALL_REPORT_SUCCESS = 'GLOBAL_ALL_REPORT_SUCCESS',
  GLOBAL_ALL_REPORT_FAILED = 'GLOBAL_ALL_REPORT_FAILED',
  GLOBAL_SEARCH_INVESTIGATION_REPORT_REQUEST = 'GLOBAL_SEARCH_INVESTIGATION_REPORT_REQUEST',
  GLOBAL_SEARCH_INVESTIGATION_REPORT_SUCCESS = 'GLOBAL_SEARCH_INVESTIGATION_REPORT_SUCCESS',
  GLOBAL_SEARCH_INVESTIGATION_REPORT_FAILED = 'GLOBAL_SEARCH_INVESTIGATION_REPORT_FAILED',
  GLOBAL_SEARCH_ARREST_REQUEST = 'GLOBAL_SEARCH_ARREST_REQUEST',
  GLOBAL_SEARCH_ARREST_SUCCESS = 'GLOBAL_SEARCH_ARREST_SUCCESS',
  GLOBAL_SEARCH_ARREST_FAILED = 'GLOBAL_SEARCH_ARREST_FAILED',
  GLOBAL_SEARCH_SEARCHING_REPORT_REQUEST = 'GLOBAL_SEARCH_SEARCHING_REPORT_REQUEST',
  GLOBAL_SEARCH_SEARCHING_REPORT_SUCCESS = 'GLOBAL_SEARCH_SEARCHING_REPORT_SUCCESS',
  GLOBAL_SEARCH_SEARCHING_REPORT_FAILED = 'GLOBAL_SEARCH_SEARCHING_REPORT_FAILED',
  GLOBAL_SEARCH_CONFISCATE_REQUEST = 'GLOBAL_SEARCH_CONFISCATE_REQUEST',
  GLOBAL_SEARCH_CONFISCATE_SUCCESS = 'GLOBAL_SEARCH_CONFISCATE_SUCCESS',
  GLOBAL_SEARCH_CONFISCATE_FAILED = 'GLOBAL_SEARCH_CONFISCATE_FAILED',
  GLOBAL_SEARCH_PHONE_UPLOAD_REQUEST = 'GLOBAL_SEARCH_PHONE_UPLOAD_REQUEST',
  GLOBAL_SEARCH_PHONE_UPLOAD_SUCCESS = 'GLOBAL_SEARCH_PHONE_UPLOAD_SUCCESS',
  GLOBAL_SEARCH_PHONE_UPLOAD_FAILED = 'GLOBAL_SEARCH_PHONE_UPLOAD_FAILED',
  GLOBAL_SEARCH_BANK_UPLOAD_REQUEST = 'GLOBAL_SEARCH_BANK_UPLOAD_REQUEST',
  GLOBAL_SEARCH_BANK_UPLOAD_SUCCESS = 'GLOBAL_SEARCH_BANK_UPLOAD_SUCCESS',
  GLOBAL_SEARCH_BANK_UPLOAD_FAILED = 'GLOBAL_SEARCH_BANK_UPLOAD_FAILED',
  GLOBAL_SEARCH_FORM_REQUEST_PHONE_REQUEST = 'GLOBAL_SEARCH_FORM_REQUEST_PHONE_REQUEST',
  GLOBAL_SEARCH_FORM_REQUEST_PHONE_SUCCESS = 'GLOBAL_SEARCH_FORM_REQUEST_PHONE_SUCCESS',
  GLOBAL_SEARCH_FORM_REQUEST_PHONE_FAILED = 'GLOBAL_SEARCH_FORM_REQUEST_PHONE_FAILED',
  GLOBAL_SEARCH_FORM_REQUEST_BANK_REQUEST = 'GLOBAL_SEARCH_FORM_REQUEST_BANK_REQUEST',
  GLOBAL_SEARCH_FORM_REQUEST_BANK_SUCCESS = 'GLOBAL_SEARCH_FORM_REQUEST_BANK_SUCCESS',
  GLOBAL_SEARCH_FORM_REQUEST_BANK_FAILED = 'GLOBAL_SEARCH_FORM_REQUEST_BANK_FAILED',
}

interface IGlobalSearchRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_REQUEST;
}

interface IGlobalSearchSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_SUCCESS;
  data: TDataTable<ISuspectEngineAttachment>;
}

interface IGlobalSearchFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_FAILED;
  error;
}

interface IGlobalSearchSuspectRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_SUSPECT_REQUEST;
}

interface IGlobalSearchSuspectSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_SUSPECT_SUCCESS;
  data: TDataTable<IGlobalSearchSuspectAttachment>;
}

interface IGlobalSearchSuspectFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_SUSPECT_FAILED;
  error;
}

interface IGlobalSearchAddressRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_ADDRESS_REQUEST;
}

interface IGlobalSearchAddressSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_ADDRESS_SUCCESS;
  data: TDataTable<IGlobalSearchAddressAttachment>;
}

interface IGlobalSearchAddressFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_ADDRESS_FAILED;
  error;
}

interface IGlobalSearchPhoneRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_PHONE_REQUEST;
}

interface IGlobalSearchPhoneSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_PHONE_SUCCESS;
  data: TDataTable<IGlobalSearchPhoneAttachment>;
}

interface IGlobalSearchPhoneFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_PHONE_FAILED;
  error;
}

interface IGlobalSearchBankRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_BANK_REQUEST;
}

interface IGlobalSearchBankSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_BANK_SUCCESS;
  data: TDataTable<IGlobalSearchBankAttachment>;
}

interface IGlobalSearchBankFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_BANK_FAILED;
  error;
}

interface IGlobalSearchVehicleRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_VEHICLE_REQUEST;
}

interface IGlobalSearchVehicleSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_VEHICLE_SUCCESS;
  data: TDataTable<IGlobalSearchVehicleAttachment>;
}

interface IGlobalSearchVehicleFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_VEHICLE_FAILED;
  error;
}

interface IGlobalSearchSocialRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_SOCIAL_REQUEST;
}

interface IGlobalSearchSocialSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_SOCIAL_SUCCESS;
  data: TDataTable<IGlobalSearchSocialAttachment>;
}

interface IGlobalSearchSocialFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_SOCIAL_FAILED;
  error;
}

interface IGlobalSearchRemarkRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_REMARK_REQUEST;
}

interface IGlobalSearchRemarkSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_REMARK_SUCCESS;
  data: TDataTable<IGlobalSearchRemarkAttachment>;
}

interface IGlobalSearchRemarkFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_REMARK_FAILED;
  error;
}

interface IGlobalSearchCircumstanceRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_CIRCUMSTANCE_REQUEST;
}

interface IGlobalSearchCircumstanceSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_CIRCUMSTANCE_SUCCESS;
  data: TDataTable<IGlobalSearchCircumstanceAttachment>;
}

interface IGlobalSearchCircumstanceFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_CIRCUMSTANCE_FAILED;
  error;
}

interface IGlobalSearchInvestigateRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_INVESTIGATE_REQUEST;
}

interface IGlobalSearchInvestigateSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_INVESTIGATE_SUCCESS;
  data: TDataTable<IGlobalSearchInvestigateAttachment>;
}

interface IGlobalSearchInvestigateFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_INVESTIGATE_FAILED;
  error;
}

interface IGlobalSearchReportRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_REPORT_REQUEST;
}

interface IGlobalSearchReportSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_REPORT_SUCCESS;
  data: TDataTable<IGlobalSearchReportAttachment>;
}

interface IGlobalSearchReportFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_REPORT_FAILED;
  error;
}

interface IGlobalAllReportRequest {
  type: ESuspectEngine.GLOBAL_ALL_REPORT_REQUEST;
}

interface IGlobalAllReportSuccess {
  type: ESuspectEngine.GLOBAL_ALL_REPORT_SUCCESS;
  data: TDataTable<IGlobalSearchAllReportAttachment>;
}

interface IGlobalAllReportFailed {
  type: ESuspectEngine.GLOBAL_ALL_REPORT_FAILED;
  error;
}

interface IGlobalSearchInvestigationReportRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_INVESTIGATION_REPORT_REQUEST;
}

interface IGlobalSearchInvestigationReportSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_INVESTIGATION_REPORT_SUCCESS;
  data: TDataTable<IGlobalSearchInvestigationReportAttachment>;
}

interface IGlobalSearchInvestigationReportFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_INVESTIGATION_REPORT_FAILED;
  error;
}

interface IGlobalSearchArrestRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_ARREST_REQUEST;
}

interface IGlobalSearchArrestSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_ARREST_SUCCESS;
  data: TDataTable<IGlobalSearchArrestAttachment>;
}

interface IGlobalSearchArrestFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_ARREST_FAILED;
  error;
}

interface IGlobalSearchSearchingReportRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_SEARCHING_REPORT_REQUEST;
}

interface IGlobalSearchSearchingReportSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_SEARCHING_REPORT_SUCCESS;
  data: TDataTable<IGlobalSearchSearchingReportAttachment>;
}

interface IGlobalSearchSearchingReportFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_SEARCHING_REPORT_FAILED;
  error;
}

interface IGlobalSearchConfiscateRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_CONFISCATE_REQUEST;
}

interface IGlobalSearchConfiscateSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_CONFISCATE_SUCCESS;
  data: TDataTable<IGlobalSearchConfiscateAttachment>;
}

interface IGlobalSearchConfiscateFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_CONFISCATE_FAILED;
  error;
}

interface IGlobalSearchPhoneUploadRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_PHONE_UPLOAD_REQUEST;
}

interface IGlobalSearchPhoneUploadSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_PHONE_UPLOAD_SUCCESS;
  data: TDataTable<IGlobalSearchPhoneUploadAttachment>;
}

interface IGlobalSearchPhoneUploadFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_PHONE_UPLOAD_FAILED;
  error;
}

interface IGlobalSearchBankUploadRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_BANK_UPLOAD_REQUEST;
}

interface IGlobalSearchBankUploadSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_BANK_UPLOAD_SUCCESS;
  data: TDataTable<IGlobalSearchBankUploadAttachment>;
}

interface IGlobalSearchBankUploadFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_BANK_UPLOAD_FAILED;
  error;
}

interface IGlobalSearchFormRequestPhoneRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_PHONE_REQUEST;
}

interface IGlobalSearchFormRequestPhoneSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_PHONE_SUCCESS;
  data: TDataTable<IGlobalSearchFormRequestPhoneAttachment>;
}

interface IGlobalSearchFormRequestPhoneFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_PHONE_FAILED;
  error;
}

interface IGlobalSearchFormRequestBankRequest {
  type: ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_BANK_REQUEST;
}

interface IGlobalSearchFormRequestBankSuccess {
  type: ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_BANK_SUCCESS;
  data: TDataTable<IGlobalSearchFormRequestBankAttachment>;
}

interface IGlobalSearchFormRequestBankFailed {
  type: ESuspectEngine.GLOBAL_SEARCH_FORM_REQUEST_BANK_FAILED;
  error;
}

export type TSuspectEngine =
  | IGlobalSearchRequest
  | IGlobalSearchSuccess
  | IGlobalSearchFailed
  | IGlobalSearchSuspectRequest
  | IGlobalSearchSuspectSuccess
  | IGlobalSearchSuspectFailed
  | IGlobalSearchAddressRequest
  | IGlobalSearchAddressSuccess
  | IGlobalSearchAddressFailed
  | IGlobalSearchPhoneRequest
  | IGlobalSearchPhoneSuccess
  | IGlobalSearchPhoneFailed
  | IGlobalSearchBankRequest
  | IGlobalSearchBankSuccess
  | IGlobalSearchBankFailed
  | IGlobalSearchVehicleRequest
  | IGlobalSearchVehicleSuccess
  | IGlobalSearchVehicleFailed
  | IGlobalSearchSocialRequest
  | IGlobalSearchSocialSuccess
  | IGlobalSearchSocialFailed
  | IGlobalSearchRemarkRequest
  | IGlobalSearchRemarkSuccess
  | IGlobalSearchRemarkFailed
  | IGlobalSearchCircumstanceRequest
  | IGlobalSearchCircumstanceSuccess
  | IGlobalSearchCircumstanceFailed
  | IGlobalSearchInvestigateRequest
  | IGlobalSearchInvestigateSuccess
  | IGlobalSearchInvestigateFailed
  | IGlobalSearchReportRequest
  | IGlobalSearchReportSuccess
  | IGlobalSearchReportFailed
  | IGlobalAllReportRequest
  | IGlobalAllReportSuccess
  | IGlobalAllReportFailed
  | IGlobalSearchInvestigationReportRequest
  | IGlobalSearchInvestigationReportSuccess
  | IGlobalSearchInvestigationReportFailed
  | IGlobalSearchArrestRequest
  | IGlobalSearchArrestSuccess
  | IGlobalSearchArrestFailed
  | IGlobalSearchSearchingReportRequest
  | IGlobalSearchSearchingReportSuccess
  | IGlobalSearchSearchingReportFailed
  | IGlobalSearchConfiscateRequest
  | IGlobalSearchConfiscateSuccess
  | IGlobalSearchConfiscateFailed
  | IGlobalSearchPhoneUploadRequest
  | IGlobalSearchPhoneUploadSuccess
  | IGlobalSearchPhoneUploadFailed
  | IGlobalSearchBankUploadRequest
  | IGlobalSearchBankUploadSuccess
  | IGlobalSearchBankUploadFailed
  | IGlobalSearchFormRequestPhoneRequest
  | IGlobalSearchFormRequestPhoneSuccess
  | IGlobalSearchFormRequestPhoneFailed
  | IGlobalSearchFormRequestBankRequest
  | IGlobalSearchFormRequestBankSuccess
  | IGlobalSearchFormRequestBankFailed
  ;
