import { TCompanyActionTypes, ECompanyAction } from 'store/company/company.type';
import { ICompany, ICompanyState, INITIAL_COMPANY_STATE } from 'shared/modules/company';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';

export default (state: ICompanyState = INITIAL_COMPANY_STATE, action: TCompanyActionTypes): ICompanyState => {
  switch (action.type) {
    case ECompanyAction.SEARCH_COMPANY_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
        onSearchLoading: false,
      };
    case ECompanyAction.SEARCH_COMPANY_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
        onSearchLoading: action.data.totalCount === 0 ? true : false,
      };
    case ECompanyAction.SEARCH_COMPANY_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
        onSearchLoading: false,
      };
    case ECompanyAction.GET_COMPANY_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECompanyAction.GET_COMPANY_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.GET_COMPANY_FAILED:
      return {
        ...state,
        selected: {} as ICompany,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECompanyAction.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.CREATE_COMPANY_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECompanyAction.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.UPDATE_COMPANY_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECompanyAction.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.DELETE_COMPANY_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.GET_COMPANY_BY_ID_CARD_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECompanyAction.GET_COMPANY_BY_ID_CARD_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.GET_COMPANY_BY_ID_CARD_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.GET_ARREST_COMPANY_BY_ID_CARD_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ECompanyAction.GET_ARREST_COMPANY_BY_ID_CARD_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ECompanyAction.GET_CAPTCHA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ECompanyAction.GET_CAPTCHA_SUCCESS:
      return {
        ...state,
        captcha: action.data,
        isPageLoading: false,
      };
    case ECompanyAction.GET_CAPTCHA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case ECompanyAction.PUT_CAPTCHA_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ECompanyAction.PUT_CAPTCHA_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ECompanyAction.PUT_CAPTCHA_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case ECompanyAction.CLEAR_SELECT_COMPANY:
      return {
        ...state,
        selected: {} as ICompany,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
