import { IOfficerLead, IOfficerReportList } from 'shared/modules/officer';

export enum EOfficer {
  GET_OFFICER_HEADER_REQUEST = 'GET_OFFICER_HEADER_REQUEST',
  GET_OFFICER_HEADER_SUCCESS = 'GET_OFFICER_HEADER_SUCCESS',
  GET_OFFICER_HEADER_FAILED = 'GET_OFFICER_HEADER_FAILED',
  GET_OFFICER_HEADER_CONFISCATE_REQUEST = 'GET_OFFICER_CONFISCATE_HEADER_REQUEST',
  GET_OFFICER_HEADER_CONFISCATE_SUCCESS = 'GET_OFFICER_CONFISCATE_HEADER_SUCCESS',
  GET_OFFICER_HEADER_CONFISCATE_FAILED = 'GET_OFFICER_CONFISCATE_HEADER_FAILED',
  GET_OFFICER_BY_TEAM_REQUEST = 'GET_OFFICER_BY_TEAM_REQUEST',
  GET_OFFICER_BY_TEAM_SUCCESS = 'GET_OFFICER_BY_TEAM_SUCCESS',
  GET_OFFICER_BY_TEAM_FAILED = 'GET_OFFICER_BY_TEAM_FAILED',
}

interface IGetOfficerHeaderRequest {
  type: EOfficer.GET_OFFICER_HEADER_REQUEST;
}

interface IGetOfficerHeaderSuccess {
  type: EOfficer.GET_OFFICER_HEADER_SUCCESS;
  data: IOfficerLead[];
}

interface IGetOfficerHeaderFailed {
  type: EOfficer.GET_OFFICER_HEADER_FAILED;
  error: Error;
}
interface IGetOfficerConfiscateHeaderRequest {
  type: EOfficer.GET_OFFICER_HEADER_CONFISCATE_REQUEST;
}

interface IGetOfficerConfiscateHeaderSuccess {
  type: EOfficer.GET_OFFICER_HEADER_CONFISCATE_SUCCESS;
  data: IOfficerLead[];
}

interface IGetOfficerConfiscateHeaderFailed {
  type: EOfficer.GET_OFFICER_HEADER_CONFISCATE_FAILED;
  error: Error;
}
interface IGetOfficerByTeamRequest {
  type: EOfficer.GET_OFFICER_BY_TEAM_REQUEST;
}

interface IGetOfficerByTeamSuccess {
  type: EOfficer.GET_OFFICER_BY_TEAM_SUCCESS;
  data: IOfficerReportList[];
}

interface IGetOfficerByTeamFailed {
  type: EOfficer.GET_OFFICER_BY_TEAM_FAILED;
  error: Error;
}

export type TOfficer =
  | IGetOfficerHeaderRequest
  | IGetOfficerHeaderSuccess
  | IGetOfficerHeaderFailed
  | IGetOfficerConfiscateHeaderRequest
  | IGetOfficerConfiscateHeaderSuccess
  | IGetOfficerConfiscateHeaderFailed
  | IGetOfficerByTeamRequest
  | IGetOfficerByTeamSuccess
  | IGetOfficerByTeamFailed;
