import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

export interface IDropdownCode extends IDropdown {
  codeNo: string;
}

export interface SelectOptionCode extends SelectOption {
  code: string;
}

const useAgencyDropdownApi = () => {
  const [agencys, isLoading, isError] = useFetchApi<IDropdownCode[]>(
    '/Master/Agency/GetDropdownList', []
  );

  const agencySelectOptions: SelectOptionCode[] = agencys.map((title: IDropdownCode) => {
    return {
      text: title.text,
      value: title.id,
      code: title.codeNo,
    };
  });

  return [agencySelectOptions, isLoading, isError] as const;
};

export default useAgencyDropdownApi;
