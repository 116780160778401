import React, { FC } from 'react';
import styled from 'styled-components';
import constants from 'shared/constants';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Modal component
interface IModalProps {
  visible?: boolean;
  className?: string;
  onClose?: any;
}

const OverlayWrapper = styled.div`
  top: 0;
  left: 0;
  background-color: ${constants.WHITE};
  position: absolute;
  display: ${(p: IModalProps) => (p.visible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-y: scroll;
`;

const CloseIcon = styled(HighlightOffIcon)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const Overlay: FC<IModalProps> = ({ visible, children, className, onClose }) => {
  return (
    <OverlayWrapper visible={visible} className={className}>
      <CloseIcon fontSize={'large'} onClick={onClose} />
      {children}
    </OverlayWrapper>
  );
};

export default Overlay;
