import { TCoords } from 'components/ui/type';
import _ from 'lodash';
import { IDTOPlaceNearbyResponse } from 'shared/interfaces/google-maps.interface';
import { getPlaceByCoords, getPlaceByCoordsNearby } from 'shared/utils/google-maps';
import { EGoogleMapsAction, TGoogleMapsActionTypes } from './google-maps.type';

export const getPlaceNameByCoords = (coords: TCoords, cb?: Function) => {
  return async (dispatch) => {
    try {
      const movedPlace = await getPlaceByCoords(coords);
      // console.log('getPlaceNameByCoords', coords)
      if (!movedPlace || !window.google) {
        return;
      }

      if (!window.google) {
        return;
      }

      dispatch({ type: EGoogleMapsAction.GET_PLACE_NAME_REQUEST });

      const googleAutoCompleteService = new window.google.maps.places.AutocompleteService();

      googleAutoCompleteService.getPlacePredictions(
        {
          input: movedPlace,
          componentRestrictions: { country: ['th'] },
        },
        (predictions: any[], status: any) => {
          if (status === 'OK') {
            dispatch(getPlaceNameByCoordsSuccess(_.get(predictions, '[0].description', ''), cb));
          } else {
            dispatch(getPlaceNameByCoordsSuccess('', cb));
          }
        }
      );
    } catch (error) {
      dispatch(getPlaceNameByCoordsFailed(error));
    }
  };
};

const getPlaceNameByCoordsSuccess = (placeName: string, cb?: Function): TGoogleMapsActionTypes => {
  if (cb) cb(placeName);

  return {
    type: EGoogleMapsAction.GET_PLACE_NAME_SUCCESS,
    placeName,
  };
};

const getPlaceNameByCoordsFailed = (error?: any): TGoogleMapsActionTypes => {
  return {
    type: EGoogleMapsAction.GET_PLACE_NAME_FAILED,
    error,
  };
};

// ;; get place nearby
export const getPlaceNameByCoordsNearby = (coords: TCoords, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EGoogleMapsAction.GET_PLACE_NEARBY_REQUEST });
    try {
      const response = await getPlaceByCoordsNearby(coords);
      // console.log('🚀 : return : response', response);
      const format = !_.isEmpty(response) && !!response ? response.slice(0, 5) : [];
      dispatch(getPlaceNameByCoordsNearbySuccess(format, cb));
    } catch (error) {
      dispatch(getPlaceNameByCoordsNearbyFailed(error));
    }
  };
};

const getPlaceNameByCoordsNearbySuccess = (data: IDTOPlaceNearbyResponse[], cb?: Function): TGoogleMapsActionTypes => {
  if (cb) cb(data);
  return {
    type: EGoogleMapsAction.GET_PLACE_NEARBY_SUCCESS,
    data,
  };
};

const getPlaceNameByCoordsNearbyFailed = (error?: any): TGoogleMapsActionTypes => {
  return {
    type: EGoogleMapsAction.GET_PLACE_NEARBY_FAILED,
    error,
  };
};
