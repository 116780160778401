import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from "shared/constant";
import { IDopaLogForm, IDopaLogSearchForm, IDopaLogSearchFormDbd, IDopaLogSearchFormMea, IDopaLogSearchFormMwa, IDopaLogSearchFormPea, IDopaLogSearchFormPpa, IDopaLogSearchFormPpf, IDopaLogSearchFormPpl, IDopaLogSearchFormPwa, IDopaLogState } from "./dopalog.interface";

export const INITIAL_DOPA_LOG_STATE: IDopaLogState = {
    all: { ...INITIAL_DATA_TABLE_TYPE },
    selected: {} as IDopaLogForm,
    error: undefined,
    isPageLoading: false,
    isTableLoading: false,
    log: { ...INITIAL_DATA_TABLE_TYPE },
};

export const INITIAL_DOPA_LOG_SEARCH_FORM: IDopaLogSearchForm = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
    // personID: '',
    // firstName: '',
    // lastName: '',
    // address: '',
    // province: '',
    // provinceID: null,
};

export const INITIAL_DOPA_LOG_MEA_SEARCH_FORM: IDopaLogSearchFormMea = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_MWA_SEARCH_FORM: IDopaLogSearchFormMwa = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_PEA_SEARCH_FORM: IDopaLogSearchFormPea = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_PWA_SEARCH_FORM: IDopaLogSearchFormPwa = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_PPA_SEARCH_FORM: IDopaLogSearchFormPpa = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_PPF_SEARCH_FORM: IDopaLogSearchFormPpf = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_PPL_SEARCH_FORM: IDopaLogSearchFormPpl = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DOPA_LOG_DBD_SEARCH_FORM: IDopaLogSearchFormDbd = {
    keyword: '',
    serviceCode: '',
    currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
    pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};
