import React from 'react';

type TTableContext = {
  title?: string;
  columns: any;
  isLoading: boolean;
  page: number;
  items: object[];
  itemsCount: number;
  itemsPerPage: number;
  totalCount: number;
  itemsEmptyCount: number;
  selectedItems: string[];
  selectedItemsCount: number;
  spaceCount?: number;
  fixCheckbox?: boolean;
  disabled?: boolean | undefined;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  onHandleChangePage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  onGraph: any;
  onDownload: any;
  onUpload: any;
  onFile: any;
  onView: any;
  onEdit: any;
  onDelete: any;
  onGraphDS: any;
  onMobile: any;
  onBank: any;
  onItem?: number;
  onCheckBox?: any;
  onViewForm?: any;
  onEditForm?: any;
  onModal?: any;
  onSwitch?: any;
  hidePage?: boolean;
  hideHeader?: boolean;
  hideOnView?: boolean;
  hideOnEdit?: boolean;
  hideOnViewForm?: boolean;
  hideOnEditForm?: boolean;
  hideOnDelete?: boolean;
  type?: string;
  order?: 'asc' | 'desc' | undefined;
  orderBy?: any;
  setOrder?: any;
  setOrderBy?: any;
  onChangeOrder?: (property: any) => void;
};

const TableContext = React.createContext({} as TTableContext);

export default TableContext;
