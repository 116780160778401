import { TDataVisualizeActionType, EDataVisualizeAction } from 'store/data-visualize/data-visualize.type';
import {
  IDataVisualizeState,
  IDataVisualizeSuspectForm,
  INITIAL_DATA_VISUALIZE_STATE,
} from 'shared/modules/data-visualize';
import { IGraph } from 'shared/modules/graph';

export default (
  state: IDataVisualizeState = INITIAL_DATA_VISUALIZE_STATE,
  action: TDataVisualizeActionType
): IDataVisualizeState => {
  switch (action.type) {
    case EDataVisualizeAction.DATA_VISUALIZE_SEARCH_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_SEARCH_SUCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_GET_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_GET_SUCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_GET_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_CREATE_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_CREATE_SUCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.DATA_VISUALIZE_CREATE_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        excelResponse: action.data,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.UPLOAD_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.SUBMIT_FILE_REPONSE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.SUBMIT_FILE_REPONSE_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.SUBMIT_FILE_REPONSE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.GET_FILE_RESPONSE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.GET_FILE_RESPONSE_SUCCESS:
      return {
        ...state,
        fileResponse: action.data,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.GET_FILE_RESPONSE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case EDataVisualizeAction.GET_GRAPH_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EDataVisualizeAction.GET_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.data,
        isPageLoading: false,
      };
    case EDataVisualizeAction.GET_GRAPH_FAILED:
      return {
        ...state,
        graph: {} as IGraph,
        error: action.error,
        isPageLoading: false,
      };
    case EDataVisualizeAction.EXCEL_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EDataVisualizeAction.EXCEL_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EDataVisualizeAction.EXCEL_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EDataVisualizeAction.CLEAR_DATA_VISUALIZE_SELECT:
      return {
        ...state,
        selected: {} as IDataVisualizeSuspectForm,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EDataVisualizeAction.CLEAR_ATTACHMENT:
      return {
        ...state,
        excelResponse: {
          ...state.excelResponse,
          fileDataList: [],
        },
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
