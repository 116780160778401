import { ISuspect, ISuspectList } from 'shared/modules/suspect';
import { ISuspectDetail } from 'shared/modules/arrest';
import { TDataTable } from 'shared/types';

export enum ESuspectAction {
  SEARCH_SUSPECT_REQUEST = 'SEARCH_SUSPECT_REQUEST',
  SEARCH_SUSPECT_SUCCESS = 'SEARCH_SUSPECT_SUCCESS',
  SEARCH_SUSPECT_FAILED = 'SEARCH_SUSPECT_FAILED',
  GET_SUSPECT_REQUEST = 'GET_SUSPECT_REQUEST',
  GET_SUSPECT_SUCCESS = 'GET_SUSPECT_SUCCESS',
  GET_SUSPECT_FAILED = 'GET_SUSPECT_FAILED',
  CREATE_SUSPECT_REQUEST = 'CREATE_SUSPECT_REQUEST',
  CREATE_SUSPECT_SUCCESS = 'CREATE_SUSPECT_SUCCESS',
  CREATE_SUSPECT_FAILED = 'CREATE_SUSPECT_FAILED',
  UPDATE_SUSPECT_REQUEST = 'UPDATE_SUSPECT_REQUEST',
  UPDATE_SUSPECT_SUCCESS = 'UPDATE_SUSPECT_SUCCESS',
  UPDATE_SUSPECT_FAILED = 'UPDATE_SUSPECT_FAILED',
  DELETE_SUSPECT_REQUEST = 'DELETE_SUSPECT_REQUEST',
  DELETE_SUSPECT_SUCCESS = 'DELETE_SUSPECT_SUCCESS',
  DELETE_SUSPECT_FAILED = 'DELETE_SUSPECT_FAILED',
  CLEAR_SELECT_SUSPECT = 'CLEAR_SELECT_SUSPECT',
  GET_SUSPECT_BY_ID_CARD_REQUEST = 'GET_SUSPECT_BY_ID_CARD_REQUEST',
  GET_SUSPECT_BY_ID_CARD_SUCCESS = 'GET_SUSPECT_BY_ID_CARD_SUCCESS',
  GET_SUSPECT_BY_ID_CARD_FAILED = 'GET_SUSPECT_BY_ID_CARD_FAILED',
  GET_ARREST_SUSPECT_BY_ID_CARD_REQUEST = 'GET_ARREST_SUSPECT_BY_ID_CARD_REQUEST',
  GET_ARREST_SUSPECT_BY_ID_CARD_SUCCESS = 'GET_ARREST_SUSPECT_BY_ID_CARD_SUCCESS',
  GET_ARREST_SUSPECT_BY_ID_CARD_FAILED = 'GET_ARREST_SUSPECT_BY_ID_CARD_FAILED',
}

interface ISearchSuspectRequest {
  type: ESuspectAction.SEARCH_SUSPECT_REQUEST;
}

interface ISearchSuspectSuccess {
  type: ESuspectAction.SEARCH_SUSPECT_SUCCESS;
  data: TDataTable<ISuspectList>;
}

interface ISearchSuspectFailed {
  type: ESuspectAction.SEARCH_SUSPECT_FAILED;
  error: Error;
}

interface IGetSuspectRequest {
  type: ESuspectAction.GET_SUSPECT_REQUEST;
}

interface IGetSuspectSuccess {
  type: ESuspectAction.GET_SUSPECT_SUCCESS;
  data: ISuspect;
}

interface IGetSuspectFailed {
  type: ESuspectAction.GET_SUSPECT_FAILED;
  error: Error;
}

interface ICreateSuspectRequest {
  type: ESuspectAction.CREATE_SUSPECT_REQUEST;
}

interface ICreateSuspectSuccess {
  type: ESuspectAction.CREATE_SUSPECT_SUCCESS;
}

interface ICreateSuspectFailed {
  type: ESuspectAction.CREATE_SUSPECT_FAILED;
  error: Error;
}

interface IUpdateSuspectRequest {
  type: ESuspectAction.UPDATE_SUSPECT_REQUEST;
}

interface IUpdateSuspectSuccess {
  type: ESuspectAction.UPDATE_SUSPECT_SUCCESS;
}

interface IUpdateSuspectFailed {
  type: ESuspectAction.UPDATE_SUSPECT_FAILED;
  error: Error;
}

interface IDeleteSuspectRequest {
  type: ESuspectAction.DELETE_SUSPECT_REQUEST;
}

interface IDeleteSuspectSuccess {
  type: ESuspectAction.DELETE_SUSPECT_SUCCESS;
}

interface IDeleteSuspectFailed {
  type: ESuspectAction.DELETE_SUSPECT_FAILED;
  error: Error;
}

interface IClearSelectSuspect {
  type: ESuspectAction.CLEAR_SELECT_SUSPECT;
}

interface IGetSuspectByIdCardRequest {
  type: ESuspectAction.GET_SUSPECT_BY_ID_CARD_REQUEST;
}

interface IGetSuspectByIdCardSuccess {
  type: ESuspectAction.GET_SUSPECT_BY_ID_CARD_SUCCESS;
  data: ISuspect;
}

interface IGetSuspectByIdCardFailed {
  type: ESuspectAction.GET_SUSPECT_BY_ID_CARD_FAILED;
  error: Error;
}

interface IGetArrestSuspectByIdCardRequest {
  type: ESuspectAction.GET_ARREST_SUSPECT_BY_ID_CARD_REQUEST;
}

interface IGetArrestSuspectByIdCardSuccess {
  type: ESuspectAction.GET_ARREST_SUSPECT_BY_ID_CARD_SUCCESS;
  data: ISuspectDetail;
}

interface IGetArrestSuspectByIdCardFailed {
  type: ESuspectAction.GET_ARREST_SUSPECT_BY_ID_CARD_FAILED;
  error: Error;
}

export type TSuspectActionTypes =
  | ISearchSuspectRequest
  | ISearchSuspectSuccess
  | ISearchSuspectFailed
  | IGetSuspectRequest
  | IGetSuspectSuccess
  | IGetSuspectFailed
  | ICreateSuspectRequest
  | ICreateSuspectSuccess
  | ICreateSuspectFailed
  | IUpdateSuspectRequest
  | IUpdateSuspectSuccess
  | IUpdateSuspectFailed
  | IDeleteSuspectRequest
  | IDeleteSuspectSuccess
  | IDeleteSuspectFailed
  | IClearSelectSuspect
  | IGetSuspectByIdCardRequest
  | IGetSuspectByIdCardSuccess
  | IGetSuspectByIdCardFailed
  | IGetArrestSuspectByIdCardRequest
  | IGetArrestSuspectByIdCardSuccess
  | IGetArrestSuspectByIdCardFailed;
