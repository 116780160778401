import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useDistrictDropdownApi = (provinceId: string | number | null | undefined) => {
  const requestData = {
    ...(provinceId ? { provinceId } : { provinceId: '0' }),
  };
  const urlParams =
    '?' +
    Object.keys(requestData)
      .map((k) => k + '=' + requestData[k])
      .join('&');
  const [districts, isLoading, isError] = useFetchApi<IDropdown[]>('/district/getDropdownList' + urlParams, []);

  const districtSelectOptions: SelectOption[] = districts.map((district: IDropdown) => {
    return {
      text: district.text,
      value: district.id,
    };
  });

  return [districtSelectOptions, isLoading, isError] as const;
};

export default useDistrictDropdownApi;
