import React, { useMemo } from 'react';
import { IUserState } from 'store/user';

const usePermission = (menu: number, data: IUserState) => {
  const { me, permission } = data;
  const viewPermission: boolean = useMemo(() => {
    var res = false;
    const listPermission = (permission || []).filter((item) => item.menuAccessID === menu);
    listPermission.map((item) => {
      if (item.viewPermission) {
        res = true;
      }
    });
    return res;
  }, [data]);
  const editPermission: boolean = useMemo(() => {
    var res = false;
    const listPermission = (permission || []).filter((item) => item.menuAccessID === menu);
    listPermission.map((item) => {
      if (item.editPermission) {
        res = true;
      }
    });
    return res;
  }, [data]);
  const deletePermission: boolean = useMemo(() => {
    var res = false;
    const listPermission = (permission || []).filter((item) => item.menuAccessID === menu);
    listPermission.map((item) => {
      if (item.deletePermission) {
        res = true;
      }
    });
    return res;
  }, [data]);
  const createPermission: boolean = useMemo(() => {
    var res = false;
    const listPermission = (permission || []).filter((item) => item.menuAccessID === menu);
    listPermission.map((item) => {
      if (item.createPermission) {
        res = true;
      }
    });
    return res;
  }, [data]);
  const exportPermission: boolean = useMemo(() => {
    var res = false;
    const listPermission = (permission || []).filter((item) => item.menuAccessID === menu);
    listPermission.map((item) => {
      if (item.exportPermission) {
        res = true;
      }
    });
    return res;
  }, [data]);
  return { viewPermission, editPermission, deletePermission, createPermission, exportPermission };
};

export default usePermission;
