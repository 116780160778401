import axios from 'shared/utils/axios';
import { TMenuActionTypes, EMenu } from './menu.type';
import { IMenu, IMenuPermission } from 'shared/modules/menu';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorageUser } from 'shared/enum/local-storage.type';

export const getMenuPermission = (cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: EMenu.GET_MENU_PERMISSION_REQUEST });
    if (reactLocalStorage.getObject(ELocalStorageUser.ACCESS_PERMISSION, false))
      dispatch(getMenuPermissionSuccess(reactLocalStorage.getObject(ELocalStorageUser.ACCESS_PERMISSION, {})));
    else
      axios
        .get(`/menu/accessPermission`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          dispatch(getMenuPermissionSuccess(response.data, cb));
        })
        .catch((error) => {
          dispatch(getMenuPermissionFailed(error));
        });
  };
};

const getMenuPermissionSuccess = (data: IMenuPermission[], cb?: Function): TMenuActionTypes => {
  if (cb) cb();
  reactLocalStorage.setObject(ELocalStorageUser.ACCESS_PERMISSION, data);
  return {
    type: EMenu.GET_MENU_PERMISSION_SUCCESS,
    data,
  };
};

const getMenuPermissionFailed = (error: Error): TMenuActionTypes => {
  return {
    type: EMenu.GET_MENU_PERMISSION_FAILED,
    error,
  };
};

export const getMenu = (cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: EMenu.GET_MENU_PERMISSION_REQUEST });
    axios
      .get(`/menu`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(getMenuSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getMenuFailed(error));
      });
  };
};

const getMenuSuccess = (data: IMenu[], cb?: Function): TMenuActionTypes => {
  return {
    type: EMenu.GET_MENU_SUCCESS,
    data,
  };
};

const getMenuFailed = (error: Error): TMenuActionTypes => {
  return {
    type: EMenu.GET_MENU_FAILED,
    error,
  };
};
