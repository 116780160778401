import { IGraphSci, IExport, IHeatmapData, IGraphSciComment } from 'shared/modules/graph-data-sci';
import { IGetDsModelHeatMapResponse } from 'shared/modules/graph-data-sci/graph-data-sci.interface';

export enum EGraphAction {
  GET_GRAPH_SCI_REQUEST = 'GET_GRAPH_SCI_REQUEST',
  GET_GRAPH_SCI_SUCCESS = 'GET_GRAPH_SCI_SUCCESS',
  GET_GRAPH_SCI_FAILED = 'GET_GRAPH_SCI_FAILED',
  GET_EXPORT_SCI_REQUEST = 'GET_EXPORT_SCI_REQUEST',
  GET_EXPORT_SCI_SUCCESS = 'GET_EXPORT_SCI_SUCCESS',
  GET_EXPORT_SCI_FAILED = 'GET_EXPORT_SCI_FAILED',
  GET_HEATMAP_REQUEST = 'GET_HEATMAP_REQUEST',
  GET_HEATMAP_SUCCESS = 'GET_HEATMAP_SUCCESS',
  GET_HEATMAP_FAILED = 'GET_HEATMAP_FAILED',
  GET_GRAPH_SCI_COMMENT_REQUEST = 'GET_GRAPH_SCI_COMMENT_REQUEST',
  GET_GRAPH_SCI_COMMENT_SUCCESS = 'GET_GRAPH_SCI_COMMENT_SUCCESS',
  GET_GRAPH_SCI_COMMENT_FAILED = 'GET_GRAPH_SCI_COMMENT_FAILED',
  SAVE_GRAPH_SCI_COMMENT_REQUEST = 'SAVE_GRAPH_SCI_COMMENT_REQUEST',
  SAVE_GRAPH_SCI_COMMENT_SUCCESS = 'SAVE_GRAPH_SCI_COMMENT_SUCCESS',
  SAVE_GRAPH_SCI_COMMENT_FAILED = 'SAVE_GRAPH_SCI_COMMENT_FAILED',
  GET_DS_MODEL_HEATMAP_REQUEST = 'GET_DS_MODEL_HEATMAP_REQUEST',
  GET_DS_MODEL_HEATMAP_SUCCESS = 'GET_DS_MODEL_HEATMAP_SUCCESS',
  GET_DS_MODEL_HEATMAP_FAILED = 'GET_DS_MODEL_HEATMAP_FAILED',
}

interface IGetGraphRequest {
  type: EGraphAction.GET_GRAPH_SCI_REQUEST;
}

interface IGetGraphSuccess {
  type: EGraphAction.GET_GRAPH_SCI_SUCCESS;
  data: IGraphSci;
}

interface IGetGraphFailed {
  type: EGraphAction.GET_GRAPH_SCI_FAILED;
  error: Error;
}
interface IGetExportRequest {
  type: EGraphAction.GET_EXPORT_SCI_REQUEST;
}

interface IGetExportSuccess {
  type: EGraphAction.GET_EXPORT_SCI_SUCCESS;
  data: IExport;
}

interface IGetExportFailed {
  type: EGraphAction.GET_EXPORT_SCI_FAILED;
  error: Error;
}

interface IGetHeatmapRequest {
  type: EGraphAction.GET_HEATMAP_REQUEST;
}

interface IGetHeatmapSuccess {
  type: EGraphAction.GET_HEATMAP_SUCCESS;
  data: IHeatmapData;
}

interface IGetHeatmapFailed {
  type: EGraphAction.GET_HEATMAP_FAILED;
  error: Error;
}

interface IGetGraphCommentRequest {
  type: EGraphAction.GET_GRAPH_SCI_COMMENT_REQUEST;
}

interface IGetGraphCommentSuccess {
  type: EGraphAction.GET_GRAPH_SCI_COMMENT_SUCCESS;
  data: IGraphSciComment;
}

interface IGetGraphCommentFailed {
  type: EGraphAction.GET_GRAPH_SCI_COMMENT_FAILED;
  error: Error;
}

interface ISaveGraphCommentRequest {
  type: EGraphAction.SAVE_GRAPH_SCI_COMMENT_REQUEST;
}

interface ISaveGraphCommentSuccess {
  type: EGraphAction.SAVE_GRAPH_SCI_COMMENT_SUCCESS;
  data: IGraphSciComment;
}

interface ISaveGraphCommentFailed {
  type: EGraphAction.SAVE_GRAPH_SCI_COMMENT_FAILED;
  error: Error;
}

// NOTE: get ds model heatmap
interface IGetDsModelHeatMapRequest {
  type: EGraphAction.GET_DS_MODEL_HEATMAP_REQUEST;
}

interface IGetDsModelHeatMapSuccess {
  type: EGraphAction.GET_DS_MODEL_HEATMAP_SUCCESS;
  data: IHeatmapData;
}

interface IGetDsModelHeatMapFailed {
  type: EGraphAction.GET_DS_MODEL_HEATMAP_FAILED;
  error: Error;
}

export type TGraphActionTypes =
  | IGetGraphRequest
  | IGetGraphSuccess
  | IGetGraphFailed
  | IGetExportRequest
  | IGetExportSuccess
  | IGetExportFailed
  | IGetHeatmapRequest
  | IGetHeatmapSuccess
  | IGetHeatmapFailed
  | IGetGraphCommentRequest
  | IGetGraphCommentSuccess
  | IGetGraphCommentFailed
  | ISaveGraphCommentRequest
  | ISaveGraphCommentSuccess
  | ISaveGraphCommentFailed
  | IGetDsModelHeatMapRequest
  | IGetDsModelHeatMapSuccess
  | IGetDsModelHeatMapFailed;
