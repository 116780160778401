import { getUri } from './utils/getUri';
import { makeid } from './utils/makeid';

const dns = window.location.origin;
// console.log(dns);

// กำหนด URL ตามเงื่อนไข
// const url =
//   dns === 'https://rtpnsb.com'
//     ? //pro
//       `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=cXp2RFV6QzRNcGp3VUh2dmF3R25LenhSZHRxSFlPQlg&redirect_uri=${getUri()}/QRCODE&scope=pid%20birthdate%20given_name%20family_name&state=${makeid(
//         6
//       )}`
//     : //sit
//       `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=eWdJd2k1TWVjREs2MDUwUkhZa0FXRVdqU3dnVVAyWEM&redirect_uri=${getUri()}/QRCODE&scope=pid%20birthdate%20given_name%20family_name&state=${makeid(
//         6
//       )}`;

export const ELinkageNSB = {
  //pro
  URL: `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=cXp2RFV6QzRNcGp3VUh2dmF3R25LenhSZHRxSFlPQlg&redirect_uri=${getUri()}/QRCODE&scope=pid%20birthdate%20given_name%20family_name&state=${makeid(
    6
  )}`,
  //sit
  // URL: `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=eWdJd2k1TWVjREs2MDUwUkhZa0FXRVdqU3dnVVAyWEM&redirect_uri=${getUri()}/QRCODE&scope=pid%20birthdate%20given_name%20family_name&state=${makeid(
  //   6
  // )}`,
  // URL: `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=cXp2RFV6QzRNcGp3VUh2dmF3R25LenhSZHRxSFlPQlg&redirect_uri=https://sit.rtpnsb.com/QRCODE&scope=pid%20birthdate%20given_name%20family_name&state=${makeid(
  //   6
  // )}`,
  // URL: `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=cXp2RFV6QzRNcGp3VUh2dmF3R25LenhSZHRxSFlPQlg&redirect_uri=https://sit.rtpnsb.com/QRCODE&scope=pid%20birthdate%20given_name%20family_name&state=`,
  // URL: url,
  OTA: 'OTA',
  URI: 'URI',
  FROM: 'FROM',
};
