import useFetchRelationDropdown, { IRelationalDropdown } from 'hooks/relation-dropdown/relation-dropdown.hooks';
import _ from 'lodash';
import React, { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TSelect } from 'shared/types';
import Select from './Select';

interface IRelationDropdownProps extends IRelationalDropdown {
  policeStationID: TSelect;
  organizationID: TSelect;
  isHeadQuarterDisabled?: boolean;
  isDivisionDisabled?: boolean;
  isSubDivisionDisabled?: boolean;
  isDepartmentDisabled?: boolean;
  isPoliceStationDisabled?: boolean;
  isHeadQuarterRequired?: boolean;
  isDivisionRequired?: boolean;
  isSubDivisionRequired?: boolean;
  isDepartmentRequired?: boolean;
  isPoliceStationRequired?: boolean;
  isOrganizationRequired?: boolean;
  headQuarterError?: string;
  divisionError?: string;
  subDivisionError?: string;
  departmentError?: string;
  policeStationError?: string;
  organizationError?: string;
  hideSubDivision?: boolean;
  hideDepartment?: boolean;
  hidePoliceStation?: boolean;
  hideOrganization?: boolean;
  state?: object;
  setState?(newState: object): void;
  validateRelationDropdown?: (
    field: 'headQuarterID' | 'subDivisionID' | 'divisionID' | 'departmentID' | 'policeStationID' | 'organizationID'
  ) => (_event: FocusEvent<HTMLDivElement>) => void;
}

const RelationDropdown: React.FC<IRelationDropdownProps> = ({
  headQuarterID,
  divisionID,
  subDivisionID,
  departmentID,
  policeStationID,
  organizationID,
  isHeadQuarterDisabled = false,
  isDivisionDisabled = false,
  isSubDivisionDisabled = false,
  // isDepartmentDisabled = false,
  isPoliceStationDisabled = false,
  isHeadQuarterRequired = false,
  isDivisionRequired = false,
  // isSubDivisionRequired = false,
  // isDepartmentRequired = false,
  isPoliceStationRequired = false,
  isOrganizationRequired = false,
  headQuarterError = '',
  divisionError = '',
  // subDivisionError = '',
  // departmentError = '',
  policeStationError = '',
  organizationError = '',
  // hideSubDivision = false,
  // hideDepartment = false,
  hidePoliceStation = false,
  hideOrganization = false,
  state,
  setState,
  validateRelationDropdown,
}) => {
  const { t } = useTranslation('ui');

  const [
    // { headQuarters, divisions, subDivisions, departments, policeStations, organizations },
    { headQuarters, divisions, policeStations, organizations },
    isLoading,
  ] = useFetchRelationDropdown({
    headQuarterID,
    divisionID,
    subDivisionID,
    departmentID,
    organizationID,
    policeStationID,
  });

  // handlers
  const onHeadQuarterChange = (value: number) => {
    if (setState && state) {
      setState({
        ...state,
        headQuarterID: value,
        divisionID: null,
        subDivisionID: null,
        departmentID: null,
        policeStationID: null,
      });
    }
  };

  const onDivisionChange = (value: number) => {
    if (setState && state) {
      setState({
        ...state,
        divisionID: value,
        subDivisionID: null,
        departmentID: null,
        policeStationID: null,
      });
    }
  };

  // const onSubDivisionChange = (value: number) => {
  //   if (setState && state) {
  //     setState({
  //       ...state,
  //       subDivisionID: value,
  //       departmentID: null,
  //       policeStationID: null,
  //     });
  //   }
  // };

  // const onDepartmentChange = (value: number) => {
  //   if (setState && state) {
  //     setState({
  //       ...state,
  //       departmentID: value,
  //       policeStationID: null,
  //     });
  //   }
  // };

  const onPoliceStationChange = (value: number) => {
    if (setState && state) {
      setState({
        ...state,
        policeStationID: value,
      });
    }
  };

  const onOrganizationsChange = (value: number) => {
    if (setState && state) {
      setState({
        ...state,
        organizationID: value,
      });
    }
  };

  return (
    <>
      <Select
        options={headQuarters}
        isLoading={isLoading}
        label={t('ui.relation_dropdown.headquarter', 'กองบัญชาการ')}
        placeholder={t('ui.relation_dropdown.headquarter', 'กองบัญชาการ')}
        value={headQuarterID}
        disabled={isHeadQuarterDisabled}
        required={isHeadQuarterRequired}
        onChange={onHeadQuarterChange}
        onBlur={
          isHeadQuarterRequired ? validateRelationDropdown && validateRelationDropdown('headQuarterID') : undefined
        }
        error={headQuarterError}
      />
      <Select
        options={divisions}
        isLoading={isLoading}
        label={t('ui.relation_dropdown.division', 'กองบังคับการ')}
        placeholder={t('ui.relation_dropdown.division', 'กองบังคับการ')}
        value={divisionID}
        disabled={_.isEmpty(divisions) || isDivisionDisabled}
        required={isDivisionRequired}
        onChange={onDivisionChange}
        onBlur={isDivisionRequired ? validateRelationDropdown && validateRelationDropdown('divisionID') : undefined}
        error={divisionError}
      />
      {/* {!hideSubDivision && (
        <Select
          options={subDivisions}
          isLoading={isLoading}
          label={t('ui.relation_dropdown.sub_division', 'ฝ่าย')}
          value={subDivisionID}
          disabled={_.isEmpty(subDivisions)}
          required={isSubDivisionRequired}
          onChange={onSubDivisionChange}
          onBlur={
            isDivisionRequired ? validateRelationDropdown && validateRelationDropdown('subDivisionID') : undefined
          }
          error={subDivisionError}
        />
      )}
      {!hideDepartment && (
        <Select
          options={departments}
          isLoading={isLoading}
          label={t('ui.relation_dropdown.department', 'กลุ่มงาน')}
          value={departmentID}
          disabled={_.isEmpty(departments)}
          required={isDepartmentRequired}
          onChange={onDepartmentChange}
          onBlur={isDivisionRequired ? validateRelationDropdown && validateRelationDropdown('departmentID') : undefined}
          error={departmentError}
        />
      )} */}
      {!hidePoliceStation && (
        <Select
          options={policeStations}
          isLoading={isLoading}
          label={t('ui.relation_dropdown.police_station', 'สถานีตำรวจ / กองกำกับการ')}
          placeholder={t('ui.relation_dropdown.police_station', 'สถานีตำรวจ / กองกำกับการ')}
          value={policeStationID}
          disabled={_.isEmpty(policeStations) || isPoliceStationDisabled}
          required={isPoliceStationRequired}
          onChange={onPoliceStationChange}
          onBlur={
            isDivisionRequired ? validateRelationDropdown && validateRelationDropdown('policeStationID') : undefined
          }
          error={policeStationError}
        />
      )}
      {!hideOrganization && (
        <Select
          options={organizations}
          isLoading={isLoading}
          label={t('ui.relation_dropdown.organization', 'สายงาน')}
          placeholder={t('ui.relation_dropdown.organization', 'สายงาน')}
          value={organizationID}
          disabled={_.isEmpty(organizations)}
          // required={isOrganizationRequired}
          onChange={onOrganizationsChange}
          onBlur={
            isDivisionRequired ? validateRelationDropdown && validateRelationDropdown('organizationID') : undefined
          }
          // error={organizationError}
        />
      )}
    </>
  );
};

export default RelationDropdown;
