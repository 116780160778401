import _ from 'lodash';

const toQueryString = (data?: object) => {
  return data
    ? Object.keys(data)
        .filter((key: string) => {
          switch (typeof data[key]) {
            case 'string':
              return data[key];
            case 'number':
              return !_.isNil(data[key]);
            default:
              return !_.isEmpty(data[key]);
          }
        })
        // eslint-disable-next-line no-sequences
        .reduce((res, key) => ((res[key] = data[key]), res), {})
    : {};
};

export { toQueryString };
