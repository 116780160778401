import { TInformActionTypes, EInformAction } from 'store/inform/inform.type';
import { IInformState, INITIAL_INFORM_STATE, IInform } from 'shared/modules/inform';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';

export default (state: IInformState = INITIAL_INFORM_STATE, action: TInformActionTypes): IInformState => {
  switch (action.type) {
    case EInformAction.SEARCH_INFORM_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case EInformAction.SEARCH_INFORM_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInformAction.SEARCH_INFORM_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case EInformAction.GET_INFORM_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInformAction.GET_INFORM_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.GET_INFORM_FAILED:
      return {
        ...state,
        selected: {} as IInform,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.CREATE_INFORM_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInformAction.CREATE_INFORM_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.CREATE_INFORM_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.UPDATE_INFORM_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInformAction.UPDATE_INFORM_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.UPDATE_INFORM_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.DELETE_INFORM_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case EInformAction.DELETE_INFORM_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.DELETE_INFORM_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case EInformAction.CLEAR_SELECT_INFORM:
      return {
        ...state,
        selected: {} as IInform,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
