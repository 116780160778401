import React, { FC, ChangeEvent, FocusEvent } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import styled from 'styled-components';
import InputLabelTemplate from './InputTemplate';
import constants from 'shared/constants';
import { Grid } from '@material-ui/core';

const TextAreaTemplate = styled(InputLabelTemplate)`
  float: right;
  padding-right: 1em;
`;

interface ITextAreaProps {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: string | null;
  resizable?: boolean;
  disabled?: boolean;
  minRows?: number;
  rowsMax?: number;
  maxLength?: number;
  error?: string;
  info?: string;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  required?: boolean;
}

const TextAreaBody = styled(TextareaAutosize)`
  width: 100%;
  /* overflow: scroll !important; */
  /* margin-bottom: 0px; */
  resize: ${(props: ITextAreaProps) => (props.resizable ? '' : 'none')};
  padding: 10.5px 14px;
  border-color: ${(props: ITextAreaProps) => (props.error ? constants.RED : 'rgba(0, 0, 0, 0.23)')};
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
  &:focus {
    outline-color: ${(props: ITextAreaProps) => (props.error ? constants.RED : '#3F51B5')};
  }
`;

const TextArea: FC<ITextAreaProps> = ({
  className,
  label,
  placeholder,
  value,
  resizable,
  disabled,
  minRows,
  rowsMax,
  maxLength,
  error,
  info,
  onChange,
  onBlur,
  required,
}) => {
  return (
    <Grid container>
      <Grid xs={2}>
        <TextAreaTemplate
          className={className}
          label={label}
          error={error}
          info={info}
          required={required}
        ></TextAreaTemplate>
      </Grid>

      <Grid xs={10}>
        <TextAreaBody
          error={error}
          minRows={minRows}
          rowsMax={rowsMax}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          value={value ? value : ''}
          resizable={resizable}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Grid>
    </Grid>
  );
};

export default TextArea;
