import {
  IInvestigationSearch,
  IInvestigationState,
  IInvestigation,
  IInvestigationValidate,
  IInvestigationAttachmentList,
  IInvestigationAttachmentListGetRequest,
  IInvestigationAttachmentDataTable,
} from './investigation.interface';
import { INITIAL_COORDS } from 'components/ui/constant/google-maps.constant';
import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { TDataTable } from 'shared/types';

export const INITIAL_INVESTIGATION_SEARCH: IInvestigationSearch = {
  caseFileID: null,
  fullName: '',
  startDate: null,
  endDate: null,
  detail: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  //  SNEW SEARCH MONTH & YEAR
  monthId: null,
  yearId: null,
  investigationYear: null,
  investigationMonth: null,
};

export const INITIAL_INVESTIGATION_STATE: IInvestigationState = {
  selected: {} as IInvestigation,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selectedByCase: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isPageLoading: false,
  isTableLoading: false,
  isButtonLoading: false,
  yearDropdownList: [],
  monthDropdownList: [],
  investigationAttachmentList: {
    dataTable: { ...INITIAL_DATA_TABLE_TYPE } as TDataTable<IInvestigationAttachmentDataTable>,
  } as IInvestigationAttachmentList,
};

export const INITIAL_INVESTIGATION: IInvestigation = {
  id: '',
  caseNo: '',
  caseID: '',
  investigationCode: '',
  caseFileID: null,
  investigationFullName: '',
  investigationDate: moment(new Date()),
  detail: '',
  caseName: '',
  year: moment(),
  place: '',
  latitude: null,
  longitude: null,
  investigationPlace: '',
  investigationPlaceAddress: '',
  attachments: [],
  newAttachments: [],
  investigationAttachments: [],
  isEditable: false,
  _propertyFoundingLatitude: INITIAL_COORDS.lat,
  _propertyFoundingLongitude: INITIAL_COORDS.lng,
  tempName: '',
  isPrivate: true,
  // RELATION DROPDOWN
  headQuarterID: null,
  divisionID: null,
  subDivisionID: null,
  departmentID: null,
  policeStationID: null,
  organizationID: null,
  createdByAgencyID: null,
  createdBySectorID: null,
  createdByDepartmentID: null,
  attachmentsDetails: [],
};

export const INITIAL_INVESTIGATION_VALIDATE: IInvestigationValidate = {
  investigationDate: '',
  detail: '',
};

export const INITIAL_INVESTIGATION_ATTACHMENT_LIST_SEARCH: IInvestigationAttachmentListGetRequest = {
  investigationYear: null,
  investigationMonth: null,
  createdByUser: '',
  investigationName: '',
  pageSize: 5,
  currentPage: 1,
};
