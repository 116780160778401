import React, { FC, FocusEvent } from 'react';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import InputTemplate from './InputTemplate';
import { MuiPickersUtilsProvider, DatePicker as MUIDatePicker } from 'material-ui-thai-datepickers';
import { DateFormat, InputSize, InputVariant } from './enum';
import moment from 'moment';

import 'moment/locale/th';
import { TDateTime } from 'shared/types';
import { Grid, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const DatePickerBody = styled(MUIDatePicker)`
  width: 100%;
  .Mui-disabled {
    background-color: #f5f5f5 !important;
    border-color: #9b9b9b;
  }
`;

const IconButtonWrapper = styled(IconButton)`
  height: 2.5rem;
  width: 2.5rem;
`;

interface IDatePickerProps {
  label?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  info?: string;
  variant?: InputVariant;
  size?: InputSize;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  value?: TDateTime;
  onChange(date: TDateTime): void;
  onBlur?(event: FocusEvent<HTMLDivElement>): void;
  format?: DateFormat;
  maxDate?: TDateTime;
  minDate?: TDateTime;
  required?: boolean;
  views?: any;
  onClear?(field: string): void;
  field?: string;
  id?: string;
}

const DatePicker: FC<IDatePickerProps> = ({
  label,
  placeholder,
  className,
  error,
  info,
  size = InputSize.SMALL,
  variant = InputVariant.OUTLINED,
  disabled,
  disableFuture,
  disablePast,
  value,
  onChange,
  onBlur,
  format = DateFormat.DD_MM_YYYY,
  maxDate,
  minDate,
  required,
  views,
  onClear,
  field,
  id,
}) => {
  return (
    <InputTemplate className={className} label={label} info={info} error={error} required={required} id={id}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
        <Grid container spacing={1}>
          <Grid item xs={onClear ? 10 : 12}>
            <DatePickerBody
              error={error ? true : false}
              placeholder={placeholder}
              disabled={disabled}
              disableFuture={disableFuture}
              disablePast={disablePast}
              size={size}
              inputVariant={variant}
              value={value ? moment(value) : null}
              maxDate={maxDate}
              minDate={minDate}
              onChange={onChange}
              onBlurCapture={onBlur}
              format={format}
              variant={'inline'}
              yearOffset={543}
              autoOk={true}
              views={views}
              // clearable
            />
          </Grid>
          {onClear && field && (
            <Grid item xs={2}>
              <IconButtonWrapper onClick={disabled ? () => {} : () => onClear(field)}>
                <ClearIcon />
              </IconButtonWrapper>
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </InputTemplate>
  );
};

export default DatePicker;
