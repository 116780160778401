import { IDopaLogState, INITIAL_DOPA_LOG_STATE } from "shared/modules/dopalog";
import { EDopaLogAction, TDopaLog } from "./dopalog.type";

export default (state: IDopaLogState = INITIAL_DOPA_LOG_STATE, action: TDopaLog): IDopaLogState => {
    switch (action.type) {
        case EDopaLogAction.GET_DOPA_LOG_REQUEST:
            return {
                ...state,
                isPageLoading: true,
                isTableLoading: true,
            };
        case EDopaLogAction.GET_DOPA_LOG_SUCCESS:
            return {
                ...state,
                all: action.data,
                isPageLoading: false,
                isTableLoading: false,
            };
        case EDopaLogAction.GET_DOPA_LOG_FAILED:
            return {
                ...state,
                error: action.error,
                isPageLoading: false,
                isTableLoading: false,
            };
        default: {
            return { ...state };
        }
    }
};