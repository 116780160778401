import React, { FC, ReactElement, useState } from 'react';
import TabVerticalTitle from './TabVerticalTitle';
import styled from 'styled-components';
import _ from 'lodash';

const StyledTabs = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  gap: 1rem;
`;

const StyledInnerTabs = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  margin-bottom: -1px;
  border-radius: 20px;
  flex-direction: column;
  width: 20%;
`;

interface TabsProps {
  children: ReactElement[];
  defaultSelected?: number;
  setSelected?(selected: string): void;
  hideIcon?: boolean;
}

const TabsVertical: FC<TabsProps> = ({ children, defaultSelected, setSelected, hideIcon = false }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <StyledTabs>
      <StyledInnerTabs>
        {children.map((item, index) => (
          <TabVerticalTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={defaultSelected || selectedTab}
            icon={item.props.src}
            // selectedTab={selectedTab || _.toNumber(defaultSelected)}
            setSelected={setSelected}
            hideIcon={hideIcon}
          />
        ))}
      </StyledInnerTabs>
      {children[selectedTab]}
    </StyledTabs>
  );
};

export default TabsVertical;
