import _ from 'lodash';
import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';
import TabTitle from './TabTitle';

const StyledTabs = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledInnerTabs = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  margin-bottom: -1px;
  border-radius: 20px;
`;

interface TabsProps {
  children: ReactElement[];
  defaultSelected?: number;
  setSelected?(selected: string): void;
  isIcon?: boolean;
}

const Tabs: FC<TabsProps> = ({ children, defaultSelected, setSelected, isIcon }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <StyledTabs>
      <StyledInnerTabs>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={defaultSelected || selectedTab}
            icon={item.props.src}
            setSelected={setSelected}
            isIcon={isIcon}
            // selectedTab={selectedTab || _.toNumber(defaultSelected)}
          />
        ))}
      </StyledInnerTabs>
      {children[selectedTab]}
    </StyledTabs>
  );
};

export default Tabs;
