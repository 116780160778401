import { Grid, IconButton, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { FilterOptionsState } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SelectOption, SelectOptionDisable, SelectOptionKey, SelectOptionString } from 'components/ui/type';
import React, { FC, FocusEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import InputTemplate from './InputTemplate';
import { InputSize, InputVariant } from './enum';

const SelectBody = styled(Autocomplete)`
  width: 100%;
  .MuiIconButton-root {
    padding: 2px;
  }
  .Mui-disabled {
    background-color: #f5f5f5 !important;
    border-color: #9b9b9b;
  }
`;

const IconButtonWrapper = styled(IconButton)`
  height: 2.5rem;
  float: left;
`;

interface ISelectProps {
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  errorText?: string;
  info?: string;
  variant?: InputVariant;
  size?: InputSize;
  disabled?: boolean;
  value?: number | number[] | string | string[] | null;
  onChange(value: unknown, event: unknown): void;
  onBlur?(event: FocusEvent<HTMLDivElement>): void;
  multiple?: boolean;
  options?: SelectOption[] | SelectOptionDisable[] | SelectOptionKey[];
  // optionString?: SelectOptionString[];
  isLoading: boolean;
  required?: boolean;
  onModalPDF?(field: string): void;
  field?: string;
  onFocus?(): void;
  freeSolo?: boolean;
}

const Select: FC<ISelectProps> = ({
  id,
  label,
  placeholder,
  className,
  error,
  errorText,
  info,
  size = InputSize.SMALL,
  variant = InputVariant.OUTLINED,
  disabled,
  value,
  onChange,
  onBlur,
  multiple = false,
  options = [],
  // optionString = [],
  isLoading,
  required,
  field,
  onModalPDF,
  onFocus,
  freeSolo = false,
}) => {
  const [optionLabels, setOptionLabels] = useState({});

  useEffect(() => {
    setOptionLabels(options.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {}));
  }, [options]);

  return (
    <InputTemplate
      className={className}
      label={label}
      info={info}
      error={error}
      errorText={errorText}
      required={required}
    >
      <Grid container>
        <Grid item xs={12}>
          <SelectBody
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            value={isLoading ? (multiple ? [] : null) : value || null}
            onChange={(_, newValue) => {
              if (onChange) onChange(newValue, _);
            }}
            onBlur={onBlur}
            multiple={multiple}
            options={options.map((option) => option.value)}
            // options={[]}
            // optionString={optionString.map((option) => option.text)}
            loading={isLoading}
            getOptionLabel={(option: any) => optionLabels[option] || ''}
            noOptionsText={'ไม่พบข้อมูล'}
            // will use in future
            // renderOption={(option, { selected }) => (
            //   <React.Fragment>
            //     <Checkbox
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       style={{ marginRight: 8 }}
            //       checked={selected}
            //     />
            //     {option.title}
            //   </React.Fragment>
            // )}
            // getOptionDisabled={(option: any, index) => index % 2 === 0 || false}
            renderInput={(param) => (
              <TextField
                {...param}
                variant={variant}
                size={size}
                error={error ? true : false}
                // label={!value ? placeholder : ''}
                placeholder={placeholder}
              />
            )}
            onFocus={onFocus}
            freeSolo={freeSolo}
          />
        </Grid>
        {/* {onModalPDF && field && <Grid item xs={2}>
          <Tooltip title="สร้างแฟ้มใหม่">
            <IconButtonWrapper >
              <AddIcon onClick={() => onModalPDF(field)} />
            </IconButtonWrapper>
          </Tooltip>
        </Grid>} */}
      </Grid>
    </InputTemplate>
  );
};

export default Select;
