import React from 'react';
import { TTableOrder } from '../type';

type TTableContext = {
  title?: string;
  columns: any;
  order: TTableOrder;
  orderBy: string;
  page: number;
  items: object[];
  itemsCount: number;
  itemsPerPage: number;
  itemsEmptyCount: number;
  selectedItems: string[];
  selectedItemsCount: number;
  onChangeOrder: (property: string) => void;
  spaceCount?: number;
  onGraph: any;
  onDownload: any;
  onUpload: any;
  onFile: any;
  onView: any;
  onEdit: any;
  onDelete: any;
  // onSelect: (event: React.ChangeEvent<HTMLInputElement>, _id: string) => void,
  // onSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onChangePage: (newPage: number) => void;
  onChangeItemsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteModal?: any;
};

const TableContext = React.createContext({} as TTableContext);

export default TableContext;
