import _ from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import { AppName, UserProfile } from './HeaderComponents';

const HEADER_SIZE = 70;
const HEADER_WIDTH = 75;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_SIZE}px;
  padding-left: ${HEADER_WIDTH}px;
  z-index: 5;
`;

const Authenticated: FC = () => {
  return (
    <HeaderWrapper>
      <AppName />
      <UserProfile />
    </HeaderWrapper>
  );
};

export default Authenticated;
