import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormContainer } from 'components/layout';
import { Button, Input } from 'components/ui';
import _ from 'lodash';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  IGoogleMap,
  IGoogleMapValidate,
  INITIAL_GOOGLE_MAP,
  INITIAL_GOOGLE_MAP_VALIDATE,
} from 'shared/modules/searching-report';
import { onInputChange, validateOnBlurChange } from 'shared/utils/event-handle';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { InputSize, InputVariant } from '../enum';
import InputTemplate from '../InputTemplate';
import { TCoords } from '../type';
// import { validateMap } from './latlng.validate';

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;

  .MuiIconButton-root {
    padding: 2px;
  }
`;

const PlaceDetail = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 14px;
`;

const ButtonMarker = styled(Button)`
  margin: auto !important;
`;

const ButtonMapType = styled(Grid)`
  z-index: 10;
  position: absolute;
  margin-top: 8em !important;
`;

interface ISearchAutocompleteProps {
  className?: string;
  disabled?: boolean;
  variant?: InputVariant;
  size?: InputSize;
  error?: string;
  place?: string;
  setPlace?(newPlace: string): void;
  setCoords(newCoords: TCoords): void;
  positions: IGoogleMap;
  setPositions: Dispatch<SetStateAction<IGoogleMap>>;
  onSubmit: () => void;
  errorState?: IGoogleMapValidate;
  setErrorState?: Dispatch<SetStateAction<IGoogleMapValidate>>;
  validateOnBlur?: (field: keyof IGoogleMapValidate) => (_event: React.FocusEvent<HTMLInputElement>) => void;
  latlng?: any;
  setLatlng?(latlng: any): void;
}

const SearchAutocomplete: React.FC<ISearchAutocompleteProps> = ({
  className,
  disabled = false,
  variant,
  size,
  error,
  place,
  setPlace = () => {},
  setCoords,
  positions,
  setPositions,
  onSubmit,
  latlng,
  setLatlng,
  errorState,
  setErrorState,
  validateOnBlur,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debounceSearchQuery = useDebouncedCallback(setSearchQuery, 500);
  const [places, setPlaces] = useState<{ text: string; value: string }[]>([]);
  const [placeValue, setPlaceValue] = useState<string | null>(null);
  // const [position, setPosition] = useState<IGoogleMap>({ ...INITIAL_GOOGLE_MAP });
  useEffect(() => {
    if (place) {
      debounceSearchQuery(place);
    }
  }, [debounceSearchQuery, place]);

  useEffect(() => {
    if (place && places.map((p) => p.text).includes(place)) {
      setPlaceValue(place);
      setPositions({ ...INITIAL_GOOGLE_MAP });
    }
  }, [place, places]);

  useEffect(() => {
    if (setLatlng) setLatlng(latlng !== '' ? latlng : '');
  });

  useEffect(() => {
    if (!searchQuery || !window.google) {
      setPlaces([]);
      return;
    }

    const googleAutoCompleteService = new window.google.maps.places.AutocompleteService();

    googleAutoCompleteService.getPlacePredictions(
      {
        input: searchQuery,
        componentRestrictions: { country: ['th'] },
      },
      (predictions: any[], status: any) => {
        if (status === 'OK' && !_.isEmpty(predictions)) {
          const resolvedPredictions = predictions.map((prediction) => ({
            value: _.get(prediction, 'place_id', ''),
            text: _.get(prediction, 'description', '').trim(),
          }));
          if (setLatlng) setLatlng('');
          setPlaces(resolvedPredictions);
        } else {
          setPlaces([]);
        }
      }
    );
  }, [searchQuery]);

  // handlers
  const onChange = (_event: ChangeEvent<{}>, value: unknown) => {
    if (!window.google || _.isEmpty(places)) {
      return;
    }

    const newPlace = places.find((p) => p.text === value);

    if (!newPlace) {
      return;
    }

    const geocoderService = new window.google.maps.Geocoder();

    geocoderService.geocode({ placeId: newPlace.value }, async (response: any) => {
      const selectedPlaceCoords: TCoords = {
        lat: _.result(response, '[0].geometry.location.lat', 0),
        lng: _.result(response, '[0].geometry.location.lng', 0),
      };

      setCoords(selectedPlaceCoords);
    });

    setPlaceValue(newPlace.text);
    setPlace(newPlace.text);
    debounceSearchQuery(newPlace.text);
  };

  const onInput =
    (field: string) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      if (setLatlng) setLatlng(event.target.value);
    };

  return (
    <>
      <InputTemplate className={className} label="ค้นหาสถานที่">
        <StyledAutocomplete
          // value={placeValue}
          placeholder="ค้นหาสถานที่"
          noOptionsText={'ไม่พบข้อมูล'}
          options={places.map((place) => place.text)}
          getOptionLabel={(option) => places.find((place) => place.text === option)?.text || ''}
          renderInput={(params) => <TextField {...params} variant={variant} size={size} error={error ? true : false} />}
          onInputChange={(_event, value) => debounceSearchQuery(value)}
          onChange={onChange}
          disabled={disabled}
        />
      </InputTemplate>
      <PlaceDetail>สถานที่ปักหมุด : {latlng !== '' ? latlng : place !== '' ? place : 'ไม่พบข้อมูล'}</PlaceDetail>
      <FormContainer column={2}>
        <Input
          label={'ละติจูด,ลองจิจูด'}
          placeholder={'เช่น 10.123456,104.123456'}
          value={latlng}
          onChange={onInput('latlng')}
          onBlur={validateOnBlur && validateOnBlur('latlng')}
          error={errorState?.latlng}
        />
        <ButtonMarker onClick={onSubmit}>{'ปักหมุด'}</ButtonMarker>
      </FormContainer>
    </>
  );
};

export default SearchAutocomplete;
