import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IDARESearch, IDARESearchValidate, IDAREState } from './dare.interface';

export const INITIAL_DARE_SEARCH: IDARESearch = {
  dateEnd: null,
  dateStart: null,
  schoolName: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_DARE_STATE: IDAREState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};

export const INITIAL_DARE_SEARCH_VALIDATE: IDARESearchValidate = {
  schoolName: '',
};
