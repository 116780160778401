import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useProvinceDropdownApi = () => {
  const [provinces, isLoading, isError] = useFetchApi<IDropdown[]>('/province/getDropdownList', []);

  const provinceSelectOptions: SelectOption[] = provinces.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [provinceSelectOptions, isLoading, isError] as const;
};

export default useProvinceDropdownApi;
