import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useMasterCarModelByBrandDropdownApi = (brand: number) => {
  const [options, isLoading, isError] = useFetchApi<IDropdown[]>(`/Master/CarModel/GetDropdownList/${brand}`, []);

  const selectOptions: SelectOption[] = options.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [selectOptions, isLoading, isError] as const;
};

export default useMasterCarModelByBrandDropdownApi;
