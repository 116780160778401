import React, { FC } from 'react';
import TableContext from './TableContext';
import { TableBody as Body, TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';
import constants from 'shared/constants';
import { LargeText } from '..';
import { ETableOrder } from '../enum';
import { TTableOrder } from '../type';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ShareOutlined as GraphIcon,
  InsertDriveFile as FileIcon,
  CloudDownloadOutlined as DownloadIcon,
  BackupOutlined as UploadIcon,
} from '@material-ui/icons';

const TableBodyRow = styled(TableRow)`
  // border-bottom: 2px solid ${constants.LIGHT_BLUE};
`;
const TableBodyColumn = styled(TableCell)`
  width: ${(props) => props.width};
`;
const TableBodyActionColumn = styled(TableBodyColumn)`
  width: 120px;
`;
const TableBodyText = styled(LargeText)`
  max-width: 200ch;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableActionWrapper = styled.div`
  margin-left: auto;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(3, max-content);

  > svg {
    cursor: pointer;
  }

  > div {
    width: 1em;
    height: 1em;
  }
`;

const comparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) return -1;
  else if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order: TTableOrder, orderBy: string) => {
  return order === ETableOrder.DESCENDING
    ? (a: any, b: any) => comparator(a, b, orderBy)
    : (a: any, b: any) => -comparator(a, b, orderBy);
};

const stableOrder = (array, comparator: Function) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};

const onSpacing = (spaceCount?: number) => {
  if (spaceCount) return <div></div>;
};

const TableBody: FC = () => (
  <TableContext.Consumer>
    {({
      columns,
      items,
      order,
      orderBy,
      page,
      itemsPerPage,
      spaceCount,
      onGraph,
      onDownload,
      onUpload,
      onFile,
      onView,
      onEdit,
      onDelete,
      onDeleteModal,
    }) => (
      <Body>
        {stableOrder(items, getComparator(order, orderBy))
          .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
          .map((item) => {
            // const isItemSelected = selectedItems.includes(item.id);
            return (
              <TableBodyRow
                key={item.id}
                // isselected={isItemSelected}
              >
                {/* <TableBodyColumn padding={'checkbox'}>
                  <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => onSelect(event, item.id)}
                  />
                </TableBodyColumn> */}
                {columns.map((column) => (
                  <TableBodyColumn key={column.key} align={column.align} width={column.width}>
                    <TableBodyText>{item[column.key]}</TableBodyText>
                  </TableBodyColumn>
                ))}
                {(onView || onEdit || onDelete || onGraph || onDownload || onFile || onUpload
                || onDeleteModal
                ) && (
                  <TableBodyActionColumn>
                    <TableActionWrapper>
                      {/* {!item.isShow && onSpacing(spaceCount)}
                      {onGraph && item.isShow && <GraphIcon onClick={() => onGraph(item.id)} />}
                      {onView && <VisibilityIcon onClick={() => onView(item.id)} />}
                      {onEdit && <EditIcon onClick={() => onEdit(item.id)} />}
                      {onUpload && <UploadIcon onClick={() => onUpload(item.id)} />}
                      {onDownload && <DownloadIcon onClick={() => onDownload(item.filePath)} />}
                      {onFile && <FileIcon onClick={() => onFile(item.id)} />}
                      {onDelete && <DeleteIcon onClick={() => onDelete(item.id)} />}
                      {onDeleteModal && <DeleteIcon onClick={() => onDeleteModal(item)} />} */}
                    </TableActionWrapper>
                  </TableBodyActionColumn>
                )}
              </TableBodyRow>
            );
          })}
      </Body>
    )}
  </TableContext.Consumer>
);

export default TableBody;
