import { IReportReciveBank, IReportReciveBankList } from 'shared/modules/recive-bank';
import { TDataTable } from 'shared/types';

export enum EReciveReportAction {
  SEARCH_RECIVE_BANK_REQUEST = 'SEARCH_RECIVE_BANK_REQUEST',
  SEARCH_RECIVE_BANK_SUCCESS = 'SEARCH_RECIVE_BANK_SUCCESS',
  SEARCH_RECIVE_BANK_FAILED = 'SEARCH_RECIVE_BANK_FAILED',
  CREATE_RECIVE_BANK_REQUEST = 'CREATE_RECIVE_BANK_REQUEST',
  CREATE_RECIVE_BANK_SUCCESS = 'CREATE_RECIVE_BANK_SUCCESS',
  CREATE_RECIVE_BANK_FAILED = 'CREATE_RECIVE_BANK_FAILED',
  UPDATE_RECIVE_BANK_REQUEST = 'UPDATE_RECIVE_BANK_REQUEST',
  UPDATE_RECIVE_BANK_SUCCESS = 'UPDATE_RECIVE_BANK_SUCCESS',
  UPDATE_RECIVE_BANK_FAILED = 'UPDATE_RECIVE_BANK_FAILED',
  GET_RECIVE_BANK_REQUEST = 'GET_RECIVE_BANK_REQUEST',
  GET_RECIVE_BANK_SUCCESS = 'GET_RECIVE_BANK_SUCCESS',
  GET_RECIVE_BANK_FAILED = 'GET_RECIVE_BANK_FAILED',
  DELETE_RECIVE_BANK_REQUEST = 'DELETE_RECIVE_BANK_REQUEST',
  DELETE_RECIVE_BANK_SUCCESS = 'DELETE_RECIVE_BANK_SUCCESS',
  DELETE_RECIVE_BANK_FAILED = 'DELETE_RECIVE_BANK_FAILED',
  CLEAR_SELECTED_RECIVE_BANK = 'CLEAR_SELECTED_RECIVE_BANK',
  GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_REQUEST = 'GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_REQUEST',
  GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_SUCCESS = 'GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_SUCCESS',
  GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_FAILED = 'GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_FAILED',
  CLEAR_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK = 'CLEAR_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK',
}

interface ISearchReportReciveRequest {
  type: EReciveReportAction.SEARCH_RECIVE_BANK_REQUEST;
}

interface ISearchReportReciveSuccess {
  type: EReciveReportAction.SEARCH_RECIVE_BANK_SUCCESS;
  data: TDataTable<IReportReciveBankList>;
  // data: any;
}

interface ISearchReportReciveFailed {
  type: EReciveReportAction.SEARCH_RECIVE_BANK_FAILED;
  error: Error;
}

interface ICreateReportReciveRequest {
  type: EReciveReportAction.CREATE_RECIVE_BANK_REQUEST;
}

interface ICreateReportReciveSuccess {
  type: EReciveReportAction.CREATE_RECIVE_BANK_SUCCESS;
  // data: TDataTable<IReportReciveBankList>;
}

interface ICreateReportReciveFailed {
  type: EReciveReportAction.CREATE_RECIVE_BANK_FAILED;
  error: Error;
}

interface IGetReportReciveRequest {
  type: EReciveReportAction.GET_RECIVE_BANK_REQUEST;
}

interface IGetReportReciveSuccess {
  type: EReciveReportAction.GET_RECIVE_BANK_SUCCESS;
  // data: TDataTable<IReportReciveBankList>;
  data: IReportReciveBank;
}

interface IGetReportReciveFailed {
  type: EReciveReportAction.GET_RECIVE_BANK_FAILED;
  error: Error;
}

interface IUpdateReportReciveRequest {
  type: EReciveReportAction.UPDATE_RECIVE_BANK_REQUEST;
}

interface IUpdateReportReciveSuccess {
  type: EReciveReportAction.UPDATE_RECIVE_BANK_SUCCESS;
  data: TDataTable<IReportReciveBankList>;
}

interface IUpdateReportReciveFailed {
  type: EReciveReportAction.UPDATE_RECIVE_BANK_FAILED;
  error: Error;
}

interface IDeleteReportReciveRequest {
  type: EReciveReportAction.DELETE_RECIVE_BANK_REQUEST;
}

interface IDeleteReportReciveSuccess {
  type: EReciveReportAction.DELETE_RECIVE_BANK_SUCCESS;
  data: TDataTable<IReportReciveBankList>;
}

interface IDeleteReportReciveFailed {
  type: EReciveReportAction.DELETE_RECIVE_BANK_FAILED;
  error: Error;
}

interface IClearSelectedReciveBank {
  type: EReciveReportAction.CLEAR_SELECTED_RECIVE_BANK;
}

interface IGetReportReciveBankFileUploadBankRequest {
  type: EReciveReportAction.GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_REQUEST;
}

interface IGetReportReciveBankFileUploadBankSuccess {
  type: EReciveReportAction.GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_SUCCESS;
  data: TDataTable<any>;
}

interface IGetReportReciveBankFileUploadBankFailed {
  type: EReciveReportAction.GET_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK_FAILED;
  error: Error;
}

interface IClearReportReciveBankFileUploadBank {
  type: EReciveReportAction.CLEAR_REPORT_RECIVE_BANK_FILE_UPLOAD_BANK;
  error: Error;
}

export type TReportReciveBank =
  | ISearchReportReciveRequest
  | ISearchReportReciveSuccess
  | ISearchReportReciveFailed
  | ICreateReportReciveRequest
  | ICreateReportReciveSuccess
  | ICreateReportReciveFailed
  | IGetReportReciveRequest
  | IGetReportReciveSuccess
  | IGetReportReciveFailed
  | IUpdateReportReciveRequest
  | IUpdateReportReciveSuccess
  | IUpdateReportReciveFailed
  | IDeleteReportReciveRequest
  | IDeleteReportReciveSuccess
  | IDeleteReportReciveFailed
  | IClearSelectedReciveBank
  | IGetReportReciveBankFileUploadBankRequest
  | IGetReportReciveBankFileUploadBankSuccess
  | IGetReportReciveBankFileUploadBankFailed
  | IClearReportReciveBankFileUploadBank;
