import React, { FC } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';

const BaseText = styled.p`
  font-family: 'THSarabunNew';
  color: ${constants.PRIMARY_COLOR};
  margin: 0;
`;

const BaseSpanText = styled.span`
  font-family: 'THSarabunNew';
  color: ${constants.PRIMARY_COLOR};
  margin: 0;
`;

const StyledMiniText = styled(BaseText)<{ indent: string | undefined }>`
  font-size: ${constants.MINI_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

const StyledSmallText = styled(BaseText)<{ indent: string | undefined }>`
  font-size: ${constants.SMALL_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

const StyledMediumText = styled(BaseText)<{ indent: string | undefined }>`
  font-size: ${constants.MEDIUM_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

const StyledLargeText = styled(BaseText)<{ indent: string | undefined }>`
  font-size: ${constants.LARGE_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

const StyledHeaderText = styled(BaseText)<{ indent: string | undefined }>`
  font-weight: bold;
  font-size: ${constants.HEADER_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

const StyledTitleText = styled(BaseText)<{ indent: string | undefined }>`
  font-weight: bold;
  font-size: ${constants.TITLE_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

const StyledLargeSpanText = styled(BaseSpanText)<{ indent: string | undefined }>`
  font-size: ${constants.LARGE_FONT}pt;
  text-indent: ${(props) => (!!props.indent ? props.indent : '')};
`;

interface IFontProps {
  className?: string;
  onClick?(): void;
  indent?: string;
}

export const MiniText: FC<IFontProps> = ({ className, children, indent }) => (
  <StyledMiniText className={className} indent={indent}>
    {children}
  </StyledMiniText>
);

export const SmallText: FC<IFontProps> = ({ className, children, indent }) => (
  <StyledSmallText className={className} indent={indent}>
    {children}
  </StyledSmallText>
);

export const MediumText: FC<IFontProps> = ({ className, children, indent }) => (
  <StyledMediumText className={className} indent={indent}>
    {children}
  </StyledMediumText>
);

export const LargeText: FC<IFontProps> = ({ className, children, onClick, indent }) => (
  <StyledLargeText className={className} onClick={onClick} indent={indent}>
    {children}
  </StyledLargeText>
);

export const HeaderText: FC<IFontProps> = ({ className, children, indent }) => (
  <StyledHeaderText className={className} indent={indent}>
    {children}
  </StyledHeaderText>
);

export const TitleText: FC<IFontProps> = ({ className, children, indent }) => (
  <StyledTitleText className={className} indent={indent}>
    {children}
  </StyledTitleText>
);

export const LargeSpanText: FC<IFontProps> = ({ className, children, indent }) => (
  <StyledLargeSpanText className={className} indent={indent}>
    {children}
  </StyledLargeSpanText>
);
