export enum EGamificationMode {
  Create = 1,
  Edit,
}

export enum EGamificationStatus {
  Active = 0,
  INACTIVE,
}

export enum EGamificationMenu {
  GamificationTable = 0,
  GamificationSetting,
}
