import { TSuspectActionTypes, ESuspectAction } from 'store/suspect/suspect.type';
import { ISuspectState, INITIAL_SUSPECT_STATE } from 'shared/modules/suspect';
import { ISuspect } from 'shared/modules/suspect';
import { ISuspectDetail } from 'shared/modules/arrest';
import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';

export default (state: ISuspectState = INITIAL_SUSPECT_STATE, action: TSuspectActionTypes): ISuspectState => {
  switch (action.type) {
    case ESuspectAction.SEARCH_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: false,
        isTableLoading: true,
      };
    case ESuspectAction.SEARCH_SUSPECT_SUCCESS:
      return {
        ...state,
        all: action.data,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESuspectAction.SEARCH_SUSPECT_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_FAILED:
      return {
        ...state,
        selected: {} as ISuspect,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.CREATE_SUSPECT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CREATE_SUSPECT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.UPDATE_SUSPECT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.UPDATE_SUSPECT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_SUSPECT_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.DELETE_SUSPECT_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.DELETE_SUSPECT_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.CLEAR_SELECT_SUSPECT:
      return {
        ...state,
        selected: {} as ISuspect,
        arrest: {} as ISuspectDetail,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_BY_ID_CARD_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_SUSPECT_BY_ID_CARD_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_SUSPECT_BY_ID_CARD_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_ARREST_SUSPECT_BY_ID_CARD_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case ESuspectAction.GET_ARREST_SUSPECT_BY_ID_CARD_SUCCESS:
      return {
        ...state,
        arrest: action.data,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case ESuspectAction.GET_ARREST_SUSPECT_BY_ID_CARD_FAILED:
      return {
        ...state,
        error: action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
