import { INITIAL_PHONE_UPLOAD_STATE, IPhoneUploadState } from 'shared/modules/phone-upload';
import { EPhoneUpload, TPhoneUpload } from './phone-upload.type';

export default (state: IPhoneUploadState = INITIAL_PHONE_UPLOAD_STATE, action: TPhoneUpload): IPhoneUploadState => {
  switch (action.type) {
    case EPhoneUpload.GET_REQUEST:
      return {
        ...state,
        isTableLoading: true,
      };
    case EPhoneUpload.GET_SUCCESS:
      return {
        ...state,
        all: action.data,
        isTableLoading: false,
      };
    case EPhoneUpload.GET_FAILED:
      return {
        ...state,
        error: action.error,
        isTableLoading: false,
      };
    case EPhoneUpload.UPLOAD_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EPhoneUpload.UPLOAD_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EPhoneUpload.UPLOAD_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EPhoneUpload.DELETE_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EPhoneUpload.DELETE_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EPhoneUpload.DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EPhoneUpload.VIEW_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EPhoneUpload.VIEW_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case EPhoneUpload.VIEW_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EPhoneUpload.GET_GRAPH_SEARCH_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EPhoneUpload.GET_GRAPH_SEARCH_SUCCESS:
      return {
        ...state,
        all: action.data,
        isPageLoading: false,
      };
    case EPhoneUpload.GET_GRAPH_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    case EPhoneUpload.PUT_REQUEST:
      return {
        ...state,
        isRemarkLoading: true,
      };
    case EPhoneUpload.PUT_SUCCESS:
      return {
        ...state,
        isRemarkLoading: false,
      };
    case EPhoneUpload.PUT_FAILED:
      return {
        ...state,
        error: action.error,
        isRemarkLoading: false,
      };
    case EPhoneUpload.GET_REMARK_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EPhoneUpload.GET_REMARK_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        remark: action.data,
      };
    case EPhoneUpload.GET_REMARK_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
