import { TGraphActionTypes, EGraphAction } from 'store/graph-data-sci/graph-data-sci.type';
import { IExportSciState, INITIAL_EXPORT_SCI_STATE, IExport } from 'shared/modules/graph-data-sci';

export default (state: IExportSciState = INITIAL_EXPORT_SCI_STATE, action: TGraphActionTypes): IExportSciState => {
  switch (action.type) {
    case EGraphAction.GET_EXPORT_SCI_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EGraphAction.GET_EXPORT_SCI_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPageLoading: false,
      };
    case EGraphAction.GET_EXPORT_SCI_FAILED:
      return {
        ...state,
        selected: {} as IExport,
        error: action.error,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
