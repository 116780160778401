import React, { FC, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { MediumText, LargeText } from './Font';
import constants from 'shared/constants';
import Checkbox from './Checkbox';
import InputTemplate from './InputTemplate';
import { InputSize, InputVariant } from './enum';
import { Grid, TextField } from '@material-ui/core';

const InputWrapper = styled.div`
  display: flex;
//   flex-direction: column;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left:7px;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;
  align-self: center;
  padding-right: 1em;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
  margin-top:-2em;

`;

const InputError = styled(MediumText)`
  height: 24px;
  color: ${constants.RED};
  white-space: nowrap;
`;

const InputInfo = styled(MediumText)`
  color: ${constants.GREY};
`;

const CheckboxWrapper = styled(Checkbox)`
    margin-top: -0.2em;
`;

const InputBody = styled(TextField)`
  width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
`;

interface IInputLabelTemplateProps {
    label?: string;
    placeholder?: string;
    className?: string;
    error?: string;
    info?: string;
    required?: boolean;
    labelCheckBox?: string;
    checked?: boolean;
    disabled?: boolean;
    variant?: InputVariant;
    size?: InputSize;
    onChangeCheckBox?(event: ChangeEvent<HTMLInputElement>): void;
    value?: string | null;
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    onClick?: any;
    onBlur?(event: FocusEvent<HTMLInputElement>): void;
    type?: string;
    maxLength?: number;
    isMultiline?: boolean;
    inputComponent?: any;
    onKeyPress?(event: KeyboardEvent<HTMLInputElement>): void;
}

const InputCheckBox: FC<IInputLabelTemplateProps> = ({
    label,
    className,
    error,
    info,
    required,
    children,
    labelCheckBox,
    checked,
    disabled,
    onChangeCheckBox,
    placeholder,
    size = InputSize.SMALL,
    variant = InputVariant.OUTLINED,
    value,
    onChange,
    onClick,
    onBlur,
    type,
    maxLength = 255,
    isMultiline,
    inputComponent,
    onKeyPress,
}) => {
    return (
        <StylesProvider injectFirst>
            <Grid container>
                <Grid xs={1}>
                    <CheckboxWrapper className="Checkbox"
                        label={labelCheckBox}
                        disabled={disabled}
                        onChange={onChangeCheckBox}
                        checked={checked}
                    />
                </Grid>
                <Grid xs={3}>
                    <LabelWrapper>
                        {required && <RequiredLabel className="RequiredLabel">*</RequiredLabel>}
                        {label && <InputLabel className="InputLabel">{label}</InputLabel>}
                    </LabelWrapper>
                </Grid>
                <Grid xs={8}>
                    <InputTemplate
                        className={className}
                        // label={label}
                        info={info}
                        error={error}
                        // required={required}
                    >
                        <InputBody
                            error={error ? true : false}
                            placeholder={placeholder}
                            disabled={disabled}
                            size={size}
                            variant={variant}
                            value={value ? value : ''}
                            onChange={onChange}
                            onClick={onClick}
                            onBlur={onBlur}
                            onKeyPress={onKeyPress}
                            type={type}
                            inputProps={{
                                maxLength: maxLength,
                            }}
                            multiline={isMultiline}
                            InputProps={{
                                inputComponent: inputComponent,
                            }}
                        />
                    </InputTemplate>
                </Grid>

                <InputWrapper className={className}>
                    {children}
                    <InputError className="InputError">{error}</InputError>
                    {info && <InputInfo>{info}</InputInfo>}
                </InputWrapper>
            </Grid>
        </StylesProvider >
    );
};

export default InputCheckBox;
