import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCarSeriesDropdownApi = (carBrandId: number | string | null | undefined) => {
  const requestData = {
    ...(carBrandId ? { carBrandId } : { carBrandId: '0' }),
  };
  const urlParams =
    '?' +
    Object.keys(requestData)
      .map((k) => k + '=' + requestData[k])
      .join('&');
  const [carSerieses, isLoading, isError] = useFetchApi<IDropdown[]>('/carSerie/getDropdownList' + urlParams, []);

  const carSeriesSelectOptions: SelectOption[] = carSerieses.map((carSeries: IDropdown) => {
    return {
      text: carSeries.text,
      value: carSeries.id,
    };
  });
  return [carSeriesSelectOptions, isLoading, isError] as const;
};
export default useCarSeriesDropdownApi;
