import { ISearchingReportForm, ISearchingReportList } from 'shared/modules/searching-report';
import { TDataTable } from 'shared/types';

export enum ESearchingReportAction {
  SEARCH_SEARCHING_REPORT_REQUEST = 'SEARCH_SEARCHING_REPORT_REQUEST',
  SEARCH_SEARCHING_REPORT_SUCCESS = 'SEARCH_SEARCHING_REPORT_SUCCESS',
  SEARCH_SEARCHING_REPORT_FAILED = 'SEARCH_SEARCHING_REPORT_FAILED',
  CREATE_SEARCHING_REPORT_REQUEST = 'CREATE_SEARCHING_REPORT_REQUEST',
  CREATE_SEARCHING_REPORT_SUCCESS = 'CREATE_SEARCHING_REPORT_SUCCESS',
  CREATE_SEARCHING_REPORT_FAILED = 'CREATE_SEARCHING_REPORT_FAILED',
  GET_SEARCHING_REPORT_REQUEST = 'GET_SEARCHING_REPORT_REQUEST',
  GET_SEARCHING_REPORT_SUCCESS = 'GET_SEARCHING_REPORT_SUCCESS',
  GET_SEARCHING_REPORT_FAILED = 'GET_SEARCHING_REPORT_FAILED',
  UPDATE_SEARCHING_REPORT_REQUEST = 'UPDATE_SEARCHING_REPORT_REQUEST',
  UPDATE_SEARCHING_REPORT_SUCCESS = 'UPDATE_SEARCHING_REPORT_SUCCESS',
  UPDATE_SEARCHING_REPORT_FAILED = 'UPDATE_SEARCHING_REPORT_FAILED',
  DELETE_SEARCHING_REPORT_REQUEST = 'DELETE_SEARCHING_REPORT_REQUEST',
  DELETE_SEARCHING_REPORT_SUCCESS = 'DELETE_SEARCHING_REPORT_SUCCESS',
  DELETE_SEARCHING_REPORT_FAILED = 'DELETE_SEARCHING_REPORT_FAILED',
  GET_PDF_REQUEST = 'GET_PDF_REQUEST',
  GET_PDF_SUCCESS = 'GET_PDF_SUCCESS',
  GET_PDF_FAILED = 'GET_PDF_FAILED',
  CLEAR_SELECTED_SEARCHING_REPORT = 'CLEAR_SELECTED_SEARCHING_REPORT',
}

interface ISearchSearchingReportRequest {
  type: ESearchingReportAction.SEARCH_SEARCHING_REPORT_REQUEST;
}

interface ISearchSearchingReportSuccess {
  type: ESearchingReportAction.SEARCH_SEARCHING_REPORT_SUCCESS;
  data: TDataTable<ISearchingReportList>;
}

interface ISearchSearchingReportFailed {
  type: ESearchingReportAction.SEARCH_SEARCHING_REPORT_FAILED;
  error: Error;
}

interface ICreateSearchingReportRequest {
  type: ESearchingReportAction.CREATE_SEARCHING_REPORT_REQUEST;
}

interface ICreateSearchingReportSuccess {
  type: ESearchingReportAction.CREATE_SEARCHING_REPORT_SUCCESS;
}

interface ICreateSearchingReportFailed {
  type: ESearchingReportAction.CREATE_SEARCHING_REPORT_FAILED;
  error: Error;
}

interface IGetSearchingReportRequest {
  type: ESearchingReportAction.GET_SEARCHING_REPORT_REQUEST;
}

interface IGetSearchingReportSuccess {
  type: ESearchingReportAction.GET_SEARCHING_REPORT_SUCCESS;
  data: ISearchingReportForm;
}

interface IGetSearchingReportFailed {
  type: ESearchingReportAction.GET_SEARCHING_REPORT_FAILED;
  error: Error;
}

interface IUpdateSearchingReportRequest {
  type: ESearchingReportAction.UPDATE_SEARCHING_REPORT_REQUEST;
}

interface IUpdateSearchingReportSuccess {
  type: ESearchingReportAction.UPDATE_SEARCHING_REPORT_SUCCESS;
}

interface IUpdateSearchingReportFailed {
  type: ESearchingReportAction.UPDATE_SEARCHING_REPORT_FAILED;
  error: Error;
}

interface IDeleteSearchingReportRequest {
  type: ESearchingReportAction.DELETE_SEARCHING_REPORT_REQUEST;
}

interface IDeleteSearchingReportSuccess {
  type: ESearchingReportAction.DELETE_SEARCHING_REPORT_SUCCESS;
}

interface IDeleteSearchingReportFailed {
  type: ESearchingReportAction.DELETE_SEARCHING_REPORT_FAILED;
  error: Error;
}

interface IGetPdfRequest {
  type: ESearchingReportAction.GET_PDF_REQUEST;
}

interface IGetPdfSuccess {
  type: ESearchingReportAction.GET_PDF_SUCCESS;
  fileUrl: string;
}

interface IGetPdfFailed {
  type: ESearchingReportAction.GET_PDF_FAILED;
  error: Error;
}

interface IClearSelectedSearchingReport {
  type: ESearchingReportAction.CLEAR_SELECTED_SEARCHING_REPORT;
}

export type TSearchingReportActionTypes =
  | ISearchSearchingReportRequest
  | ISearchSearchingReportSuccess
  | ISearchSearchingReportFailed
  | ICreateSearchingReportRequest
  | ICreateSearchingReportSuccess
  | ICreateSearchingReportFailed
  | IGetSearchingReportRequest
  | IGetSearchingReportSuccess
  | IGetSearchingReportFailed
  | IUpdateSearchingReportRequest
  | IUpdateSearchingReportSuccess
  | IUpdateSearchingReportFailed
  | IDeleteSearchingReportRequest
  | IDeleteSearchingReportSuccess
  | IDeleteSearchingReportFailed
  | IGetPdfRequest
  | IGetPdfSuccess
  | IGetPdfFailed
  | IClearSelectedSearchingReport;
