import React, { FC } from 'react';
import styled from 'styled-components';
import { MediumText } from './Font';

const QnAWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const Q = styled(MediumText)`
  flex: 1;
  font-weight: bold;
`;

const A = styled(MediumText)`
  flex: 1;
`;

interface IQnAProps {
  q?: string;
  a?: string | null;
}

const QnA: FC<IQnAProps> = ({ q, a }) => (
  <QnAWrapper>
    <Q>{q}</Q>
    <A>{a ? a : ''}</A>
  </QnAWrapper>
);

export default QnA;
