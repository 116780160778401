import React, { FC } from 'react';
import styled from 'styled-components';
import constants from 'shared/constants';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Modal component
interface IModalProps {
  visible?: boolean;
  className?: string;
  onClose?: any;
}

const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  background-color: #0005;
  position: fixed;
  display: ${(p: IModalProps) => (p.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`;

const ModalBody = styled.div`
  // position: relative;
  background-color: ${constants.WHITE};
  display: flex;
  flex-direction: column;
  width: 1000px;
  // height: 600px;
`;

const CloseIcon = styled(HighlightOffIcon)`
  float:right;
  left: 100%;
  top: 0px;
  cursor: pointer;
  z-index: 2;
  position: sticky;
`;

const Modal: FC<IModalProps> = ({ visible, children, className, onClose }) => {
  return (
    <ModalWrapper visible={visible}>
      <ModalBody className={className}>
        {onClose && <CloseIcon fontSize={'large'} onClick={onClose} />}
        {children}
      </ModalBody>
    </ModalWrapper>
  );
};

export default Modal;
