import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCaseTypeDropdownApi = () => {
  const [caseTypes, isLoading, isError] = useFetchApi<IDropdown[]>('/caseType/getDropdownList', []);

  const caseTypeSelectOptions: SelectOption[] = caseTypes.map((caseType) => {
    return {
      text: caseType.text,
      value: caseType.id,
    };
  });
  return [caseTypeSelectOptions, isLoading, isError] as const;
};
export default useCaseTypeDropdownApi;
