export enum ImageSize {
  Mini = 'mini',
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Big = 'big',
  Huge = 'huge',
  Massive = 'massive',
}

export enum ImageFloated {
  Left = 'left',
  Right = 'right',
}

export enum EInputAccept {
  AllImages = 'image/*',
  PDF = 'application/pdf',
  XML = 'application/xml',
  CSV = 'application/csv',
  DOCX = 'application/docx',
  XMLS = 'application/vnd.ms-excel',
  XMLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
}
