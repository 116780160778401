import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useOfficerHeaderCountDropdownApi = () => {
  const [counts, isLoading, isError] = useFetchApi<IDropdown[]>('/officerHeader/getCountDropdownList', []);

  const officerHeaderCountSelectOptions: SelectOption[] = counts.map((count) => {
    return {
      text: count.text,
      value: count.id,
    };
  });

  return [officerHeaderCountSelectOptions, isLoading, isError] as const;
};

export default useOfficerHeaderCountDropdownApi;
