import React, { FC, ReactElement, useState } from 'react';
import TabVerticalCheckboxTitle from './TabVerticalCheckboxTitle';
import styled from 'styled-components';
import _, { indexOf } from 'lodash';
import { EModel } from 'shared/modules/model/model.enum';

const StyledTabs = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  gap: 1rem;
`;

const StyledInnerTabs = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  margin-bottom: -1px;
  border-radius: 20px;
  flex-direction: column;
  /* width: 20%; */
  flex: 1;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 3;
`;

interface TabsProps {
  children: ReactElement[];
  selected: number[];
  setSelected(selected: number[]): void;
  hideIcon?: boolean;
}

const TabsVerticalCheckbox: FC<TabsProps> = ({ children, selected, setSelected, hideIcon = false }) => {
  return (
    <StyledTabs>
      <StyledInnerTabs key={`tab-vertical-checkbox-inner-`}>
        {children.map((item, index) => (
          <TabVerticalCheckboxTitle
            key={`tabs-vertica-checkbox-title-${index}`}
            title={item.props.title}
            index={index}
            icon={item.props.src}
            iconActive={item.props.active}
            selected={selected}
            setSelected={setSelected}
            hideIcon={hideIcon}
          />
        ))}
      </StyledInnerTabs>
      <StyledContent key={`tab-vertical-checkbox-content-`}>
        {children
          .filter((item, index) => selected.includes(index))
          .map((item, index) => {
            return <div key={`tab-vertical-checkbox-content-${index}`}>{children[selected[index]]}</div>;
          })}
      </StyledContent>
    </StyledTabs>
  );
};

export default TabsVerticalCheckbox;
