import { INITIAL_DATA_TABLE_TYPE } from 'shared/constant';
import { ICreateUserForm } from 'shared/modules/user-management';
import { DEFAULT_USER_STATE } from 'shared/modules/user-management/user-management.constant';
import { UserManagementAction, UserManagementActionTypes, IUserManagementState } from './user-management.type';

export const userManagementReducer = (
  state: IUserManagementState = DEFAULT_USER_STATE,
  action: UserManagementActionTypes
): IUserManagementState => {
  switch (action.type) {
    case UserManagementAction.REGISTER_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case UserManagementAction.REGISTER_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case UserManagementAction.REGISTER_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case UserManagementAction.SEARCH_USERS_REQUEST:
      return {
        ...state,
        isButtonLoading: true,
        isPageLoading: true,
        isTableLoading: true,
      };
    case UserManagementAction.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        all: action.users,
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case UserManagementAction.SEARCH_USERS_FAILED:
      return {
        ...state,
        all: { ...INITIAL_DATA_TABLE_TYPE },
        isButtonLoading: false,
        isPageLoading: false,
        isTableLoading: false,
      };
    case UserManagementAction.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case UserManagementAction.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        selected: action.selectedUser,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case UserManagementAction.GET_USER_BY_ID_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case UserManagementAction.EDIT_USER_REQUEST:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: true,
      };
    case UserManagementAction.EDIT_USER_SUCCESS:
      return {
        ...state,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case UserManagementAction.EDIT_USER_FAILED:
      return {
        ...state,
        ...action.error,
        isButtonLoading: false,
        isPageLoading: false,
      };
    case UserManagementAction.CLEAR_SELECTED_USER_STATE:
      return {
        ...state,
        selected: {} as ICreateUserForm,
        isButtonLoading: false,
        isPageLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
