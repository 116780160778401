import { ISuspectSearch, ISuspect, ISuspectState, ISuspectSearchValidate, ISuspectValidate } from './suspect.interface';
import { INITIAL_SUSPECT_DETAIL } from '../arrest';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';

export const INITIAL_SUSPECT_SEARCH: ISuspectSearch = {
  dateEnd: null,
  dateStart: null,
  drugTypeID: [],
  orderStatusID: null,
  suspectFullName: '',
  suspectLevelID: null,
  suspectTypeID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_SUSPECT_SEARCH_VALIDATE: ISuspectSearchValidate = {
  suspectFullName: '',
};

export const INITIAL_SUSPECT: ISuspect = {
  id: '',
  address: '',
  age: '',
  alley: '',
  birthDate: null,
  building: '',
  description: '',
  districtID: null,
  drugTypeID: [],
  gender: null,
  suspectIDCard: '',
  orderStatusID: null,
  postalCode: '',
  profileImgUrl: undefined,
  provinceID: null,
  road: '',
  subDistrictID: null,
  suspectFirstName: '',
  suspectLastName: '',
  suspectLevelID: null,
  suspectTitleID: null,
  suspectTypeID: null,
  appearance: '',
  imageFile: undefined,
  passportNo: '',
  middleName: '',
  nickName: '',
  telephoneNo: '',
};

export const INITIAL_SUSPECT_VALIDATE: ISuspectValidate = {
  suspectTitleID: '',
  suspectFirstName: '',
  suspectLastName: '',
  gender: '',
  address: '',
  building: '',
  alley: '',
  road: '',
  postalCode: '',
  suspectTypeID: '',
  suspectLevelID: '',
  drugTypeID: '',
  orderStatusID: '',
  suspectIDCard: '',
};

export const INITIAL_SUSPECT_STATE: ISuspectState = {
  selected: {} as ISuspect,
  arrest: INITIAL_SUSPECT_DETAIL,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};
