import { SelectOption } from 'components/ui/type';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { useFetchApi } from 'shared/utils/hooks';

const useMasteOffCodeDropdownApi = () => {
  const [offcode, isLoading, isError] = useFetchApi<IDropdown[]>('/Master/OffCode/GetTextDropdownList', []);

  const offCodeSelectOptions: SelectOption[] = offcode.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [offCodeSelectOptions, isLoading, isError] as const;
};

export default useMasteOffCodeDropdownApi;
