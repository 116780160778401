import { IOfficerLead, IOfficerState } from './officer.interface';

export const INITIAL_OFFICER_STATE: IOfficerState = {
  officerHeader: [],
  officerLead: [],
  officerReport: [],
  error: undefined,
  isPageLoading: false,
  isButtonLoading: false,
};

export const INITIAL_OFFICER_LEAD_MODAL: IOfficerLead = {
  id: '',
  officerHeadFullID: null,
  officerHeadFullName: '',
  no: 0,
  officerCode: '',
  positionName: '',
  headQuarterName: '',
  divisionName: '',
  policeStationName: '',
  subDivisionName: '',
  mobilePhoneNumber: '',
  titleID: null,
  titleName: '',
  officerHeadName: '',
  detail: '',
  firstName: '',
  lastName: '',
}