import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useMasterShortTitleByTypeDropdownApi = (type: string) => {
  const [options, isLoading, isError] = useFetchApi<IDropdown[]>(`/Master/ShortTitle/GetDropdownList/${type}`, []);
  
  const selectOptions: SelectOption[] = options.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });

  return [selectOptions, isLoading, isError] as const;
};

export default useMasterShortTitleByTypeDropdownApi;
