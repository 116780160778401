import React from 'react';
import styled from 'styled-components';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import { LargeText } from './Font';

const CheckboxTemplate = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center;
`;

const StyledCheckbox = styled(MaterialCheckbox)``;

const CheckboxLabel = styled(LargeText)`
  font-weight: bold;
  font-size: 22px;
`;

interface ICheckbox {
  className?: string;
  label?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Checkbox: React.FC<ICheckbox> = ({
  className,
  label,
  defaultChecked,
  checked = false,
  disabled = false,
  onChange,
}) => {
  return (
    <CheckboxTemplate className={className}>
      <StyledCheckbox defaultChecked={defaultChecked} checked={checked} disabled={disabled} onChange={onChange} />
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxTemplate>
  );
};

export default Checkbox;
