import { StatusAlert } from 'components/ui';
import { AlertStatus } from 'components/ui/enum';
import { ITeamManagementForm } from 'shared/modules/team-management';
import axios from 'shared/utils/axios';
import {
  ITeamArrestManagementList,
  ITeamSequesterManagementList,
  TeamManagementAction,
  TeamManagementActionTypes,
} from './team-management.type';
import { toQueryString } from 'shared/utils/query-string';
import i18next from 'i18n';
import { TDataTable } from 'shared/types';
import { IPagination } from 'shared/interfaces/pagination.interface';
import { SelectOption } from 'components/ui/type';
import IDropdown from 'shared/interfaces/dropdown.interface';

// GET TeamArrest
export const searchTeams = (data?: IPagination, cb?: Function) => {
  return async (dispatch: (arg0: TeamManagementActionTypes) => void) => {
    dispatch({ type: TeamManagementAction.SEARCH_TEAMS_REQUEST });
    try {
      const response = await axios.get('/officerHeader/officerHeadArrestList', {
        params: toQueryString(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const resolvedResponse: TDataTable<ITeamArrestManagementList> = {
        ...response.data,
        table: response.data?.table,
      };
      const dropdown = await axios.get('/officerHeader/headArrestDropdownList', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(searchTeamsSuccess(resolvedResponse, dropdown.data, cb));
    } catch (error) {
      dispatch(searchTeamsFailed(error));
    }
  };
};

const searchTeamsSuccess = (
  Teams: TDataTable<ITeamArrestManagementList>,
  dropdown: IDropdown[],
  cb?: Function
): TeamManagementActionTypes => {
  if (cb) cb();
  const dropdownMap: SelectOption[] = dropdown.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });
  return {
    type: TeamManagementAction.SEARCH_TEAMS_SUCCESS,
    Teams,
    dropdownMap,
  };
};

const searchTeamsFailed = (error: Error): TeamManagementActionTypes => {
  return {
    type: TeamManagementAction.SEARCH_TEAMS_FAILED,
    error,
  };
};

//GET Teams sequester
export const searchTeamsSequester = (data?: ITeamSequesterManagementList, cb?: Function) => {
  return async (dispatch: (arg0: TeamManagementActionTypes) => void) => {
    dispatch({ type: TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_REQUEST });
    try {
      const response = await axios.get('/officerHeader/officerHeadConfisCateList', {
        params: toQueryString(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const resolvedResponse: TDataTable<ITeamSequesterManagementList> = {
        ...response.data,
        table: response.data?.table,
      };
      const dropdown = await axios.get('/officerHeader/headConfiscateDropdownList', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(searchTeamsSequesterSuccess(resolvedResponse, dropdown.data, cb));
    } catch (error) {
      dispatch(searchTeamsSequesterFailed(error));
    }
  };
};

const searchTeamsSequesterSuccess = (
  TeamsSequester: TDataTable<ITeamSequesterManagementList>,
  dropdown: IDropdown[],
  cb?: Function
): TeamManagementActionTypes => {
  if (cb) cb();
  const dropdownMap: SelectOption[] = dropdown.map((title: IDropdown) => {
    return {
      text: title.text,
      value: title.id,
    };
  });
  return {
    type: TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_SUCCESS,
    TeamsSequester,
    dropdownMap,
  };
};

const searchTeamsSequesterFailed = (error: Error): TeamManagementActionTypes => {
  return {
    type: TeamManagementAction.SEARCH_TEAMS_SEQUESTERS_FAILED,
    error,
  };
};
// POST ArrestTeams
export const onAddTeamsArrest = (userId: ITeamManagementForm, cb?: Function) => {
  return async (dispatch: (arg0: TeamManagementActionTypes) => void) => {
    dispatch({ type: TeamManagementAction.ADD_ARREST_TEAMS_REQUEST });
    try {
      await axios.post('/officerHeader/headArrest', JSON.stringify(userId), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(onAddTeamsArrestSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(onAddTeamsArrestFailed(error));
    }
  };
};

const onAddTeamsArrestSuccess = (): TeamManagementActionTypes => {
  StatusAlert(i18next.t('main:alert.success.save', 'บันทึกสำเร็จ'), AlertStatus.Success);
  return {
    type: TeamManagementAction.ADD_ARREST_TEAMS_SUCCESS,
  };
};

const onAddTeamsArrestFailed = (error: Error): TeamManagementActionTypes => {
  StatusAlert(error.message, AlertStatus.Error);

  return {
    type: TeamManagementAction.ADD_ARREST_TEAMS_FAILED,
    error,
  };
};
// POST ArrestTeams
export const onAddTeamsSequester = (userId: ITeamManagementForm, cb?: Function) => {
  return async (dispatch: (arg0: TeamManagementActionTypes) => void) => {
    dispatch({ type: TeamManagementAction.ADD_SEQUESTER_TEAMS_REQUEST });
    try {
      await axios.post('/officerHeader/headConfiscate', JSON.stringify(userId), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(onAddTeamsSequesterSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(onAddTeamsSequesterFailed(error));
    }
  };
};

const onAddTeamsSequesterSuccess = (): TeamManagementActionTypes => {
  StatusAlert(i18next.t('main:alert.success.save', 'บันทึกสำเร็จ'), AlertStatus.Success);
  return {
    type: TeamManagementAction.ADD_SEQUESTER_TEAMS_SUCCESS,
  };
};

const onAddTeamsSequesterFailed = (error: Error): TeamManagementActionTypes => {
  StatusAlert(error.message, AlertStatus.Error);

  return {
    type: TeamManagementAction.ADD_SEQUESTER_TEAMS_FAILED,
    error,
  };
};

// DELETE /OfficerHeaderArrest/{id}
export const deleteOfficerHeader = (id: string, cb?: Function) => {
  return async (dispatch: (arg0: TeamManagementActionTypes) => void) => {
    dispatch({ type: TeamManagementAction.DELETE_OFFICERHEADERS_REQUEST });

    try {
      await axios.delete(`/officerHeader/headArrest/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(deleteOfficerHeaderSuccess());

      if (cb) cb();
    } catch (error) {
      dispatch(deleteOfficerHeaderFailed(error));
    }
  };
};

const deleteOfficerHeaderSuccess = (): TeamManagementActionTypes => {
  StatusAlert(i18next.t('main:alert.success.delete', 'ลบข้อมูลสำเร็จ'), AlertStatus.Success);

  return {
    type: TeamManagementAction.DELETE_OFFICERHEADERS_SUCCESS,
  };
};

const deleteOfficerHeaderFailed = (error: Error): TeamManagementActionTypes => {
  StatusAlert(error.message, AlertStatus.Error);

  return {
    type: TeamManagementAction.DELETE_OFFICERHEADERS_FAILED,
    error,
  };
};

// DELETE /OfficerHeaderConfiscate/{id}
export const deleteOfficerHeaderConfiscate = (id: string, cb?: Function) => {
  return async (dispatch: (arg0: TeamManagementActionTypes) => void) => {
    dispatch({ type: TeamManagementAction.DELETE_OFFICERHEADERS_REQUEST });

    try {
      await axios.delete(`/officerHeader/headConfiscate/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(deleteOfficerHeaderConfiscateSuccess());

      if (cb) cb();
    } catch (error) {
      dispatch(deleteOfficerHeaderConfiscateFailed(error));
    }
  };
};

const deleteOfficerHeaderConfiscateSuccess = (): TeamManagementActionTypes => {
  StatusAlert(i18next.t('main:alert.success.delete', 'ลบข้อมูลสำเร็จ'), AlertStatus.Success);

  return {
    type: TeamManagementAction.DELETE_OFFICERHEADERS_SUCCESS,
  };
};

const deleteOfficerHeaderConfiscateFailed = (error: Error): TeamManagementActionTypes => {
  StatusAlert(error.message, AlertStatus.Error);

  return {
    type: TeamManagementAction.DELETE_OFFICERHEADERS_FAILED,
    error,
  };
};
