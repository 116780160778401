import { IEditUserProfileForm } from 'shared/modules/user-profile';

export enum EUserProfileAction {
  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',
  EDIT_USER_PROFILE_REQUEST = 'EDIT_USER_PROFILE_REQUEST',
  EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS',
  EDIT_USER_PROFILE_FAILED = 'EDIT_USER_PROFILE_FAILED',
  CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE',
}

interface IGetUserInfoRequest {
  type: EUserProfileAction.GET_USER_INFO_REQUEST;
}

interface IGetUserInfoSuccess {
  type: EUserProfileAction.GET_USER_INFO_SUCCESS;
  data: IEditUserProfileForm;
}

interface IGetUserInfoFailed {
  type: EUserProfileAction.GET_USER_INFO_FAILED;
  error: Error;
}

interface IEditUserProfileRequest {
  type: EUserProfileAction.EDIT_USER_PROFILE_REQUEST;
}

interface IEditUserProfileSuccess {
  type: EUserProfileAction.EDIT_USER_PROFILE_SUCCESS;
}

interface IEditUserProfileFailed {
  type: EUserProfileAction.EDIT_USER_PROFILE_FAILED;
  error: Error;
}

interface IClearUserProfile {
  type: EUserProfileAction.CLEAR_USER_PROFILE;
}

export type TUserProfileActionTypes =
  | IGetUserInfoRequest
  | IGetUserInfoSuccess
  | IGetUserInfoFailed
  | IEditUserProfileRequest
  | IEditUserProfileSuccess
  | IEditUserProfileFailed
  | IClearUserProfile;
