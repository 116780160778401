import { useFetchApi } from 'shared/utils/hooks';
import IDropdown from 'shared/interfaces/dropdown.interface';
import { SelectOption } from 'components/ui/type';

const useCurrencyTypeDropdownApi = () => {
  const [cuurencyTypes, isLoading, isError] = useFetchApi<IDropdown[]>('/currencyType/getDropdownList', []);

  const currencyTypeSelectOptions: SelectOption[] = cuurencyTypes.map((currencyType) => {
    return {
      text: currencyType.text,
      value: currencyType.id,
    };
  });
  return [currencyTypeSelectOptions, isLoading, isError] as const;
};

export default useCurrencyTypeDropdownApi;
