import { INITIAL_GOOGLE_MAPS_STATE } from 'shared/constant';
import { IGoogleMapsState } from 'shared/interfaces/google-maps.interface';
import { EGoogleMapsAction, TGoogleMapsActionTypes } from './google-maps.type';

export default (
  state: IGoogleMapsState = { ...INITIAL_GOOGLE_MAPS_STATE },
  action: TGoogleMapsActionTypes
): IGoogleMapsState => {
  switch (action.type) {
    case EGoogleMapsAction.GET_PLACE_NAME_REQUEST:
      return {
        ...state,
        isPlaceLoading: true,
      };
    case EGoogleMapsAction.GET_PLACE_NAME_SUCCESS:
      return {
        ...state,
        placeName: action.placeName,
        isPlaceLoading: false,
      };
    case EGoogleMapsAction.GET_PLACE_NAME_FAILED:
      return {
        ...state,
        isPlaceLoading: false,
      };
    // ;; get place nearby
    case EGoogleMapsAction.GET_PLACE_NEARBY_REQUEST:
      return {
        ...state,
        isPlaceLoading: true,
      };
    case EGoogleMapsAction.GET_PLACE_NEARBY_SUCCESS:
      return {
        ...state,
        isPlaceLoading: false,
        placeNearby: [],
      };
    case EGoogleMapsAction.GET_PLACE_NEARBY_FAILED:
      return {
        ...state,
        isPlaceLoading: false,
      };
    default:
      return { ...state };
  }
};
