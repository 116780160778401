import moment, { Moment } from 'moment';
import { EDateFormat } from 'shared/enum/date-format.enum';

export const dateThaiFormat = (date: number | string | Date | Moment | undefined | null, format: EDateFormat) => {
  if (date) {
    if (moment.locale() === 'th') return moment(date).add(543, 'year').format(format);
    else return moment(date).format(format);
  } else {
    return '';
  }
};

export const getTime = (date: number | string | Date | Moment | undefined | null, format: EDateFormat) => {
  if (!date) {
    return '';
  }

  return moment(date).format(format);
};
