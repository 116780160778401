import React, { FC, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputTemplateLabel from './InputTemplateLabel';
import { InputSize, InputVariant } from './enum';
import InputLabelTemplate from './InputTemplateLabel';
import { Grid } from '@material-ui/core';
import { MediumText } from '.';
import constants from 'shared/constants';

const InputBody = styled(TextField)`
  width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
  flex: 6;
`;

const InputError = styled(MediumText)`
  height: 24px;
  color: ${constants.RED};
  white-space: nowrap;
`;

export interface IInputLabelProps {
  label?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  info?: string;
  variant?: InputVariant;
  size?: InputSize;
  disabled?: boolean;
  value?: string | null;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onClick?: any;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  type?: string;
  maxLength?: number;
  isMultiline?: boolean;
  inputComponent?: any;
  required?: boolean;
  onKeyPress?(event: KeyboardEvent<HTMLInputElement>): void;
}

const InputLabel: FC<IInputLabelProps> = ({
  label,
  placeholder,
  className,
  error,
  info,
  size = InputSize.SMALL,
  variant = InputVariant.OUTLINED,
  disabled,
  value,
  onChange,
  onClick,
  onBlur,
  type,
  maxLength = 255,
  isMultiline,
  inputComponent,
  required,
  onKeyPress,
}) => {
  return (
    <>
      <Grid container>
        <Grid xs={4}>
          <InputLabelTemplate
            className={className}
            label={label}
            info={info}
            // error={error}
            required={required}
          />
        </Grid>
        <Grid xs={8}>
          <InputBody
            error={error ? true : false}
            placeholder={placeholder}
            disabled={disabled}
            size={size}
            variant={variant}
            value={value ? value : ''}
            onChange={onChange}
            onClick={onClick}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            type={type}
            inputProps={{
              maxLength: maxLength,
            }}
            multiline={isMultiline}
            InputProps={{
              inputComponent: inputComponent,
            }}
          />
          <InputError className="InputError">{error}</InputError>
        </Grid>
      </Grid>
    </>
  );
};

export default InputLabel;
