import { TableHead as Head, TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import React, { FC } from 'react';
import constants from 'shared/constants';
import { EDataTableLayout } from 'shared/enum/data-table.enum';
import styled from 'styled-components';
import { LargeText } from '..';
import { TTableColumn } from '../type';
import TableContext from './TableContext';

const TableHeaderRow = styled(TableRow)<{ disabled?: boolean }>`
  ${(props) => (props.disabled ? `background-color: ${constants.GREY}` : `background-color: ${constants.LIGHT_BLUE}`)};
  position: sticky;
  top: 0; /* Stick the header to the top of the table */
  z-index: 1;
  .MuiTableCell-root {
    border: 1.8px solid #e6e6e6; /* Add border to each cell */
  }
`;
const TableHeaderColumn = styled(TableCell)`
  text-align: left !important;
  .MuiTableCell-root {
    padding: 5px 5px 5px 0px;
  }
  span {
    width: max-content;
  }
  ${(props) => (props.width ? `width: ${props.width}` : ``)};
`;
const TableHeaderText = styled(LargeText)`
  font-weight: bold;
  /* width: max-content; */
  text-align: center;

  @media (max-width: 1480px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const TableHeaderOrder = styled(TableSortLabel)``;

const TableHeader: FC = () => (
  <TableContext.Consumer>
    {({
      columns,
      fixCheckbox,
      disabled,
      onGraph,
      onDownload,
      onUpload,
      onFile,
      onView,
      onEdit,
      onDelete,
      onGraphDS,
      onMobile,
      onBank,
      onItem,
      onCheckBox,
      onViewForm,
      onEditForm,
      onModal,
      onSwitch,
      hideHeader,
      hideOnEditForm,
      order = 'asc',
      orderBy,
      setOrder,
      setOrderBy,
      onChangeOrder,
    }) => (
      <Head>
        {!hideHeader && (
          <TableHeaderRow disabled={disabled}>
            {onCheckBox && (
              <TableHeaderColumn width={EDataTableLayout.WIDTHSMALL}>
                <TableHeaderText>{}</TableHeaderText>
              </TableHeaderColumn>
            )}
            {columns.map((column: TTableColumn) => (
              <TableHeaderColumn key={column.key} align={column.align} width={column.width}>
                {column.key && orderBy && order && onChangeOrder && (
                  <TableHeaderOrder
                    active={orderBy === column.key}
                    direction={orderBy === column.key ? order : 'asc'}
                    onClick={() => onChangeOrder(column.key)}
                  >
                    <TableHeaderText>{column.title}</TableHeaderText>
                  </TableHeaderOrder>
                )}
                {!(orderBy && order) && <TableHeaderText>{column.title}</TableHeaderText>}
              </TableHeaderColumn>
            ))}
            {/* {(onGraph ||
              onDownload ||
              onUpload ||
              onFile ||
              onView ||
              onEdit ||
              onDelete ||
              onGraphDS ||
              onMobile ||
              onBank ||
              onItem ||
              onCheckBox ||
              onViewForm ||
              onEditForm ||
              onModal ||
              onSwitch) && (
              <TableHeaderColumn width={EDataTableLayout.WIDTHHEADER}>
                <TableHeaderText>{'จัดการ'}</TableHeaderText>
              </TableHeaderColumn>
            )} */}
          </TableHeaderRow>
        )}
      </Head>
    )}
  </TableContext.Consumer>
);

export default TableHeader;
