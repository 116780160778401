import { AlertMessageWithHeader } from 'components/ui';
import { SelectOption } from 'components/ui/type';
import i18next from 'i18next';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ELocalStorageDropdown } from 'shared/enum/local-storage.type';
import { IDropdown } from 'shared/interfaces';
import { TSelect } from 'shared/types';
import { axiosWithCache } from 'shared/utils/axios';

export interface IRelationalDropdown {
  headQuarterID: TSelect;
  divisionID: TSelect;
  subDivisionID: TSelect;
  departmentID: TSelect;
  organizationID: TSelect;
  policeStationID: TSelect;
}

interface IDropdownCode extends IDropdown {
  codeNo: string;
}

const resolveToSelectOptions = (value: IDropdownCode[]): SelectOption[] => {
  return value.map((v: IDropdownCode, index) => ({
    text: v.text,
    // text: v.text + '-' + v.id + '-' + index + '-' + v.codeNo,
    value: v.id,
    code: v.codeNo,
  }));
};

const useFetchRelationDropdown = (relationDropdown: IRelationalDropdown) => {
  const [headQuarters, setHeadQuarters] = useState<SelectOption[]>([]);
  const [divisions, setDivisions] = useState<SelectOption[]>([]);
  const [subDivisions, setSubDivisions] = useState<SelectOption[]>([]);
  const [departments, setDepartments] = useState<SelectOption[]>([]);
  const [policeStations, setPoliceStations] = useState<SelectOption[]>([]);
  const [organizations, setOrganizations] = useState<SelectOption[]>([]);

  const [isHeadQuarterLoading, setIsHeadQuarterLoading] = useState<boolean>(true);
  const [isDivisionLoading, setIsDivisionLoading] = useState<boolean>(true);
  const [isSubDivisionLoading, setIsSubDivisionLoading] = useState<boolean>(true);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState<boolean>(true);
  const [isPoliceStationLoading, setIsPoliceStationLoading] = useState<boolean>(true);
  const [isOrganizationsLoading, setIOrganizationsLoading] = useState<boolean>(true);

  const fetchOrganizations = useCallback(async (): Promise<void> => {
    try {
      const dropdown = reactLocalStorage.getObject(ELocalStorageDropdown.DROPDOWN_ORGANIZATION, []);
      setIOrganizationsLoading(true);
      const token = localStorage.getItem('token');

      if (_.isEmpty(dropdown)) {
        const response = await axiosWithCache.get('/master/organization/getDropdownList', {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });
        reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_ORGANIZATION, resolveToSelectOptions(response.data));
        setOrganizations(resolveToSelectOptions(response.data));
        setIOrganizationsLoading(false);
      } else {
        setOrganizations(dropdown);
        setIOrganizationsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }, []);

  const fetchHeadQuarters = useCallback(async (): Promise<void> => {
    // กองบัญชาการ
    try {
      setIsHeadQuarterLoading(true);
      const token = localStorage.getItem('token');

      const response = await axiosWithCache.get('/master/agency/getDropdownList', {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      });
      // const response = await axiosWithCache.get('/Master/Agency/GetDropdownList', {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('token')}`,
      //   },
      // });
      reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_HEADQUARTER, resolveToSelectOptions(response.data));
      setHeadQuarters(resolveToSelectOptions(response.data));
      setIsHeadQuarterLoading(false);
    } catch (error) {
      throw error;
    }
  }, []);

  const fetchDivisions = useCallback(async (): Promise<void> => {
    // กองบังคับการ
    try {
      if (!relationDropdown.headQuarterID) {
        setDivisions([]);
        setIsDivisionLoading(false);

        return;
      }

      setIsDivisionLoading(true);
      const token = localStorage.getItem('token');

      const response = await axiosWithCache.get(
        `/master/agency/${relationDropdown.headQuarterID}/sector/getDropdownList`,
        {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        }
      );
      // const response = await axiosWithCache.get(
      //   `/Master/Agency/${relationDropdown.headQuarterID}/Sector/GetDropdownList`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem('token')}`,
      //     },
      //   }
      // );

      reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_DIVISION, resolveToSelectOptions(response.data));
      setDivisions(resolveToSelectOptions(response.data));
      setIsDivisionLoading(false);
    } catch (error) {
      throw error;
    }
  }, [relationDropdown.headQuarterID]);

  const fetchSubDivisions = useCallback(async (): Promise<void> => {
    try {
      if (!relationDropdown.divisionID) {
        setSubDivisions([]);
        setIsSubDivisionLoading(false);

        return;
      }

      setIsSubDivisionLoading(true);

      const response = await axiosWithCache.get(
        `/subDivision/getDropdownList?divisionId=${relationDropdown.divisionID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      setSubDivisions(resolveToSelectOptions(response.data));
      setIsSubDivisionLoading(false);
    } catch (error) {
      throw error;
    }
  }, [relationDropdown.divisionID]);

  const fetchDepartments = useCallback(async (): Promise<void> => {
    try {
      if (!relationDropdown.subDivisionID) {
        setDepartments([]);
        setIsDepartmentLoading(false);

        return;
      }

      setIsDepartmentLoading(true);

      const response = await axiosWithCache.get(
        `/department/getDropdownList?subDivisionId=${relationDropdown.subDivisionID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      setDepartments(resolveToSelectOptions(response.data));
      setIsDepartmentLoading(false);
    } catch (error) {
      throw error;
    }
  }, [relationDropdown.subDivisionID]);

  const fetchPoliceStations = useCallback(async (): Promise<void> => {
    // สถานีตำรวจ กองกำกับการ
    try {
      // if (!relationDropdown.departmentID) {
      //   setPoliceStations([]);
      //   setIsPoliceStationLoading(false);

      //   return;
      // }
      if (!relationDropdown.divisionID) {
        // if (!relationDropdown.divisionID || _.isNil(relationDropdown.divisionID)) {
        setPoliceStations([]);
        setIsPoliceStationLoading(false);
        return;
      }

      setIsPoliceStationLoading(true);

      // const response = await axiosWithCache.get(
      //   `/policeStation/getDropdownList?departmentId=${relationDropdown.departmentID}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem('token')}`,
      //     },
      //   }
      // );
      const token = localStorage.getItem('token');

      const response = await axiosWithCache.get(
        `/master/sector/${relationDropdown.divisionID}/department/getDropdownList`,
        {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        }
      );
      // const response = await axiosWithCache.get(
      //   `/Master/Sector/${relationDropdown.divisionID}/Department/GetDropdownList`,
      //   // `/policeStation/getDropdownList?departmentId=${relationDropdown.divisionID}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem('token')}`,
      //     },
      //   }
      // );

      reactLocalStorage.setObject(ELocalStorageDropdown.DROPDOWN_POLICESTATION, resolveToSelectOptions(response.data));
      setPoliceStations(resolveToSelectOptions(response.data));
      setIsPoliceStationLoading(false);
    } catch (error) {
      throw error;
    }
  }, [relationDropdown.divisionID]);

  useEffect(() => {
    const fetchAllDropdown = async () => {
      try {
        await Promise.all([
          fetchHeadQuarters(),
          fetchDivisions(),
          fetchSubDivisions(),
          fetchDepartments(),
          fetchPoliceStations(),
          fetchOrganizations(),
        ]);
      } catch (error) {
        AlertMessageWithHeader(i18next.t('hooks.header.alert.error', 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง'), error.message);
      }
    };

    fetchAllDropdown();
  }, [fetchDepartments, fetchDivisions, fetchHeadQuarters, fetchPoliceStations, fetchSubDivisions, fetchOrganizations]);

  return [
    { headQuarters, divisions, subDivisions, departments, policeStations, organizations },
    isHeadQuarterLoading ||
      isDivisionLoading ||
      isSubDivisionLoading ||
      isDepartmentLoading ||
      isPoliceStationLoading ||
      isOrganizationsLoading,
  ] as const;
};

export default useFetchRelationDropdown;
