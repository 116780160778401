import { INITIAL_ROLESETTING_STATE, IRoleSettingState } from 'shared/modules/role-setting';
import { ERoleSetting, TRoleSetting } from './role-setting.type';

export default (state: IRoleSettingState = INITIAL_ROLESETTING_STATE, action: TRoleSetting): IRoleSettingState => {
  switch (action.type) {
    case ERoleSetting.GET_REQUEST:
      return {
        ...state,
        // isPageLoading: true,
        isTableLoading: true,
        isButtonLoading: true,
      };
    case ERoleSetting.GET_SUCCESS:
      return {
        ...state,
        all: action.data,
        // isPageLoading: false,
        isTableLoading: false,
        isButtonLoading: false,
      };
    case ERoleSetting.GET_FAILED:
      return {
        ...state,
        error: action.error,
        // isPageLoading: false,
        isTableLoading: false,
        isButtonLoading: false,
      };
    case ERoleSetting.CREATE_ROLE_SETTING_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isButtonLoading: true,
      };
    case ERoleSetting.CREATE_ROLE_SETTING_SUCCESS:
      return {
        ...state,
        // all: action.data,
        isPageLoading: false,
        isButtonLoading: false,
      };
    case ERoleSetting.CREATE_ROLE_SETTING_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
        isButtonLoading: false,
      };
    default:
      return state;
  }
};
